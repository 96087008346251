import React, {useCallback, useEffect, useRef, useState} from "react"
import {DEFAULT_OPERATING_HOURS, useDataContext} from "../hooks/context"
import {formatVisibility, TabIds, VisibilityOptions, ZoneType} from "../consts"
import {ErrorToast} from "../../../shared/utils/toast"
import {AttributeSort} from "../../../utils/sort"
import {AddTextButton, Button, CloseButton} from "../../../shared/components/buttons"
import {ListView} from "../../../shared/components/list"
import {CreatableSelect, MultiSegmentSelect, Select} from "../../../shared/components/select"
import {Slider} from "../../../shared/components/slider"
import {DEBOUNCE_DURATION} from "../../../shared/utils/const"
import {TitleView} from "../shared/titleview"
import {DescriptionView} from "../shared/descriptionview"
import {AttributeItem, formatResource, MultiRowSelector, StringArrayModal, TitledAttributes} from "../shared/components"
import Warehouse from "../../../data/warehouse"
import {EntryInput, EntryModal, EntryResource} from "../../../shared/components/modal"
import {convertMinutesToRawTime, convertOperationalHoursToDisplay, convertTimeToMinutes, WEEKDAY_DATA} from "../../../shared/utils/date/manager"
import {arrayEquals} from "../../../shared/utils/array"
import {TextButtons} from "../shared/text_buttons";

export function ZoneDetailView() {
  const {selectedTab, selectedZone, isCreatingZone, updateZone, deleteZone} = useDataContext()

  const handleOnUpdate = useCallback(values => {
    updateZone(values)
  }, [updateZone])

  const handleOnDelete = useCallback((callback) => {
    deleteZone && deleteZone(callback)
  }, [deleteZone])

  if (selectedTab !== TabIds.LAYOUT || !selectedZone) {
    return null
  }

  return (
    <div className="wireless-config-detail">
      <TitleView
        isCreating={isCreatingZone}
        resource={selectedZone}
        placeholder={`${getDefaultNameForZoneType(selectedZone?.type)} Name`}
        onUpdate={handleOnUpdate}
        onDelete={handleOnDelete}
      />
      <ListView>
        <DescriptionView
          isCreating={isCreatingZone}
          resource={selectedZone}
          onUpdate={handleOnUpdate}
        />
        <BaseAttributes/>
        <LayoutAttributes/>
        <SquareFootage/>
        <Capacity/>
        <Utilization/>
        <Maintenance/>
      </ListView>
      <Buttons/>
    </div>
  )
}

const getDefaultNameForZoneType = zoneType => {
  switch (zoneType || ZoneType.DEFAULT) {
    case ZoneType.CAMPUS:
      return "Campus"

    case ZoneType.BUILDING:
      return "Building"

    default:
      return "Zone"
  }
}

const DEFAULT_ZONE_TYPE_OPTIONS = new Set([ZoneType.BUILDING, ZoneType.FLOOR])

function BaseAttributes() {
  const {schoolId, selectedZone: zone, zoneTypes, updateZone} = useDataContext()

  const [showFloorPicker, setShowFloorPicker] = useState(false)
  const [showRoomPicker, setShowRoomPicker] = useState(false)

  const isEdit = zone.id !== null

  const zoneType = zone.type != null ? ({label: zone.type, value: zone.type}) : null
  let zoneTypeOptions = zoneTypes.filter(t => zoneType?.value !== t && t !== ZoneType.SCHOOL)
    .map(t => ({label: t, value: t}))

  const usages = Warehouse.wireless_config.getUsages()
  const primaryUsage = zone.getPrimaryUsage()
  let primaryUsageOptions
  if (primaryUsage) {
    primaryUsageOptions = usages.getAll().filter(u => u.id !== primaryUsage.id)
  } else {
    primaryUsageOptions = usages.getAll()
  }
  primaryUsageOptions = primaryUsageOptions.map(formatUsage)
    .sort(AttributeSort("label"))

  const secondaryUsage = zone.getSecondaryUsage()
  const isSecondaryDisabled = primaryUsage == null || !usages.getById(primaryUsage.id)?.secondary_usages.length
  let secondaryUsageOptions = []
  if (!isSecondaryDisabled) {
    if (secondaryUsage) {
      secondaryUsageOptions = primaryUsage.secondary_usages
        .filter(u => u.id !== secondaryUsage.id)
    } else {
      secondaryUsageOptions = primaryUsage.secondary_usages
    }
  }
  secondaryUsageOptions = secondaryUsageOptions.map(formatUsage)
    .sort(AttributeSort("label"))

  const parentZone = zone.hasParent() && zone.getParent()?.id !== schoolId ? zone.getParent() : null
  const fullParentZone = zone.getParent()

  const skipZoneIds = new Set([zone.id, ...zone.subZoneIter().map(s => s.id)])
  let parentZoneOptions
  if (zone.type === ZoneType.BUILDING) {
    parentZoneOptions = Warehouse.wireless_config.getZones().filter(p => !skipZoneIds.has(p.id) && p.type === ZoneType.CAMPUS)
  } else if (zone.getBuilding()?.id) {
    parentZoneOptions = Warehouse.wireless_config.getZones().filter(p => !skipZoneIds.has(p.id) && p.getBuilding()?.id === zone.getBuilding()?.id)
  } else {
    parentZoneOptions = Warehouse.wireless_config.getZones().filter(p => !skipZoneIds.has(p.id))
  }

  if (zone.type === ZoneType.DEFAULT) {
    const primary = []
    const base = []
    const other = []

    for (let p of parentZoneOptions) {
      if (p.id === schoolId) {
        continue
      }

      if (DEFAULT_ZONE_TYPE_OPTIONS.has(p.type)) {
        if (p.floors.filter(f => zone.floors.includes(f)).length > 0) {
          primary.push(p)
        } else {
          base.push(p)
        }
      } else {
        other.push(p)
      }
    }

    parentZoneOptions = [
      ...primary.sort(AttributeSort("name")),
      ...base.sort(AttributeSort("name")),
      ...other.sort(AttributeSort("name"))
    ]
  } else {
    parentZoneOptions = parentZoneOptions.filter(o => o.id !== schoolId)
      .sort(AttributeSort("name"))
  }

  parentZoneOptions = parentZoneOptions.map(formatResource)

  if (!zone?.hasParent() || zone.getParent()?.id !== schoolId) {
    parentZoneOptions = [{label: "None", value: schoolId}, ...parentZoneOptions]
  }

  const building = zone?.getBuilding()
  let buildingOptions
  if (fullParentZone?.getBuilding()) {
    buildingOptions = [fullParentZone.getBuilding()]
  } else {
    buildingOptions = Warehouse.wireless_config.getBuildings().getAll().slice()
  }

  if (building) {
    buildingOptions = buildingOptions.filter(u => u.id !== building.id)
  }

  buildingOptions = buildingOptions.map(formatResource)
    .sort(AttributeSort("label"))

  let visibilityValue = null
  let visibilityOptions = VisibilityOptions.slice()
  if (zone.visibility_option != null) {
    visibilityValue = formatVisibility(VisibilityOptions.find(o => o.id === zone.visibility_option))
    visibilityOptions = visibilityOptions.filter(o => o.id !== zone.visibility_option)
  }

  visibilityOptions = visibilityOptions.map(formatVisibility)

  const isFloorSingle = zone?.getFloorZone() != null

  return (
    <TitledAttributes>
      <AttributeItem label="BUILDING">
        <Select
          placeholder="--"
          value={formatResource(building)}
          options={buildingOptions}
          isSearchable
          onChange={newBuilding => {
            updateZone({
              body: {
                building_id: newBuilding.value.id,
              },
              onError: () => {
                ErrorToast("Unable to assign Building")
              }
            })
          }}
        />
      </AttributeItem>

      <AttributeItem label="PARENT">
        <Select
          placeholder="--"
          value={formatResource(parentZone)}
          options={parentZoneOptions}
          isSearchable
          onChange={newParent => {
            updateZone({
              body: {parent_id: newParent.value.id},
              onError: () => {
                ErrorToast("Unable to assign Parent Zone")
              }
            })
          }}/>
      </AttributeItem>

      <AttributeItem label="TYPE">
        <Select
          placeholder="--"
          value={zoneType}
          options={zoneTypeOptions}
          isSearchable
          onChange={newType => {
            updateZone({
              body: {type: newType.value},
              onError: () => {
                ErrorToast("Unable to set the Type")
              }
            })
          }}/>
      </AttributeItem>

      <BuildingAliases
        zone={zone}
        onChange={newAliases => {
          if (!newAliases.includes(zone.name)) {
            ErrorToast("Building name must remain in the alias list")
            return
          }

          updateZone({
            body: {aliases: newAliases},
            onError: () => ErrorToast("Unable to set the Aliases")
          })
        }}
      />

      <BuildingOperatingHours
        zone={zone}
        onChange={(newHours, callback) => {
          updateZone({
            body: {operating_hours: newHours},
            onSuccess: callback,
            onError: () => ErrorToast("Unable to update the Operating Hours")
          })
        }}
      />

      <AttributeItem label="PRIMARY USAGE">
        <Select
          placeholder="--"
          value={formatUsage(primaryUsage)}
          options={primaryUsageOptions}
          isSearchable
          onChange={newPrimaryUsage => {
            updateZone({
              body: {
                primary_usage_id: newPrimaryUsage.value,
                secondary_usage_id: null
              },
              onError: () => {
                ErrorToast("Unable to update the Primary Usage")
              }
            })
          }}/>
      </AttributeItem>

      <AttributeItem label="SECONDARY USAGE" disabled={isSecondaryDisabled}>
        <Select
          placeholder="--"
          value={formatUsage(secondaryUsage)}
          options={secondaryUsageOptions}
          isSearchable
          disabled={isSecondaryDisabled}
          onChange={newSecondaryUsage => {
            updateZone({
              body: {
                secondary_usage_id: newSecondaryUsage.value
              },
              onError: () => {
                ErrorToast("Unable to update the Secondary Usage")
              }
            })
          }}/>
      </AttributeItem>

      <AttributeItem label={isFloorSingle ? "FLOOR" : "FLOORS"}>
        <MultiRowSelector label="FLOORS" values={zone.floors} showing={showFloorPicker} onShowPicker={() => setShowFloorPicker(true)}/>
        <StringArrayModal
          show={showFloorPicker}
          modelKey={isFloorSingle ? "Floor" : "Floors"}
          isSingle={isFloorSingle}
          isEdit={isEdit}
          values={zone.floors}
          onHide={() => setShowFloorPicker(false)}
          onSave={(floors) => {
            updateZone({
              body: {floors},
              onSuccess: () => setShowFloorPicker(false),
              onError: () => {
                ErrorToast(`Unable to update ${isFloorSingle ? "Floor" : "Floors"}`)
              }
            })
          }}
          validator={value => {
            if (value === "-") return true
            return value.length === parseInt(value).toString().length
          }}
        />
      </AttributeItem>

      <AttributeItem label="ROOMS">
        <MultiRowSelector label="ROOMS" values={zone.rooms} showing={showRoomPicker} onShowPicker={() => setShowRoomPicker(true)}/>
        <StringArrayModal show={showRoomPicker} modelKey="Room" isEdit={isEdit} values={zone.rooms} onHide={() => setShowRoomPicker(false)} onSave={(rooms) => {
          updateZone({
            body: {rooms},
            onSuccess: () => setShowRoomPicker(false),
            onError: () => {
              ErrorToast("Unable to update Rooms")
            }
          })
        }}/>
      </AttributeItem>

      <AttributeItem label="VISBILITY">
        <Select
          placeholder="--"
          value={visibilityValue}
          options={visibilityOptions}
          onChange={newOption => {
            updateZone({
              body: {
                visibility_option: newOption.value
              },
              onError: () => {
                ErrorToast("Unable to update Visibility Option")
              }
            })
          }}/>
      </AttributeItem>
    </TitledAttributes>
  )
}

const canSeeZoneInherit = zone => {
  switch (zone.type) {
    case ZoneType.FLOOR:
    case ZoneType.DEFAULT:
      return true

    default:
      return false
  }
}

function LayoutAttributes() {
  const {selectedZone, updateZone} = useDataContext()

  const inheritGeoJSON = !!selectedZone.inherit_geojson

  return (
    <TitledAttributes title="Layout">
      {canSeeZoneInherit(selectedZone) &&
        <AttributeItem label="INHERIT">
          <Slider
            checked={inheritGeoJSON}
            onChecked={() => {
              updateZone({
                body: {inherit_geojson: !inheritGeoJSON},
                onError: () => {
                  ErrorToast("Unable to update the Inherit Option")
                }
              })
            }}/>
        </AttributeItem>
      }
    </TitledAttributes>
  )
}

function BuildingAliases({zone, onChange}) {
  if (!zone?.isBuildingZone()) {
    return null
  }

  const formatAlias = alias => {
    return {label: alias, value: alias}
  }

  return (
    <AttributeItem label="ALIASES">
      <CreatableSelect
        placeholder="--"
        disabled={!zone.hasBuilding()}
        isMulti={true}
        isSearchable={true}
        value={zone.getAliases() != null ? zone.getAliases().map(formatAlias) : []}
        onChange={newAliases => onChange && onChange(newAliases.map(a => a.value))}
      />
    </AttributeItem>
  )
}

function BuildingOperatingHours({zone, onChange}) {
  const show = !zone?.isBuildingZone()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(false)
  }, [zone?.id, show])

  if (!zone?.isBuildingZone()) {
    return null
  }

  return (
    <>
      <AttributeItem label="OPERATING HOURS">
        <div className="column-flex justify-content-center gap-100 building-operating-hours" onClick={() => setShowModal(true)}>
          {!zone.hasOperatingHours && <span>--</span>}
          {zone.hasOperatingHours && zone.getOperatingHours().map((hours, i) => <span key={i}>{hours.label}</span>)}
        </div>
      </AttributeItem>
      <BuildingOperatingHoursModal
        show={showModal}
        onHide={() => setShowModal(false)}
        hours={zone.getOperatingHours().map(hours => hours.value)}
        onSave={newHours => {
          if (!onChange) {
            return
          }

          const values = newHours.flatMap(hour => hour.days.map(d => ({day_of_week: d, start_time: hour.start_time, end_time: hour.end_time})))
            .sort(AttributeSort("day_of_week", "start_time"))

          return onChange(values, () => setShowModal(false))
        }}
      />
    </>
  )
}

const isOperatingHourValid = operatingHour => {
  return operatingHour?.days?.length && operatingHour?.start_time != null && operatingHour?.end_time != null
}

const OperatingHoursOptions = Object.values(WEEKDAY_DATA)
  .sort(AttributeSort("value"))
  .map(d => ({id: d.value, label: d.shortName}))

function BuildingOperatingHoursModal({show, hours, onHide, onSave}) {
  const [operatingHours, setOperatingHours] = useState([])
  const scrollViewRef = useRef(null)

  useEffect(() => {
    setOperatingHours(() => {
      if (!show) {
        return []
      }

      if (!hours?.length) {
        return convertOperationalHoursToDisplay(DEFAULT_OPERATING_HOURS).map(v => v.value)
      }

      return hours
    })
  }, [show, hours])

  if (!show) {
    return
  }

  const handleOnHide = () => onHide && onHide()
  const handleOnSave = () => onSave && onSave(operatingHours)

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: handleOnHide
    },
    primary: {
      title: "Save",
      disabled: arrayEquals(hours, operatingHours) || operatingHours.length === 0 || operatingHours.map(isOperatingHourValid).filter(v => !v).length > 0,
      onClick: handleOnSave
    }
  }

  const handleAdd = () => {
    setOperatingHours(() => [...operatingHours, {days: [2, 3, 4, 5, 6], start_time: 540, end_time: 1020}])
    const getDiv = () => {
      const findDiv = (div, cssClass) => {
        if (!div?.children?.length) {
          return null
        }

        for (let child of div.children) {
          if (child.className === cssClass) {
            return child
          }
        }

        return null
      }

      let current = scrollViewRef.current
      for (let divClass of ["scroll-view-container", "scrollable"]) {
        current = findDiv(current, divClass)
        if (!current) {
          break
        }
      }

      return current
    }

    const scrollIntoView = () => {
      const scrollViewDiv = getDiv()
      if (!scrollViewDiv) {
        return
      }

      scrollViewDiv.lastElementChild?.scrollIntoView({behavior: 'smooth', block: 'end'})
    }

    setTimeout(scrollIntoView, 200)
  }

  return (
    <EntryModal show={show} title="Operation Hours" onHide={onHide} buttons={buttons}>
      <ListView ref={scrollViewRef} className="wireless-config-detail-attributes-operation-hours-modal" style={{height: operatingHours.length * 188}}>
        {operatingHours.map((hour, i) => <BuildingOperatingHoursOption
          key={i}
          data={hour}
          onUpdate={row => {
            setOperatingHours(old => {
              const newValues = []
              for (let index = 0; index < old.length; index++) {
                if (i === index) newValues.push(row)
                else newValues.push(old[index])
              }

              return newValues
            })
          }}
          onRemove={() => {
            setOperatingHours(old => old.filter((v, index) => i !== index))
          }}
        />)}
      </ListView>
      <AddTextButton onClick={handleAdd}>Add Operation Hours</AddTextButton>
    </EntryModal>
  )
}

function BuildingOperatingHoursOption({data, onUpdate, onRemove}) {
  const [selectedIds, setSelectedIds] = useState([])
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)

  useEffect(() => {
    setSelectedIds(() => data?.days || [])
    setStartTime(() => data?.start_time)
    setEndTime(() => data?.end_time)
  }, [data])

  const handleOnDaysChange = days => {
    setSelectedIds(() => days)
    handleOnChange(days, startTime, endTime)
  }

  const handleOnStartChange = newValue => {
    const newStartTime = convertTimeToMinutes(newValue)
    let newEndTime = endTime

    setStartTime(() => newStartTime)
    if (newStartTime != null && newEndTime != null && newStartTime >= newEndTime) {
      newEndTime = null
      setEndTime(newEndTime)
    }
    handleOnChange(selectedIds, newStartTime, newEndTime)
  }

  const handleOnEndChange = newValue => {
    let newStartTime = startTime
    const newEndTime = convertTimeToMinutes(newValue)

    setEndTime(() => newEndTime)
    if (newStartTime != null && newEndTime != null && newEndTime <= newStartTime) {
      newStartTime = null
      setStartTime(newStartTime)
    }

    handleOnChange(selectedIds, newStartTime, newEndTime)
  }

  const handleOnChange = (newDays, newStartTime, newEndTime) => {
    if (arrayEquals(data?.days, newDays) && data?.start_time === newStartTime && data?.end_time === newEndTime) {
      return
    }

    onUpdate && onUpdate({days: newDays, start_time: newStartTime, end_time: newEndTime})
  }

  return (
    <div className="wireless-config-detail-attributes-operation-hours-modal-item">
      <CloseButton onClick={onRemove} style={{position: "absolute", width: 32, height: 32, top: 0, right: 0}}/>
      <EntryResource label="Days of Week">
        <MultiSegmentSelect className="primary" selectedIds={selectedIds} options={OperatingHoursOptions} onChange={handleOnDaysChange}/>
      </EntryResource>

      <div className="two-column-grid gap-200">
        <EntryInput label="Start Time" type="time" value={convertMinutesToRawTime(startTime)} onChange={handleOnStartChange}/>
        <EntryInput label="End Time" type="time" value={convertMinutesToRawTime(endTime)} onChange={handleOnEndChange}/>
      </div>
    </div>
  )
}

function SquareFootage() {
  const {selectedZone, updateZone} = useDataContext()

  return (
    <TitledAttributes title="Square Footage" variant="split">
      <AttributeItem label="Override" variant="vertical">
        <OverrideItem resourceId={selectedZone.id} value={selectedZone.square_footage?.override} qualifier="sq ft" onChange={value => {
          updateZone({
            body: {square_footage: value},
            onError: () => {
              ErrorToast("Unable to update the Override Square Footage")
            }
          })
        }}/>
      </AttributeItem>
      <AttributeItem label="Calculated" variant="vertical">
        <OverrideItem resourceId={selectedZone.id} value={selectedZone.square_footage?.calculated} qualifier="sq ft"/>
      </AttributeItem>
    </TitledAttributes>
  )
}

function Capacity() {
  const {selectedZone, updateZone} = useDataContext()

  return (
    <TitledAttributes title="Capacity" variant="split">
      <AttributeItem label="Override" variant="vertical">
        <OverrideItem resourceId={selectedZone.id} value={selectedZone.capacity?.override} onChange={value => {
          updateZone({
            body: {capacity: value},
            onError: () => {
              ErrorToast("Unable to update the Override Capacity")
            }
          })
        }}/>
      </AttributeItem>
      <AttributeItem label="Calculated" variant="vertical">
        <OverrideItem resourceId={selectedZone.id} value={selectedZone.capacity?.calculated}/>
      </AttributeItem>
    </TitledAttributes>
  )
}

function Utilization() {
  const {selectedZone, utilization, updateZone} = useDataContext()

  const value = selectedZone.utilization != null ? {label: selectedZone.utilization, value: selectedZone.utilization} : null
  const options = utilization.filter(value => value !== selectedZone.utilization)
    .map(value => ({label: value, value}))

  return (
    <TitledAttributes title="Utilization">
      <AttributeItem label="Minimum Occupancy Threshold">
        <Select
          placeholder="--"
          value={value}
          options={options}
          onChange={newUtilization => {
            updateZone({
              body: {utilization: newUtilization.value},
              onError: () => {
                ErrorToast("Unable to update the Minimum Utilization Occupancy Threshold")
              }
            })
          }}
        />
      </AttributeItem>
    </TitledAttributes>
  )
}

function MoneyInput({isCreating, value, onChange}) {
  const [inputValue, setInputValue] = useState("")
  const [hasChanged, setHasChanged] = useState(false)

  useEffect(() => {
    setInputValue(() => value != null ? value.toString() : "")
    setHasChanged(() => false)
  }, [value])

  const handleOnSave = useCallback(() => {
    if (!onChange) {
      return
    }

    const value = inputValue.length ? parseInt(inputValue) : null
    onChange(value)
  }, [inputValue, onChange])

  const handleOnCancel = useCallback(() => {
    setInputValue(() => value != null ? value.toString() : "")
    setHasChanged(() => false)
  }, [value])

  return (
    <div className="column-flex justify-content-center gap-200">
      <div className="row-flex align-items-center gap-100">
        {value !== "" && <span>$</span>}
        <input
          type="number"
          placeholder="--"
          value={inputValue}
          onChange={e => {
            let newValue = e.currentTarget.value.length ? parseInt(e.currentTarget.value).toString() : ""
            setInputValue(() => newValue)
            setHasChanged(() => newValue !== inputValue)

            if (isCreating && onChange) {
              const value = newValue.length ? parseInt(newValue) : null
              onChange(value)
            }
          }}
        />
      </div>
      <TextButtons
        show={hasChanged && !isCreating}
        onSave={handleOnSave}
        onCancel={handleOnCancel}
      />
    </div>
  )
}

function Maintenance() {
  const {isCreatingZone, selectedZone, updateZone} = useDataContext()
  const [yearly, setYearly] = useState("")
  const [deferred, setDeferred] = useState("")

  useEffect(() => {
    if (selectedZone?.maintenance?.yearly !== yearly) {
      setYearly(() => selectedZone?.maintenance?.yearly)
    }
  }, [selectedZone?.maintenance?.yearly, yearly])

  useEffect(() => {
    if (selectedZone?.maintenance?.deferred !== deferred) {
      setDeferred(() => selectedZone?.maintenance?.deferred)
    }
  }, [selectedZone?.maintenance?.deferred, deferred])

  if (selectedZone?.type !== ZoneType.BUILDING) {
    return
  }

  return (
    <TitledAttributes title="Maintenance">
      <AttributeItem label="Yearly Amount">
        <MoneyInput isCreating={isCreatingZone} value={yearly} onChange={value => {
          updateZone && updateZone({
            body: {yearly_maintenance_dollars: value},
            onError: () => {
              ErrorToast("Unable to update the Yearly Maintenance Amount")
            }
          })
        }}
        />
      </AttributeItem>
      <AttributeItem label="Deferred Amount">
        <MoneyInput isCreating={isCreatingZone} value={deferred} onChange={value => {
          updateZone && updateZone({
            body: {deferred_maintenance_dollars: value},
            onError: () => {
              ErrorToast("Unable to update the Deferred Maintenance Amount")
            }
          })
        }}
        />
      </AttributeItem>
    </TitledAttributes>
  )
}

function OverrideItem({resourceId, value, qualifier, onChange}) {
  const valueString = value != null ? value.toString() : ""
  const [text, setText] = useState("")
  const [cachedValue, setCachedValue] = useState("")

  useEffect(() => {
    setText(valueString)
    setCachedValue(valueString)
  }, [resourceId, valueString])

  useEffect(() => {
    if (!onChange || text === cachedValue) {
      return
    }

    const timeout = setTimeout(() => {
      if (text !== cachedValue) {
        onChange(!text.length ? null : parseInt(text))
        setCachedValue(text)
      }
    }, DEBOUNCE_DURATION)

    return () => {
      clearTimeout(timeout)
    }
  }, [text, cachedValue, onChange])

  const isEditable = onChange != null
  const hasQualifier = qualifier != null

  const handleOnChange = e => {
    const content = e.currentTarget.value.trim()
    if (!content.length) {
      setText("")
      return
    }

    const newValue = parseInt(content)
    if (newValue && Number.isSafeInteger(newValue)) {
      const value = newValue.toString()
      setText(value)
    }
  }

  return (
    <div className="wireless-config-detail-attributes-override-item">
      {isEditable && <input placeholder="--" value={text} onChange={handleOnChange}/>}
      {!isEditable && <span>{text || "--"}</span>}
      {hasQualifier && <span>{qualifier}</span>}
    </div>
  )
}

function Buttons() {
  const {isCreatingResource, selectedZone, draw, resetNewZone, saveNewZone} = useDataContext()

  const isSaveDisabled = useCallback(() => {
    if (!selectedZone?.name) {
      return true
    }

    if (selectedZone?.type === ZoneType.CAMPUS) {
      return false
    }

    if (selectedZone?.isBuildingZone() && !selectedZone.getAliases()?.length) {
      return true
    }

    return !draw.hasData
  }, [selectedZone, draw.hasData])

  if (!isCreatingResource) {
    return null
  }

  return (
    <div className="wireless-config-detail-buttons">
      <Button variant="secondary" onClick={resetNewZone}>Cancel</Button>
      <Button variant="primary" disabled={isSaveDisabled()} onClick={saveNewZone}>Save</Button>
    </div>
  )
}

const formatUsage = usage => {
  if (!usage) {
    return null
  }

  return {label: usage.display_name, value: usage.id}
}
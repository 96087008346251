export const TabIds = {
  LAYOUT: "layout",
  BEHAVIORS: "behaviors",
  EVENTS: "events",
  USAGES: "usages"
}

export const LayoutResourceType = {
  ZONE: "zone",
  AP: "ap"
}

const _TabOptions = [
  {
    id: TabIds.LAYOUT,
    label: "Layout",
  },
  {
    id: TabIds.BEHAVIORS,
    label: "Behaviors",
    hidden: true
  },
  {
    id: TabIds.EVENTS,
    label: "Events",
    hidden: true
  },
  {
    id: TabIds.USAGES,
    label: "Usages",
    hidden: true
  }
]

export const TabOptions = _TabOptions.filter(t => t.hidden == null || !t.hidden)

export const ZoneType = {
  SCHOOL: "School",
  CAMPUS: "Campus",
  BUILDING: "Building",
  FLOOR: "Floor",
  INDIVIDUAL: "Individual",
  DEFAULT: "Default"
}

export const getNextZoneType = zone => {
  if (!zone || !zone.type) {
    return ZoneType.DEFAULT
  }

  switch (zone.type) {
    case ZoneType.CAMPUS:
      return ZoneType.BUILDING

    case ZoneType.BUILDING:
      return ZoneType.FLOOR

    default:
      return ZoneType.DEFAULT
  }
}

export const DrawableZoneTypes = new Set([ZoneType.BUILDING, ZoneType.FLOOR, ZoneType.INDIVIDUAL, ZoneType.DEFAULT])

export const VisibilityOption = {
  VISIBLE: {id: 1, label: "VISIBLE"},
  ADMIN: {id: 2, label: "ADMIN"},
  HIDDEN: {id: 3, label: "HIDDEN"},
}

export const VisibilityOptions = [
  VisibilityOption.VISIBLE,
  VisibilityOption.ADMIN,
  VisibilityOption.HIDDEN
]

export const formatVisibility = value => {
  if (!value) {
    return null
  }

  return {label: value.label, value: value.id}
}

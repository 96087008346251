import {arrayEquals} from "./array";

export const keyLoader = (data, keys) => {
  if (!data) {
    return null
  }

  for (let key of keys) {
    if (key.includes(".")) {
      const [newKey, ...rest] = key.split(".")
      if (data[newKey] != null) {
        const newValue = keyLoader(data[newKey], rest)
        if (newValue != null) {
          return newValue
        }
      }
    }

    if (data[key] != null) {
      return data[key]
    }
  }
}

function isObject(variable) {
  return typeof variable === "object" && variable !== null && !Array.isArray(variable)
}

export const objectEqual = (o1, o2) => {
  if (!isObject(o1) || !isObject(o2)) return false

  const k1 = Object.keys(o1)
  const k2 = Object.keys(o2)
  if (!arrayEquals(k1, k2)) return false

  const v1 = Object.values(o1)
  const v2 = Object.values(o2)
  return arrayEquals(v1, v2)
}
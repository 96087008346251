import {formatDate} from "../format";
import {AttributeSort} from "../../../utils/sort";

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

const DAYS = []
for (let i = 1; i <= 30; i++) {
  DAYS.push(i.toString())
}

DAYS.sort()

const LOWERED_MONTHS = {}
for (let m of MONTHS.sort()) {
  LOWERED_MONTHS[m.toLowerCase()] = m
}

export function getPredictiveDate(value, dateOffset = 0) {
  const today = new Date()
  if (dateOffset == null) {
    dateOffset = 0
  }

  if (value == null || typeof value !== "string" || !value.length) {
    const date = new Date(today)
    date.setDate(date.getDate() + dateOffset)

    const text = formatDate(date)

    return {
      text,
      predictive: text,
      month: MONTHS[date.getMonth()],
      day: date.getDate(),
      year: date.getFullYear()
    }
  }

  const currentMonth = today.getMonth()
  const currentDay = today.getDate()
  const currentYear = today.getFullYear()

  const components = value.split(" ")
    .map(v => v.toLowerCase())
    .map(v => v.trim())

  let month = MONTHS[currentMonth]
  let day = currentDay
  let year = currentYear

  if (components.length > 3) return null

  if (components.length >= 1) {
    const monthTest = getMonth(components[0])
    if (!monthTest) return null
    month = monthTest

    if (components.length === 1) {
      day = 1
    }
  }

  if (components.length >= 2) {
    if (components[1].endsWith(",") || components.length > 2) {
      const dayTest = Number.parseInt(components[1].replace(",", ""))
      if (Number.isNaN(dayTest)) {
        return null
      }

      day = dayTest
    } else {
      const dayTest = getDay(components[1])
      if (!dayTest) return null
      day = dayTest
    }
  }

  if (components.length === 3) {
    const yearTest = getYear(components[2], currentYear)
    if (!yearTest) return null
    year = yearTest
  }

  let date
  try {
    date = new Date(Date.parse(`${month}-${day}-${year}`))
    date.setDate(date.getDate() + dateOffset)
  } catch (e) {
    console.error(e)
    return null
  }

  const text = formatDate(date)
  const isDirect = value.toLowerCase() === text.toLowerCase()

  return {
    text,
    predictive: isDirect ? null : text.slice(value.length),
    month,
    day,
    year
  }
}

function getMonth(value) {
  const result = Object.keys(LOWERED_MONTHS).filter(m => m.startsWith(value))
  return result.length ? LOWERED_MONTHS[result[0]] : null
}

function getDay(value) {
  if (typeof value === "number") {
    value = value.toString()
  }

  value = value.trim()
  if (!value.length) {
    return 1
  }

  const result = DAYS.filter(d => d.startsWith(value))
  if (!result.length) {
    return null
  }

  if (result.length === 1) {
    return Number.parseInt(value)
  }

  return Number.parseInt(result[1])
}

function getYear(value, defaultValue) {
  const defaultString = defaultValue.toString()

  if (typeof value === "number") {
    value = value.toString()
  }

  if (!value.length) {
    return defaultValue
  }

  if (value.length > 4) {
    return null
  }

  const year = Number.parseInt(value)
  if (Number.isNaN(year)) {
    return null
  }

  if (value.length === 4) {
    return year
  }

  if (defaultString.startsWith(value)) {
    return defaultValue
  }

  const current = Number.parseInt(defaultString.slice(0, value.length))
  if (current > year) {
    for (let y = value.length; y < 4; y++) {
      value += "9"
    }
  } else {
    for (let y = value.length; y < 4; y++) {
      value += "0"
    }
  }

  return Number.parseInt(value)
}

export const WEEKDAY_DATA = {
  MONDAY: {
    label: "Monday",
    shortName: "Mon",
    value: 2,
    next: 3,
    ordinal: 0
  },
  TUESDAY: {
    label: "Tuesday",
    shortName: "Tue",
    value: 3,
    next: 4,
    ordinal: 1
  },
  WEDNESDAY: {
    label: "Wednesday",
    shortName: "Wed",
    value: 4,
    next: 5,
    ordinal: 2
  },
  THURSDAY: {
    label: "Thursday",
    shortName: "Thu",
    value: 5,
    next: 6,
    ordinal: 3
  },
  FRIDAY: {
    label: "Friday",
    shortName: "Fri",
    value: 6,
    next: 7,
    ordinal: 4
  },
  SATURDAY: {
    label: "Saturday",
    shortName: "Sat",
    value: 7,
    next: 1,
    ordinal: 5
  },
  SUNDAY: {
    label: "Sunday",
    shortName: "Sun",
    value: 1,
    next: 2,
    ordinal: 6
  },
}

export const convertMinutesToRawTime = value => {
  if (value == null || value < 0 || value > 1440) {
    return null
  }

  let hours = Math.floor(value / 60)
  const minutes = Math.floor(value % 60)

  if (hours === 24) {
    hours = 0
  }

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
}

export const convertTimeToMinutes = value => {
  if (value == null || value.length < 3 || !value.includes(":")) {
    return null
  }

  return value.split(":")
    .map(v => Number.parseInt(v))
    .map((v, i) => {
      if (i === 0) {
        return v * 60
      }

      return v
    })
    .reduce((a, b) => a + b, 0)
}

export const DAY_OF_WEEK_TO_WEEKDAY_LOOKUP = {}
for (let day of Object.values(WEEKDAY_DATA)) {
  DAY_OF_WEEK_TO_WEEKDAY_LOOKUP[day.value] = day
}

export const convertMinutesToTime = minutes => {
  if (minutes == null) {
    return null
  }

  if (minutes < 0) {
    throw new Error("Minutes must be greater than or equal to 0")
  }

  if (minutes > 1440) {
    throw new Error("Minutes must be less than or equal to 1440")
  }

  if (!(typeof minutes === "number")) {
    throw new Error("Minutes must be a number")
  }

  if (!minutes || minutes === 1440) return "12:00am"

  let H = Math.floor(minutes / 60)
  const m = H < 12 ? "am" : "pm"

  if (H === 0) {
    H = 12
  }

  if (H > 12) {
    H -= 12
  }

  const M = minutes % 60

  return `${H}:${M.toString().padStart(2, "0")}${m}`
}

export const convertOperationalHoursToDisplay = hours => {
  const convert = ([time, operationalHours]) => {
    const days = Array.from(new Set(operationalHours.map(o => DAY_OF_WEEK_TO_WEEKDAY_LOOKUP[o.day_of_week]))).sort(AttributeSort("ordinal", "start_time"))
    const groupings = []

    if (days.length) {
      const minTime = operationalHours[0].start_time
      const maxTime = operationalHours[0].end_time

      let current = {
        ordinal: days[0].ordinal,
        days: new Set([days[0].value]),
        start_time: minTime,
        end_time: maxTime,
        data: [days[0]]
      }

      groupings.push(current)

      for (let i = 1; i < days.length; i++) {
        const previousDay = days[i - 1]
        const currentDay = days[i]

        if (previousDay.ordinal + 1 === currentDay.ordinal) {
          current.days.add(currentDay.value)
          current.data.push(currentDay)
        } else {
          current = {
            ordinal: currentDay.ordinal,
            days: new Set([currentDay.value]),
            start_time: minTime,
            end_time: maxTime,
            data: [currentDay]
          }
          groupings.push(current)
        }
      }
    }

    const formatGrouping = grouping => {
      if (!grouping.data.length) {
        return null
      }

      const first = grouping.data[0].shortName
      let day
      if (grouping.data.length === 1) {
        day = first
      } else {
        day = `${first} - ${grouping.data[grouping.data.length - 1].shortName}`
      }

      return {
        ordinal: grouping.ordinal,
        days: Array.from(grouping.days).sort(),
        start_time: grouping.start_time,
        end_time: grouping.end_time,
        data: `${day} ${time}`
      }
    }

    return groupings.map(formatGrouping)
  }

  return Object.entries(Object.groupBy(hours, h => `${convertMinutesToTime(h.start_time)} - ${convertMinutesToTime(h.end_time)}`))
    .flatMap(convert)
    .sort(AttributeSort("ordinal", "start_time"))
    .map(v => ({label: v.data, value: {days: v.days, start_time: v.start_time, end_time: v.end_time}}))
}
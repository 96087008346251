import {featureCollection as FeatureCollection} from "@turf/turf";

export class GeoJSONDataShed {
  constructor(features) {
    if (!features) {
      features = []
    }

    this._features = features
  }

  setAll(schoolId, features) {
    this._features = features
  }

  getAll() {
    return FeatureCollection(this._features)
  }

  getFeatures() {
    return [...this._features]
  }

  add(feature) {
    if (!feature) {
      return
    }

    this.addBulk([feature])
  }

  addBulk(features) {
    if (!features?.length) {
      return
    }

    const ids = features.map(f => f.properties.id)
    this._features = [...this._features.filter(f => !ids.includes(f.properties.id)), ...features]
  }

  remove(id) {
    if (!id) {
      return
    }

    this.removeBulk([id])
  }

  removeBulk(ids) {
    if (!ids?.length) {
      return
    }

    this._features = this._features.filter(f => !ids.includes(f.properties.id))
  }

  clear() {
    this._features = []
  }
}
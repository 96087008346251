import React from "react";
import Card from "../../shared/components/card";
import Checkbox from "../../shared/components/checkbox";
import {Geocoder} from "./map/drawHandlers/geocoder";

const OPTIONS = [
  {id: "zones", label: "Zones"},
  {id: "aps", label: "Access Points"},
  {id: "floor_plan", label: "Floor Plan"},
]

export function MapOptions({map, state, onChange}) {
  const handleChange = option => {
    if (onChange == null) return
    onChange(option)
  }

  return (
    <div className="map-buttons-options">
      {OPTIONS.map(option => <MapOption
        key={option.id}
        label={option.label}
        checked={state[option.id]}
        onChange={() => handleChange(option.id)}
      />)}
      <Geocoder map={map}/>
    </div>
  )
}

function MapOption({label, checked, onChange}) {
  checked = !!checked

  return (
    <Card className="map-option" onClick={onChange}>
      <Checkbox checked={checked}/>
      <span>{label}</span>
    </Card>
  )
}

import React, {useEffect, useReducer} from "react";
import {TabBody, TabHeader, Tabs} from "../../shared/components/tabs";
import {formatString} from "../../shared/utils/format";
import {GeneralTab} from "../../shared/pages/school/components/general";
import {SemesterTab} from "../../shared/pages/school/components/semester";
import Api, {getErrorMessage} from "../../shared/api/api";
import {ErrorToast} from "../../shared/utils/toast";
import {AdminTab} from "../../shared/pages/school/components/admin";
import Indicator from "../../shared/components/loader";

import {ReactComponent as PreviousIcon} from "../../assets/icons/school/prev_icon.svg";
import {ReactComponent as NextIcon} from "../../shared/assets/icons/next_icon.svg";
import {CopyButton, IconButton} from "../../shared/components/buttons";
import {SchoolListView} from "./list_view";
import {SecurityTab} from "../../shared/pages/school/components/security";

const GENERAL_TAB = {id: "general", label: "General"}
const SEMESTER_TAB = {id: "semester", label: "Semester"}
const ADMIN_TAB = {id: "admin", label: "Admin"}
const SECURITY_TAB = {id: "security", label: "Security"}

const TABS = [
  GENERAL_TAB,
  SEMESTER_TAB,
  ADMIN_TAB,
  SECURITY_TAB
]

const DEFAULT_DATA = {
  selectedTabId: GENERAL_TAB.id,
  loading: false,
  general: null,
  semester: {
    current: null,
    previous: [],
    future: []
  },
  admin: {
    facility: {
      // zone_ratio: {
      //   users: 2,
      //   sqft: 80
      // },
      // facility_dollars_per_student: 8004,
    },
    dashboard: {
      // target_percentage: 18
    }
  },
  security: {
    deidentify: {
      enabled: false,
      option: null,
      options: [],
      interval: null,
      intervals: [],
      starts_on: null,
      ends_on: null,
    }
  }
}

const SET_LOADING = "SET_LOADING"
const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"

const SET_GENERAL_DATA = "SET_GENERAL_DATA"
const SET_SEMESTER_DATA = "SET_SEMESTER_DATA"
const SET_ADMIN_DATA = "SET_ADMIN_DATA"
const SET_SECURITY_DATA = "SET_SECURITY_DATA"

const SchoolReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case SET_ACTIVE_TAB:
      return {
        ...state,
        selectedTabId: action.payload
      }

    case SET_GENERAL_DATA:
      return {
        ...state,
        loading: false,
        general: action.payload
      }

    case SET_SEMESTER_DATA:
      return {
        ...state,
        loading: false,
        semester: action.payload
      }

    case SET_ADMIN_DATA:
      return {
        ...state,
        loading: false,
        admin: action.payload
      }

    case SET_SECURITY_DATA:
      return {
        ...state,
        loading: false,
        security: action.payload
      }

    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export default function SchoolView({school, schools, carouselDisabled, onSchoolAdded, onNextSchool, onPreviousSchool, onSchoolSelected, onLocationChange}) {
  const [state, dispatch] = useReducer(SchoolReducer, DEFAULT_DATA)

  const setSelectedTab = tab => dispatch({type: SET_ACTIVE_TAB, payload: tab.id})
  const setLoading = loading => dispatch({type: SET_LOADING, payload: loading})

  const setGeneralData = data => dispatch({type: SET_GENERAL_DATA, payload: data})
  const setSemesterData = data => dispatch({type: SET_SEMESTER_DATA, payload: data})
  const setAdminData = data => dispatch({type: SET_ADMIN_DATA, payload: data})
  const setSecurityData = data => dispatch({type: SET_SECURITY_DATA, payload: data})

  const schoolId = school ? school.id : null
  const schoolName = school ? school.name : null

  useEffect(() => {
    if (schoolId == null) {
      return
    }

    setLoading(true)

    const controller = new AbortController()
    switch (state.selectedTabId) {
      case GENERAL_TAB.id:
        Api.get(`schools/${schoolId}/general`, {signal: controller.signal})
          .then((response) => setGeneralData(response.data.general))
          .catch(e => {
            setLoading(false)

            if (e.handled) return

            console.error(e.error)
            ErrorToast("Unable to fetch School Data")
          })
        break

      case SEMESTER_TAB.id:
        Api.get(`schools/${schoolId}/semester`, {signal: controller.signal})
          .then((response) => setSemesterData(response.data))
          .catch(e => {
            setLoading(false)

            if (e.handled) return

            console.error(e.error)
            ErrorToast("Unable to fetch Semester Data")
          })
        break

      case ADMIN_TAB.id:
        Api.get(`schools/${schoolId}/admin`, {signal: controller.signal})
          .then((response) => setAdminData(response.data))
          .catch(e => {
            setLoading(false)

            if (e.handled) return

            console.error(e.error)
            ErrorToast("Unable to fetch Admin Data")
          })
        break

      case SECURITY_TAB.id:
        Api.get(`schools/${schoolId}/security`, {signal: controller.signal})
          .then((response) => setSecurityData(response.data))
          .catch(e => {
            setLoading(false)

            if (e.handled) return

            console.error(e.error)
            ErrorToast("Unable to fetch Security Data")
          })
        break

      default:
        throw new Error(`Unknown action type: ${state.selectedTabId}`)
    }
  }, [schoolId, state.selectedTabId]);

  return (
    <div className="school-layout">
      <SchoolListView
        school={school}
        schools={schools}
        onSchoolSelected={onSchoolSelected}
        onSchoolAdded={(school, callback) => {
          setLoading(true)
          Api.post(`schools`, school)
            .then(response => {
              const newSchool = response.data.school
              setLoading(false)
              onSchoolAdded && onSchoolAdded(newSchool, callback)
            })
            .catch(e => {
              setLoading(false)
              if (e.handled) return
              console.error(e.error)
              ErrorToast("Unable to create School")
            })
        }}
      />
      <div className="school-page da-card">
        <SchoolCarousel disabled={carouselDisabled} onPrevious={onPreviousSchool} onNext={onNextSchool}/>
        <div className="school-page-header">
          <span className="school-page-header-name">{formatString(schoolName)}</span>
          <div className="school-page-header-links">
            <span>{schoolId}</span>
            <CopyButton variant="secondary" value={schoolId}/>
          </div>
        </div>
        <Tabs>
          <TabHeader selectedTab={state.selectedTabId} items={TABS} onSelectTab={setSelectedTab}/>
          <TabBody className="school-tab-body">
            {state.general != null &&
              <GeneralTab
                show={state.selectedTabId === GENERAL_TAB.id}
                canManageSchool={true}
                data={state.general}
                onItemChange={data => {
                  setLoading(true)
                  Api.put(`schools/${schoolId}/general`, data)
                    .then((response) => {
                      setGeneralData(response.data.general)
                      if (onLocationChange != null && (data.longitude != null || data.latitude != null || data.zoom != null)) {
                        onLocationChange({
                          ...school,
                          location: {longitude: response.data.general.longitude, latitude: response.data.general.latitude, zoom: response.data.general.zoom}
                        })
                      }
                    })
                    .catch(e => {
                      setLoading(false)

                      if (e.handled) return

                      console.error(e.error)
                      ErrorToast("Unable to update School")
                    })
                }}
              />
            }
            <SemesterTab
              show={state.selectedTabId === SEMESTER_TAB.id}
              canManageSchool={true}
              data={state.semester}
              onSemesterAdd={(data, callback) => {
                setLoading(true)
                Api.post(`schools/${schoolId}/semester`, data)
                  .then((response) => {
                    setSemesterData(response.data)
                    callback()
                  })
                  .catch(e => {
                    setLoading(false)

                    if (e.handled) return

                    const message = getErrorMessage(e.error)
                    if (message) {
                      ErrorToast(message)
                      return
                    }

                    console.error(e.error)
                    ErrorToast("Unable to create Semester")
                  })
              }}
              onSemesterUpdate={(semester, data, callback) => {
                setLoading(true)
                Api.put(`schools/${schoolId}/semester/${semester.id}`, data)
                  .then((response) => {
                    setSemesterData(response.data)
                    callback()
                  })
                  .catch(e => {
                    setLoading(false)

                    if (e.handled) return

                    const message = getErrorMessage(e.error)
                    if (message) {
                      ErrorToast(message)
                      return
                    }

                    console.error(e.error)
                    ErrorToast("Unable to update Semester")
                  })
              }}
              onSemesterRemove={(semester, callback) => {
                setLoading(true)
                Api.delete(`schools/${schoolId}/semester/${semester.id}`)
                  .then((response) => {
                    setSemesterData(response.data)
                    callback()
                  })
                  .catch(e => {
                    setLoading(false)

                    if (e.handled) return

                    const message = getErrorMessage(e.error)
                    if (message) {
                      ErrorToast(message)
                      return
                    }

                    console.error(e.error)
                    ErrorToast("Unable to Delete Semester")
                  })
              }}
            />
            <AdminTab
              show={state.selectedTabId === ADMIN_TAB.id}
              data={state.admin}
              canManageSchool={true}
              onItemChange={(data) => {
                setLoading(true)
                Api.put(`schools/${schoolId}/admin`, data)
                  .then((response) => setAdminData(response.data))
                  .catch(e => {
                    setLoading(false)

                    if (e.handled) return

                    const message = getErrorMessage(e.error)
                    if (message) {
                      ErrorToast(message)
                      return
                    }

                    console.error(e.error)
                    ErrorToast("Unable to update Admin")
                  })
              }}
            />
            <SecurityTab
              show={state.selectedTabId === SECURITY_TAB.id}
              data={state.security}
              canManageSchool={true}
              onItemChange={(data, callback) => {
                setLoading(true)
                Api.put(`schools/${schoolId}/security`, data)
                  .then(response => {
                    setSecurityData(response.data)
                    callback()
                  })
                  .catch(e => {
                    setLoading(false)

                    if (e.handled) return

                    const message = getErrorMessage(e.error)
                    if (message) {
                      ErrorToast(message)
                      return
                    }

                    console.error(e.error)
                    ErrorToast("Unable to update Security")
                  })
              }}
            />
          </TabBody>
        </Tabs>
        <Indicator show={state.loading}/>
      </div>
    </div>
  )
}

const SchoolCarousel = ({disabled, onPrevious, onNext}) => {
  return (
    <div className="school-carousel">
      <IconButton disabled={disabled} variant="secondary" onClick={() => onPrevious && onPrevious()}><PreviousIcon/></IconButton>
      <IconButton disabled={disabled} variant="secondary" onClick={() => onNext && onNext()}><NextIcon/></IconButton>
    </div>
  )
}
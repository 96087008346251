import {useEffect, useState} from "react";
import {createPortal} from "react-dom";

export function ElementLoader({elementId, ...props}) {
  const [hasElement, setHasElement] = useState(false)
  useEffect(() => {
    if (hasElement) {
      return
    }

    let timer = null
    const checkElement = () => {
      if (!hasElement) {
        const element = document.getElementById(elementId)
        const isLoaded = !!element

        if (isLoaded) {
          setHasElement(true)
          if (timer != null) {
            clearTimeout(timer)
            timer = null
          }
        } else {
          timer = setInterval(checkElement, 100)
        }
      } else if (timer !== null) {
        clearInterval(timer)
        timer = null
      }
    }

    checkElement()
    return () => {
      if (timer != null) {
        clearInterval(timer)
        timer = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (!hasElement) {
    return null
  }

  return createPortal(
    props.children,
    document.getElementById(elementId)
  )
}


export const convertToTime = value => {
  if (value == null) {
    return null
  }

  let newValue = value.replaceAll(/[^0-9]/g, "").slice(0, 4)

  if (!newValue.length) {
    return null
  }

  const firstChar = newValue.charAt(0)
  const firstNumber = parseInt(firstChar)
  const secondNumber = parseInt(newValue.charAt(1))

  const total = parseInt(newValue)

  if (total === 0 || total > 2359) {
    return null
  } else if (newValue.length === 1) {
    return firstNumber > 1 ? `${firstNumber}:` : `${firstNumber}`
  }

  if (newValue.length === 4) {
    const hours = parseInt(newValue.slice(0, 2))
    if (hours < 10 || hours > 12) {
      return null
    }

    const minutes = parseInt(newValue.slice(2, 4))
    if (minutes >= 60) {
      return null
    }

    return `${newValue.slice(0, 2)}:${newValue.slice(2, 4)}`
  } else if (total >= 2 && total <= 9) {
    return `${total}:`
  } else if ((newValue.length <= 3 || (firstNumber >= 2 && firstNumber <= 9 && total <= 259)) && secondNumber <= 5) {
    return `${firstChar}:${newValue.slice(1)}`
  }

  return null
}
import React from "react";
import {Badge} from "./badge";

const colors = (color) => ({"--icon-color": `var(--${color}-500)`, "--icon-hover": `var(--${color}-600)`});
const DEFAULT_COLOR = colors("primary-color")

const COLOR_MAP = {}

let index = 0;
for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
  const key = String.fromCharCode(i)

  switch (index) {
    case 0:
      COLOR_MAP[key] = colors("green-color")
      break

    case 1:
      COLOR_MAP[key] = colors("purple-color")
      break

    case 2:
      COLOR_MAP[key] = colors("orange-color")
      break

    default:
      COLOR_MAP[key] = DEFAULT_COLOR
  }

  index++
  if (index >= 4) index = 0
}

// const getColor = (name) => {
//   if (name == null || !name.length) return DEFAULT_COLOR
//
//   const firstChar = name.charAt(0).toUpperCase()
//   const backgroundColors = COLOR_MAP[firstChar]
//   return backgroundColors != null ? backgroundColors : DEFAULT_COLOR
// }

const getInitials = name => {
  const getFirstValue = value => value != null && value.length ? value[0] : ""

  if (name == null || !name.length) return null;

  const components = name.split(" ");
  if (components.length === 1) return getFirstValue(components[0]);
  return `${getFirstValue(components[0])}${getFirstValue(components.at(-1))}`;
}

export default function NameIcon({name, badgeCount, onClick}) {
  if (name != null) name = name.toUpperCase();

  return (
    <div className="name-icon" onClick={() => onClick && onClick()}>
      <span>{getInitials(name)}</span>
      <Badge count={badgeCount}/>
    </div>
  )
}
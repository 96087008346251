import {DataShed} from "./base";
import {ZoneType} from "../../pages/wireless_config/consts";
import {AttributeSort} from "../../utils/sort";
import {Zone} from "../models/zone";

export class ZoneDataShed extends DataShed {
  constructor(onDataChange) {
    super(onDataChange)

    this._idLookup = {}
    this._buildingToZoneLookup = {}

    this._campusZones = []
    this._buildingZones = []
    this._floorZones = []
  }

  getById(id) {
    if (!id) {
      return null
    }

    return this._idLookup[id]
  }

  getCampusZones() {
    return this._campusZones
  }

  getBuildingZones() {
    return this._buildingZones
  }

  getZoneForBuilding(buildingId) {
    if (!buildingId) {
      return null
    }

    return this.getById(this._buildingToZoneLookup[buildingId])
  }

  getFloorZones() {
    return this._floorZones
  }

  _handleChange(schoolId) {
    this._idLookup = {}
    this._buildingToZoneLookup = {}

    this._campusZones = []
    this._buildingZones = []
    this._floorZones = []

    // add school zone to lookup
    this._idLookup[schoolId] = new Zone({
      id: schoolId,
      name: "Entire School",
      type: ZoneType.SCHOOL,
    })

    for (let zone of this._values) {
      this._idLookup[zone.id] = zone

      switch (zone.type) {
        case ZoneType.CAMPUS:
          this._campusZones.push(zone)
          break

        case ZoneType.BUILDING:
          this._buildingZones.push(zone)
          this._buildingToZoneLookup[zone.buildingId] = zone.id
          break

        case ZoneType.FLOOR:
          this._floorZones.push(zone)
          break

        default:
          // do nothing
          break
      }
    }

    this._buildingZones = this._buildingZones.sort(AttributeSort("name"))
    this._floorZones = this._floorZones.sort(AttributeSort("name"))
  }
}
// import {useDataContext} from "../../hooks/context";
// import {angle as _angle} from "@turf/turf";

export function PolygonInfo() {
  return null
//   const {draw} = useDataContext()
//
//   if (!draw.geojson) {
//     return
//   }
//
//   const polygon = draw.geojson.features.filter(f => f.geometry.type === "Polygon")[0]
//
//   if (!polygon) {
//     return
//   }
//
//   const polyCoordinates = polygon.geometry.coordinates[0]
//   const coordinates = []
//
//   for (let i = 0; i < polyCoordinates.length; i++) {
//     coordinates.push({
//       coordinate: polyCoordinates[i],
//       angle: () => {
//         const result = _angle(
//           polyCoordinates[i - 1 < 0 ? polyCoordinates.length - 1 : i],
//           polyCoordinates[i],
//           polyCoordinates[i + 1 < polyCoordinates.length ? i : 0]
//         )
//
//         return result != null ? result.toString() : ""
//       }
//     })
//   }
//
//   return (
//     <div className="map-overlay-polygon-info da-card">
//       <span>{polygon.properties.name}</span>
//       {coordinates.map((data, i) => (
//         <PolygonCoordinate key={i} index={i} data={data}/>
//       ))}
//     </div>
//   )
// }
//
// function PolygonCoordinate({data, index}) {
//   const handleLongitudeChange = e => {
//
//   }
//
//   const handleLatitudeChange = e => {
//
//   }
//
//   const handleAngleChange = e => {
//
//   }
//
//   return (
//     <div>
//       <div>
//         <span>Longitude</span>
//         <input value={data.coordinate[0].toString()} onChange={handleLongitudeChange}/>
//       </div>
//       <div>
//         <span>Latitude</span>
//         <input value={data.coordinate[1].toString()} onChange={handleLatitudeChange}/>
//       </div>
//       <div>
//         <span>Angle</span>
//         <input value={data.angle()} onChange={handleAngleChange}/>
//       </div>
//     </div>
//   )
}
import {DataShed} from "../sheds/base";
import {LoadingStatus} from "../../shared/api/loading";
import {ZoneDataShed} from "../sheds/zone";
import {ApDataShed} from "../sheds/ap";
import {GeoJSONDataShed} from "../sheds/geojson";
import {UsageDataShed} from "../sheds/usage";

export class WirelessConfigDepo {
  constructor() {
    this._loadingStatus = LoadingStatus.NONE

    this._zones = new ZoneDataShed(this._updateResourceCounts())
    this._aps = new ApDataShed(this._updateResourceCounts())
    this._geojson = new GeoJSONDataShed()
    this._buildings = new DataShed()
    this._usages = new UsageDataShed()
    this._utilization = []
    this._utilizationDefaults = {default: 1}
    this._resourceLimiter = null
    this._resourceCounts = {
      all: 0,
      complete: 0,
      incomplete: 0
    }

    this._pauseCount = false
  }

  getLoadingStatus() {
    return this._loadingStatus
  }

  setLoadingStatus(status) {
    this._loadingStatus = status
  }

  setData(schoolId, {zones, aps, buildings, geojson, usages, utilization, utilizationDefaults}) {
    this._loadingStatus = LoadingStatus.FETCHED

    // the sheds will callback when the setAll func is called so disable. that way we only calculate everything once
    this._pauseCount = true

    this._zones.setAll(schoolId, zones)
    this._aps.setAll(schoolId, aps)
    this._geojson.setAll(schoolId, geojson)
    this._buildings.setAll(schoolId, buildings)
    this._usages.setAll(schoolId, usages)
    this._utilization = utilization
    this._utilizationDefaults = utilizationDefaults
    this._resourceLimiter = null

    this._pauseCount = false

    this._calculateCounts()
  }

  getBuildings() {
    return this._buildings
  }

  getZones() {
    return this._zones
  }

  getAps() {
    return this._aps
  }

  getGeoJSON() {
    return this._geojson
  }

  getUsages() {
    return this._usages
  }

  getUtilization() {
    if (this._utilization == null) {
      return []
    }

    return this._utilization
  }

  getUtilizationDefaults() {
    if (this._utilizationDefaults == null) {
      return {default: 1}
    }

    return this._utilizationDefaults
  }

  getResourceLimiter() {
    return this._resourceLimiter
  }

  setResourceLimiter(zoneIds, apIds) {
    if (!zoneIds?.length && !apIds?.length) {
      this._resourceLimiter = null
      return
    }

    this._resourceLimiter = {
      zoneIds: zoneIds,
      apIds: apIds,
    }
  }

  getResourceCounts() {
    return this._resourceCounts
  }

  clear() {
    this._loadingStatus = LoadingStatus.NONE

    this._zones.clear()
    this._aps.clear()
    this._geojson.clear()
    this._resourceLimiter = null
    this._calculateCounts()
  }

  _calculateCounts() {
    this._updateResourceCounts()()
  }

  _updateResourceCounts() {
    const this2 = this

    return () => {
      if (this2._pauseCount) {
        return
      }

      let all = 0, complete = 0, incomplete = 0

      for (let resource of this2._zones.getAll()) {
        all += 1
        if (resource.isComplete()) complete += 1
        else incomplete += 1
      }

      for (let resource of this2._aps.getAll()) {
        all += 1
        if (resource.isComplete()) complete += 1
        else incomplete += 1
      }

      this2._resourceCounts = {
        all,
        complete,
        incomplete
      }
    }
  }
}

import {useRef} from "react"

import {ListView} from "./list"
import {EmptyView} from "./views"
import {PlusButton} from "./buttons"

import {ReactComponent as CaretIcon} from "../assets/icons/button/caret_icon.svg"
import {ReactComponent as AlertIcon} from "../../shared/assets/icons/alert_icon.svg"

export const CollapsableListView = ({hasData, isLoading, datasource, delegate}) => {
  if (!hasData) {
    return <EmptyView>{isLoading ? "Loading..." : "No Resources"}</EmptyView>
  }

  const children = []
  for (let row = 0; row < datasource.numberOfRows(); row++) {
    const rowDatasource = datasource.getDataForRow(row)

    children.push(<CollapsableView
      key={rowDatasource.resource.id}
      datasource={rowDatasource}
      delegate={delegate}
    />)
  }

  return (
    <ListView className="map-listview-content">
      <div className="map-listview-collapsable">
        {children}
      </div>
    </ListView>
  )
}

const CollapsableView = ({datasource, delegate}) => {
  const caretRef = useRef(null)

  const handleOnResourceSelected = (e) => {
    if (!datasource.isSelectable() || (datasource.isSelected() && caretRef.current && caretRef.current.contains(e.target))) {
      return
    }

    delegate?.onSelect && delegate.onSelect(datasource.isSelected() ? null : datasource.resource)
  }

  const handleOnAdd = () => {
    delegate?.onCreate && delegate.onCreate(datasource.resource)
  }

  const caretOrPlaceholder = datasource.hasSubResources() ?
    <div className="map-listview-collapsable-layout-caret" ref={caretRef} onClick={datasource.handleToggle} aria-expanded={datasource.isOpen()}>
      <div><CaretIcon/></div>
    </div> :
    <span/>

  return (
    <div id={datasource.resource.id} className="map-listview-collapsable">
      <div className="map-listview-collapsable-layout" aria-multiline={datasource.hasSubResources()}>
        {caretOrPlaceholder}
        <div className={`map-listview-collapsable-header ${datasource.isSelectable() ? "selectable" : ""}`} aria-selected={datasource.isSelected()}>
          <div className={`map-listview-collapsable-header-content ${datasource.hasIcon() ? "three-column" : "two-column"}`}>
            {datasource.hasIcon() ? datasource.getIcon(datasource.resource, handleOnResourceSelected) : null}
            <span className="map-listview-collapsable-header-content-primary">{datasource.resource.name}</span>
            <div className="map-listview-collapsable-header-content-buttons">
              {!datasource.resource.isComplete() && <AlertIcon/>}
              {datasource.isCreatable() && <PlusButton onClick={handleOnAdd}/>}
            </div>
            <span className="map-listview-collapsable-header-content-secondary">{datasource.resource.description}</span>
            <div className="map-listview-collapsable-header-click" onClick={handleOnResourceSelected}/>
          </div>
        </div>
        <div className="map-listview-collapsable-content">
          {datasource.hasSubResources() && datasource.isOpen() && datasource.subResourceDatasources().map(subResourceDatasource => (
            <CollapsableView
              key={subResourceDatasource.resource.id}
              datasource={subResourceDatasource}
              delegate={delegate}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

import React, {useEffect, useRef, useState} from "react";

import {Select} from "../shared/components/select";

import {ReactComponent as CheckIcon} from "../shared/assets/icons/item_selected_icon.svg"
import {SchoolIcon} from "./icons/school";

export const SchoolDropdown = ({currentSchool, schools, onSchoolSelected}) => {
  const formatter = (school) => ({label: school.name, value: school, isCurrent: currentSchool != null && school.id === currentSchool.id});

  return (
    <div className="da-navbar-school">
      <SchoolIcon school={currentSchool}/>
      <Select
        value={currentSchool ? formatter(currentSchool) : ""}
        options={schools.map(formatter)}
        components={{Option: SchoolOption}}
        onChange={(newValue) => [
          onSchoolSelected && onSchoolSelected(newValue.value)
        ]}
      />
    </div>
  )
}

const DEFAULT_CLASSNAME = "da-navbar-school-option da-select__option"
const HOVER_CLASSNAME = "da-select__option--is-focused"

const SchoolOption = ({...props}) => {
  const [classNames, setClassNames] = useState([DEFAULT_CLASSNAME]);
  const divRef = useRef(null)


  useEffect(() => {
    const div = divRef.current

    if (div == null) {
      return
    }

    const setMouseOver = () => setClassNames([DEFAULT_CLASSNAME, HOVER_CLASSNAME])
    const setMouseLeave = () => setClassNames([DEFAULT_CLASSNAME])

    div.addEventListener("mouseover", setMouseOver, {passive: true})
    div.addEventListener("mouseleave", setMouseLeave, {passive: true})

    return () => {
      div.removeEventListener("mouseover", setMouseOver, {passive: true})
      div.removeEventListener("mouseleave", setMouseLeave, {passive: true})
    }
  }, []);

  const handleOnClick = () => {
    props.setValue(props.data)
  }

  return (
    <div ref={divRef} className={classNames.join(" ")} onClick={handleOnClick}>
      <SchoolIcon school={props.data.value}/>
      <span>{props.data.label}</span>
      {!!props.data.isCurrent && <CheckIcon style={{marginLeft: "auto"}}/>}
    </div>
  )
}
import {SchoolIcon} from "../../components/icons/school";
import {AddButton} from "../../shared/components/buttons";
import Search from "../../shared/components/search";
import {forwardRef, useEffect, useReducer, useRef, useState} from "react";
import {EntryModal} from "../../shared/components/modal";
import {ListView} from "../../shared/components/list";
import {EmptyView} from "../../shared/components/views";

const DEFAULT_STATE = {
  search: {
    isSearching: false,
    value: null,
    lowered: null
  },
  newModal: {
    show: false
  }
}

const SET_SEARCH = "SET_SEARCH"
const SHOW_NEW_MODAL = "SHOW_NEW_MODAL"

const SchoolListViewReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: {
          isSearching: action.payload != null,
          value: action.payload,
          lowered: action.payload != null ? action.payload.toLowerCase() : null,
        }
      }

    case SHOW_NEW_MODAL:
      return {
        ...state,
        newModal: {
          show: action.payload
        }
      }

    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

export function SchoolListView({school, schools, onSchoolAdded, onSchoolSelected}) {
  const [state, dispatch] = useReducer(SchoolListViewReducer, DEFAULT_STATE)
  const [listViewHeight, setListViewHeight] = useState("0")

  const viewRef = useRef(null)
  const headerRef = useRef(null)

  const viewBaseHeight = viewRef.current != null ? Math.floor(viewRef.current.getBoundingClientRect().height) : null
  const headerBaseHeight = headerRef.current != null ? Math.floor(headerRef.current.getBoundingClientRect().height) : null

  useEffect(() => {
    if (viewBaseHeight == null || headerBaseHeight == null) {
      return
    }

    const parentHeight = `calc(${viewBaseHeight}px - var(--content-inset) * 2)`
    const headerHeight = `calc(${headerBaseHeight}px + var(--content-inset))`

    const height = `calc(${parentHeight} - ${headerHeight})`
    if (height !== listViewHeight) {
      setListViewHeight(height)
    }
  }, [viewBaseHeight, headerBaseHeight, listViewHeight]);

  const setSearch = search => dispatch({type: SET_SEARCH, payload: search})

  const showNewModal = () => dispatch({type: SHOW_NEW_MODAL, payload: true})
  const hideNewModal = () => dispatch({type: SHOW_NEW_MODAL, payload: false})

  const handleOnSchoolAdded = (school) => {
    onSchoolAdded && onSchoolAdded(school, hideNewModal)
  }

  const filterSchool = (school) => {
    if (state.search.lowered == null) return true
    return school.id === state.search.lowered || school.name.toLowerCase().includes(state.search.lowered)
  }

  const filteredSchools = (schools || []).filter(filterSchool)
  const hasSchools = filteredSchools.length > 0

  return (
    <div ref={viewRef} className="school-list-view da-card">
      <HeaderView ref={headerRef} search={state.search.value} onSearch={setSearch} onNewSchoolClick={showNewModal}/>
      <ListView>
        {!hasSchools && <EmptyView>No Schools</EmptyView>}
        {hasSchools && filteredSchools.map(s =>
          <ListViewItem
            key={s.id}
            school={s}
            isSelected={school.id === s.id}
            onClick={onSchoolSelected}
          />
        )}
      </ListView>
      <NewSchoolModal
        show={state.newModal.show}
        onHide={hideNewModal}
        onNewSchoolClick={handleOnSchoolAdded}
      />
    </div>
  )

}

const HeaderView = forwardRef(({search, onSearch, onNewSchoolClick}, ref) => {
  const handleOnNewSchoolClick = () => {
    onNewSchoolClick && onNewSchoolClick();
  }

  return (
    <div ref={ref} className="school-list-view-header">
      <AddButton onClick={handleOnNewSchoolClick}>New School</AddButton>
      <Search search={search} onSearch={onSearch}/>
    </div>
  )
})

function ListViewItem({school, isSelected, onClick}) {
  const handleOnClick = () => {
    onClick && onClick(school)
  }

  return (
    <div className="school-list-view-item" aria-selected={isSelected} onClick={handleOnClick}>
      <SchoolIcon school={school}/>
      <span>{school.name}</span>
    </div>
  )
}

function NewSchoolModal({show, onHide, onNewSchoolClick}) {
  const [data, setData] = useState({})

  useEffect(() => {
    setData({})
  }, [show])

  if (!show) return null

  const isButtonDisabled = () => {
    return true
  }

  const handleOnClick = () => {
    onNewSchoolClick && onNewSchoolClick(data)
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Create",
      disabled: isButtonDisabled({}, data),
      onClick: handleOnClick
    }
  }
  return (
    <EntryModal show={show} title="Create School" buttons={buttons} onHide={onHide}>

    </EntryModal>
  )
}
import {ReactComponent as InfoIcon} from "../../shared/assets/icons/info_icon.svg";

export function Banner({...props}) {
  let {className, children, ...rest} = props;

  const classOptions = ["settings-banner"]
  if (className) classOptions.push(className);

  return (
    <div className={classOptions.join(" ")} {...rest}>
      <div>
        <InfoIcon/>
        {children}
      </div>
    </div>
  )
}

export function EmptyView({...props}) {
  const {className, children, ...rest} = props;

  const classNames = ["da-empty-view"]
  if (className != null) classNames.push(className)

  return (
    <div className={classNames.join(" ")} {...rest}>
      <span>{children}</span>
    </div>
  )
}

export function MultiEmptyView({...props}) {
  const {className, children, ...rest} = props;

  const classNames = ["da-empty-view"]
  if (className != null) classNames.push(className)

  return (
    <div className={classNames.join(" ")} {...rest}>
      {children}
    </div>
  )
}

import {useCallback} from "react";
import {center as findCenter, featureCollection as FeatureCollection} from "@turf/turf"
import {useDataContext} from "../hooks/context";

import {IconButton} from "../../../shared/components/buttons";
import {ReactComponent as CenterIcon} from "../../../shared/assets/icons/map/map_center_icon.svg";

export function MapControlButtonGroup({...props}) {
  const {className, children, ...rest} = props

  return (
    <div className={`map-overlay-control${className ? "" + className : ""}`} {...rest}>
      {children}
    </div>
  )
}

export function MapIconControl({show = true, variant = "secondary", selected, onClick, ...props}) {
  if (!show) {
    return null
  }

  const {className, children, ...rest} = props

  return (
    <div className={`${className || ""} map-overlay-control-icon ${variant}`} aria-selected={selected} {...rest}>
      <IconButton onClick={onClick}>{children}</IconButton>
    </div>
  )
}

export const getMapFeatures = (map, drawGeojson, geojson, resource) => {
  let features = []

  if (drawGeojson) {
    features = [...features, ...drawGeojson.features.filter(f => f.properties.id === resource.id)]
  }

  if (geojson?.features) {
    features = [...features, ...geojson.features.filter(f => f.properties.id === resource.id)]
  }

  const hasFeatures = features.length > 0

  let center = null
  if (hasFeatures) {
    const centerPoint = findCenter(FeatureCollection(features))
    if (centerPoint) {
      center = centerPoint.geometry.coordinates
    }
  }

  return {
    features,
    center,
  }
}

export const centerMapOnFeatures = (map, center) => {
  if (!center) {
    return false
  }

  map.flyTo({center})
  return true
}

export function CenterMapButton({map}) {
  const {selectedResource, geojson, draw} = useDataContext()

  const handleOnCenter = useCallback(() => {
    if (!selectedResource?.id) {
      return
    }

    const {center} = getMapFeatures(map, draw?.geojson, geojson, selectedResource)
    centerMapOnFeatures(map, center)
  }, [map, selectedResource, draw, geojson])

  if (!selectedResource) {
    return null
  }

  return (
    <div>
      <MapIconControl onClick={handleOnCenter}>
        <CenterIcon/>
      </MapIconControl>
    </div>
  )
}
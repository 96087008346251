import {EntryInput} from "../../../../shared/components/modal";
import React, {useEffect, useReducer, useRef} from "react";
import Api from "../../../../shared/api/api";
import {ErrorToast} from "../../../../shared/utils/toast";
import Indicator from "../../../../shared/components/loader";
import {Banner} from "../../../../shared/components/views";

const DEFAULT_DATA = {
  loading: false,
  user: {
    first_name: "",
    last_name: "",
  },
}

class Timer {
  constructor(listener) {
    this.timer = null;
    this.listener = listener
  }

  unregisterListener() {
    this.listener = null
    this.cancel()
  }

  add(data) {
    this.cancel()

    const listener = this.listener
    this.timer = setTimeout(() => {
      listener(data)
    }, 800)
  }

  cancel() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }
}

const SET_LOADING = "SET_LOADING"
const setLoadingEvent = loading => ({type: SET_LOADING, payload: loading})

const SET_NAME = "SET_NAME"
const setNameEvent = data => ({type: SET_NAME, payload: data})

const RESET = "RESET"
const resetEvent = () => ({type: RESET})

function PersonalReducer(state = {}, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case SET_NAME:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }

    case RESET:
      return DEFAULT_DATA

    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

export function PersonalPane({show, school, user, isManaged, onUserUpdate}) {
  const [state, dispatch] = useReducer(PersonalReducer, DEFAULT_DATA)
  const timerRef = useRef(null);

  const setLoading = loading => dispatch(setLoadingEvent(loading))

  useEffect(() => {
    const process = (name) => {
      setLoading(true)
      Api.put(`/settings/${school.id}/user`, {name})
        .then(response => {
          setLoading(false)
          onUserUpdate && onUserUpdate(response.data.user)
        })
        .catch(e => {
          setLoading(false)

          if (e.handled) return

          console.error(e.error)
          ErrorToast("Unable to update User name")
        })
    }

    timerRef.current = new Timer(process)
    return () => {
      timerRef.current.unregisterListener()
      timerRef.current = null
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let firstName = null, lastName = null
  if (user != null && user.name.length) {
    const components = user.name.split(" ")
    firstName = components[0]
    lastName = components.slice(1).join(" ")
  }

  firstName = firstName || ""
  lastName = lastName || ""

  useEffect(() => {
    if (!show || !firstName || !lastName) dispatch(resetEvent())
    else dispatch(setNameEvent({first_name: firstName, last_name: lastName}))
  }, [show, firstName, lastName]);

  if (!show) return null

  const handleChange = (key, value) => {
    const newData = {
      first_name: state.user.first_name.trim(),
      last_name: state.user.last_name.trim(),
      [key]: value.trim()
    }

    dispatch(setNameEvent(newData))

    const newName = `${newData.first_name} ${newData.last_name}`
    const hasFirstName = newData.first_name != null && newData.first_name.length > 0
    const hasLastName = newData.last_name != null && newData.last_name.length > 0

    if (hasFirstName && hasLastName && newName !== user.name) timerRef.current.add(newName)
    else timerRef.current.cancel()
  }

  return (
    <div className="settings-personal-container">
      {isManaged && <Banner>Ipd Authentication enabled. You cannot edit Personal Information</Banner>}
      <div className="settings-personal-content">
        <EntryInput label="First Name" value={state.user.first_name} onChange={(value) => {
          handleChange("first_name", value)
        }}/>
        <EntryInput label="Last Name" value={state.user.last_name} onChange={(value) => {
          handleChange("last_name", value)
        }}/>
        <EntryInput label="Email" value={user.email} editable={false}/>
      </div>
      <Indicator show={state.loading}/>
    </div>
  )
}
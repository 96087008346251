import React from "react";

export default function Divider({disabled, ...props}) {
  disabled = !!disabled
  if (disabled) return null

  return (
    <div className="da-divider" {...props}/>
  )
}

export function VerticalDivider({disabled, ...props}) {
  disabled = !!disabled
  if (disabled) return null

  return (
    <div className="da-vertical-divider" {...props}/>
  )
}

import React, {useCallback, useEffect, useState} from "react";
import {ErrorToast} from "../../../shared/utils/toast";
import {TextButtons} from "./text_buttons";

export function DescriptionView({isCreating, resource, onUpdate}) {
  const [description, setDescription] = useState(null)

  const resolveValue = value => !value?.length ? null : value
  const hasChange = resolveValue(description) !== resolveValue(resource?.description)

  useEffect(() => {
    const newDescription = resource?.description || null
    setDescription(() => newDescription)
  }, [resource?.description]);

  const handleSave = useCallback(() => {
    if (!onUpdate) {
      return
    }

    let cleanDescription = description?.trim()
    if (!cleanDescription?.length) {
      cleanDescription = null
    }

    if (description !== cleanDescription) {
      setDescription(() => cleanDescription)
    }

    if (resource?.description !== cleanDescription) {
      onUpdate && onUpdate({
        body: {description: cleanDescription},
        onError: () => {
          ErrorToast("Unable to update the Description")
        }
      })
    }
  }, [description, onUpdate, resource?.description])

  const handleCancel = useCallback(() => {
    setDescription(resource?.description)
  }, [resource?.description])

  return (
    <div className="wireless-config-detail-description-view">
      <textarea value={description ? description : ""} onChange={e => {
        const newDescription = e.target.value.length ? e.target.value : null
        setDescription(() => newDescription)

        if (isCreating && onUpdate) {
          let cleanDescription = newDescription?.trim()
          if (cleanDescription?.length === 0) {
            cleanDescription = null
          }

          if (description !== cleanDescription) {
            onUpdate && onUpdate({
              body: {description: cleanDescription},
              onError: () => {
                ErrorToast("Unable to update the Description")
              }
            })
          }
        }
      }}/>
      <TextButtons show={hasChange && !isCreating} onSave={handleSave} onCancel={handleCancel}/>
    </div>
  )
}


import React from "react";
import {ReactComponent as EmptyIcon} from "../assets/icons/checkbox/checkbox_empty.svg"
import {ReactComponent as CheckedIcon} from "../assets/icons/checkbox/checkbox_checked.svg"

export default function Checkbox({checked, onChange, disabled = false, ...props}) {
  checked = !!checked
  disabled = !!disabled

  let {className} = props

  const classNames = ["da-checkbox"]
  if (className != null) classNames.push(className)

  const handleOnClick = () => {
    if (disabled || !onChange) {
      return
    }

    onChange(!checked)
  }

  return (
    <div className={classNames.join(" ")} aria-checked={checked} aria-disabled={disabled} onClick={handleOnClick}>
      {checked ? <CheckedIcon/> : <EmptyIcon/>}
    </div>
  )
}
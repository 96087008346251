import React, {useEffect, useReducer, useRef, useState} from "react";

import SettingsSearch from "./search";

import {CloseButton, DeleteButton, DropDownButton} from "../../../../shared/components/buttons";
import {EntryModal} from "../../../../shared/components/modal";

import Checkbox from "../../../../shared/components/checkbox";
import {EmptyView} from "../../../../shared/components/views";
import NameIcon from "../../../../shared/components/name_icon";
import Search from "../../../../shared/components/search";

import {AttributeSort} from "../../../../utils/sort";
import Card from "../../../../shared/components/card";
import {ListView} from "../../../../shared/components/list";

const DEFAULT_DATA = {
  search: {
    isSearching: false,
    term: null,
    lowered: null
  },
  delete: {
    show: false,
    user: null
  },
  uninvite: {
    show: false,
    user: null
  },
  invite: {
    show: false
  },
}

const SEARCH_USER = "SEARCH_USER"
const searchUserEvent = search => ({type: SEARCH_USER, payload: search == null || !search.length ? null : search})

const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL"
const showDeleteModalEvent = user => ({type: SHOW_DELETE_MODAL, payload: user})

const SHOW_UNINVITE_MODAL = "SHOW_UNINVITE_MODAL"
const showUninviteModalEvent = user => ({type: SHOW_UNINVITE_MODAL, payload: user})

const SHOW_INVITE_MODAL = "SHOW_INVITE_MODAL"
const showInviteModalEvent = show => ({type: SHOW_INVITE_MODAL, payload: show})

function UsersReducer(state, action) {
  switch (action.type) {
    case SEARCH_USER:
      return {
        ...state,
        search: {
          ...state.search,
          term: action.payload,
          lowered: action.payload != null ? action.payload.toLowerCase() : null,
          isSearching: action.payload != null,
        }
      }

    case SHOW_DELETE_MODAL:
      return {
        ...state,
        delete: {
          ...state.delete,
          show: action.payload != null,
          user: action.payload
        }
      }

    case SHOW_UNINVITE_MODAL:
      return {
        ...state,
        uninvite: {
          ...state.uninvite,
          show: action.payload != null,
          user: action.payload
        }
      }

    case SHOW_INVITE_MODAL:
      return {
        ...state,
        invite: {
          ...state.invite,
          show: action.payload
        }
      }

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}


export function UserPane({show, user, data, onInviteClick, onRoleClick, onRoleDropdownClose, onDeleteClick}) {
  const [state, dispatch] = useReducer(UsersReducer, DEFAULT_DATA)

  if (!show) return null

  const filterUsers = (user) => {
    if (!state.search.isSearching) return true

    return user.name.toLowerCase().includes(state.search.lowered)
      || user.email.toLowerCase().includes(state.search.lowered)
      || user.roles.map(role => role.name.toLowerCase()).filter(role => role.includes(state.search.lowered)).length > 0
  }

  const searchUsers = term => dispatch(searchUserEvent(term))
  const showInviteModal = () => dispatch(showInviteModalEvent(true))
  const hideInviteModal = () => dispatch(showInviteModalEvent(false))
  const showDeleteModal = user => dispatch(showDeleteModalEvent(user))
  const hideDeleteModal = () => dispatch(showDeleteModalEvent(null))
  const showUninviteModal = user => dispatch(showUninviteModalEvent(user))
  const hideUninviteModal = () => dispatch(showUninviteModalEvent(null))

  return (
    <div className="settings-users-list">
      <SettingsSearch
        buttonTitle="Invite User"
        search={state.search.term}
        onSearch={searchUsers}
        onAdd={showInviteModal}
      />
      <ListView>
        <div className="settings-users-user-section-container">
          <UserSection
            canManageUsers={true}
            currentUser={user}
            searching={state.search.isSearching}
            emptyText="No users added to school"
            users={data.users.filter(filterUsers)}
            roles={data.roles}
            onRoleClick={onRoleClick}
            onRoleDropdownClose={onRoleDropdownClose}
            onDeleteClick={showDeleteModal}
          />
          <UserSection
            title="Pending Assignment"
            canManageUsers={true}
            currentUser={user}
            searching={state.search.isSearching}
            emptyText="No pending users waiting for assignment"
            users={data.pending_users.filter(filterUsers)}
            roles={data.roles}
            deleteIcon="close"
            onRoleClick={onRoleClick}
            onRoleDropdownClose={onRoleDropdownClose}
            onDeleteClick={showUninviteModal}
          />
          <UserSection
            title="Invitations"
            canManageUsers={true}
            currentUser={user}
            searching={state.search.isSearching}
            emptyText="No invitations pending"
            users={data.invited_users.filter(filterUsers)}
            roles={data.roles}
            deleteIcon="close"
            onRoleClick={onRoleClick}
            onRoleDropdownClose={onRoleDropdownClose}
            onDeleteClick={showUninviteModal}
          />
        </div>
      </ListView>
      <InviteUserModal
        show={state.invite.show}
        roles={data.roles}
        onHide={hideInviteModal}
        onInvite={(emails, roles) => onInviteClick(emails, roles, hideInviteModal)}
      />
      <DeleteUserModal
        show={state.delete.show}
        user={state.delete.user}
        onHide={hideDeleteModal}
        onDelete={() => onDeleteClick(state.delete.user, hideDeleteModal)}
      />
      <UninviteUserModal
        show={state.uninvite.show}
        user={state.uninvite.user}
        onHide={hideUninviteModal}
        onDelete={() => onDeleteClick(state.uninvite.user, hideUninviteModal)}
      />
    </div>
  )
}

function UserSection({title, searching, emptyText, canManageUsers, currentUser, users, roles, onRoleClick, onRoleDropdownClose, deleteIcon, onDeleteClick}) {
  const hasTitle = !!title
  const hasUsers = users != null && users.length > 0

  return (
    <div className="settings-users-section">
      {hasTitle && <span className="settings-users-section-title">{title}</span>}
      <div className="settings-users-section-header settings-users-table-layout">
        <span>USER</span>
        <span>ROLE</span>
        <span/>
      </div>
      {!hasUsers && <EmptyView>{searching ? "No users found." : emptyText}</EmptyView>}
      {hasUsers && users.map((user) =>
        <UserRow
          key={user.id}
          canManageUsers={canManageUsers}
          currentUser={currentUser}
          user={user}
          roles={roles}
          deleteIcon={deleteIcon}
          onRoleClick={onRoleClick}
          onRoleDropdownClose={onRoleDropdownClose}
          onDeleteClick={onDeleteClick}
        />
      )}
    </div>
  )
}

function UserRow({canManageUsers, currentUser, user, roles, deleteIcon, onRoleClick, onRoleDropdownClose, onDeleteClick}) {
  const isCurrentUser = currentUser.id === user.id
  const hasButtons = !!canManageUsers && !isCurrentUser

  const handleDelete = () => onDeleteClick && onDeleteClick(user)

  const deleteButton = deleteIcon === "close"
    ? <CloseButton variant="danger" onClick={handleDelete}/>
    : <DeleteButton onClick={handleDelete}/>

  return (
    <div className="settings-users-row-container settings-users-table-layout">
      <div className="settings-users-row-user-info">
        <NameIcon name={user.name}/>
        <div className="settings-users-row-names">
          <span className="title">{user.name} {isCurrentUser && <span>(You)</span>}</span>
          <span className="email">{user.email}</span>
        </div>
      </div>
      <RoleSelector
        selectedRoles={user.roles}
        roles={roles}
        onRoleClick={(role, isAdding) => onRoleClick && onRoleClick(user, role, isAdding)}
        onRoleDropdownClose={onRoleDropdownClose}
      />
      <div className="settings-users-row-buttons">
        {hasButtons && deleteButton}
      </div>
    </div>
  )
}

const DEFAULT_POSITION = {top: 0, left: 0}

function RoleSelector({selectedRoles, roles, alignment = "left", onRoleClick, onRoleDropdownClose}) {
  const buttonRef = useRef()
  const [show, setShow] = useState(false)
  const [position, setPosition] = useState(DEFAULT_POSITION)

  useEffect(() => {
    if (!show || buttonRef.current == null) {
      setPosition(DEFAULT_POSITION)
      return
    }

    const newPosition = {top: buttonRef.current?.getBoundingClientRect().height}
    if (alignment === "right") newPosition.right = 0
    else newPosition.left = 0
    setPosition(newPosition)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, alignment])

  const showDropdown = () => setShow(true)
  const hideDropdown = () => {
    setShow(false)
    onRoleDropdownClose && onRoleDropdownClose()
  }

  if (selectedRoles == null) selectedRoles = []
  if (roles == null) roles = []

  let buttonTitle
  switch (selectedRoles.length) {
    case 0:
      buttonTitle = "Not Assigned"
      break

    case 1: {
      buttonTitle = selectedRoles[0].name
      break
    }

    default:
      buttonTitle = `${selectedRoles.length} groups`
      break
  }

  return (
    <div className="settings-users-row-role-actions">
      <DropDownButton title={buttonTitle} buttonRef={buttonRef} onClick={showDropdown}>
        <RoleDropDownModal
          show={show}
          position={position}
          selectedRoles={selectedRoles}
          roles={roles}
          onRoleClick={onRoleClick}
          onHide={hideDropdown}
        />
      </DropDownButton>
    </div>
  )
}

function InviteUserModal({show, roles, onHide, onInvite}) {
  const [emails, setEmails] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  useEffect(() => {
    setEmails([])
    setSelectedRoles([])
  }, [show]);

  if (!show) return null

  const handleInvite = () => onInvite && onInvite(emails, selectedRoles.map(r => r.id))
  const handleEmails = newEmails => {
    setEmails(newEmails)
  }
  const handleRoles = (role, isAdding) => {
    let newRoles
    if (isAdding) newRoles = [...selectedRoles, role].sort(AttributeSort("name"))
    else newRoles = selectedRoles.filter(r => r.id !== role.id)

    setSelectedRoles(newRoles)
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Invite",
      disabled: !emails.length || !selectedRoles.length,
      onClick: handleInvite
    }
  }

  return (
    <EntryModal show={show} title="Invite Users" onHide={onHide} buttons={buttons} cardClassName="da-modal-invite-content" bodyClassName="da-modal-invite-body">
      <EmailView emails={emails} onChange={handleEmails}>
        <RoleSelector alignment="right" selectedRoles={selectedRoles} roles={roles} onRoleClick={handleRoles}/>
      </EmailView>
    </EntryModal>
  )
}

function EmailView({emails, onChange, ...props}) {
  const [value, setValue] = useState("")
  const hasEmails = emails != null && emails.length > 0
  const placeholder = hasEmails ? null : "john.doe@example.com"

  const handleText = (e) => setValue(e.target.value.trim())

  const handleKeyDown = (e) => {
    const newValue = value.trim()
    if (e.key !== "Enter" || !newValue.length || emails.includes(newValue)) return

    onChange([
      newValue,
      ...emails
    ])

    setValue("")

  }
  const handleRemove = (email) => {
    onChange(emails.filter(e => e !== email))
  }

  return (
    <div className="settings-users-invite-email-container">
      <div className="settings-users-invite-email-header">
        <input autoFocus placeholder={placeholder} value={value} onKeyDown={handleKeyDown} onChange={handleText}/>
        {props.children}
      </div>
      <div className="settings-users-invite-emails">
        {emails.map((email, i) => <EmailViewItem key={`email-${i}`} email={email} onRemove={() => handleRemove(email)}/>)}
      </div>
    </div>
  )
}

function EmailViewItem({email, onRemove}) {
  return (
    <div className="settings-users-invite-email-item-container">
      <span>{email}</span>
      <div className="settings-users-invite-email-item-button">
        <CloseButton onClick={onRemove}/>
      </div>
    </div>
  )
}

function DeleteUserModal({show, user, onHide, onDelete}) {
  if (!show || user == null) return null

  const handleOnClick = () => onDelete && onDelete(user)

  const buttons = {
    secondary: {
      title: "No, keep this user",
      onClick: onHide
    },
    danger: {
      title: "Yes, delete this user",
      onClick: handleOnClick
    }
  }

  return (
    <EntryModal show={show} title={`Delete ${user.name}?`} onHide={onHide} buttons={buttons} cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body">
      <p>{`This action can not be undone. The deletion is permanent and ${user.name} will not have access to their account.`}</p>
    </EntryModal>
  )
}

function UninviteUserModal({show, user, onHide, onDelete}) {
  if (!show || user == null) return null

  const handleOnClick = () => onDelete && onDelete(user)

  const buttons = {
    secondary: {
      title: "No, keep this user",
      onClick: onHide
    },
    danger: {
      title: "Yes, uninvite this user",
      onClick: handleOnClick
    }
  }

  return (
    <EntryModal show={show} title={`Uninvite ${user.name}?`} onHide={onHide} buttons={buttons} cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body">
      <p>{`This action can not be undone. The deletion is permanent and ${user.name} will not have access to their account.`}</p>
    </EntryModal>
  )
}

function RoleDropDownModal({show, position, selectedRoles, roles, onRoleClick, onHide}) {
  const [search, setSearch] = useState(null)
  useEffect(() => {
    if (!show && search != null) setSearch(null)
  }, [show, search]);

  if (!show) return

  const filterRoles = role => {
    if (!search) return true

    return role.name.toLowerCase().includes(search.toLowerCase())
  }

  const userLookup = new Set(selectedRoles.map((role) => role.id))
  const formattedRoles = roles.map((role) => ({...role, isAdded: userLookup.has(role.id), original: role}))
  const hasRoles = formattedRoles.length > 0

  return (
    <DropDownModal className="settings-users-dropdown-modal" cardClassName="settings-users-dropdown-modal-card" show={show} onHide={onHide} position={position}>
      <div>
        <Search className="dark-search" search={search} onSearch={setSearch}/>
      </div>
      <div className="settings-users-dropdown-role-list">
        {hasRoles && formattedRoles.filter(filterRoles).map((role) => <RoleItem key={role.id} role={role} onClick={onRoleClick}/>)}
      </div>
    </DropDownModal>
  )
}

function RoleItem({role, onClick}) {
  const disabled = !role.assignable

  const handleClick = () => {
    if (disabled || onClick == null) {
      return
    }

    onClick(role.original, !role.isAdded)
  }


  return (
    <div className="settings-users-user-role-item" aria-disabled={disabled} onClick={handleClick}>
      <Checkbox checked={role.isAdded} disabled={disabled} onChange={handleClick}/>
      <span>{role.name}</span>
    </div>
  )
}

function DropDownModal({show, position, onHide, ...props}) {
  const modalRef = useRef()
  const cardRef = useRef()

  useEffect(() => {
    if (!show || modalRef.current == null) return

    const handleOutside = (e) => {
      if (!modalRef.current?.contains(e.target)) {
        onHide && onHide()
      }
    }

    window.addEventListener("mouseup", handleOutside, {passive: true})
    return () => window.removeEventListener("mouseup", handleOutside, {passive: true})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, modalRef]);

  if (!show || position == null) return null

  let {className, cardClassName, style, children, ...rest} = props
  if (className == null) className = "da-dropdown-modal"
  else className += " da-dropdown-modal"

  style = {
    ...(style || {}),
    ...(position || {})
  }

  return (
    <div className={className} style={style} ref={modalRef} {...rest}>
      <Card className={cardClassName} cardRef={cardRef} {...rest}>
        {children}
      </Card>
    </div>
  )
}

import {Badge} from "./badge";
import {ListView} from "./list";

export function Tabs({...props}) {
  const {className, children, ...rest} = props;
  const classNames = ["da-tab"]
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(" ")} {...rest}>
      {children}
    </div>
  )
}

export function TabHeader({selectedTab, items, onSelectTab, ...props}) {
  const {className, ...rest} = props;
  const classNames = ["da-tab-header"]
  if (className != null) classNames.push(className)

  return (
    <div className={classNames.join(" ")} {...rest}>
      {(items || []).map(item =>
        <TabHeaderItem
          key={item.id}
          selected={selectedTab === item.id}
          item={item}
          onClick={() => onSelectTab(item)}
        />
      )}
    </div>
  )
}

function TabHeaderItem({selected, item, onClick}) {
  selected = !!selected

  const classNames = ["da-tab-header-item"]
  if (selected) classNames.push("da-tab-header-item-selected")
  return (
    <div className={classNames.join(" ")} onClick={() => onClick && onClick()}>
      <span>{item.label}</span>
      <Badge count={item.badgeCount}/>
    </div>
  )
}

export function TabBody({...props}) {
  let {className, children} = props

  if (className == null) className = "da-tab-body"
  else className = `da-tab-body ${className}`

  return (
    <ListView>
      <div className={className}>
        {children}
      </div>
    </ListView>
  )
}
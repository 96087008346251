export function Badge({count}) {
  if (!count) {
    return null
  }

  if (count > 99) {
    count = "99+"
  }

  return (
    <div className="badge">{count}</div>
  )
}
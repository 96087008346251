import {createContext, useCallback, useContext, useEffect, useReducer} from "react"
import {LoadingStatus} from "../shared/api/loading";
import Api from "../shared/api/api";
import {ErrorToast} from "../shared/utils/toast";
import {AttributeSort} from "../utils/sort";
import {useParams} from "react-router";

export const AlertContext = createContext(null)
export const useAlertContext = () => useContext(AlertContext)

const RESET = "RESET"

const SET_LOADING_STATE = "SET_LOADING_STATE"
const SET_FILTER = "SET_FILTER"
const SET_SEARCH = "SET_SEARCH"

const SET_DATA = "SET_DATA"
const ADD_DATA = "ADD_DATA"
const UPDATE_DATA = "UPDATE_DATA"
const DELETE_DATA = "DELETE_DATA"

export const HEADER_FILTER_IDS = {
  ALL: "All",
  STUDENT: "Student",
  FACILITY: "Facility",
  GENERAL: "General"
}

const buildLookup = (alerts) => {
  const out = {}

  for (let alert of alerts) {
    out[alert.id] = alert
  }

  return out
}

const filterData = (filter, search, data) => {
  if (search != null) {
    search = search.toLowerCase()
  }

  return data.filter(d => searchFilter(filter, d) && searchData(search, d))
}

const searchFilter = (filter, data) => {
  if (filter === HEADER_FILTER_IDS.ALL) {
    return true
  }

  return data.type === filter
}

const searchData = (search, data) => {
  if (search == null) {
    return true
  }

  if (data.name != null && data.name.toLowerCase().includes(search)) {
    return true
  }

  if (data.schedule != null) {
    if (data.schedule.action != null && data.schedule.action.toLowerCase().includes(search)) {
      return true
    }

    if (data.schedule.time_as_string != null && data.schedule.time_as_string.toLowerCase().includes(search)) {
      return true
    }
  }

  return false
}

const metaWrapper = (state) => {
  let facility = 0, general = 0, student = 0
  state.data.forEach(item => {
    switch (item.type) {
      case "General":
        general += 1
        break

      case "Facility":
        facility += 1
        break

      case "Student":
        student += 1
        break

      default:
        throw new Error(`Unknown type ${item.type}`)
    }
  })

  return {
    ...state,
    meta: {
      all: state.data.length,
      facility,
      student,
      general
    }
  }
}

const ViewState = {
  NEW: 0,
  EDIT: 1
}

const DEFAULT_DATA = {
  loading: false,
  loadingState: LoadingStatus.NONE,
  search: {
    value: null,
  },
  meta: {
    all: 0,
    facility: 0,
    student: 0,
    general: 0,
  },
  data: [],
  alerts: [],
  lookup: {},
  selectedFilter: HEADER_FILTER_IDS.ALL,
}

const AlertProviderReducer = (state, action) => {
  switch (action.type) {
    case RESET:
      return DEFAULT_DATA

    case SET_LOADING_STATE:
      return {
        ...state,
        loading: action.payload === LoadingStatus.FETCHING,
        loadingState: action.payload,
      }

    case SET_FILTER:
      return metaWrapper({
        ...state,
        selectedFilter: action.payload,
        alerts: filterData(action.payload, state.search.value, state.data),
      })

    case SET_SEARCH:
      return metaWrapper({
        ...state,
        search: {value: action.payload},
        alerts: filterData(state.selectedFilter, action.payload, state.data),
      })

    case SET_DATA:
      return metaWrapper({
        ...state,
        loading: false,
        loadingState: LoadingStatus.FETCHED,
        data: action.payload.alerts,
        alerts: filterData(state.selectedFilter, state.search.value, action.payload.alerts),
        lookup: buildLookup(action.payload.alerts),
      })

    case ADD_DATA:
    case UPDATE_DATA: {
      const newData = [...state.data.filter(d => d.id !== action.payload.id), action.payload].sort(AttributeSort("name"))

      return metaWrapper({
        ...state,
        loading: false,
        loadingState: LoadingStatus.FETCHED,
        data: newData,
        alerts: filterData(state.selectedFilter, state.search.value, newData),
        lookup: buildLookup(newData),
      })
    }

    case DELETE_DATA: {
      const newData = state.data.filter(d => d.id !== action.payload)

      return metaWrapper({
        ...state,
        loading: false,
        loadingState: LoadingStatus.FETCHED,
        data: newData,
        alerts: filterData(state.selectedFilter, state.search.value, newData),
        lookup: buildLookup(newData),
      })
    }

    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

export const useAlertProvider = (school) => {
  const [state, dispatch] = useReducer(AlertProviderReducer, DEFAULT_DATA)
  const {alertId} = useParams()

  let isCreate = false
  let selectedAlert = null
  if (alertId != null) {
    if (alertId === "new") {
      isCreate = true
    } else {
      selectedAlert = state.lookup[alertId]
    }
  }

  useEffect(() => {
    dispatch({type: RESET})
  }, [school?.id])

  const setLoadingState = useCallback(state => dispatch({type: SET_LOADING_STATE, payload: state}), [])
  const viewState = ViewState.NEW

  return {
    loading: state.loading,
    viewState,
    school: school,

    detail: {
      isCreate
    },
    data: {
      selectedFilter: state.selectedFilter,
      search: state.search.value,
      meta: state.meta,
      alerts: state.alerts,
      selectedAlert
    },

    loadDataIfNeeded: useCallback(() => {
      if (!school?.id || state.loadingState !== LoadingStatus.NONE) return

      setLoadingState(LoadingStatus.FETCHING)

      const controller = new AbortController()
      Api.get(`schools/${school?.id}/alerts`, {signal: controller.signal})
        .then(response => dispatch({type: SET_DATA, payload: response.data}))
        .catch(e => {
          setLoadingState(LoadingStatus.ERROR)

          if (e.handled) return

          console.log(e.error)
          ErrorToast("Unable to fetch School Alert data")
        })

      return () => controller.abort()
    }, [school?.id, setLoadingState, state.loadingState]),

    actions: {
      setFilter: useCallback(filter => dispatch({type: SET_FILTER, payload: filter.id}), []),
      setSearch: useCallback(search => dispatch({type: SET_SEARCH, payload: search}), []),

      run: useCallback((alertId, options) => {
        if (!school?.id) {
          return
        }

        const {successCallback, errorCallback} = options

        setLoadingState(LoadingStatus.FETCHING)
        Api.post(`schools/${school?.id}/alerts/${alertId}/run`, {})
          .then(_ => {
            setLoadingState(LoadingStatus.FETCHED)
            successCallback && successCallback()
          })
          .catch(e => {
            setLoadingState(LoadingStatus.ERROR)

            if (e.handled) return

            console.error(e.error)
            errorCallback && errorCallback(e.error)
          })
      }, [school?.id, setLoadingState]),

      add: useCallback((body, options) => {
        if (!school?.id || !body) {
          return
        }

        const {successCallback, errorCallback} = options

        setLoadingState(LoadingStatus.FETCHING)
        Api.post(`schools/${school.id}/alerts`, body)
          .then(response => {
            dispatch({type: ADD_DATA, payload: response.data.alert})
            successCallback && successCallback()
          })
          .catch(e => {
            setLoadingState(LoadingStatus.ERROR)

            if (e.handled) return

            console.error(e.error)
            errorCallback && errorCallback(e.error)
          })
      }, [school?.id, setLoadingState]),
      update: useCallback((alert, options) => {
        if (!school?.id || !alert?.id) {
          return
        }

        let {data, body, successCallback, errorCallback} = options
        if (!data) {
          data = body
        }

        const updateData = values => dispatch({type: UPDATE_DATA, payload: {...alert, ...values}})

        setLoadingState(LoadingStatus.FETCHING)
        updateData(data)

        Api.put(`schools/${school.id}/alerts/${alert.id}`, body)
          .then(response => {
            updateData(response.data.alert)
            successCallback && successCallback()
          })
          .catch(e => {
            setLoadingState(LoadingStatus.ERROR)

            if (e.handled) return

            console.error(e.error)
            updateData(alert)
            errorCallback && errorCallback(e.error)
          })
      }, [school?.id, setLoadingState]),
      delete: useCallback((alertId, options) => {
        if (!school?.id || !alertId) {
          return
        }

        setLoadingState(LoadingStatus.FETCHING)
        const {successCallback, errorCallback} = options
        Api.delete(`schools/${school.id}/alerts/${alertId}`)
          .then(_ => {
            dispatch({type: DELETE_DATA, payload: alertId})
            successCallback && successCallback()
          })
          .catch(e => {
            setLoadingState(LoadingStatus.ERROR)

            if (e.handled) return

            console.error(e.error)
            errorCallback && errorCallback(e.error)
          })
      }, [school?.id, setLoadingState])
    }
  }
}
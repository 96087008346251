import {useEffect, useRef} from "react";

export function ListView(props) {
  const {className, children, ...rest} = props;
  const classNames = ["scroll-view"]
  if (className) {
    classNames.push(className)
  }

  return (
    <div className={classNames.join(" ")} {...rest}>
      <div className="scroll-view-container">
        <div className="scrollable">
          {children}
        </div>
      </div>
    </div>
  )
}

function LoadingListItem({text, onViewed, ...props}) {
  const timerRef = useRef(null)
  const observerRef = useRef(null)
  const elementRef = useRef(null)

  const {className, ...rest} = props
  const classNames = ["paginated-scroll-view-loading-item"]
  if (className != null) classNames.push(className)

  useEffect(() => {
    const loader = () => {
      if (elementRef.current == null) {
        return
      }

      if (observerRef.current != null) {
        observerRef.current.disconnect()
      }

      observerRef.current = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && onViewed) {
            onViewed()
          }
        })
      })

      observerRef.current.observe(elementRef.current)

      clearInterval(timerRef.current)
    }

    timerRef.current = setInterval(loader, 100)
    return () => {
      if (timerRef.current != null) {
        clearInterval(timerRef.current)
      }
    }
  }, [onViewed]);

  return (
    <div className={classNames.join(" ")} {...rest} ref={elementRef}>
      <span>{text}</span>
    </div>
  )
}

export function PaginatedListView({hasMore, loadingText = "Loading...", loadingClassName, onPaginate, ...props}) {
  hasMore = !!hasMore
  const {children, ...rest} = props;

  return (
    <ListView {...rest}>
      {children}
      {hasMore && <LoadingListItem className={loadingClassName} text={loadingText} onViewed={onPaginate}/>}
    </ListView>
  )
}
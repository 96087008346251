import React, {useCallback, useEffect, useReducer} from "react"

import "react-toastify/dist/ReactToastify.css"
import "mapbox-gl/dist/mapbox-gl.css"

import "./index.css"
import "./shared/css/App.css"
import "./shared/css/AttributeInput.css"
import "./shared/css/Button.css"
import "./shared/css/LoginRedirect.css"
import "./shared/css/NavBar.css"
import "./shared/css/SplitLayout.css"
import "./shared/css/TableView.css"

import "./shared/css/pages/MapPage.css"
import "./shared/css/pages/SchoolPage.css"
import "./shared/css/pages/SettingsPage.css"

import "./css/App.css"
import "./css/NavBar.css"
import "./css/MapPage.css"
import "./css/DashboardPage.css"
import "./css/SchoolPage.css"
import "./css/WirelessConfig.css"
import "./css/AlertsPage.css"
import "./css/SparkPage.css"
import "./css/SettingsPage.css"

import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom"
import {useLocation} from "react-router"
import {ToastContainer} from "react-toastify"

import Api from "./shared/api/api"

import NavigationBar from "./shared/components/navbar"
import IdleTimeout from "./components/idle_timeout"

import MapView from "./pages/map/view"
import Console from "./pages/console"
import SchoolView from "./pages/school/view"
import WirelessConfigView from "./pages/wireless_config/view"
import AlertsView from "./pages/alerts/view"

import Indicator from "./shared/components/loader"

import {ReactComponent as AlertIcon} from "./shared/assets/icons/navbar/alert_icon.svg"
import {ReactComponent as DashboardIcon} from "./shared/assets/icons/navbar/dashboard_icon.svg"
import {ReactComponent as LogoutIcon} from "./shared/assets/icons/navbar/logout_icon.svg"
import {ReactComponent as MapIcon} from "./shared/assets/icons/navbar/map_icon.svg"
import {ReactComponent as SchoolIcon} from "./shared/assets/icons/navbar/school_icon.svg"
import {ReactComponent as SettingsIcon} from "./shared/assets/icons/navbar/settings_icon.svg"
import {ReactComponent as WirelessConfigIcon} from "./shared/assets/icons/navbar/wireless_icon.svg"

import SettingsModal from "./components/modals/settings"
import {ErrorToast} from "./shared/utils/toast"
import {initMap} from "./shared/hooks/map"
import {SchoolDropdown} from "./components/dropdown"
import {AttributeSort} from "./utils/sort"
import {ErrorMessage} from "./pages/error"
import {AlertDetail} from "./pages/alerts/detail"
import {TabOptions} from "./pages/wireless_config/consts"
import {SparkView} from "./pages/spark/view"

import WareHouse from "./data/warehouse"
import {AlertContext, useAlertProvider} from "./hooks/alert_context";

initMap(process.env.REACT_APP_MAP_ACCESS_KEY)

const DEFAULT_DATA = {
  loading: false,
  showSettings: false,
  user: {
    id: null,
    name: null,
    email: null
  },
  school: null,
  schools: [],
  user_settings: {},
  is_system_admin: false,
  notifications: {
    count: 0,
    settings: {
      count: 0,
      personal: 0,
      users: 0,
      roles: 0,
      admin_integrations: 0,
      school_integrations: 0,
    },
  },
  wifiConfigDataReload: {
    state: 0
  }
}

const SET_LOADING = "SET_LOADING"
const SHOW_SETTINGS = "SHOW_SETTINGS"
const SET_DATA = "SET_DATA"
const UPDATE_USER = "UPDATE_USER"
const SET_SCHOOL = "SET_SCHOOL"
const ADD_SCHOOL = "ADD_SCHOOL"
const UPDATE_SCHOOL = "UPDATE_SCHOOL"
const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
const SET_USER_SETTINGS = "SET_USER_SETTINGS"

const SET_WIFI_CONFIG_DATA_RELOAD = "SET_WIFI_CONFIG_DATA_RELOAD"


const AppReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case SHOW_SETTINGS:
      return {
        ...state,
        showSettings: action.payload
      }

    case SET_DATA: {
      return {
        ...state,
        loading: false,
        ...action.payload
      }
    }

    case UPDATE_USER:
      return {
        ...state,
        user: action.payload
      }

    case SET_SCHOOL: {
      WareHouse.wireless_config.clear()
      WareHouse.onSchoolChanged(action.payload)

      return {
        ...state,
        school: action.payload
      }
    }

    case ADD_SCHOOL:
      return {
        ...state,
        school: action.payload,
        schools: [...state.schools, action.payload].sort(AttributeSort("name"))
      }

    case UPDATE_SCHOOL: {
      const newSchool = state.school.id === action.payload.id ? action.payload : state.school
      const newSchools = state.schools.map(s => s.id === action.payload.id ? newSchool : s)

      return {
        ...state,
        school: newSchool,
        schools: newSchools
      }
    }

    case SET_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      }

    case SET_USER_SETTINGS:
      return {
        ...state,
        ...action.payload
      }

    case SET_WIFI_CONFIG_DATA_RELOAD: {
      return {
        ...state,
        wifiConfigDataReload: {
          state: action.payload
        }
      }
    }

    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
}

const NON_LOGIN_SCREEN_PATHS = [
  "/error"
]

const isUserRequiredScreen = () => {
  const paths = NON_LOGIN_SCREEN_PATHS.filter(p => window.location.pathname.startsWith(p))
  return paths.length === 0
}

function App() {
  const [state, dispatch] = useReducer(AppReducer, DEFAULT_DATA)

  const setLoading = loading => dispatch({type: SET_LOADING, payload: loading});

  const showSettings = () => dispatch({type: SHOW_SETTINGS, payload: true})
  const hideSettings = () => dispatch({type: SHOW_SETTINGS, payload: false})

  const setData = data => dispatch({type: SET_DATA, payload: data})
  const updateUser = user => dispatch({type: UPDATE_USER, payload: user})

  const setSchool = school => {
    dispatch({type: SET_SCHOOL, payload: school})
    Api.put("/user", {school_id: school.id})
      .then(response => setUserSettings(response.data))
      .catch(e => {
        if (e.handled) return
        console.error(e)
      })
  }

  const addSchool = school => dispatch({type: ADD_SCHOOL, payload: school})
  const updateSchool = school => dispatch({type: UPDATE_SCHOOL, payload: school})

  const setNotifications = (data) => dispatch({type: SET_NOTIFICATIONS, payload: data})
  const setUserSettings = settings => dispatch({type: SET_USER_SETTINGS, payload: settings})

  const flagWifiConfigDataReload = () => dispatch({type: SET_WIFI_CONFIG_DATA_RELOAD, payload: 1})

  let userRequired = isUserRequiredScreen()

  useEffect(() => {
    if (!userRequired) {
      return
    }

    setLoading(true)

    const controller = new AbortController();
    Api.get(`/landing`, {signal: controller.signal})
      .then(response => setData(response.data))
      .catch(e => {
        if (e.handled) return

        console.error(e.error)
        ErrorToast("Unable to load data")
      })

    return () => controller.abort()
  }, [userRequired]);

  const clearWifiConfigDataReload = useCallback(() => {
    dispatch({type: SET_WIFI_CONFIG_DATA_RELOAD, payload: 0})
  }, [])

  const onDropdownItemSelected = (optionId) => {
    switch (optionId) {
      case DROPDOWN_OPTION_IDS.HELP:
        console.log("HELP clicked")
        break

      case DROPDOWN_OPTION_IDS.CHAT:
        console.log("CHAT clicked")
        break

      case DROPDOWN_OPTION_IDS.SETTINGS:
        showSettings()
        break

      case DROPDOWN_OPTION_IDS.LOGOUT:
        Api.post(`/auth/logout`, {})
          .catch(e => {
            if (e.handled) return

            console.error(e.error)
            ErrorToast("Unable to logout")
          })
        break

      default:
        throw new Error(`Unknown DropdownItem id ${optionId}`)
    }
  }

  const handleOnPreviousSchool = () => {
    let newIndex = state.schools.findIndex(s => s.id === state.school.id) - 1
    if (newIndex < 0) {
      newIndex = state.schools.length - 1
    }

    setSchool(state.schools[newIndex])
  }

  const handleOnNextSchool = () => {
    let newIndex = state.schools.findIndex(s => s.id === state.school.id) + 1
    if (newIndex >= state.schools.length) {
      newIndex = 0
    }

    setSchool(state.schools[newIndex])
  }

  const handleLocationChange = (school) => {
    updateSchool(school)
  }

  return (
    <>
      <IdleTimeout/>
      <div id="map-content"/>
      {!state.loading &&
        <>
          <BrowserRouter>
            <Routes>
              <Route path="/error" element={<ErrorMessage/>}/>
              <Route path="/" element={<Layout state={state} onSchoolSelected={setSchool} onDropdownItemSelected={onDropdownItemSelected}/>}>
                {state.is_system_admin && <Route path="/" element={
                  <MapView school={state.school}/>
                }/>}
                {state.is_system_admin && <Route path="/dashboard" element={
                  <Console school={state.school}/>
                }/>}
                {state.is_system_admin && <Route path="/school" element={
                  <SchoolView
                    school={state.school}
                    schools={state.schools}
                    carouselDisabled={state.schools.length <= 1}
                    onSchoolAdded={addSchool}
                    onPreviousSchool={handleOnPreviousSchool}
                    onNextSchool={handleOnNextSchool}
                    onSchoolSelected={setSchool}
                    onLocationChange={handleLocationChange}
                  />
                }/>}
                {!state.is_system_admin && <Route path="/" element={
                  <WirelessConfigView
                    school={state.school}
                    reloadState={state.wifiConfigDataReload.state}
                    onReloaded={clearWifiConfigDataReload}
                  />
                }/>}
                <Route path="/wireless_config" element={
                  <WirelessConfigView
                    school={state.school}
                    reloadState={state.wifiConfigDataReload.state}
                    onReloaded={clearWifiConfigDataReload}
                  />
                }/>
                {state.is_system_admin && <Route path="/alert" element={
                  <AlertsView/>
                }/>}
                {state.is_system_admin && <Route path="/alert/:alertId" element={
                  <AlertDetail/>
                }/>}
                {state.is_system_admin && <Route path="/spark" element={
                  <SparkView school={state.school}/>
                }/>}
              </Route>
            </Routes>
          </BrowserRouter>
          <div id="modal-content"/>
          {state.is_system_admin &&
            <SettingsModal
              show={state.showSettings}
              school={state.school}
              schools={state.schools}
              onSchoolSelected={setSchool}
              user={state.user}
              notifications={state.notifications.settings}
              onHide={hideSettings}
              onUserUpdate={updateUser}
              onNotificationsUpdated={() => {
                Api.get(`/landing/notifications`)
                  .then(response => setNotifications(response.data))
                  .catch(e => {
                    if (e.handled) return
                    console.error(e.error)
                    ErrorToast("Unable to fetch notifications")
                  })
              }}
              onApsUpdated={flagWifiConfigDataReload}
            />
          }
        </>
      }
      <ToastContainer position="bottom-center"/>
      <Indicator show={state.loading}/>
    </>
  )
}

const NAVIGATION_OPTION_IDS = {
  ALERT: "alert",
  MAP: "map",
  DASHBOARD: "dashboard",
  SCHOOL: "school",
  WIRELESS_CONFIG: "wireless_config",
  SPARK: "spark"
}

const NAVIGATION_OPTIONS = {
  badgeCount: 0,
  routes: [
    {id: NAVIGATION_OPTION_IDS.MAP, requiresSystemAdmin: true, label: "Map", route: "", icon: <MapIcon/>},
    {id: NAVIGATION_OPTION_IDS.DASHBOARD, requiresSystemAdmin: true, label: "Dashboard", icon: <DashboardIcon/>},
    {id: NAVIGATION_OPTION_IDS.SCHOOL, requiresSystemAdmin: true, label: "School", icon: <SchoolIcon/>},
    {id: NAVIGATION_OPTION_IDS.WIRELESS_CONFIG, requiresSystemAdmin: false, label: "Wireless Config", search: `?tab=${TabOptions[0].id}`, icon: <WirelessConfigIcon/>},
    {id: NAVIGATION_OPTION_IDS.ALERT, requiresSystemAdmin: true, label: "Alerts", icon: <AlertIcon/>},
    {id: NAVIGATION_OPTION_IDS.SPARK, requiresSystemAdmin: true, label: "Spark", icon: <AlertIcon/>}
  ]
}

const getCurrentId = location => {
  let {pathname} = location;
  pathname = pathname.slice(1)

  // default landing page
  if (!pathname.length) return NAVIGATION_OPTION_IDS.MAP

  const components = pathname.split("/");
  if (!components.length) return null

  return components[0]
}

const DROPDOWN_OPTION_IDS = {
  HELP: "help",
  CHAT: "chat",
  SETTINGS: "settings",
  LOGOUT: "logout"
}

const DROPDOWN_OPTIONS = [
  // [
  //   {id: DROPDOWN_OPTION_IDS.HELP, label: "Help & Tutorials", icon: <HelpIcon/>},
  //   {id: DROPDOWN_OPTION_IDS.CHAT, label: "Chat with Support", icon: <ChatIcon/>}
  // ],
  [
    {id: DROPDOWN_OPTION_IDS.SETTINGS, requiresSystemAdmin: true, label: "Settings", icon: <SettingsIcon/>},
    {id: DROPDOWN_OPTION_IDS.LOGOUT, requiresSystemAdmin: false, label: "Log out", className: "danger-action", icon: <LogoutIcon/>}
  ]
]

function Layout({state, onSchoolSelected, onDropdownItemSelected}) {
  const alertProvider = useAlertProvider(state.school)
  const location = useLocation()
  const currentId = getCurrentId(location)

  const permissionFilter = o => !o.requiresSystemAdmin || (o.requiresSystemAdmin && state.is_system_admin)

  const navigationOptions = {
    ...{
      ...NAVIGATION_OPTIONS,
      routes: NAVIGATION_OPTIONS.routes.filter(permissionFilter)
    },
    badgeCount: state.notifications.count
  }

  const buildOption = option => {
    return {
      ...option,
      badgeCount: state.notifications[option.id] != null ? state.notifications[option.id].count : 0
    }
  }

  const dropdownOptions = DROPDOWN_OPTIONS.map(dropdown => dropdown.filter(permissionFilter).map(buildOption))

  return (
    <AlertContext.Provider value={alertProvider}>
      <div className="app-container">
        <NavigationBar user={state.user} currentId={currentId} navigationOptions={navigationOptions} dropdownOptions={dropdownOptions} onDropdownItemSelected={onDropdownItemSelected}>
          <SchoolDropdown currentSchool={state.school} schools={state.schools} onSchoolSelected={onSchoolSelected}/>
        </NavigationBar>
        <Outlet/>
      </div>
    </AlertContext.Provider>
  )
}

export default App;

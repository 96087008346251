import React, {useCallback, useEffect, useMemo, useReducer, useState} from "react"
import {LoadingStatus} from "../../../../shared/api/loading"
import Api from "../../../../shared/api/api"
import {ErrorToast} from "../../../../shared/utils/toast"
import Indicator from "../../../../shared/components/loader"
import {ListView} from "../../../../shared/components/list"
import {EmptyView} from "../../../../shared/components/views"
import SettingsSearch from "./search"
import {Pill, PillGroup} from "../../../../shared/components/pill";
import {AttributeSort} from "../../../../utils/sort";
import {DeleteEntryModal, EntryInput, EntryModal} from "../../../../shared/components/modal";
import {DeleteButton} from "../../../../shared/components/buttons";

const DEFAULT_DATA = {
  loading: false,
  loading_state: LoadingStatus.NONE,
  search: {
    isSearching: false,
    term: null,
    lowered: null
  },
  data: [],
  users: [],
  roles: [],
  showNewUserModal: false,
  deleteUser: null
}

const buildEvents = base => ({
  STARTED: `${base}_STARTED`,
  FINISHED: `${base}_FINISHED`,
  ERROR: `${base}_ERRORED`,
})

const RESET = "RESET"
const FETCH_DATA = buildEvents("FETCH_DATA")
const SET_SEARCH_TERM = "SET_SEARCH_TERM"
const SEARCH_DATA = buildEvents("SEARCH_DATA")

const ADD_USER = buildEvents("ADD_USER")
const UPDATE_USER = buildEvents("UPDATE_USER")
const DELETE_USER = buildEvents("DELETE_USER")

const SHOW_NEW_USER_MODAL = "SHOW_NEW_USER_MODAL"
const SHOW_DELETE_USER_MODAL = "SHOW_DELETE_USER_MODAL"

const UserManagementReducer = (state = DEFAULT_DATA, action) => {
  switch (action.type) {
    case RESET:
      return DEFAULT_DATA

    case FETCH_DATA.STARTED:
      return {
        ...state,
        loading: true,
        loading_state: LoadingStatus.FETCHING
      }

    case FETCH_DATA.FINISHED:
      return {
        ...state,
        loading: false,
        loading_state: LoadingStatus.FETCHED,
        data: action.payload.users,
        roles: action.payload.roles,
      }

    case FETCH_DATA.ERROR:
      return {
        ...state,
        loading: false,
        loading_state: LoadingStatus.ERROR,
      }

    case SET_SEARCH_TERM:
      return {
        ...state,
        search: {
          ...state.search,
          term: action.payload,
          lowered: action.payload ? action.payload.toLowerCase() : action.payload
        }
      }

    case SEARCH_DATA.STARTED:
      return {
        ...state,
        loading: true,
        search: {
          ...state.search,
          isSearching: true
        }
      }

    case SEARCH_DATA.FINISHED:
      return {
        ...state,
        loading: false,
        search: {
          ...state.search,
          isSearching: false
        },
        users: action.payload,
      }

    case ADD_USER.STARTED:
    case UPDATE_USER.STARTED:
    case DELETE_USER.STARTED:
      return {
        ...state,
        loading: true,
      }

    case ADD_USER.FINISHED:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload].sort(AttributeSort("name")),
        showNewUserModal: false
      }

    case UPDATE_USER.FINISHED:
      return {
        ...state,
        loading: false,
        data: [...state.data.filter(u => u.id !== action.payload.id), action.payload].sort(AttributeSort("name"))
      }

    case DELETE_USER.FINISHED:
      return {
        ...state,
        loading: false,
        data: state.data.filter(u => u.id !== action.payload.id),
        deleteUser: null
      }

    case ADD_USER.ERROR:
    case UPDATE_USER.ERROR:
    case DELETE_USER.ERROR:
      return {
        ...state,
        loading: false,
      }

    case SHOW_NEW_USER_MODAL:
      return {
        ...state,
        showNewUserModal: action.payload,
      }

    case SHOW_DELETE_USER_MODAL:
      return {
        ...state,
        deleteUser: action.payload,
      }

    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

export function UserManagementPane({show}) {
  const [state, dispatch] = useReducer(UserManagementReducer, DEFAULT_DATA)

  useEffect(() => {
    if (!show) {
      dispatch({type: RESET})
    }
  }, [show])

  useEffect(() => {
    if (!show || state.loading_state !== LoadingStatus.NONE) {
      return
    }

    dispatch({type: FETCH_DATA.STARTED})
    Api.get(`settings/user_management`)
      .then(response => dispatch({type: FETCH_DATA.FINISHED, payload: response.data}))
      .catch(e => {
        dispatch({type: FETCH_DATA.ERROR})

        if (e.handled) {
          return
        }

        console.error(e.error)
        ErrorToast("Unable to fetch data.")
      })
  }, [show, state.loading_state])

  const handleOnSearch = useCallback((search) => {
    if (state.search === search) {
      return
    }

    dispatch({type: SET_SEARCH_TERM, payload: search})
  }, [state.search])

  useEffect(() => {
    if (!state.search.lowered) {
      dispatch({type: SEARCH_DATA.FINISHED, payload: state.data})
      return
    }

    dispatch({type: SEARCH_DATA.STARTED})
    const users = state.data.filter(user => user.name.toLowerCase().includes(state.search.lowered) || user.roles.filter(r => r.name.toLowerCase().includes(state.search.lowered)).length > 0)
    dispatch({type: SEARCH_DATA.FINISHED, payload: users})
  }, [state.search.lowered, state.data]);

  const handleOnAdd = useCallback(() => dispatch({type: SHOW_NEW_USER_MODAL, payload: true}), [])
  const handleOnDelete = useCallback(user => {
    dispatch({type: DELETE_USER.STARTED})
    Api.delete(`settings/user_management/${user.id}`)
      .then(() => dispatch({type: DELETE_USER.FINISHED, payload: user}))
      .catch(e => {
        dispatch({type: DELETE_USER.ERROR})
        if (e.handled) {
          return
        }

        console.error(e.handled)
        ErrorToast("Unable to delete user.")
      })
  }, [])

  const handleOnAddRole = useCallback((user, role) => {
    dispatch({type: UPDATE_USER.STARTED})
    const body = {roles: [...user.roles.map(r => r.name), role]}
    Api.put(`settings/user_management/${user.id}`, body)
      .then(response => dispatch({type: UPDATE_USER.FINISHED, payload: response.data.user}))
      .catch(e => {
        dispatch({type: UPDATE_USER.ERROR})
        if (e.handled) {
          return
        }

        console.error(e.error)
        ErrorToast("Unable to add role")
      })
  }, [])

  const handleOnRemoveRole = useCallback((user, roleId) => {
    dispatch({type: UPDATE_USER.STARTED})
    const body = {roles: [...user.roles.filter(r => r.id !== roleId).map(r => r.name)]}

    Api.put(`settings/user_management/${user.id}`, body)
      .then(response => dispatch({type: UPDATE_USER.FINISHED, payload: response.data.user}))
      .catch(e => {
        dispatch({type: UPDATE_USER.ERROR})
        if (e.handled) {
          return
        }

        console.error(e.error)
        ErrorToast("Unable to add role")
      })
  }, [])

  if (!show) {
    return null
  }

  const hasUsers = state.users.length > 0
  const isLoaded = [LoadingStatus.FETCHED, LoadingStatus.ERROR].includes(state.loading_state)

  return (
    <div className="settings-users-list">
      <SettingsSearch
        search={state.search.term}
        onSearch={handleOnSearch}
        buttonTitle="Add User"
        onAdd={handleOnAdd}
      />
      {hasUsers && (
        <ListView>
          {state.users.map((user) => (
            <User
              key={user.id}
              user={user}
              roles={state.roles}
              onDelete={() => dispatch({type: SHOW_DELETE_USER_MODAL, payload: user})}
              onAddRole={handleOnAddRole}
              onRemoveRole={handleOnRemoveRole}
            />
          ))}
        </ListView>
      )}
      {!hasUsers && isLoaded && <EmptyView>No users found.</EmptyView>}
      <NewUserModal
        show={state.showNewUserModal}
        roles={state.roles}
        onHide={() => dispatch({type: SHOW_NEW_USER_MODAL, payload: false})}
        onSave={data => {
          dispatch({type: ADD_USER.STARTED})
          Api.post(`settings/user_management`, data)
            .then(response => dispatch({type: ADD_USER.FINISHED, payload: response.data.user}))
            .catch(e => {
              dispatch({type: ADD_USER.ERROR})
              if (e.handled) {
                return
              }

              console.error(e.error)
              ErrorToast("Unable to add user")
            })
        }}
      />
      <DeleteUserModal
        user={state.deleteUser}
        onHide={() => dispatch({type: SHOW_DELETE_USER_MODAL, payload: null})}
        onDelete={handleOnDelete}
      />
      <Indicator show={state.loading}/>
    </div>
  )
}

function User({user, roles, onDelete, onAddRole, onRemoveRole}) {
  const userRoles = useMemo(() => {
    if (!user?.roles?.length) {
      return {}
    }

    const roles = {}
    for (let role of user.roles) {
      roles[role.name] = role
    }

    return roles
  }, [user?.roles])

  const handleOnDelete = useCallback(() => {
    onDelete && onDelete(user)
  }, [onDelete, user])

  const handleOnPillClick = useCallback(value => {
    if (!value?.id) {
      onAddRole && onAddRole(user, value.name)
      // adding
    } else {
      onRemoveRole && onRemoveRole(user, value.id)
    }
  }, [user, onAddRole, onRemoveRole])

  const getRoleValue = useCallback(role => {
    if (!userRoles || userRoles[role] == null) {
      return {id: null, name: role}
    }

    return userRoles[role]
  }, [userRoles])

  const hasRole = useCallback(role => {
    return userRoles && userRoles[role] != null
  }, [userRoles])

  return (
    <div className="settings-user-management-user">
      <div className="settings-user-management-user-header">
        <div>
          <span>{user.name}</span>
          <span>{user.email}</span>
        </div>
        <DeleteButton onClick={handleOnDelete}/>
      </div>
      <PillGroup>
        {roles.map((role) => (
          <Pill
            key={role}
            value={{label: role, value: getRoleValue(role)}}
            isSelected={hasRole(role)}
            onClick={handleOnPillClick}
          />
        ))}
      </PillGroup>
    </div>
  )
}

const buildNewUserData = () => ({
  name: null,
  email: null,
  roles: [],
  hasManuallyChangedEmail: false
})

function NewUserModal({show, roles, onHide, onSave}) {
  const [data, setData] = useState(buildNewUserData)

  useEffect(() => {
    if (!show) {
      setData(buildNewUserData)
    }
  }, [show])

  const isButtonDisabled = () => {
    return (!data.name || !data.email || !data.roles.length)
  }

  const handleOnSave = useCallback(() => {
    onSave && onSave({
      name: data.name,
      email: `${data.email}@degreeanalytics.com`,
      roles: data.roles
    })
  }, [data, onSave])

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide,
    },
    primary: {
      title: "Add",
      disabled: isButtonDisabled(),
      onClick: handleOnSave,
    }
  }

  const handleNameChange = newName => {
    setData(prevState => {
      const args = {}
      if (!prevState.hasManuallyChangedEmail) {
        args.email = newName.toLowerCase().replace(" ", ".")
      }

      return {
        ...prevState,
        name: newName,
        ...args
      }
    })
  }

  const handleEmailChange = newEmail => {
    setData(prevState => ({...prevState, email: newEmail, hasManuallyChangedEmail: true}))
  }

  const handleOnPillClick = role => {
    setData(prevState => {
      const roles = prevState.roles.includes(role) ? prevState.roles.filter(r => r !== role) : [...prevState.roles, role]
      return {
        ...prevState,
        roles
      }
    })
  }

  return (
    <EntryModal bodyClassName="settings-user-management-new-user-modal" show={show} title="Update User" buttons={buttons} onHide={onHide}>
      <EntryInput label="Name" value={data.name} autoFocus={true} onChange={handleNameChange}/>
      <EntryInput label="Email" value={data.email} onChange={handleEmailChange}>
        <span>@degreeanalytics.com</span>
      </EntryInput>
      <PillGroup>
        {roles.map((role) => (
          <Pill
            key={role}
            value={{label: role, value: role}}
            isSelected={data.roles.includes(role)}
            onClick={handleOnPillClick}
          />
        ))}
      </PillGroup>
    </EntryModal>
  )
}

function DeleteUserModal({user, onHide, onDelete}) {
  return (
    <DeleteEntryModal show={user != null} value={user?.name} onHide={onHide} onDelete={() => onDelete && onDelete(user)}/>
  )
}
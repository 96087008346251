import React, {useEffect, useRef, useState} from "react";
import ReactSelect from "react-select";
import ReactCreatableSelect from 'react-select/creatable';
import {VerticalDivider} from "./divider";
import {ReactComponent as CaretIcon} from "../assets/icons/next_icon.svg";

export function Select({placeholder, value, options, disabled = false, isMulti = false, isSearchable = false, isClearable = false, onChange, ...props}) {
  const {className, ...rest} = props

  const classNames = ["da-select-container", "settings-role-select-permission-container"]
  if (className != null) classNames.push(className)

  return (
    <ReactSelect
      className={classNames.join(" ")}
      classNamePrefix="da-select"
      placeholder={placeholder}
      isDisabled={disabled}
      value={value}
      options={options}
      isMulti={!!isMulti}
      isSearchable={!!isSearchable}
      isClearable={!!isClearable}
      onChange={onChange}
      {...rest}
    />
  )
}

/*
 Expects value and options to have a payload of

  {
    label: <display text>,
    value: <value to pass on>,
    style: {
      backgroundColor: <background color>,
      dividerColor: <divider color>,
      iconColor: <icon color>
    }
  },
 */

export function ColoredSelect({value, options, onChange, additionalClassName, ...props}) {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(() => !open)
  }

  const handleOnItemClick = (item) => {
    toggleOpen()
    onChange && onChange(item)
  }

  const classNames = ["da-colored-select"]
  if (additionalClassName != null) {
    classNames.push(additionalClassName)
  }

  return (
    <div className={classNames.join(" ")} {...props}>
      <div>
        <div className="da-colored-select-value-container" style={{backgroundColor: value.style.backgroundColor}} onClick={toggleOpen}>
          <ColoredSelectItem option={value}/>
          <div className="da-colored-select-value-divider">
            <VerticalDivider style={{backgroundColor: value.style.dividerColor}}/>
            <CaretIcon style={{color: value.style.iconColor}}/>
          </div>
        </div>
      </div>
      {open && (
        <div className="da-colored-select-options-container">
          <ColoredSelectItems
            options={options}
            displayed={open}
            onItemClick={handleOnItemClick}
            onClose={toggleOpen}
          />
        </div>
      )}
    </div>
  )
}

function ColoredSelectItems({options, displayed, onItemClick, onClose}) {
  const containerRef = useRef()
  const debounceRef = useRef(0)

  useEffect(() => {
    const handleClick = (e) => {
      if (!displayed || containerRef.current == null) {
        return
      }

      if (debounceRef.current !== 0 && !containerRef.current?.contains(e.target)) {
        onClose && onClose()
        debounceRef.current = 0
        return
      }

      debounceRef.current += 1
    }

    window.addEventListener("click", handleClick)
    return () => window.removeEventListener("click", handleClick)
  }, [displayed, onClose]);

  const handleOnClick = (option) => {
    return () => {
      onItemClick && onItemClick(option)
    }
  }

  return (
    <div className="da-card da-colored-select-options" ref={containerRef}>
      {options.map(option => (
        <div key={option.value} className="da-colored-select-options-item">
          <ColoredSelectItem option={option} onClick={handleOnClick(option)}/>
        </div>
      ))}
    </div>
  )
}

function ColoredSelectItem({option, onClick}) {
  return (
    <div className="da-colored-select-values" onClick={onClick}>
      <div className="da-colored-select-values-icon" style={{backgroundColor: option.style.iconColor}}/>
      <span className="da-colored-select-values-text">{option.label}</span>
    </div>
  )
}

export function CreatableSelect({placeholder, value, options, disabled = false, isMulti = false, isSearchable = false, isClearable = false, onChange, ...props}) {
  const {className, ...rest} = props

  const classNames = ["da-select-container", "settings-role-select-permission-container"]
  if (className != null) classNames.push(className)

  return (
    <ReactCreatableSelect
      className={classNames.join(" ")}
      classNamePrefix="da-select"
      placeholder={placeholder}
      isDisabled={disabled}
      value={value}
      options={options}
      isMulti={!!isMulti}
      isSearchable={!!isSearchable}
      isClearable={!!isClearable}
      onChange={onChange}
      {...rest}
    />
  )
}

export const SegmentSelect = ({selectedId, options, onChange, ...props}) => {
  const {className, ...rest} = props
  const classNames = []
  if (className != null) classNames.push(className)

  return (
    <div className={`da-segment-select ${classNames.join(" ")}`} {...rest}>
      {options.map(option => (
        <SegmentSelectItem isSelected={selectedId === option.id} key={option.id} option={option} onClick={onChange} />
      ))}
    </div>
  )
}

export const MultiSegmentSelect = ({selectedIds, options, onChange, ...props}) => {
  const {className, ...rest} = props
  const classNames = []
  if (className != null) classNames.push(className)

  if (!selectedIds) selectedIds = []

  const handleOnAdd = segment => {
    if (!onChange) return
    onChange([...selectedIds, segment.id])
  }

  const handleOnRemove = segment => {
    if (!onChange) return
    onChange(selectedIds.filter(v => v !== segment.id))
  }

  return (
    <div className={`da-segment-select ${classNames.join(" ")}`} {...rest}>
      {options.map(option => (
        <SegmentSelectItem isSelected={selectedIds.includes(option.id)} key={option.id} option={option} onClick={handleOnAdd} OnRemove={handleOnRemove} />
      ))}
    </div>
  )
}

const SegmentSelectItem = ({isSelected, option, onClick, OnRemove}) => {
  const handleOnClick = () => {
    if (isSelected && OnRemove) {
      OnRemove(option)
    } else if (!isSelected && onClick) {
      onClick(option)
    }
  }

  return (
    <div className={isSelected ? "da-segment-select-option-selected" : "da-segment-select-option-not-selected"} onClick={handleOnClick}>
      <span>{option.label}</span>
      {option.count != null && <span>({option.count})</span>}
    </div>
  )
}

import {useDataContext} from "../../hooks/context";
import {useCallback, useEffect, useState} from "react";
import {getBaseLayerId, getSmallestFeature, MapKeys} from "./utils";
import {LayoutResourceType} from "../../consts";
import {booleanPointInPolygon} from "@turf/turf";
import Warehouse from "../../../../data/warehouse";

const DRAW_FILL_COLOR = "#EE5B5B"
const DRAW_STROKE_COLOR = "#E14646"

const getAllFloorIds = (zone) => {
  const floorZone = zone?.getFloorZone()
  if (!floorZone) {
    return []
  }

  return [floorZone.id, ...floorZone.getSubZones().map(c => c.id)]
}

const getDirectFloorIds = (zone) => {
  const floorZone = zone?.getFloorZone()
  if (!floorZone) {
    return []
  }

  // make sure that we show all the floor children
  const out = new Set(floorZone.getSubZones().map(c => c.id))

  if (zone.id === floorZone.id) {
    return Array.from(out)
  }

  // add our current stuff to the stack
  out.add(zone.id)
  for (let child of zone.getSubZones()) {
    out.add(child.id)
  }

  // create a direct link to from zone to floor to make sure that parent zones are rendered
  let parent = zone.getParent()
  while (parent && parent.id !== floorZone.id) {
    out.add(parent.id)
    for (let child of parent.getSubZones()) {
      out.add(child.id)
    }

    parent = parent.getParent()
  }

  return Array.from(out)
}

export function MapSource({map, isMapLoading, showZones, showAps}) {
  const {schoolId, geojson, filters, zones, aps, strandedAps, selectedResourceType, selectedZone, selectedAp, draw, isCreatingZone} = useDataContext()
  const [isLoaded, setIsLoaded] = useState(false)

  let buildingZoneId = ""
  if (selectedZone) {
    buildingZoneId = selectedZone.getBuildingZone()?.id || ""
  } else if (selectedAp?.getBuilding()?.id) {
    buildingZoneId = selectedAp?.getBuilding()?.id
  }

  let selectedBuildingId = ""
  if (selectedZone?.getBuilding()?.id) {
    selectedBuildingId = selectedZone.getBuilding().id
  } else if (selectedAp?.getBuilding()?.id) {
    selectedBuildingId = selectedAp.getBuilding().id
  }

  const getLayerIndexId = (map) => {
    const layers = map.getStyle().layers
    for (let layer of layers) {
      if (layer.type === "symbol") {
        return layer.id
      }
    }
  }

  /**
   * Only show buildings other than the current selected building
   * @type {function(): (string|(string|string)[]|string[]|(string|*|string)[])[]}
   */
  const buildZoneOtherLayerFilter = useCallback(() => {
    return ["all", ["==", "type", showZones ? "zone" : ""], ["==", "sub_type", "building"]]
  }, [showZones])

  /**
   * Only show the currently selected building
   * @type {function(): (string|string[]|(string|*|string)[])[]}
   */
  const buildZoneBuildingLayerFilter = useCallback(() => {
    return ["all", ["==", "sub_type", "building"], ["==", "id", buildingZoneId]]
  }, [buildingZoneId])

  /**
   * Only show the floor zone if a zone or one of its children are selected
   * @type {function(): (string|string[]|*[])[]}
   */
  const buildZoneFloorLayerFilter = useCallback(() => {
    const options = [["==", "sub_type", "floor"]]

    if (selectedZone) {
      options.push(["in", "id", ...getAllFloorIds(selectedZone)])
    } else if (selectedAp) {
      options.push(["in", "id", ...getZonesForAp(geojson?.features, selectedAp).map(f => f.properties.id)])
    } else {
      options.push(["==", "id", ""])
    }

    return ["all", ...options]
  }, [selectedZone, selectedAp, geojson?.features])

  /**
   * Only show the floor direct children
   * @type {function(): (string|string[]|*[])[]}
   */
  const buildZoneFloorChildLayerFilter = useCallback(() => {
    const options = [["==", "sub_type", "default"]]

    if (selectedZone) {
      options.push(["in", "id", ...getDirectFloorIds(selectedZone)])
    } else if (selectedAp) {
      options.push(["in", "id", ...getZonesForAp(geojson?.features, selectedAp).map(f => f.properties.id)])
    } else {
      options.push(["==", "id", ""])
    }

    return ["all", ...options]
  }, [selectedZone, selectedAp, geojson?.features])

  /**
   * Outline any currently selected zone
   * @type {function(): (string|*|string)[]}
   */
  const buildOutlineLayerFilter = useCallback(() => {
    if (isCreatingZone || selectedZone?.id) {
      return ["==", "id", (isCreatingZone && selectedZone?.hasParent() ? selectedZone.getParent().id : selectedZone?.id) || ""]
    }

    const zones = getZonesForAp(geojson?.features, selectedAp)
    if (!zones.length) {
      return ["==", "id", ""]
    }

    const {polygon} = getSmallestFeature(zones)
    return ["==", "id", polygon.properties.id]
  }, [geojson?.features, isCreatingZone, selectedAp, selectedZone])

  /**
   * Only show aps other than the current selected ap
   * @type {function(): (string|(string|string)[]|(string|*|string)[])[]}
   */
  const buildOtherApFilter = useCallback(() => {
    const options = []

    if (selectedResourceType == null) {
      options.push(["==", "id", "ohai"])
    } else {
      if (selectedResourceType === LayoutResourceType.ZONE) {
        options.push(["==", "type", showAps ? "ap" : ""])
        options.push(["==", "building_id", selectedBuildingId])
        options.push(["in", "floor", ...(selectedZone?.floors || [])])
      } else if (selectedResourceType === LayoutResourceType.AP) {
        options.push(["==", "type", "ap"])
        options.push(["!=", "id", selectedAp?.id || ""])

        if (!showAps) {
          options.push(["==", "building_id", selectedBuildingId])

          if (selectedAp?.floor) {
            options.push(["in", "floor", ...([selectedAp.floor])])
          }
        }
      }
    }

    return ["all", ...options]
  }, [showAps, selectedResourceType, selectedZone?.floors, selectedBuildingId, selectedAp?.id, selectedAp?.floor])

  /**
   * Only show current ap
   * @type {function(): (string|(string|string)[]|(string|*|string)[])[]}
   */
  const buildSelectedApFilter = useCallback(() => {
    return ["==", "id", selectedAp?.id || ""]
  }, [selectedAp?.id])

  useEffect(() => {
    if (!map || isMapLoading || !geojson || isLoaded) {
      return
    }

    const firstSymbolId = getLayerIndexId(map)

    map.addSource(MapKeys.SOURCE_ID, {type: "geojson", data: geojson})
    map.addSource(MapKeys.DRAW_SOURCE_ID, {type: "geojson", data: draw.geojson})

    // css color: --primary-color-500
    addZoneLayer(
      map,
      MapKeys.OTHER_ZONE_LAYER,
      MapKeys.SOURCE_ID,
      firstSymbolId,
      buildZoneOtherLayerFilter(),
      "#1eb3f3"
    )

    // css color: --primary-color-500
    addZoneLayer(
      map,
      MapKeys.CURRENT_ZONE_BUILDING_LAYER,
      MapKeys.SOURCE_ID,
      firstSymbolId,
      buildZoneBuildingLayerFilter(),
      "#1eb3f3"
    )

    // css color: --green-color-500
    addZoneLayer(
      map,
      MapKeys.CURRENT_ZONE_FLOOR_LAYER,
      MapKeys.SOURCE_ID,
      firstSymbolId,
      buildZoneFloorLayerFilter(),
      "#5fc884",
    )

    addZoneLayer(
      map,
      MapKeys.CURRENT_ZONE_FLOOR_CHILD_LAYER,
      MapKeys.SOURCE_ID,
      firstSymbolId,
      buildZoneFloorChildLayerFilter()
    )

    map.addLayer({
      id: MapKeys.CURRENT_ZONE_OUTLINE_LAYER,
      type: "line",
      source: MapKeys.SOURCE_ID,
      filter: buildOutlineLayerFilter(),
      paint: {
        "line-color": ["get", "icon_outline_color"],
        "line-width": 3
      }
    }, firstSymbolId)

    const stops = [
      [12, 4],
      [22, 20]
    ]

    map.addLayer({
      id: MapKeys.DRAW_ZONE_POLYGON_LAYER,
      type: "fill",
      source: MapKeys.DRAW_SOURCE_ID,
      filter: ["all", ["==", "type", "zone"], ["==", "isCompleted", true]],
      paint: {
        "fill-color": DRAW_FILL_COLOR,
        "fill-opacity": .2,
      }
    }, firstSymbolId)

    map.addLayer({
      id: MapKeys.DRAW_ZONE_LINE_LAYER,
      type: "line",
      source: MapKeys.DRAW_SOURCE_ID,
      filter: ["==", "type", "zone"],
      paint: {
        "line-color": DRAW_STROKE_COLOR,
        "line-width": 2,
      }
    }, firstSymbolId)

    map.addLayer({
      id: MapKeys.DRAW_ZONE_POINT_LAYER,
      type: "circle",
      source: MapKeys.DRAW_SOURCE_ID,
      filter: ["==", "type", "zone"],
      paint: {
        "circle-color": DRAW_STROKE_COLOR,
      }
    }, firstSymbolId)

    map.addLayer({
      id: MapKeys.OTHER_AP_LAYER,
      type: "circle",
      source: MapKeys.SOURCE_ID,
      filter: buildOtherApFilter(),
      paint: {
        "circle-color": ["get", "other_icon_fill_color"],
        "circle-stroke-color": ["get", "other_icon_outline_color"],
        "circle-stroke-width": {
          base: 5,
          stops
        },
        "circle-opacity": 1,
        "circle-radius": {
          base: 5,
          stops
        }
      }
    }, firstSymbolId)

    map.addLayer({
      id: MapKeys.CURRENT_AP_LAYER,
      type: "circle",
      source: MapKeys.SOURCE_ID,
      filter: buildSelectedApFilter(),
      paint: {
        "circle-color": ["get", "selected_icon_fill_color"],
        "circle-stroke-color": ["get", "selected_icon_outline_color"],
        "circle-stroke-width": {
          base: 5,
          stops
        },
        "circle-opacity": 1,
        "circle-radius": {
          base: 5,
          stops
        }
      }
    }, firstSymbolId)

    map.addLayer({
      id: MapKeys.DRAW_AP_LAYER,
      type: "circle",
      source: MapKeys.DRAW_SOURCE_ID,
      filter: ["==", "type", "ap"],
      paint: {
        "circle-color": DRAW_FILL_COLOR,
        "circle-stroke-color": DRAW_STROKE_COLOR,
        "circle-stroke-width": {
          base: 5,
          stops
        },
        "circle-opacity": 1,
        "circle-radius": {
          base: 5,
          stops
        }
      }
    }, firstSymbolId)

    setIsLoaded(true)
  }, [schoolId, map, isMapLoading, geojson, isLoaded, draw.geojson, buildZoneOtherLayerFilter, buildZoneBuildingLayerFilter, buildZoneFloorLayerFilter, buildZoneFloorChildLayerFilter, buildOutlineLayerFilter, buildOtherApFilter, buildSelectedApFilter])

  useEffect(() => {
    if (!map || isMapLoading || !map.getSource(MapKeys.SOURCE_ID) || !geojson) {
      return
    }

    let features = [...geojson.features]
    if (filters?.hasFilters) {
      const zoneIds = new Set(zones.flatMap(z => z.subZoneIter()).map(z => z.id))
      const apIds = new Set([...aps, strandedAps].map(a => a.id))

      features = features.filter(f => {
        if (f.properties.type === "zone") {
          return zoneIds.has(f.properties.id)
        } else if (f.properties.type === "ap") {
          return apIds.has(f.properties.id)
        }

        return false
      })
    }

    map.getSource(MapKeys.SOURCE_ID).setData({
      ...geojson,
      features
    })
  }, [map, isMapLoading, geojson, filters, zones, aps, strandedAps])

  useEffect(() => {
    if (!map || !map.getSource(MapKeys.DRAW_SOURCE_ID)) {
      return
    }

    map.getSource(MapKeys.DRAW_SOURCE_ID).setData(draw.geojson)
  }, [map, draw.geojson])

  useEffect(() => {
    if (!map || !isLoaded) {
      return
    }

    updateFilter(map, MapKeys.OTHER_ZONE_LAYER, buildZoneOtherLayerFilter())
  }, [map, isLoaded, buildZoneOtherLayerFilter])

  useEffect(() => {
    if (!map || !isLoaded) {
      return
    }

    updateFilter(map, MapKeys.CURRENT_ZONE_BUILDING_LAYER, buildZoneBuildingLayerFilter())
  }, [map, isLoaded, buildZoneBuildingLayerFilter])

  useEffect(() => {
    if (!map || !isLoaded) {
      return
    }

    updateFilter(map, MapKeys.CURRENT_ZONE_FLOOR_LAYER, buildZoneFloorLayerFilter())
  }, [map, isLoaded, buildZoneFloorLayerFilter])

  useEffect(() => {
    if (!map || !isLoaded) {
      return
    }

    updateFilter(map, MapKeys.CURRENT_ZONE_FLOOR_CHILD_LAYER, buildZoneFloorChildLayerFilter())
  }, [map, isLoaded, buildZoneFloorChildLayerFilter])

  useEffect(() => {
    if (!map || !isLoaded) {
      return
    }

    updateFilter(map, MapKeys.CURRENT_ZONE_OUTLINE_LAYER, buildOutlineLayerFilter())
  }, [map, isLoaded, buildOutlineLayerFilter])

  useEffect(() => {
    if (!map || !isLoaded) {
      return
    }

    updateFilter(map, MapKeys.OTHER_AP_LAYER, buildOtherApFilter())
  }, [map, isLoaded, buildOtherApFilter])

  useEffect(() => {
    if (!map || !isLoaded) {
      return
    }

    updateFilter(map, MapKeys.CURRENT_AP_LAYER, buildSelectedApFilter())
  }, [map, isLoaded, buildSelectedApFilter])

  return null
}

const addZoneLayer = (map, layerId, sourceId, insertAboveLayerId, filter, color = null, opacity = .2) => {
  if (!map || map.getLayer(layerId)) {
    return
  }

  const baseLayerId = getBaseLayerId(layerId)
  if (!map.getLayer(baseLayerId)) {
    map.addLayer({
      id: baseLayerId,
      type: "fill",
      source: sourceId,
      filter,
      paint: {
        "fill-color": "#fff",
        "fill-opacity": 1,
      }
    }, insertAboveLayerId)
  }

  const fillColor = color ? hexToRgba(color, opacity) : ["get", "icon_fill_color"]
  const outlineColor = color ? hexToRgba(color, 1) : ["get", "icon_outline_color"]

  if (!map.getLayer(layerId)) {
    map.addLayer({
      id: layerId,
      type: "fill",
      source: sourceId,
      filter,
      paint: {
        "fill-color": fillColor,
        "fill-opacity": 1,
        "fill-outline-color": outlineColor,
      }
    }, insertAboveLayerId)
  }
}

const updateFilter = (map, layerId, filter) => {
  const baseLayerId = getBaseLayerId(layerId)

  if (map.getLayer(baseLayerId)) {
    map.setFilter(baseLayerId, filter)
  }

  if (map.getLayer(layerId)) {
    map.setFilter(layerId, filter)
  }
}

const hexToRgba = (hex, alpha = 1) => {
  // Remove the '#' if it exists
  hex = hex.replace("#", "");

  // Check if the hex code is 3 or 6 characters long
  let r, g, b, a = alpha;
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  } else if (hex.length === 8) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
    a = parseInt(hex.slice(6, 8), 16);
  } else {
    throw new Error("Invalid hex color code");
  }

  // Return the rgba string
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

const getZonesForAp = (features, ap) => {
  if (!features || !ap?.id || !ap.floor) {
    return []
  }

  const point = features.filter(f => f.geometry.type === "Point" && f.properties.id === ap.id)
    .reduce((accumulator, feature) => feature, null)

  if (!point) {
    return []
  }

  return features.filter(f => f.geometry.type === "Polygon" && f.properties.floors?.includes(ap.floor) && booleanPointInPolygon(point.geometry.coordinates, f))
}

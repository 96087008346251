import {formatDate} from "./format";

export const NO_SKIP = ["NO_SKIP"]

export const buildData = (data, skipKeys = NO_SKIP) => {
  if (data == null) return {}

  if (skipKeys === NO_SKIP || skipKeys == null) {
    skipKeys = new Set()
  } else {
    skipKeys = new Set(skipKeys)
  }

  const newData = {}

  for (let [key, value] of Object.entries(data)) {
    if (skipKeys.has(key)) {
      continue
    }

    newData[key] = value
  }

  return newData
}

export const getChanges = (base, current) => {
  if (!base) {
    return !!current && Object.keys(current).length ? current : null
  }

  if (!current || !Object.keys(current).length) {
    return null
  }

  const allKeys = new Set(Object.keys(base).concat(Object.keys(current)))
  const changes = {}
  let hasChanges = false

  for (let key of allKeys) {
    let s = base[key]
    let c = current[key]

    // make sure undefineds are nulls
    if (s == null) s = null
    if (c == null) c = null

    if (key.toLowerCase().includes("date")) {
      const newS = formatDate(s)
      if (newS !== "Invalid Date") {
        s = newS
      }

      const newC = formatDate(c)
      if (newC !== "Invalid Date") {
        c = newC
      }
    }

    if (s !== c) {
      hasChanges = true
      changes[key] = c
    }
  }

  return hasChanges ? changes : null
}

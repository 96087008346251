import {ReactComponent as DALogo} from "../shared/assets/images/da-logo-light.svg";
import {BackgroundImage} from "../shared/components/background";
import {useLocation} from "react-router";

export function ErrorMessage() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  let title = params.get("title")
  if (title == null || title.trim().length === 0) {
    title = "An Unexpected Error Occurred"
  }

  let message = params.get("message")
  if (message == null || message.trim().length === 0) {
    message = "Please contact a Site Administrator"
  }

  return (
    <div className="login-redirect">
      <BackgroundImage/>
      <div className="login-redirect-container">
        <DALogo/>
        <div className="da-card">
          <h1>{title}</h1>
          <div>
            <span>{message}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
import {CheckButton, ClearButton} from "../../../shared/components/buttons";

export function TextButtons({show, onSave, onCancel}) {
  if (!show) {
    return null
  }

  return (
    <div className="wireless-config-detail-text-buttons">
      <CheckButton variant="primary" onClick={onSave}/>
      <ClearButton onClick={onCancel}/>
    </div>
  )
}
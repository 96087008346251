import {Geocoder as MapBoxGeocoder} from "@mapbox/search-js-react"
import {getAccessKey} from "../../../../shared/hooks/map";
import {useEffect, useState} from "react";
import {useDataContext} from "../../hooks/context";
import {buildPolygon, MAP_LAYER_OPTIONS} from "./utils";
import {booleanPointInPolygon} from "@turf/turf";
import {ZoneType} from "../../consts";

export function Geocoder({map}) {
  const {schoolId, isCreatingZone, selectedZone, draw} = useDataContext()
  const [currentAddress, setCurrentAddress] = useState("");

  useEffect(() => {
    setCurrentAddress("")
  }, [schoolId]);

  return (
    <div className="map-option-geocoder-container">
      <MapBoxGeocoder
        accessToken={getAccessKey()}
        map={map}
        value={currentAddress}
        onChange={setCurrentAddress}
        onRetrieve={result => {
          if (!result) {
            return
          }

          if (isCreatingZone && selectedZone?.type === ZoneType.BUILDING && !draw.geojson?.features?.filter(f => f.geometry.type === "Polygon" && f.properties.type === "zone" && f.properties.isCompleted).length) {
            const feature = map.queryRenderedFeatures()
              .filter(feature => MAP_LAYER_OPTIONS.includes(feature.sourceLayer) && booleanPointInPolygon(result.geometry, feature))
              .flatMap(feature => buildPolygon(feature.geometry, {type: "zone", isCompleted: true}))
              .filter(feature => feature)
              .reduce((accumulator, feature) => feature, null)

            if (feature) {
              draw.replace([feature])
            }
          }

          map.flyTo({center: result.geometry.coordinates})
        }}
      />
    </div>
  )
}
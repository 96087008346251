import {DataShed} from "./base";
import {AttributeSort} from "../../utils/sort";

export class ApDataShed extends DataShed {
  constructor(onDataChange) {
    super(onDataChange)

    this._idLookup = {}
    this._buildingFloorLookup = {}

    this._stranded = []
  }

  setAll(schoolId, values) {
    this._values = []
    this._stranded = []

    for (let ap of values) {
      if (ap.hasParent()) {
        this._values.push(ap)
      } else {
        this._stranded.push(ap)
      }
    }

    this._handleChange()
    this._onDataChangeListener && this._onDataChangeListener()
  }

  addBulk(values) {
    const apIds = values.map(ap => ap.id)

    this._values = this._values.filter(v => !apIds.includes(v.id))
    this._stranded = this._stranded.filter(v => !apIds.includes(v.id))

    let addedValues = false, addedStranded = false
    for (let ap of values) {
      if (ap.hasParent()) {
        addedValues = true
        this._values.push(ap)
      } else {
        addedStranded = true
        this._stranded.push(ap)
      }
    }

    if (addedValues) {
      this._values = this._values.sort(AttributeSort("name"))
    }

    if (addedStranded) {
      this._stranded = this._stranded.sort(AttributeSort("name"))
    }

    this._handleChange()
    this._onDataChangeListener && this._onDataChangeListener()
  }

  clear() {
    super.clear();
    this._stranded = []
  }

  getById(id) {
    if (!id) {
      return null
    }

    return this._idLookup[id]
  }

  getAll() {
    return this._values
  }

  getAllStranded() {
    return this._stranded
  }

  getApsInBuildingByFloor(buildingId) {
    if (!buildingId) {
      return {}
    }

    return this._buildingFloorLookup[buildingId]
  }

  getByBuildingFloor(buildingId, floor) {
    if (!buildingId || !floor || !this._buildingFloorLookup[buildingId] || !this._buildingFloorLookup[buildingId][floor]?.length) {
      return []
    }

    const data = this._buildingFloorLookup[buildingId][floor]
    if (!data) {
      return []
    }

    return data.sort(AttributeSort("name"))
  }

  _handleChange() {
    this._idLookup = {}
    this._buildingFloorLookup = {}
    for (let ap of [...this._values, ...this._stranded]) {
      this._idLookup[ap.id] = ap

      if (ap.buildingId && ap.floor) {
        if (!this._buildingFloorLookup[ap.buildingId]) {
          this._buildingFloorLookup[ap.buildingId] = {}
        }

        if (!this._buildingFloorLookup[ap.buildingId][ap.floor]) {
          this._buildingFloorLookup[ap.buildingId][ap.floor] = []
        }

        this._buildingFloorLookup[ap.buildingId][ap.floor].push(ap)
      }
    }

    for (let floorData of Object.values(this._buildingFloorLookup)) {
      for (let floor of Object.keys(floorData)) {
        floorData[floor] = floorData[floor].sort(AttributeSort("name"))
      }
    }
  }
}
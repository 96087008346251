import React from "react";
import {ReactComponent as LoadingIcon} from "../assets/icons/loading_icon.svg";
import {createPortal} from "react-dom";

export default function Indicator({show, ...props}) {
  if (!show || (typeof show === "string" && show.toLowerCase() === "false")) {
    return null;
  }

  return createPortal(
    <div className="loader-container" {...props}><LoadingIcon/></div>,
    document.getElementById("root")
  )
}

export function InlineIndicator({show, ...props}) {
  if (!show) {
    return null
  }

  return (
    <div className="inline-loader-container" {...props}><LoadingIcon/></div>
  )
}
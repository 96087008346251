import React, {useEffect, useReducer, useState} from "react";

import {DeleteButton, EditButton} from "../../../../shared/components/buttons";
import {EntryInput, EntryModal} from "../../../../shared/components/modal";

import {EmptyView} from "../../../../shared/components/views";
import {Slider} from "../../../../shared/components/slider";
import {Select} from "../../../../shared/components/select";

import SettingsSearch from "./search";
import {ListView} from "../../../../shared/components/list";

const DEFAULT_ROLE_DATA = {
  id: null,
  name: "",
  description: "",
  claims: []
}

const DEFAULT_DATA = {
  search: null,
  isSearching: false,
  createModal: {
    show: false
  },
  editModal: {
    show: false,
    role: DEFAULT_ROLE_DATA
  },
  deleteModal: {
    show: false,
    role: null
  },
}

const SEARCH = "SEARCH"
const searchEvent = search => ({type: SEARCH, payload: search});

const SHOW_CREATE_MODAL = "SHOW_CREATE_MODAL"
const showCreateModalEvent = show => ({type: SHOW_CREATE_MODAL, payload: show})

const SHOW_EDIT_MODAL = "SHOW_EDIT_MODAL"
const showEditModalEvent = (show, role) => ({type: SHOW_EDIT_MODAL, payload: {show, role}})

const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL"
const showDeleteModalEvent = (show, role) => ({type: SHOW_DELETE_MODAL, payload: {show, role}})

const RoleReducer = (state, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        search: action.payload,
        isSearching: action.payload != null
      }

    case SHOW_CREATE_MODAL:
      return {
        ...state,
        createModal: {
          show: action.payload
        }
      }

    case SHOW_EDIT_MODAL:
      return {
        ...state,
        editModal: action.payload
      }

    case SHOW_DELETE_MODAL:
      return {
        ...state,
        deleteModal: action.payload
      }

    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
}

export function RolePane({show, data, onRoleCreated, onRoleUpdated, onRoleDeleted}) {
  const [state, dispatch] = useReducer(RoleReducer, DEFAULT_DATA)

  const searchRole = search => dispatch(searchEvent(search))
  const showCreateModal = show => dispatch(showCreateModalEvent(show))
  const showEditModal = (show, role) => dispatch(showEditModalEvent(show, role))
  const showDeleteModal = (show, role) => dispatch(showDeleteModalEvent(show, role))

  useEffect(() => {
    return () => {
      showCreateModal(false)
      showEditModal(false, DEFAULT_ROLE_DATA)
    }
  }, [show]);

  if (!show || data == null) return null

  const filterRole = role => {
    if (!state.isSearching) return true

    const lowered = state.search.toLowerCase()
    return role.name.toLowerCase().includes(lowered)
      || (role.description != null && role.description.toLowerCase().includes(lowered))
      || role.claims.map(claim => claim.toLowerCase().includes(lowered)).filter(claim => claim).length > 0
  }

  const roles = (data.roles || []).filter(filterRole)
  const hasRoles = roles.length > 0

  const handleCreateClick = () => showCreateModal(true)
  const handleEditClick = role => showEditModal(true, role)
  const handleDeleteClick = role => showDeleteModal(true, role)

  return (
    <div className="settings-role-list">
      <SettingsSearch
        buttonTitle={"Add Role"}
        search={state.search}
        onSearch={searchRole}
        onAdd={handleCreateClick}
      />
      {hasRoles && <ListView className="settings-role-list-content">{roles.map((role, index) => <Role key={`role_${index}`} role={role} onEditClick={() => handleEditClick(role)} onDeleteClick={() => handleDeleteClick(role)}/>)}</ListView>}
      {!hasRoles && <EmptyView>No roles created</EmptyView>}
      <CreateRoleModal
        show={state.createModal.show}
        permissions={data.permissions}
        onHide={() => showCreateModal(false)}
        onCreate={data => onRoleCreated(data, () => showCreateModal(false))}
      />
      <EditRoleModal
        show={state.editModal.show}
        role={state.editModal.role}
        permissions={data.permissions}
        onHide={() => showEditModal(false)}
        onUpdate={data => onRoleUpdated({...state.editModal.role, ...data}, data, () => showEditModal(false, DEFAULT_ROLE_DATA))}
      />
      <DeleteRoleModal
        show={state.deleteModal.show}
        role={state.deleteModal.role}
        onHide={() => showDeleteModal(false, null)}
        onDelete={(data) => onRoleDeleted(data, () => showDeleteModal(false, DEFAULT_ROLE_DATA))}
      />
    </div>
  )
}

function Role({role, onEditClick, onDeleteClick}) {
  return (
    <div className="settings-role-list-item">
      <RoleInfo role={role}/>
      {role.editable && <RoleActions onEditClick={onEditClick} onDeleteClick={onDeleteClick}/>}
    </div>
  )
}

function RoleInfo({role}) {
  return (
    <div className="settings-role-list-item-info">
      <span>{role.name}</span>
      <span>{role.description}</span>
    </div>
  )
}

function RoleActions({onEditClick, onDeleteClick}) {
  return (
    <div className="settings-role-list-item-actions">
      <EditButton onClick={onEditClick}/>
      <DeleteButton onClick={onDeleteClick}/>
    </div>
  )
}

const isDataSame = (original, current) => {
  const compare = (attribute) => {
    let v1 = original[attribute]
    let v2 = current[attribute]

    // use v2 since v1 would have null values instead of types
    if (typeof v2 === "string") {
      // don't trim v1 since we want the original value
      v1 = v1 || ""
      // trim v2 since we only want changed values and we don't allow leading/following whitespace
      v2 = (v2 || "").trim()
    }

    // use v2 since v1 would have null values instead of types
    if (Array.isArray(v2)) {
      if (v1.length !== v2.length) return false
      const a1 = v1.sort()
      const a2 = v2.sort()

      for (let i = 0; i < a1.length; i++) {
        if (a1[i] !== a2[i]) return false
      }

      return true
    }

    return v1 === v2
  }

  return compare("name") && compare("description") && compare("claims")
}

const isButtonDisabled = (original, current) => {
  if (!!original !== !!current || original == null || current == null) return true
  if (current.name == null || current.name.trim() === '') return true
  return isDataSame(original, current)
}

const DataHandler = (data, setData) => {
  return {
    handleDataChange: (key, value) => {
      setData({...data, [key]: value})
    },
    handlePermissionChange: (newValues) => {
      let claims = new Set(data.claims)

      for (let [key, value] of Object.entries(newValues)) {
        if (!value) {
          claims.delete(key)
        } else {
          claims.add(key)
        }
      }

      setData({...data, claims: Array.from(claims)})
    }
  }
}

function CreateRoleModal({show, permissions, onHide, onCreate}) {
  const [data, setData] = useState({})
  const handler = DataHandler(data, setData)

  useEffect(() => {
    setData({...DEFAULT_ROLE_DATA})
  }, [show]);

  const handleOnClick = () => {
    const body = {
      name: data.name.trim(),
      description: data.description.trim(),
      claims: data.claims
    }

    if (body.description == null || !body.description.length) {
      delete body.description
    }

    onCreate(body)
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Create",
      disabled: isButtonDisabled({}, data),
      onClick: handleOnClick
    }
  }

  return (
    <EntryModal show={show} title="Create Role" onHide={onHide} buttons={buttons}>
      <ModalBody
        role={data}
        permissions={permissions}
        onChange={handler.handleDataChange}
        onPermissionChange={handler.handlePermissionChange}
      />
    </EntryModal>
  )
}

function EditRoleModal({show, role, permissions, onHide, onUpdate}) {
  const [data, setData] = useState({})
  const handler = DataHandler(data, setData)

  const handleOnClick = () => {
    const body = {}

    const getString = attribute => {
      const v1 = role[attribute]
      let v2 = data[attribute]

      // convert "" or "   " to null.  if not "" make sure we trim value
      v2 = v2 != null && v2.trim().length === 0 ? null : v2.trim()

      return v1 !== v2 ? {changed: true, value: v2} : {changed: false}
    }

    const getArray = attribute => {
      const v1 = (role[attribute] || []).sort()
      const v2 = (data[attribute] || []).sort()

      if (v1.length !== v2.length) return {changed: true, value: v2}

      for (let i = 0; i < v1.length; i++) {
        if (v1[i] !== v2[i]) return {changed: true, value: v2}
      }

      return {changed: false}
    }

    const name = getString("name")
    if (name.changed) body.name = name.value

    const description = getString("description")
    if (description.changed) body.description = description.value

    const claims = getArray("claims")
    if (claims.changed) body.claims = claims.value

    onUpdate(body)
  }

  useEffect(() => {
    if (role == null) {
      setData({...DEFAULT_ROLE_DATA})
      return
    }

    const formatString = value => value == null ? "" : value

    setData({
      name: formatString(role.name),
      description: formatString(role.description),
      claims: role.claims
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Update",
      disabled: isButtonDisabled(role, data),
      onClick: handleOnClick
    }
  }

  return (
    <EntryModal show={show} title="Update Role" onHide={onHide} buttons={buttons}>
      <ModalBody
        role={data}
        permissions={permissions}
        onChange={handler.handleDataChange}
        onPermissionChange={handler.handlePermissionChange}/>
    </EntryModal>
  )
}

function DeleteRoleModal({show, role, onHide, onDelete}) {
  const [current, setCurrent] = useState("")

  useEffect(() => {
    setCurrent("")
  }, [show]);

  if (!show || role == null) return null

  const handleOnClick = () => onDelete && onDelete(role)

  const buttons = {
    secondary: {
      title: "No, keep this role",
      onClick: onHide
    },
    danger: {
      title: "Yes, delete this role",
      disabled: current !== role.name,
      onClick: handleOnClick
    }
  }

  return (
    <EntryModal show={show} title={`Delete ${role.name}?`} onHide={onHide} buttons={buttons} cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body">
      <p>{`This action can not be undone. The deletion of ${role.name} is permanent and will effect any users of the app.`}</p>
      <div>
        <p>Please type <span>{role.name}</span> to confirm</p>
        <input autoFocus value={current} onChange={(e) => setCurrent(e.target.value)}/>
      </div>
    </EntryModal>
  )
}

function RadioPermission({name, value, onValueChange}) {
  return (
    <div className="settings-role-entry-modal-permission">
      <span>{name}</span>
      <Slider checked={value} onChecked={onValueChange}/>
    </div>
  )
}

function SelectPermission({name, value, options, multi, onValueChange}) {
  return (
    <div className="settings-role-entry-modal-permission">
      <span>{name}</span>
      <Select
        value={value}
        options={options}
        isMulti={multi}
        isSearchable={false}
        onChange={(newValue) => {
          if (multi) onValueChange(newValue.map(v => v.value))
          else onValueChange([newValue.value])
        }}
      />
    </div>
  )
}

function ModalBody({role, permissions, onChange, onPermissionChange}) {
  const hasPermissions = !!permissions

  return (
    <div className="settings-role-entry-modal">
      <EntryInput label="Role Name" value={role.name} onChange={(value) => {
        onChange("name", value)
      }}/>
      <EntryInput label="Description" multi value={role.description} onChange={(value) => {
        onChange("description", value)
      }}/>
      <span className="settings-role-entry-modal-subtitle">Permissions</span>
      {hasPermissions && permissions.map((permission, index) => {
        const key = `settings-permission-${index}`
        if (permission.type === "radio") {
          return (
            <RadioPermission
              key={key}
              name={permission.label}
              value={role.claims.includes(permission.claims[0].value)}
              onValueChange={checked => {
                onPermissionChange({[permission.claims[0].value]: checked})
              }}
            />
          )
        }

        if (permission.type === "select") {
          let value = null
          const options = []

          if (permission.is_multi) {
            value = []
            permission.claims.forEach(claim => {
              if (role.claims.includes(claim.value)) value.push(claim)
              else options.push(claim)
            })
          } else {
            permission.claims.forEach(claim => {
              if (role.claims.includes(claim.value)) value = claim
              else options.push(claim)
            })
          }

          return (
            <SelectPermission
              key={key}
              name={permission.label}
              value={value}
              options={options}
              multi={permission.is_multi}
              onValueChange={newValues => {
                const data = {}
                permission.claims.forEach(claim => {
                  data[claim.value] = newValues.includes(claim.value)
                })
                onPermissionChange(data)
              }}
            />
          )
        }

        throw new Error(`Unsupported permission type "${permission.value.type}"`)
      })}
    </div>
  )
}
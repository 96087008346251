export const TYPE_OPTIONS = [
  {
    label: "General",
    value: "General",
    style: {
      backgroundColor: "var(--neutral-color-100)",
      dividerColor: "var(--neutral-color-125)",
      iconColor: "var(--neutral-color-500)",
    },
    required_fields: [
      "school_id"
    ]
  },
  {
    label: "Facility",
    value: "Facility",
    style: {
      backgroundColor: "var(--green-color-100)",
      dividerColor: "var(--green-color-125)",
      iconColor: "var(--green-color-500)",
    },
    required_fields: [
      "school_id",
      "zone_id"
    ]
  },
  {
    label: "Student",
    value: "Student",
    style: {
      backgroundColor: "var(--purple-color-100)",
      dividerColor: "var(--purple-color-125)",
      iconColor: "var(--purple-color-500)",
    },
    required_fields: [
      "school_id",
      "student_id"
    ]
  },
]

export const PRIORITY_OPTIONS = [
  {
    label: "Info",
    value: 0,
    style: {
      backgroundColor: "var(--primary-color-100)",
      dividerColor: "var(--primary-color-125)",
      iconColor: "var(--primary-color-500)",
    }
  },
  {
    label: "Warning",
    value: 1,
    style: {
      backgroundColor: "var(--orange-color-100)",
      dividerColor: "var(--orange-color-125)",
      iconColor: "var(--orange-color-500)",
    }
  },
  {
    label: "Critical",
    value: 2,
    style: {
      backgroundColor: "var(--red-color-100)",
      dividerColor: "var(--red-color-125)",
      iconColor: "var(--red-color-500)",
    }
  },
]

export const DAILY_FREQUENCY = {label: "Daily", value: "daily"}
export const WEEKLY_FREQUENCY = {label: "Weekly", value: "weekly"}


export const timeToSeconds = (value) => {
  if (value.time == null || value.time.length < 4 || value.meridiem == null) {
    return null
  }

  const components = value.time.split(':')
  let hours = parseInt(components[0])
  if (value.meridiem === 0 && hours === 12) {
    hours = 0
  } else if (value.meridiem === 1) {
    hours += 12
  }

  let seconds = hours * 60 * 60

  if (components.length > 1) {
    const minutes = parseInt(components[1]) * 60
    seconds += minutes
  }

  return seconds
}

export const secondsToTime = (value) => {
  let hours = Math.floor(value / 3600)
  if (hours === 0) {
    hours = 12
  }

  let minutes = Math.floor((value % 3600) / 60)
  if (minutes < 10) {
    minutes = `0${minutes}`
  } else {
    minutes = minutes.toString()
  }

  let meridiem = 0
  if (value >= 12 * 60 * 60) {
    hours -= 12
    meridiem = 1
  }

  return {time: `${hours}:${minutes}`, meridiem}
}

export const formatWeekDays = days => {
  let values = []

  if (days.includes(0)) {
    values.push("Mon")
  }

  if (days.includes(1)) {
    values.push("Tue")
  }

  if (days.includes(2)) {
    values.push("Wed")
  }

  if (days.includes(3)) {
    values.push("Thu")
  }

  if (days.includes(4)) {
    values.push("Fri")
  }

  if (days.includes(5)) {
    values.push("Sat")
  }

  if (days.includes(6)) {
    values.push("Sun")
  }

  return `on ${values.join(", ")}`
}

export const hasRequiredFields = (requiredFields, columns) => {
  if (!requiredFields || !columns || !columns.length) {
    return false
  }

  const required = new Set(requiredFields)
  return (columns || []).filter(c => required.has(c)).length === required.size
}
import {useNavigate} from "react-router";
import {Button, CancelButton, SaveButton} from "../../shared/components/buttons";
import {getInputValue} from "../../shared/utils/format";
import {ColoredSelect} from "../../shared/components/select";
import {DAILY_FREQUENCY, formatWeekDays, hasRequiredFields, PRIORITY_OPTIONS, secondsToTime, timeToSeconds, TYPE_OPTIONS, WEEKLY_FREQUENCY} from "./shared";
import React, {useEffect, useReducer, useState} from "react";
import {TabBody, TabHeader, Tabs} from "../../shared/components/tabs";
import {ReactComponent as EabIcon} from "../../assets/icons/alerts/eab_icon.svg";
import {ReactComponent as EditIcon} from "../../shared/assets/icons/button/edit_icon.svg";
import {ReactComponent as PlayIcon} from "../../shared/assets/icons/play_icon.svg";
import {ReactComponent as TimeIcon} from "../../shared/assets/icons/clock_icon.svg";
import {TableView} from "../../shared/components/table";
import Api, {SHORT_POLL_INTERVAL} from "../../shared/api/api";
import Indicator from "../../shared/components/loader";
import {ErrorToast, SuccessToast} from "../../shared/utils/toast";
import {EntryColoredSelector, EntryInput, EntryModal, EntrySelector, EntrySlider} from "../../shared/components/modal";
import {convertToTime} from "./date_utils";
import {useAlertContext} from "../../hooks/alert_context";

const DEFAULT_NOTIFICATION_OPTIONS = {
  EAB: false
}

const DEFAULT_NOTIFICATION_VALUES = Object.entries(DEFAULT_NOTIFICATION_OPTIONS).filter(([_, visible]) => visible).map(([key]) => key)

const QUERY_TAB = {id: "query", label: "SQL Query"}
const GENERAL_TAB = {id: "general", label: "General"}

const TABS = [
  GENERAL_TAB,
  QUERY_TAB,
]

const LIMIT_OPTIONS = [
  {label: "5", value: 5},
  {label: "10", value: 10},
  {label: "25", value: 25},
  {label: "50", value: 50},
]

const FREQUENCY_OPTIONS = [
  DAILY_FREQUENCY,
  WEEKLY_FREQUENCY
]

const INITIAL_TYPE = TYPE_OPTIONS[0]

const DEFAULT_ALERT_DATA = {
  id: undefined,
  name: null,
  type: INITIAL_TYPE.value,
  priority: PRIORITY_OPTIONS[0].value,
  is_system_alert: false,
  message: null,
  query: null,
  schedule: null,
  columns: [],
  notifications: DEFAULT_NOTIFICATION_VALUES,
}

const copyAlert = (base, data) => ({
  ...base,
  notifications: [...base.notifications],
  ...(data != null ? data : {})
})

const getChanges = (original, current) => {
  const compareNotifications = () => {
    const v1 = original.notifications?.sort()
    const v2 = current.notifications?.sort()

    return v1?.length === v2?.length && v1.every((n, i) => n === v2[i])
  }

  const out = {}
  for (let key of Object.keys(original)) {
    if (key === "notifications") {
      if (!compareNotifications()) {
        out[key] = current[key]
      }
    } else if (original[key] !== current[key]) {
      out[key] = current[key]
    }
  }

  return Object.keys(out).length ? out : null
}

const DEFAULT_DATA = {
  isLoading: false,
  selectedTabId: TABS[0].id,
  original: copyAlert(DEFAULT_ALERT_DATA),
  data: copyAlert(DEFAULT_ALERT_DATA),
  columns: [],
  limit: LIMIT_OPTIONS[0].value,
  preview: [],
  query_id: null,
  scheduleModal: {
    show: false,
    data: null
  },
  required_fields: {
    types: {},
    notifications: {}
  },
  notification_options: DEFAULT_NOTIFICATION_OPTIONS
}

const SET_LOADING = "SET_LOADING"
const SET_TAB = "SET_TAB"

const SET_DATA = "SET_DATA"
const UPDATE_DATA = "UPDATE_DATA"

const SET_QUERY_ID = "SET_QUERY_ID"
const SET_PREVIEW = "SET_PREVIEW"
const SET_LIMIT = "SET_LIMIT"

const SET_META_FIELDS = "SET_META_FIELDS"

const SHOW_SCHEDULE_MODAL = "SHOW_SCHEDULE_MODAL"

const AlertDetailReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }

    case SET_TAB:
      return {
        ...state,
        selectedTabId: action.payload
      }

    case SET_DATA:
      return {
        ...state,
        original: copyAlert(action.payload),
        data: copyAlert(action.payload),
        columns: action.payload.columns
      }

    case UPDATE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }

    case SET_QUERY_ID:
      return {
        ...state,
        ...action.payload
      }

    case SET_PREVIEW:
      return {
        ...state,
        isLoading: false,
        query_id: null,
        data: {
          ...state.data,
          columns: action.payload.columns
        },
        ...action.payload
      }

    case SET_LIMIT:
      return {
        ...state,
        limit: action.payload
      }

    case SET_META_FIELDS:
      return {
        ...state,
        required_fields: action.payload.columns,
        notification_options: action.payload.notifications,
        isLoading: false,
      }

    case SHOW_SCHEDULE_MODAL:
      return {
        ...state,
        scheduleModal: action.payload
      }

    default:
      throw new Error(`Unhandled action type ${action.type}`)
  }
}

const QueryStatus = {
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  FINISHED: "FINISHED",
  ERROR: "ERROR",
  CANCELLED: "CANCELLED"
}


export function AlertDetail() {
  const {loading, school, loadDataIfNeeded, detail, data, actions} = useAlertContext()

  const navigate = useNavigate()

  const [state, dispatch] = useReducer(AlertDetailReducer, DEFAULT_DATA)

  const setLoading = loading => dispatch({type: SET_LOADING, payload: loading})
  const setTab = tab => dispatch({type: SET_TAB, payload: tab.id})
  const updateData = data => dispatch({type: UPDATE_DATA, payload: data})

  const setQueryId = (queryId, isLoading) => dispatch({type: SET_QUERY_ID, payload: {query_id: queryId, isLoading}})
  const setPreviewData = (columns, rows) => dispatch({type: SET_PREVIEW, payload: {columns, preview: rows}})
  const setLimit = limit => dispatch({type: SET_LIMIT, payload: limit})

  const setMetaFields = meta => dispatch({type: SET_META_FIELDS, payload: meta})

  const showScheduleModal = data => dispatch({type: SHOW_SCHEDULE_MODAL, payload: {show: true, data}})
  const hideScheduleModal = () => dispatch({type: SHOW_SCHEDULE_MODAL, payload: {show: false, data: null}})

  useEffect(() => {
    loadDataIfNeeded()
  }, [loadDataIfNeeded])

  useEffect(() => {
    if (data.selectedAlert?.id === state.data?.id) {
      return
    }

    dispatch({type: SET_DATA, payload: data.selectedAlert})
  }, [data.selectedAlert, state.data?.id]);

  useEffect(() => {
    if (school?.id == null) {
      return
    }

    setLoading(true)

    const controller = new AbortController();
    Api.get(`schools/${school.id}/alerts/meta`, {signal: controller.signal})
      .then(response => setMetaFields(response.data.meta))
      .catch(e => {
        setLoading(false)

        if (e.handled) return

        console.log(e.error)
        ErrorToast("Unable to fetch School Alert meta data")
      })
  }, [school?.id])

  useEffect(() => {
    if (!state.query_id || school?.id == null) {
      return
    }

    let interval = null

    const poll = () => {
      Api.post(`schools/${school.id}/alerts/preview/status`, {query_id: state.query_id})
        .then(response => {
          if (response.data.status === QueryStatus.CANCELLED) {
            ErrorToast("Preview was cancelled")
            setQueryId(null)
            clearInterval(interval)
          } else if (response.data.status === QueryStatus.ERROR) {
            ErrorToast("Preview failed")
            setQueryId(null)
            clearInterval(interval)
          } else if (response.data.status === QueryStatus.FINISHED) {
            setQueryId(null, true)
            clearInterval(interval)

            Api.post(`schools/${school.id}/alerts/preview/results`, {query_id: state.query_id})
              .then(response => {
                setPreviewData(response.data.columns, response.data.rows)
              })
              .catch(e => {
                setLoading(false)

                if (e.handled) return

                console.error(e.error)
                ErrorToast("Unable to fetch results for preview")
              })
          } else if ([QueryStatus.PENDING, QueryStatus.RUNNING].includes(response.data.status)) {
            // don't do anything
          } else {
            throw new Error(`Unhandled response type: ${response.data.status}`)
          }
        })
        .catch(e => {
          setQueryId(null)

          if (e.handled) return

          console.error(e.error)
          ErrorToast("Unable to get status")
        })
    }

    interval = setInterval(poll, SHORT_POLL_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [school?.id, state.query_id]);

  const handleNameChange = e => {
    updateData({
      name: e.target.value !== "" ? e.target.value : null
    })
  }

  const handleTypeChange = newType => {
    updateData({
      type: newType.value
    })
  }

  const handlePriorityChange = newPriority => {
    updateData({
      priority: newPriority.value
    })
  }

  const handleSystemAlertChange = newValue => {
    updateData({
      is_system_alert: newValue
    })
  }

  const handleScheduleChange = newSchedule => {
    updateData({
      schedule: newSchedule
    })
    hideScheduleModal()
  }

  const handleMessageChange = newMessage => {
    updateData({
      message: newMessage
    })
  }

  const handleNotificationChange = newNotification => {
    // clear current value
    let notifications = state.data.notifications.filter(n => n !== newNotification.key)

    // add value if needed
    if (newNotification.value) {
      notifications.push(newNotification.key)
      notifications = notifications.sort()
    }

    updateData({
      notifications
    })
  }

  const handleQueryChange = newQuery => {
    updateData({
      query: newQuery,
      columns: []
    })
  }

  const handleRunQuery = () => {
    setLoading(true)
    Api.post(`schools/${school.id}/alerts/preview`, {
      query: state.data.query.trim(),
      limit: state.limit
    })
      .then(response => {
        setQueryId(response.data.id, true)
      })
      .catch(e => {
        setLoading(false)

        if (e.handled) return

        console.error(e.error)
        ErrorToast("Unable to execute query")
      })
  }

  const handleCancel = () => {
    navigate("/alert", {state: {created: false}})
  }

  const getRequiredFields = () => {
    const baseValues = state.required_fields.types[state.data.type]
    let requiredFields = baseValues != null ? [...baseValues] : []

    // add any required fields needed for a notification
    const notifications = state.data.notifications.flatMap(n => state.required_fields.notifications[n])
    if (notifications.length > 0) {
      requiredFields = Array.from(new Set([...requiredFields, ...notifications]))
    }

    return requiredFields.sort()
  }

  const isSaveDisabled = () => {
    const isValid = key => state.data[key]?.trim().length > 0

    if (!isValid("name")) {
      return true
    }

    if (!isValid("type")) {
      return true
    }

    if (!isValid("query")) {
      return true
    }

    if (!isValid("message")) {
      return true
    }

    if (state.data.schedule == null) {
      return true
    }

    if (!state.required_fields?.types[state.data.type]) {
      return true
    }

    if (!state.data.columns?.length) {
      return true
    }

    const hasAllFields = hasRequiredFields(getRequiredFields(), state.data.columns)
    if (!hasAllFields) {
      return true
    }

    return getChanges(state.original, state.data) == null
  }

  const handleSave = () => {
    if (detail.isCreate) {
      const body = {
        name: state.data.name,
        is_system_alert: state.data.is_system_alert,
        type: state.data.type,
        priority: state.data.priority,
        query: state.data.query.trim(),
        message: state.data.message.trim(),
        schedule: state.data.schedule,
        columns: state.data.columns,
        notifications: state.data.notifications
      }
      actions.add(body, {
        successCallback: () => {
          SuccessToast(`${state.data.name} was created`)
          navigate("/alert")
        },
        errorCallback: () => ErrorToast(`Unable to create ${state.data.name}`)
      })
    } else {
      actions.update(state.original, {
        body: getChanges(state.original, state.data),
        successCallback: () => {
          SuccessToast(`${state.data.name} was updated`)
          navigate("/alert")
        },
        errorCallback: () => ErrorToast(`Unable to update ${state.data.name}`)
      })
    }
  }

  const typeValue = TYPE_OPTIONS.filter(t => t.value === state.data.type)[0]
  const typeOptions = TYPE_OPTIONS.filter(t => t.value !== typeValue.value)

  const nameArgs = detail.isCreate ? {autoFocus: true} : {}

  return (
    <div className="enable-events da-card alert-detail">
      <div className="alert-detail-header">
        <input placeholder="Alert" value={getInputValue(state.data, "name")} onChange={handleNameChange} {...nameArgs}/>
        <ColoredSelect
          additionalClassName="alert-detail-header-type"
          value={typeValue}
          options={typeOptions}
          onChange={handleTypeChange}
        />
      </div>
      <div className="alert-detail-body">
        <Tabs>
          <TabHeader selectedTab={state.selectedTabId} items={TABS} onSelectTab={setTab}/>
          <TabBody className="alert-detail-body-content">
            <General
              show={state.selectedTabId === GENERAL_TAB.id}
              data={state.data}
              notificationOptions={state.notification_options}
              onPriorityChange={handlePriorityChange}
              onSystemAlertChange={handleSystemAlertChange}
              onScheduleClick={showScheduleModal}
              onMessageChange={handleMessageChange}
              onNotificationChange={handleNotificationChange}
            />
            <Query
              show={state.selectedTabId === QUERY_TAB.id}
              query={state.data.query}
              columns={state.columns}
              requiredColumns={getRequiredFields()}
              limit={state.limit}
              previewData={state.preview}
              onQueryChange={handleQueryChange}
              onLimitChange={setLimit}
              onRunQuery={handleRunQuery}
            />
          </TabBody>
        </Tabs>
      </div>
      <div className="alert-detail-buttons">
        <CancelButton onClick={handleCancel}/>
        <SaveButton disabled={isSaveDisabled()} onClick={handleSave}/>
      </div>
      <ScheduleModal
        show={state.scheduleModal.show}
        data={state.scheduleModal.data}
        onHide={hideScheduleModal}
        onFinish={handleScheduleChange}
      />
      <Indicator show={loading || state.isLoading}/>
    </div>
  )
}

const General = ({show, data, notificationOptions, onPriorityChange, onSystemAlertChange, onScheduleClick, onMessageChange, onNotificationChange}) => {
  if (!show) {
    return null
  }

  const priorityValue = PRIORITY_OPTIONS.filter(p => p.value === data.priority)[0]
  const priorityOptions = PRIORITY_OPTIONS.filter(p => p.value !== priorityValue)

  return (
    <div className="alert-detail-body-general">
      <EntryColoredSelector
        label="PRIORITY"
        value={priorityValue}
        options={priorityOptions}
        onChange={onPriorityChange}
      />

      <EntrySlider
        label="SYS ADMIN ONLY"
        value={data.is_system_alert}
        onChange={onSystemAlertChange}
      />

      <EntrySchedule
        label="SCHEDULE"
        value={data.schedule}
        onClick={onScheduleClick}
      />

      <EntryInput
        className="alert-detail-message-input"
        label="MESSAGE"
        placeholder="Message to display in alert.  Uses standard formatting options like `My message for school {school_name}`"
        value={data.message}
        multi={true}
        onChange={onMessageChange}
      />

      <Notifications
        data={data.notifications}
        options={notificationOptions}
        onCheck={onNotificationChange}
      />
    </div>
  )
}

const Query = ({show, query, columns, requiredColumns, limit, previewData, onQueryChange, onLimitChange, onRunQuery}) => {
  if (!show) {
    return null
  }

  const handleQueryChange = (e) => {
    const value = e.target.value.replaceAll(";", "")
    onQueryChange && onQueryChange(value.length > 0 ? value : null)
  }

  const limitValue = LIMIT_OPTIONS.filter(l => l.value === limit)[0]
  const limitOptions = LIMIT_OPTIONS.filter(l => l.value !== limit)

  const handleLimitChange = newLimit => {
    onLimitChange && onLimitChange(newLimit.value)
  }

  const formatColumns = values => {
    if (!values?.length) {
      return <span className="column-value">--</span>
    }

    return (
      <>
        {values.sort().map((column, i) => {
          const classNames = ["column-value"]

          if (columns && !columns.includes(column)) {
            classNames.push("danger")
          }

          return <span key={i} className={classNames.join(" ")}>{column}</span>
        })}
      </>
    )
  }

  return (
    <div className="alert-detail-body-query-container">
      <div className="alert-detail-body-query">
        <div className="alert-detail-body-query-header">
          <div className="alert-detail-body-query-header-buttons">
            <Button variant="secondary" disabled={query == null || !query.trim().length} onClick={onRunQuery}>
              <PlayIcon/>
              Run Query
            </Button>
            <div className="alert-detail-body-query-header-buttons-required-columns">
              <span className="column-label">Required Columns:</span>
              <div className="column-value">{formatColumns(requiredColumns)}</div>
              <span className="column-label">Current Columns:</span>
              <div className="column-value">{formatColumns(columns)}</div>
            </div>
            <EntrySelector
              label="Limit"
              value={limitValue}
              options={limitOptions}
              onChange={handleLimitChange}
            />
          </div>
          <textarea className="alert-detail-body-query-body" value={getInputValue(query)} onChange={handleQueryChange}/>
        </div>
        <QueryPreview columns={columns} data={previewData}/>
      </div>
    </div>
  )
}

const EntrySchedule = ({label, value, onClick}) => {
  const formatSchedule = () => {
    if (value == null) {
      return ""
    }

    let textComponents = []

    if (value.frequency === WEEKLY_FREQUENCY.value) {
      textComponents.push(WEEKLY_FREQUENCY.label)
      if (value.days.length < 7) {
        textComponents.push(formatWeekDays(value.days))
      }
    } else {
      textComponents.push("Today")
    }

    const {time, meridiem} = secondsToTime(value.run_ts)
    textComponents.push(`at ${time} ${meridiem === 1 ? "PM" : "AM"}`)

    return textComponents.join(" ")
  }

  const handleOnClick = () => {
    onClick && onClick(value)
  }

  return (
    <div className="da-modal-entry-input da-modal-entry-schedule">
      <span className="da-modal-entry-input-label">{label}</span>
      <div className="da-modal-entry-input-schedule" onClick={handleOnClick}>
        <span>{formatSchedule()}</span>
        <EditIcon/>
      </div>
    </div>
  )
}

const QueryPreview = ({columns, data}) => {
  const formatColumn = column => {
    return column.trim()
      .replaceAll("_", " ")
      .toUpperCase()
  }

  return (
    <div className="alert-detail-body-query-preview">
      <span className="alert-detail-body-query-preview-header">Preview Data</span>
      <TableView
        classNames={{
          container: "alert-detail-body-query-preview-table"
        }}
        noDataMessage="No Results"
        columns={columns.map(c => ({label: formatColumn(c), key: c}))}
        data={data}
      />
    </div>
  )
}

const AM_OPTION = {label: "AM", value: 0}
const PM_OPTION = {label: "PM", value: 1}
const MERIDIEM_OPTIONS = [
  AM_OPTION,
  PM_OPTION
]

const DEFAULT_FREQUENCY_DATA = {
  start_date: null,
  end_date: null,
  frequency: WEEKLY_FREQUENCY.value,
  time: null,
  meridiem: PM_OPTION.value,
  days: {
    sunday_enabled: false,
    monday_enabled: false,
    tuesday_enabled: false,
    wednesday_enabled: false,
    thursday_enabled: false,
    friday_enabled: false,
    saturday_enabled: false,
  }
}

const ScheduleModal = ({show, data, onHide, onFinish}) => {
  const [currentData, setCurrentData] = useState(() => ({
    ...DEFAULT_FREQUENCY_DATA,
    ...(data || {})
  }))

  useEffect(() => {
    if (!show) {
      setCurrentData(() => DEFAULT_FREQUENCY_DATA)
    } else {
      let convertedData = {}

      if (data != null) {
        const {time, meridiem} = secondsToTime(data.run_ts)
        convertedData = {
          frequency: data.frequency,
          time,
          meridiem,
          days: formatSelectedDays(data.days)
        }
      }

      setCurrentData(() => ({
        ...DEFAULT_FREQUENCY_DATA,
        ...convertedData
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (!show) {
    return
  }

  const formatSelectedDays = (days) => {
    const out = {
      sunday_enabled: false,
      monday_enabled: false,
      tuesday_enabled: false,
      wednesday_enabled: false,
      thursday_enabled: false,
      friday_enabled: false,
      saturday_enabled: false,
    }

    if (days == null || days.length === 0) {
      return out
    }

    if (days.includes(0)) {
      out.monday_enabled = true
    }

    if (days.includes(1)) {
      out.tuesday_enabled = true
    }

    if (days.includes(2)) {
      out.wednesday_enabled = true
    }

    if (days.includes(3)) {
      out.thursday_enabled = true
    }

    if (days.includes(4)) {
      out.friday_enabled = true
    }

    if (days.includes(5)) {
      out.saturday_enabled = true
    }

    if (days.includes(6)) {
      out.sunday_enabled = true
    }

    return out
  }

  const getSelectedDays = () => {
    const days = []

    if (currentData == null || currentData.days == null) {
      return days
    }

    if (currentData.days.monday_enabled) {
      days.push(0)
    }

    if (currentData.days.tuesday_enabled) {
      days.push(1)
    }

    if (currentData.days.wednesday_enabled) {
      days.push(2)
    }

    if (currentData.days.thursday_enabled) {
      days.push(3)
    }

    if (currentData.days.friday_enabled) {
      days.push(4)
    }

    if (currentData.days.saturday_enabled) {
      days.push(5)
    }

    if (currentData.days.sunday_enabled) {
      days.push(6)
    }

    return days
  }

  const isButtonDisabled = () => {
    if (timeToSeconds(currentData) == null || currentData.frequency == null) {
      return true
    }

    if (currentData.frequency === WEEKLY_FREQUENCY.value) {
      if (getSelectedDays().length === 0) {
        return true
      }
    }

    return false
  }

  const handleOnSave = () => {
    const schedule = {
      frequency: currentData.frequency,
      run_ts: timeToSeconds(currentData),
    }

    if (currentData.frequency === WEEKLY_FREQUENCY.value) {
      schedule.days = getSelectedDays()
    }

    onFinish && onFinish(schedule)
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Save",
      disabled: isButtonDisabled(),
      onClick: handleOnSave
    }
  }

  const frequencyValue = FREQUENCY_OPTIONS.filter(f => f.value === currentData.frequency)[0]
  const frequencyOptions = FREQUENCY_OPTIONS.filter(f => f.value !== currentData.frequency)

  const updateData = data => {
    setCurrentData(old => ({
      ...old,
      ...data
    }))
  }

  // const handleStartDateChange = newDate => updateData({start_date: newDate})
  // const handleEndDateChange = newDate => updateData({end_date: newDate})
  const handleFrequencyChange = newFrequency => updateData({frequency: newFrequency.value})
  const handleDayChange = newValues => updateData({days: newValues})
  const handleTimeChange = newTime => updateData({time: newTime})
  const handleMeridiemChange = newMeridiem => updateData({meridiem: newMeridiem.value})

  const showDayPicker = currentData.frequency === WEEKLY_FREQUENCY.value

  return (
    <EntryModal cardClassName="alert-detail-schedule-modal" bodyClassName="alert-detail-schedule-modal-body" show={show} title={currentData.name || "Create Schedule"} buttons={buttons} onHide={onHide}>
      {/*<div className="alert-detail-schedule-modal-dates">*/}
      {/*  <EntryDate*/}
      {/*    label="Start Date"*/}
      {/*    value={currentData.start_date}*/}
      {/*    onChange={(newValue) => {*/}
      {/*      if (newValue != null && !newValue.length) {*/}
      {/*        newValue = null*/}
      {/*      }*/}

      {/*      if (currentData.start_date === newValue) {*/}
      {/*        return*/}
      {/*      }*/}

      {/*      handleStartDateChange(newValue)*/}
      {/*    }}*/}
      {/*  />*/}

      {/*  <EntryDate*/}
      {/*    label="End Date"*/}
      {/*    value={currentData.end_date}*/}
      {/*    onChange={(newValue) => {*/}
      {/*      if (newValue != null && !newValue.length) {*/}
      {/*        newValue = null*/}
      {/*      }*/}

      {/*      if (currentData.end_date === newValue) {*/}
      {/*        return*/}
      {/*      }*/}

      {/*      handleEndDateChange(newValue)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      <EntrySelector
        label="Frequency"
        value={frequencyValue}
        options={frequencyOptions}
        onChange={handleFrequencyChange}
      />

      <EntryDayPicker
        show={showDayPicker}
        values={currentData.days}
        onChange={handleDayChange}
      />

      <EntryTimePicker
        timeValue={currentData.time}
        meridiemValue={currentData.meridiem}
        onTimeChange={handleTimeChange}
        onMeridiemChange={handleMeridiemChange}
      />
    </EntryModal>
  )
}

const EntryDayPicker = ({show, values, onChange}) => {
  if (!show) {
    return null
  }

  const handleClick = ordinal => {
    const getKey = () => {
      switch (ordinal) {
        case 0:
          return "monday_enabled"

        case 1:
          return "tuesday_enabled"

        case 2:
          return "wednesday_enabled"

        case 3:
          return "thursday_enabled"

        case 4:
          return "friday_enabled"

        case 5:
          return "saturday_enabled"

        case 6:
          return "sunday_enabled"

        default:
          ErrorToast(`Unknown day ${ordinal}`)
      }
    }

    return () => {
      const key = getKey()
      onChange && onChange({
        ...values,
        [key]: !values[key]
      })
    }
  }

  return (
    <div className="da-modal-entry-input da-modal-entry-day-picker">
      <span className="da-modal-entry-input-label">Days of Week</span>
      <div className="da-modal-entry-input-day-picker">
        <span className="da-modal-entry-input-day-picker-day" aria-selected={!!values.sunday_enabled} onClick={handleClick(6)}>Sun</span>
        <span className="da-modal-entry-input-day-picker-day" aria-selected={!!values.monday_enabled} onClick={handleClick(0)}>Mon</span>
        <span className="da-modal-entry-input-day-picker-day" aria-selected={!!values.tuesday_enabled} onClick={handleClick(1)}>Tue</span>
        <span className="da-modal-entry-input-day-picker-day" aria-selected={!!values.wednesday_enabled} onClick={handleClick(2)}>Wed</span>
        <span className="da-modal-entry-input-day-picker-day" aria-selected={!!values.thursday_enabled} onClick={handleClick(3)}>Thu</span>
        <span className="da-modal-entry-input-day-picker-day" aria-selected={!!values.friday_enabled} onClick={handleClick(4)}>Fri</span>
        <span className="da-modal-entry-input-day-picker-day" aria-selected={!!values.saturday_enabled} onClick={handleClick(5)}>Sat</span>
      </div>
    </div>
  )
}

const EntryTimePicker = ({timeValue, meridiemValue, onTimeChange, onMeridiemChange}) => {
  const handleOnTimeChange = e => {
    if (!onTimeChange) {
      return
    }

    let newValue = e.target.value

    const isDelete = newValue.length < (timeValue || "").length
    if (isDelete) {
      const deletedValue = timeValue.slice(newValue.length, timeValue.length)
      if (deletedValue === ":") {
        // delete extra
        newValue = newValue.slice(0, newValue.length - 1)
      }
    }

    newValue = convertToTime(newValue)
    if (timeValue !== newValue) {
      onTimeChange(newValue)
    }
  }

  const meridiemSelectValue = MERIDIEM_OPTIONS.filter(m => m.value === meridiemValue)[0]
  const meridiemSelectOptions = MERIDIEM_OPTIONS.filter(m => m.value !== meridiemValue)

  return (
    <div className="da-modal-entry-input-time-picker">
      <div className="da-modal-entry-input">
        <span className="da-modal-entry-input-label">Time</span>
        <div className="da-modal-entry-input-schedule da-modal-entry-input-time-picker-input">
          <input value={getInputValue(timeValue)} onChange={handleOnTimeChange}/>
          <TimeIcon/>
        </div>
      </div>
      <EntrySelector
        value={meridiemSelectValue}
        options={meridiemSelectOptions}
        onChange={onMeridiemChange}
      />
    </div>
  )
}

function Notifications({data, options, onCheck}) {
  const handleOnCheck = (key) => (value) => {
    onCheck && onCheck({key, value})
  }

  const lookup = {}
  data.forEach(notification => {
    lookup[notification] = true
  })

  return (
    <div className="alert-detail-notifications">
      <span className="alert-detail-notifications-title">Notifications</span>
      <NotificationHeader title="EAB" disabled={!options.EAB} checked={lookup.EAB} onChange={handleOnCheck("EAB")}>
        <EabIcon/>
      </NotificationHeader>
    </div>
  )
}

function NotificationHeader({title, disabled, checked, onChange, ...props}) {
  disabled = !!disabled

  return (
    <div className="alert-detail-notifications-row-header">
      <div className="alert-detail-notifications-row-header-container" aria-disabled={disabled}>
        {props.children}
        <span>{title}</span>
      </div>
      <EntrySlider value={checked} disabled={disabled} onChange={onChange}/>
    </div>
  )
}
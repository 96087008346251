import React, {useEffect, useReducer} from "react";

import Api from "../../../shared/api/api";

import {PersonalPane} from "./panes/personal";
import {UserPane} from "./panes/user";
import {RolePane} from "./panes/role";
import {AdminIntegrationPane} from "./panes/admin_integrations";
import {SchoolIntegrationPane} from "./panes/school_integration";
import {SchoolUrlPane} from "./panes/school_url";

import Indicator from "../../../shared/components/loader";

import {AttributeSort} from "../../../utils/sort";
import {CloseButton} from "../../../shared/components/buttons";
import {ErrorToast} from "../../../shared/utils/toast";
import {Modal} from "../../../shared/components/modal";
import {Badge} from "../../../shared/components/badge";
import {SchoolDropdown} from "../../dropdown";
import {IgnoreApView} from "./panes/ap_ignore";
import {UserManagementPane} from "./panes/user_management";

const OPTION_IDS = {
  PERSONAL: 0,
  PASSWORD: 1,
  USERS: 2,
  ROLES: 3,
  ADMIN_INTEGRATIONS: 4,
  SCHOOL_INTEGRATIONS: 5,
  SCHOOL_URLS: 6,
  IGNORE_APS: 7,
  USER_MANAGEMENT: 8
}

const DEFAULT_DATA = {
  loading: false,
  canAdminIntegrationPolling: false,
  canSchoolIntegrationPolling: false,
  option: {
    id: OPTION_IDS.PERSONAL,
    title: "Personal Information",
  },
  usersData: {
    is_auth_managed: false,
    users: [],
    pending_users: [],
    invited_users: [],
    roles: []
  },
  roleData: {
    roles: [],
    permissions: []
  },
  adminIntegrationData: {
    notifications: {
      integrations: 0,
      pending: 0
    },
    integrations: [],
    pending: [],
    tags: []
  },
  schoolIntegrationData: {
    notifications: {
      connected: 0,
      unconnected: 0,
    },
    connected: [],
    unconnected: [],
  },
  schoolUrlData: {
    schools: []
  },
  ignoreApData: {
    patterns: []
  }
}

const SET_LOADING = "SET_LOADING"
const setLoadingEvent = loading => ({type: SET_LOADING, payload: loading})

const CHANGE_SCHOOL = "CHANGE_SCHOOL"
const changeSchoolEvent = () => ({type: CHANGE_SCHOOL})

const SET_USERS_DATA = "SET_USERS_DATA"
const setUsersDataEvent = data => ({type: SET_USERS_DATA, payload: data})

const DELETE_USER = "DELETE_USER"
const deleteUserEvent = userId => ({type: DELETE_USER, payload: userId})

const UPDATE_USER_ROLES = "UPDATE_USER_ROLES"
const updateUserRolesEvent = user => ({type: UPDATE_USER_ROLES, payload: user})

const ADD_INVITED_USERS = "ADD_INVITED_USERS"
const addInvitedUsersEvent = users => ({type: ADD_INVITED_USERS, payload: users})

const SET_ROLE_DATA = "SET_ROLE_DATA"
const setRoleDataEvent = data => ({type: SET_ROLE_DATA, payload: data})

const SET_OPTION = "SET_OPTION"
const setOptionEvent = (id, title) => ({type: SET_OPTION, payload: {id, title}})

const ADD_ROLE = "ADD_ROLE_FINISHED"
const addRoleEvent = data => ({type: ADD_ROLE, payload: data})

const UPDATE_ROLE = "UPDATE_ROLE_FINISHED"
const updateRoleEvent = data => ({type: UPDATE_ROLE, payload: data})

const DELETE_ROLE = "DELETE_ROLE_FINISHED"
const deleteRoleEvent = roleId => ({type: DELETE_ROLE, payload: roleId})

const SET_SCHOOL_INTEGRATION_DATA = "SET_SCHOOL_INTEGRATION_DATA"
const setSchoolIntegrationDataEvent = data => ({type: SET_SCHOOL_INTEGRATION_DATA, payload: data})

const SET_ADMIN_INTEGRATION_DATA = "SET_ADMIN_INTEGRATION_DATA"
const setAdminIntegrationDataEvent = data => ({type: SET_ADMIN_INTEGRATION_DATA, payload: data})

const UPDATE_UNCONNECTED_STATE = "UPDATE_UNCONNECTED_STATE"
const updateUnconnectedStateEvent = integration => ({type: UPDATE_UNCONNECTED_STATE, payload: integration})

const CANCEL_ADMIN_INTEGRATION_POLLING = "CANCEL_ADMIN_INTEGRATION_POLLING"
const cancelAdminIntegrationPollingEvent = cancel => ({type: CANCEL_ADMIN_INTEGRATION_POLLING, payload: cancel})

const CANCEL_SCHOOL_INTEGRATION_POLLING = "CANCEL_SCHOOL_INTEGRATION_POLLING"
const cancelSchoolIntegrationPollingEvent = cancel => ({type: CANCEL_SCHOOL_INTEGRATION_POLLING, payload: cancel})

const SET_SCHOOL_DATA = "SET_SCHOOL_DATA"
const setSchoolDataEvent = data => ({type: SET_SCHOOL_DATA, payload: data})

const SET_AP_DATA = "SET_AP_DATA"
const setApDataEvent = data => ({type: SET_AP_DATA, payload: data})

const SORT_USERS = "SORT_USERS"
const sortUsersEvent = () => ({type: SORT_USERS, payload: null})

const SettingsReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case CHANGE_SCHOOL:
      return {
        ...DEFAULT_DATA,
        // loading: true
      }

    case SET_USERS_DATA:
      return {
        ...state,
        loading: false,
        usersData: action.payload
      }

    case DELETE_USER:
      return {
        ...state,
        loading: false,
        usersData: {
          ...state.usersData,
          users: state.usersData.users.filter(user => user.id !== action.payload),
          pending_users: state.usersData.pending_users.filter(user => user.id !== action.payload),
          invited_users: state.usersData.invited_users.filter(user => user.id !== action.payload)
        }
      }

    case UPDATE_USER_ROLES: {
      const buildList = users => {
        const newUsers = []
        for (let user of users) {
          if (user.id === action.payload.id) newUsers.push(action.payload)
          else newUsers.push(user)
        }

        return newUsers
      }

      return {
        ...state,
        loading: false,
        usersData: {
          ...state.usersData,
          users: buildList(state.usersData.users),
          pending_users: buildList(state.usersData.pending_users),
          invited_users: buildList(state.usersData.invited_users)
        }
      }
    }

    case ADD_INVITED_USERS:
      return {
        ...state,
        loading: false,
        usersData: {
          ...state.usersData,
          invited_users: [...state.usersData.invited_users, ...action.payload].sort(AttributeSort("name"))
        }
      }

    case SET_ROLE_DATA:
      return {
        ...state,
        loading: false,
        roleData: action.payload
      }

    case SET_OPTION:
      return {
        ...state,
        option: action.payload
      }

    case ADD_ROLE:
      return {
        ...state,
        loading: false,
        roleData: {
          ...state.roleData,
          roles: [...state.roleData.roles, action.payload].sort(AttributeSort("name"))
        }
      }

    case UPDATE_ROLE: {
      let roles = state.roleData.roles.filter(role => role.id !== action.payload.id)
      roles.push(action.payload)

      return {
        ...state,
        loading: false,
        roleData: {
          ...state.roleData,
          roles: roles.sort(AttributeSort("name"))
        }
      }
    }

    case DELETE_ROLE:
      return {
        ...state,
        loading: false,
        roleData: {
          ...state.roleData,
          roles: state.roleData.roles.filter(role => role.id !== action.payload)
        }
      }

    case SET_ADMIN_INTEGRATION_DATA:
      return {
        ...state,
        loading: false,
        adminIntegrationData: action.payload
      }

    case SET_SCHOOL_INTEGRATION_DATA:
      return {
        ...state,
        loading: false,
        schoolIntegrationData: action.payload
      }

    case UPDATE_UNCONNECTED_STATE: {
      const newUnconnected = []
      for (let group of state.schoolIntegrationData.unconnected) {
        const newGroup = {
          ...group,
          integrations: []
        }
        newUnconnected.push(newGroup)

        for (let integration of group.integrations) {
          newGroup.integrations.push(integration.id === action.payload.id ? action.payload : integration)
        }
      }

      return {
        ...state,
        schoolIntegrationData: {
          ...state.schoolIntegrationData,
          unconnected: newUnconnected
        }
      }
    }

    case CANCEL_ADMIN_INTEGRATION_POLLING:
      return {
        ...state,
        canAdminIntegrationPolling: action.payload
      }

    case CANCEL_SCHOOL_INTEGRATION_POLLING:
      return {
        ...state,
        canSchoolIntegrationPolling: action.payload
      }

    case SET_SCHOOL_DATA:
      return {
        ...state,
        loading: false,
        schoolUrlData: {
          ...state.schoolUrlData,
          ...action.payload
        }
      }

    case SET_AP_DATA:
      return {
        ...state,
        loading: false,
        ignoreApData: {
          ...state.ignoreApData,
          ...action.payload
        }
      }

    case SORT_USERS: {
      const allUsers = [
        ...state.usersData.users,
        ...state.usersData.pending_users,
        ...state.usersData.invited_users
      ]

      const users = []
      const pending_users = []
      const invited_users = []

      for (let user of allUsers) {
        if (user.claimed && user.number_of_roles > 0) {
          users.push(user)
        } else if (!user.claimed && user.number_of_roles > 0) {
          invited_users.push(user)
        } else {
          pending_users.push(user)
        }
      }

      return {
        ...state,
        usersData: {
          ...state.usersData,
          users,
          pending_users,
          invited_users
        }
      }
    }

    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
}

export default function SettingsModal({show, school, schools, user, notifications, onHide, onUserUpdate, onSchoolSelected, onNotificationsUpdated, onApsUpdated}) {
  const [state, dispatch] = useReducer(SettingsReducer, DEFAULT_DATA)

  const setLoading = loading => dispatch(setLoadingEvent(loading))
  const changeSchool = () => dispatch(changeSchoolEvent())
  const setUsersData = data => dispatch(setUsersDataEvent(data))
  const deleteUser = userId => dispatch(deleteUserEvent(userId))
  const updateUserRoles = user => dispatch(updateUserRolesEvent(user))
  const addInvitedUsers = users => dispatch(addInvitedUsersEvent(users))
  const setRoleData = data => dispatch(setRoleDataEvent(data))
  const addRole = role => dispatch(addRoleEvent(role))
  const updateRole = role => dispatch(updateRoleEvent(role))
  const deleteRole = roleId => dispatch(deleteRoleEvent(roleId))
  const setSchoolIntegrationData = data => dispatch(setSchoolIntegrationDataEvent(data))
  const setAdminIntegrationData = data => dispatch(setAdminIntegrationDataEvent(data))
  const updateUnconnectedState = integration => dispatch(updateUnconnectedStateEvent(integration))
  const cancelAdminIntegrationPolling = cancel => dispatch(cancelAdminIntegrationPollingEvent(cancel))
  const cancelSchoolIntegrationPolling = cancel => dispatch(cancelSchoolIntegrationPollingEvent(cancel))
  const setSchoolData = data => dispatch(setSchoolDataEvent(data))
  const setApData = data => dispatch(setApDataEvent(data))
  const sortUsers = () => dispatch(sortUsersEvent())

  const schoolId = school ? school.id : null

  useEffect(() => {
    changeSchool()
  }, [schoolId]);

  useEffect(() => {
    if (!show && schoolId) return

    const controller = new AbortController();
    if (state.option.id === OPTION_IDS.USERS) {
      setLoading(true)
      Api.get(`settings/${schoolId}/user`, {signal: controller.signal})
        .then((response) => {
          setUsersData(response.data)
        })
        .catch(e => {
          setLoading(false)

          if (e.handled) return

          console.error(e.error)
          ErrorToast("Unable to fetch Users data")
        })
    } else if (state.option.id === OPTION_IDS.ROLES) {
      setLoading(true)
      Api.get(`settings/${schoolId}/role`, {signal: controller.signal})
        .then(response => {
          setRoleData(response.data)
        })
        .catch(e => {
          setLoading(false)

          if (e.handled) return

          console.error(e.error)
          ErrorToast("Unable to fetch Role data")
        })
    } else if (state.option.id === OPTION_IDS.ADMIN_INTEGRATIONS) {
      setLoading(true)
      Api.get(`/settings/integration`, {signal: controller.signal})
        .then(response => {
          setAdminIntegrationData(response.data)
        })
        .catch(e => {
          setLoading(false)

          if (e.handled) return

          console.error(e.error)
          ErrorToast("Unable to fetch Integration data")
        })
    } else if (state.option.id === OPTION_IDS.SCHOOL_INTEGRATIONS) {
      setLoading(true)
      Api.get(`/settings/${schoolId}/integration`, {signal: controller.signal})
        .then(response => {
          setSchoolIntegrationData(response.data)
        })
        .catch(e => {
          setLoading(false)

          if (e.handled) return

          console.error(e.error)
          ErrorToast("Unable to fetch Integration data")
        })
    } else if (state.option.id === OPTION_IDS.SCHOOL_URLS) {
      setLoading(true)
      Api.get("/settings/schools/urls", {signal: controller.signal})
        .then(response => {
          setSchoolData(response.data)
        })
        .catch(e => {
          setLoading(false)

          if (e.handled) return

          console.error(e.error)
          ErrorToast("Unable to fetch School Url data")
        })
    } else if (state.option.id === OPTION_IDS.IGNORE_APS) {
      setLoading(true)
      Api.get(`/settings/schools/${schoolId}/aps`, {signal: controller.signal})
        .then(response => setApData(response.data))
        .catch(e => {
          setLoading(false)

          if (e.handled) return

          console.error(e.error)
          ErrorToast("Unable to fetch Ap data")
        })
    }

    return () => controller.abort()
  }, [schoolId, show, state.option.id]);

  if (!show) return null

  const handleClick = (id, title) => {
    if (state.option.id === id) return
    dispatch(setOptionEvent(id, title))
  }

  const handleOnNotificationsUpdated = () => {
    onNotificationsUpdated && onNotificationsUpdated()
  }

  const handleOnApsUpdated = () => {
    onApsUpdated && onApsUpdated()
  }

  const usesSchoolDropdown = [OPTION_IDS.USERS, OPTION_IDS.ROLES, OPTION_IDS.SCHOOL_INTEGRATIONS, OPTION_IDS.IGNORE_APS].includes(state.option.id)

  return (
    <Modal show={show} cardClassName="settings-modal-card" onHide={onHide}>
      <div className="settings-modal-container">
        <div className="settings-modal-left">
          <PaneGroup title="ACCOUNT">
            <PaneItem id={OPTION_IDS.PERSONAL} title="Personal Information" currentId={state.option.id} notification={notifications.personal} onClick={handleClick}/>
          </PaneGroup>
          <PaneGroup title="ORGANIZATION">
            <PaneItem id={OPTION_IDS.USERS} title="Users" currentId={state.option.id} notification={notifications.users} onClick={handleClick}/>
            <PaneItem id={OPTION_IDS.ROLES} title="Roles & Permissions" currentId={state.option.id} notification={notifications.roles} onClick={handleClick}/>
            <PaneItem id={OPTION_IDS.SCHOOL_INTEGRATIONS} title="School Integrations" currentId={state.option.id} notification={notifications.school_integrations} onClick={handleClick}/>
            <PaneItem id={OPTION_IDS.IGNORE_APS} title="Ignore Aps" currentId={state.option.id} notification={notifications.ignore_aps} onClick={handleClick}/>
          </PaneGroup>
          <PaneGroup title="MANAGEMENT">
            <PaneItem id={OPTION_IDS.ADMIN_INTEGRATIONS} title="Admin Integrations" currentId={state.option.id} notification={notifications.admin_integrations} onClick={handleClick}/>
            <PaneItem id={OPTION_IDS.SCHOOL_URLS} title="School Urls" currentId={state.option.id} notification={notifications.school_urls} onClick={handleClick}/>
            <PaneItem id={OPTION_IDS.USER_MANAGEMENT} title="User Management" currentId={state.option.id} notification={notifications.user_management} onClick={handleClick}/>
          </PaneGroup>
        </div>
        <div className="settings-modal-right">
          <div className="settings-modal-right__header">
            <span>{state.option.title}</span>
            <div className="settings-modal-right__header__buttons">
              {usesSchoolDropdown &&
                <SchoolDropdown currentSchool={school} schools={schools} onSchoolSelected={onSchoolSelected}/>
              }
              <CloseButton onClick={onHide}/>
            </div>
          </div>

          <PersonalPane
            show={state.option.id === OPTION_IDS.PERSONAL}
            school={school}
            user={user}
            onUserUpdate={onUserUpdate}
          />

          <UserPane
            show={state.option.id === OPTION_IDS.USERS}
            user={user}
            data={state.usersData}
            onInviteClick={(emails, roles, callback) => {
              setLoading(true)

              const formatEmail = (email) => {
                return {
                  name: email.split("@")[0],
                  email: email
                }
              }

              const body = {
                users: emails.map(formatEmail),
                roles,
                send_invite: true
              }

              Api.post(`settings/${schoolId}/user/invite`, body)
                .then(response => {
                  addInvitedUsers(response.data.users)
                  callback()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to invite users")
                })
            }}
            onRoleClick={(userToUpdate, role, isAdding) => {
              const originalRoles = [...userToUpdate.roles]
              let newRoles

              if (isAdding) newRoles = [...userToUpdate.roles, role].sort(AttributeSort("name"))
              else newRoles = [...userToUpdate.roles.filter((r) => r.id !== role.id)]

              updateUserRoles({
                ...userToUpdate,
                number_of_roles: userToUpdate.number_of_roles + 1,
                roles: newRoles
              })

              const body = {
                roles: newRoles.map(r => r.id)
              }

              Api.post(`settings/${schoolId}/user/${userToUpdate.id}/role`, body)
                .then(response => {
                  updateUserRoles(response.data.user)
                })
                .catch(e => {
                  setLoading(false)
                  updateUserRoles({
                    ...userToUpdate,
                    roles: originalRoles
                  })

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to update roles for user")
                })
            }}
            onRoleDropdownClose={sortUsers}
            onDeleteClick={(userToDelete, callback) => {
              setLoading(true)

              Api.delete(`settings/${schoolId}/user/${userToDelete.id}`)
                .then(() => {
                  deleteUser(userToDelete.id)
                  callback()
                })
                .catch(e => {
                  if (e.handled) return

                  console.error(e.error)
                  setLoading(false)
                  ErrorToast("Unable to delete User")
                })
            }}
          />

          <RolePane
            show={state.option.id === OPTION_IDS.ROLES}
            user={user}
            data={state.roleData}
            onRoleCreated={(role, callback) => {
              setLoading(true)

              Api.post(`settings/${schoolId}/role`, role)
                .then((response) => {
                  addRole(response.data.role)
                  callback()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast(`Unable to create role ${role.name}`)
                })
            }}
            onRoleUpdated={(role, body, callback) => {
              setLoading(true)

              Api.put(`settings/${schoolId}/role/${role.id}`, body)
                .then((response) => {
                  updateRole(response.data.role)
                  callback()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast(`Unable to update role ${role.name}`)
                })
            }}
            onRoleDeleted={(role, callback) => {
              setLoading(true)

              Api.delete(`settings/${schoolId}/role/${role.id}`)
                .then(() => {
                  deleteRole(role.id)
                  callback()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast(`Unable to delete role ${role.name}`)
                })
            }}
          />

          <AdminIntegrationPane
            show={state.option.id === OPTION_IDS.ADMIN_INTEGRATIONS}
            data={state.adminIntegrationData}
            cancelPolling={state.canSchoolIntegrationPolling}
            onApprovePending={(callback) => {
              setLoading(true)
              Api.post(`settings/integration/pending`, {})
                .then((response) => {
                  setAdminIntegrationData(response.data)
                  callback()

                  handleOnNotificationsUpdated()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to approve Pending Authorizations")
                })
            }}
            onRejectPending={(callback) => {
              setLoading(true)
              Api.delete(`settings/integration/pending`)
                .then((response) => {
                  setAdminIntegrationData(response.data)
                  callback()

                  handleOnNotificationsUpdated()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to reject Pending Authorizations")
                })
            }}
            onPoll={(signal) => {
              Api.get(`settings/integration`, {signal})
                .then((response) => {
                  setAdminIntegrationData(response.data)
                  const isProcessing = response.data.pending.filter(p => p.is_processing).length > 0
                  if (!isProcessing) {
                    handleOnNotificationsUpdated()
                  }
                })
                .catch(e => {
                  if (e.handled) return
                  console.error(e.error)
                  cancelAdminIntegrationPolling(true)
                })
            }}
          />
          <SchoolIntegrationPane
            show={state.option.id === OPTION_IDS.SCHOOL_INTEGRATIONS}
            integrations={state.schoolIntegrationData}
            cancelPolling={state.canSchoolIntegrationPolling}
            onInflateIntegration={(integrationId, callback) => {
              Api.get(`settings/${schoolId}/integration/${integrationId}`)
                .then(response => {
                  setLoading(false)
                  callback(response.data)
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  callback(null, "Unable to get App details")
                })
            }}
            onConnect={(integration, data, callback) => {
              setLoading(true)

              updateUnconnectedState({
                ...integration,
                state: 2,
                is_processing: true
              })

              Api.post(`/settings/${schoolId}/integration/${integration.id}`, data)
                .then(response => {
                  setSchoolIntegrationData(response.data)
                  callback()
                  handleOnNotificationsUpdated()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  updateUnconnectedState(integration)
                  ErrorToast("Unable to connect App")
                })
            }}
            onDisconnect={(integration, callback) => {
              setLoading(true)

              Api.delete(`settings/${schoolId}/integration/${integration.id}`)
                .then(response => {
                  setSchoolIntegrationData(response.data)
                  callback()
                  handleOnNotificationsUpdated()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to disconnect App")
                })
            }}
            onPoll={(signal) => {
              Api.get(`settings/${schoolId}/integration`, {signal})
                .then((response) => {
                  setSchoolIntegrationData(response.data)
                })
                .catch(e => {
                  if (e.handled) return
                  console.error(e.error)
                  cancelSchoolIntegrationPolling(true)
                })
            }}
          />
          <SchoolUrlPane
            show={state.option.id === OPTION_IDS.SCHOOL_URLS}
            data={state.schoolUrlData}
            onUpdate={(changes, handler) => {
              const body = {
                schools: []
              }

              for (let [key, value] of Object.entries(changes)) {
                body.schools.push({
                  school_id: key,
                  short_name: value
                })
              }

              setLoading(true)

              Api.post("settings/schools/urls", body)
                .then(response => {
                  setSchoolData(response.data)
                  handler && handler()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to update School Urls")
                })
            }}
            onDeploy={(handler) => {
              setLoading(true)

              Api.post("settings/schools/urls/deploy", {})
                .then(() => {
                  setLoading(false)
                  handler && handler()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to deploy School Resource Stack")
                })
            }}
          />
          <IgnoreApView
            show={state.option.id === OPTION_IDS.IGNORE_APS}
            school={school}
            data={state.ignoreApData}
            onAdd={(data, handler) => {
              setLoading(true)
              Api.post(`settings/schools/${schoolId}/aps`, data)
                .then(response => {
                  const newData = state.ignoreApData.patterns.slice()
                  newData.push(response.data.pattern)

                  setApData({patterns: newData.sort(AttributeSort("pattern"))})
                  handleOnApsUpdated()
                  handler && handler()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to update Pattern")
                })
            }}
            onDelete={(data, handler) => {
              setLoading(true)
              Api.post(`settings/schools/${schoolId}/aps/delete`, data)
                .then(() => {
                  const newData = state.ignoreApData.patterns.filter(p => p.pattern !== data.pattern)
                  setApData({patterns: newData})
                  handleOnApsUpdated()
                  handler && handler()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to delete Pattern")
                })
            }}
            onSync={handler => {
              setLoading(true)
              Api.post(`settings/schools/${schoolId}/aps/sync`, {})
                .then(response => {
                  setApData(response.data)
                  handleOnApsUpdated()
                  handler && handler()
                })
                .catch(e => {
                  setLoading(false)

                  if (e.handled) return

                  console.error(e.error)
                  ErrorToast("Unable to sync Patterns")
                })
            }}
          />

          <UserManagementPane
            show={state.option.id === OPTION_IDS.USER_MANAGEMENT}
          />
        </div>
      </div>
      <Indicator show={state.loading}/>
    </Modal>
  )
}

function PaneGroup({title, ...props}) {
  return (
    <div className="pane-group">
      <span className="settings-pane-spacing">{title}</span>
      {props.children}
    </div>
  )
}

function PaneItem({id, title, currentId, notification, onClick}) {
  return (
    <div className="pane-item" aria-selected={currentId === id} onClick={() => onClick && onClick(id, title)}>
      <span>{title}</span>
      <Badge count={notification}/>
    </div>
  )
}
import {useCallback} from "react";

const useViewport = () => {
  const isVisible = useCallback((elementId) => {
    if (!elementId) {
      return null
    }

    const element = document.getElementById(elementId)
    const rect = element.getBoundingClientRect()

    return rect.top < 0 || rect.bottom > (window.innerHeight || document.documentElement.clientHeight)
  }, [])

  return {
    isVisible,
    scrollIntoViewIfNeeded: useCallback((elementId) => {
      // if (!isVisible(elementId)) {
      //   return
      // }
      //
      // const element = document.getElementById(elementId)
      // element.scrollIntoView({behavior: "smooth"})
    }, [])
  }
}

export default useViewport
import Warehouse from "../warehouse";
import {ZoneType} from "../../pages/wireless_config/consts";
import {keyLoader} from "../../shared/utils/object";

export class AccessPoint {
  constructor(data) {
    this.id = data?.id

    this.name = data?.name
    this.description = data?.description
    this.buildingId = keyLoader(data, ["buildingId", "building.id", "building_id"])
    this.floor = data?.floor
  }

  getBuilding() {
    if (!this.buildingId) {
      return null
    }

    return Warehouse.wireless_config.getBuildings().getById(this.buildingId)
  }

  getFloorZone() {
    if (!this.buildingId || !this.floor) {
      return null
    }

    return Warehouse.wireless_config.getZones().getAll().find(z => z.type === ZoneType.FLOOR && z.getBuilding()?.id === this.buildingId && z.floors.includes(this.floor))
  }

  isComplete() {
    return this.getFloorZone() != null
  }

  hasParent() {
    return this.buildingId != null && this.floor != null
  }
}
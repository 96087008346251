import Api from "../../../shared/api/api";
import {ZoneType} from "../consts";
import {Zone} from "../../../data/models/zone";
import {AttributeSort} from "../../../utils/sort";
import {AccessPoint} from "../../../data/models/ap";
import Warehouse from "../../../data/warehouse";
import WareHouse from "../../../data/warehouse";
import {Building} from "../../../data/models/building";

const NUMBER_OF_FETCHES = 12

export async function getData(schoolId) {
  let response = await Api.get(`schools/${schoolId}/wireless/pages`)

  let urls = [
    ...buildUrls(`schools/${schoolId}/wireless/geojson`, response.data.pages.geojson).map(url => ({key: "geojson", url})),
    ...buildUrls(`schools/${schoolId}/wireless/buildings`, response.data.pages.buildings).map(url => ({key: "buildings", url})),
    ...buildUrls(`schools/${schoolId}/wireless/usages`, response.data.pages.usages).map(url => ({key: "usages", url})),
    ...buildUrls(`schools/${schoolId}/wireless/zones`, response.data.pages.zones).map(url => ({key: "zones", url})),
    ...buildUrls(`schools/${schoolId}/wireless/aps`, response.data.pages.aps).map(url => ({key: "aps", url}))
  ]

  const results = await loadAll(urls)
  const data = Object.groupBy(results, ({key}) => key)

  const aps = formatAps(data.aps?.flatMap(r => r.data))
  const zones = formatZones(schoolId, data.zones?.flatMap(r => r.data))
  const buildings = formatBuildings(data.buildings?.flatMap(r => r.data))

  return {
    pages: response.data.pages,
    zoneTypes: response.data.refs.types,
    utilization: response.data.refs.utilization,
    utilizationDefaults: response.data.refs.utilization_defaults,
    geojson: data.geojson?.reduce((acc, v) => acc.concat(v.data), []) || [],
    usages: data.usages?.reduce((acc, v) => acc.concat(v.data), []) || [],
    buildings,
    zones,
    aps
  }
}

const formatBuildings = buildings => {
  if (buildings == null) {
    return []
  }

  return buildings.map(building => new Building(building))
}

const formatAps = aps => {
  if (aps == null) {
    return []
  }

  return aps.map(ap => new AccessPoint(ap))
}

const formatZones = (schoolId, raw) => {
  if (raw == null) {
    return []
  }

  const copy = []

  for (let z of raw) {
    const newZone = new Zone({...z}, copy)

    copy.push(newZone)

    if ((!newZone.hasParent() && newZone.id !== schoolId) || newZone.getParent()?.id === schoolId) {
      newZone.is_root = true
    }
  }

  return copy.sort(AttributeSort("name"))
}

class ZoneHandler {
  constructor(schoolId) {
    this.schoolId = schoolId

    this.promises = []
    this.zones = []
    this.features = []
    this.skipZoneIds = new Set()
  }

  async add(body) {
    const postResponse = await Api.post(`schools/${this.schoolId}/wireless/zones`, body)
    const geojsonResponse = await Api.get(`schools/${this.schoolId}/wireless/zones/${postResponse.data.zone.id}/geojson`)

    this.addZone(postResponse.data.zone)
    this.addFeature(geojsonResponse.data.geojson)

    return postResponse.data.zone
  }

  async update(zoneId, body) {
    this.promises.push({type: "zone", promise: Api.put(`schools/${this.schoolId}/wireless/zones/${zoneId}`, body)})
    await this.flush()

    this.promises.push({type: "feature", promise: Api.get(`schools/${this.schoolId}/wireless/zones/${zoneId}/geojson`)})
    await this.flush()
  }

  async delete(zone) {
    await Api.delete(`schools/${this.schoolId}/wireless/zones/${zone.id}`)
    this.skipZoneIds.add(zone.id)

    Warehouse.wireless_config.getZones().remove(zone.id)
    Warehouse.wireless_config.getGeoJSON().remove(zone.id)
  }

  addZone(zone) {
    this.addZones([zone])
  }

  addZones(zones) {
    zones = zones.map(zone => new Zone(zone))
    const newZoneIds = new Set(zones.map(z => z.id))
    this.zones = [...this.zones.filter(z => !newZoneIds.has(z.id)), ...zones]

    Warehouse.wireless_config.getZones().addBulk(zones)
  }

  addFeature(feature) {
    this.addFeatures([feature])
  }

  addFeatures(features) {
    features = features.filter(f => f)
    const newFeatureIds = new Set(features.map(f => f.properties.id))
    this.features = [...this.features.filter(f => !newFeatureIds.has(f.properties.id)), ...features]

    WareHouse.wireless_config.getGeoJSON().addBulk(features)
  }

  async flush() {
    const _this = this

    return new Promise((resolve, reject) => {
      if (!_this.promises.length) {
        resolve()
        return
      }

      processPromises(_this.promises)
        .then(({zone, feature}) => {
          if (zone) {
            _this.addZones(zone.map(z => z.zone))
          }

          if (feature) {
            _this.addFeatures(feature.map(f => f.geojson))
          }

          _this.promises.length = 0

          resolve()
        })
        .catch(reject)
    })
  }

  results() {
    return {zones: this.zones, features: this.features, skipZoneIds: Array.from(this.skipZoneIds)}
  }
}

export async function postZone(schoolId, body) {
  const handler = new ZoneHandler(schoolId)

  const result = await handler.add(body)
  const zone = WareHouse.wireless_config.getZones().getById(result.id)

  if (body.type === ZoneType.FLOOR && zone.hasParent()) {
    await addFloorsToParents(handler, zone.getParent(), zone.floors)
  }

  if (body.type === ZoneType.BUILDING && body.floors?.length) {
    for (let floor of body.floors) {
      await handler.add({
        parent_id: zone.id,
        building_id: zone.buildingId,
        name: `${body.name} FL ${floor}`,
        type: ZoneType.FLOOR,
        floors: [floor],
        inherit_geojson: true,
        geojson: body.geojson,
        utilization: 5,
        visibility_option: body.visibility_option,
      })
    }
  }

  if (zone.buildingId && (!Warehouse.wireless_config.getBuildings().getById(zone.buildingId) || zoneHasBuildingChanges(zone, body))) {
    const buildingResponse = await Api.get(`schools/${schoolId}/wireless/buildings/${zone.buildingId}`)
    Warehouse.wireless_config.getBuildings().add(new Building(buildingResponse.data.building))
  }

  return {zoneId: zone.id, ...handler.results()}
}

export async function putZone(schoolId, currentZone, body) {
  const handler = new ZoneHandler(schoolId)

  if (body.type === ZoneType.CAMPUS) {
    body.parent_id = schoolId
    body.building_id = null
  }

  if (body.parent_id !== undefined && Warehouse.wireless_config.getZones().getById(body.parent_id)) {
    const parent = Warehouse.wireless_config.getZones().getById(body.parent_id)
    if (parent.type === ZoneType.FLOOR && currentZone.floors.filter(f => parent.floors.includes(f)).length !== parent.floors.length) {
      body.floors = parent.floors
    }
  }

  if (![ZoneType.CAMPUS, ZoneType.BUILDING].includes(currentZone.type) && body.floors !== undefined) {
    for (let child of currentZone.subZoneIter(child => child.floors.filter(f => body.floors.includes(f)).length !== body.floors.length)) {
      await handler.update(child.id, {floors: body.floors})
    }
  }

  if (currentZone.type === ZoneType.FLOOR && body.floors !== undefined && body.floors.length && currentZone.floors?.length && currentZone.name.endsWith(`FL ${currentZone.floors[0]}`)) {
    body.name = currentZone.name.replace(`FL ${currentZone.floors[0]}`, `FL ${body.floors[0]}`)
  }

  if (body.inherit_geojson) {
    const parent = Warehouse.wireless_config.getZones().getById(body.parent_id || currentZone.getParent()?.id)
    const parentGeoJSON = Warehouse.wireless_config.getGeoJSON().getFeatures().filter(f => f.properties.id === parent.id).reduce((acc, f) => f)
    body.geojson = {
      type: parentGeoJSON.type,
      geometry: parentGeoJSON.geometry
    }
  }

  if (body.geojson !== undefined) {
    for (let child of currentZone.subZoneIter(child => child.inherit_geojson)) {
      await handler.update(child.id, {geojson: body.geojson})
    }
  }

  if (body.visibility_option !== undefined) {
    for (let child of currentZone.subZoneIter()) {
      await handler.update(child.id, {visibility_option: body.visibility_option})
    }
  }

  await handler.update(currentZone.id, body)

  const adjustedZone = handler.zones.filter(z => z.id === currentZone.id)[0]

  if (body.floors !== undefined && adjustedZone.hasFloors()) {
    await addFloorsToParents(handler, adjustedZone.getParent(), body.floors)
  }

  let building = null
  if (adjustedZone.buildingId != null && zoneHasBuildingChanges(adjustedZone, body)) {
    const buildingResponse = await Api.get(`schools/${schoolId}/wireless/buildings/${adjustedZone.buildingId}`)
    building = new Building(buildingResponse.data.building)
    Warehouse.wireless_config.getBuildings().add(building)
  }

  return {...handler.results(), building}
}

export async function deleteZone(schoolId, zoneId) {
  const handler = new ZoneHandler(schoolId)
  const zone = WareHouse.wireless_config.getZones().getById(zoneId)
  const rootZone = zone.getRootLevel()
  const floors = new Set(zone.floors)

  const floorLookup = {}
  if (rootZone && !zone.hasSubZones()) {
    for (let floor of zone.floors) {
      floorLookup[floor] = 0
    }

    for (let child of rootZone.subZoneIter(c => c.id !== zoneId)) {
      for (let floor of child.floors.filter(f => floors.has(f))) {
        floorLookup[floor] += 1
      }
    }
  }

  await handler.delete(zone)

  if (zone.hasSubZones()) {
    for (let child of zone.getSubZones()) {
      await handler.update(child.id, {parent_id: zone.parent?.id || schoolId, inherit_geojson: false})
    }
  }

  const invalidFloors = Object.entries(floorLookup)
    .filter((values) => values[1] === 0)
    .map((values) => values[0])

  if (invalidFloors.length) {
    await removeFloorsToParents(handler, zone.parent, invalidFloors)
  }

  return {...handler.results()}
}

export async function putAp(schoolId, currentAp, body) {
  const response = await Api.put(`schools/${schoolId}/wireless/aps/${currentAp.id}`, body)
  Warehouse.wireless_config.getAps().add(new AccessPoint(response.data.ap))
  Warehouse.wireless_config.getGeoJSON().add(response.data.geojson)
  return response.data
}

export async function deleteAp(schoolId, apId) {
  await Api.delete(`schools/${schoolId}/wireless/aps/${apId}`)
  Warehouse.wireless_config.getAps().remove(apId)
  Warehouse.wireless_config.getGeoJSON().remove(apId)
  return {ap: null, geojson: null, skipApIds: [apId]}
}

const buildUrls = (baseUrl, maxPages) => {
  const urls = []
  for (let page = 1; page <= maxPages; page++) {
    urls.push(`${baseUrl}?page=${page}`)
  }

  return urls
}

async function loadAll(values) {
  const results = []
  for (let chunk = 0; chunk <= values.length; chunk += NUMBER_OF_FETCHES) {
    const promises = []
    for (let i = chunk; i < chunk + NUMBER_OF_FETCHES && i <= values.length - 1; i++) {
      const value = values[i]
      promises.push(new Promise((resolve, reject) => {
        const isComplex = typeof value === "object"
        const url = isComplex ? value.url : value

        Api.get(url)
          .then(response => {
            if (isComplex) {
              const {key} = value
              resolve({key, data: response.data[key]})
            } else {
              resolve(response.data)
            }
          })
          .catch(reject)
      }))
    }

    if (!promises.length) {
      continue
    }

    await Promise.all(promises)
      .then(responses => {
        for (let data of responses) {
          results.push(data)
        }
      })
  }

  return results
}

async function processPromises(promises) {
  const results = {}
  for (let chunk = 0; chunk <= promises.length; chunk += NUMBER_OF_FETCHES) {
    const newPromises = []
    for (let i = chunk; i < chunk + NUMBER_OF_FETCHES; i++) {
      if (i >= promises.length) {
        break
      }

      const {type, promise} = promises[i]
      newPromises.push(new Promise((resolve, reject) => {
        promise.then(response => {
          resolve({type, data: response.data})
        })
          .catch(reject)
      }))
    }

    if (!newPromises.length) {
      continue
    }

    await Promise.all(newPromises)
      .then(responses => {
        for (let {type, data} of responses) {
          if (!results[type]) {
            results[type] = []
          }

          results[type].push(data)
        }
      })
  }

  return results
}

async function addFloorsToParents(handler, zone, floors) {
  if (!zone || !zone.type || [ZoneType.SCHOOL, ZoneType.CAMPUS].includes(zone.type)) {
    return
  }

  let current = zone

  while (current) {
    if (!current?.type || [ZoneType.SCHOOL, ZoneType.CAMPUS].includes(current.type)) {
      return
    }

    if (current.floors.filter(f => !floors.includes(f)).length) {
      await handler.update(current.id, {floors: Array.from(new Set([...floors, ...current.floors])).sort()})
    }

    current = current.parent
  }
}

async function removeFloorsToParents(handler, zone, floors) {
  if (!zone?.type || [ZoneType.SCHOOL, ZoneType.CAMPUS].includes(zone.type)) {
    return
  }

  let current = zone

  while (current) {
    if (!current?.type || [ZoneType.SCHOOL, ZoneType.CAMPUS].includes(current.type)) {
      return
    }

    if (current.floors.filter(f => floors.includes(f)).length) {
      await handler.update(current.id, {floors: current.floors.filter(f => !floors.includes(f)).sort()})
    }

    current = current.parent
  }
}

function zoneHasBuildingChanges(zone, body) {
  if (!zone || zone.type !== ZoneType.BUILDING) {
    return false
  }

  for (let key of ["name"]) {
    if (body[key] !== undefined) {
      return true
    }
  }

  return false
}
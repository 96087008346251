import React, {useEffect, useReducer, useState} from "react";
import {TabBody, TabHeader, Tabs} from "../../../../shared/components/tabs";
import Indicator from "../../../../shared/components/loader";
import Search from "../../../../shared/components/search";
import {AddTextButton, ConnectButton, ConnectingButton, DeleteButton, DisconnectButton, DisconnectingButton, OverflowButton, PendingButton} from "../../../../shared/components/buttons";
import {EntryInput, EntryModal} from "../../../../shared/components/modal";
import {POLL_INTERVAL} from "../../../../shared/api/api";
import {EmptyView} from "../../../../shared/components/views";

const CONNECTED_TAB = {id: "connected", label: "Connected Apps"}
const UNCONNECTED_TAB = {id: "unconnected", label: "Unconnected Apps"}

const TABS = [
  CONNECTED_TAB,
  UNCONNECTED_TAB,
]

const DEFAULT_DATA = {
  connectionModal: {
    show: false,
    integration: null
  },
  disconnectModal: {
    show: false,
    integration: null
  },
  selectedTabId: CONNECTED_TAB.id,
}

const SHOW_CONNECTION_MODAL = "SHOW_CONNECTION_MODAL"
const SHOW_DISCONNECTION_MODAL = "SHOW_DISCONNECTION_MODAL"

const RESET = "RESET"
const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"

const IntegrationReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_CONNECTION_MODAL:
      return {
        ...state,
        connectionModal: {
          show: action.payload != null,
          integration: action.payload
        }
      }

    case SHOW_DISCONNECTION_MODAL:
      return {
        ...state,
        disconnectModal: {
          show: action.payload != null,
          integration: action.payload
        }
      }

    case RESET:
      return DEFAULT_DATA

    case SET_ACTIVE_TAB:
      return {
        ...state,
        selectedTabId: action.payload
      }

    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
}

const areConnectionsProcessing = (integrations) => {
  const isUnConnectedProcessing = integrations && integrations.unconnected && integrations.unconnected.flatMap(u => u.integrations).filter(u => u.is_processing).length > 0
  const isConnectedProcessing = integrations && integrations.connected && integrations.connected.flatMap(u => u.integrations).filter(u => u.is_processing).length > 0
  return isUnConnectedProcessing || isConnectedProcessing
}

export function SchoolIntegrationPane({show, cancelPolling, integrations, onInflateIntegration, onConnect, onDisconnect, onPoll}) {
  const [state, dispatch] = useReducer(IntegrationReducer, DEFAULT_DATA)

  const showConnectionModal = integration => dispatch({type: SHOW_CONNECTION_MODAL, payload: integration})
  const hideConnectionModal = () => dispatch({type: SHOW_CONNECTION_MODAL, payload: null})

  const showDisconnectionModal = integration => dispatch({type: SHOW_DISCONNECTION_MODAL, payload: integration})
  const hideDisconnectionModal = () => dispatch({type: SHOW_DISCONNECTION_MODAL, payload: null})

  const resetData = () => dispatch({type: RESET})
  const setSelectedTab = tab => dispatch({type: SET_ACTIVE_TAB, payload: tab.id})

  const isProcessing = areConnectionsProcessing(integrations)

  useEffect(() => {
    resetData()

    let interval = null
    let controller = null

    if (show && !cancelPolling && isProcessing) {
      interval = setInterval(() => {
        controller = new AbortController()
        onPoll(controller.signal)
      }, POLL_INTERVAL)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      if (controller) {
        controller.abort()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, cancelPolling, isProcessing]);

  if (!show) return null

  const notifications = integrations.notifications
  const tabs = TABS.map(tab => ({
    ...tab,
    badgeCount: notifications[tab.id]
  }))

  return (
    <>
      <Tabs>
        <TabHeader selectedTab={state.selectedTabId} items={tabs} onSelectTab={setSelectedTab}/>
        <TabBody className="settings-integrations-tab-body">
          <ConnectedApp
            show={state.selectedTabId === CONNECTED_TAB.id}
            integrations={integrations.connected}
            onDisconnect={integration => {
              showDisconnectionModal(integration)
            }}
          />
          <UnConnectedApp
            show={state.selectedTabId === UNCONNECTED_TAB.id}
            integrations={integrations.unconnected}
            onConnect={(integration) => {
              if (integration.has_next_step) {
                showConnectionModal(integration)
                return
              }

              onConnect && onConnect(integration, {})
            }}
          />
        </TabBody>
      </Tabs>
      <Indicator show={state.loading}/>
      <ConnectionModal
        onInflate={onInflateIntegration}
        onConnect={(integration, data) => {
          onConnect && onConnect(integration, data, hideConnectionModal)
        }}
        onHide={hideConnectionModal}
        {...state.connectionModal}
      />
      <DisconnectionModal
        onDisconnect={integration => {
          onDisconnect && onDisconnect(integration, hideDisconnectionModal)
        }}
        onHide={hideDisconnectionModal}
        {...state.disconnectModal}
      />
    </>
  )
}

function ConnectedApp({show, integrations, onDisconnect}) {
  return (
    <IntegrationContent
      show={show}
      integrations={integrations}
      emptyText="No Connected Apps"
      actionLoader={(integration) => {
        if (integration.is_processing) {
          return <DisconnectingButton className="settings-integration-action-button-primary" disabled/>
        }

        return (
          <div className="settings-integration-action-connected-group">
            <DisconnectButton className="settings-integration-action-button-danger" onClick={() => onDisconnect && onDisconnect(integration)}/>
            <OverflowButton onClick={() => {
              console.log(`overflow clicked`)
            }}
            />
          </div>
        )
      }}
    />
  )
}

function UnConnectedApp({show, integrations, onConnect}) {
  return (
    <IntegrationContent
      show={show}
      integrations={integrations}
      emptyText="No Unconnected Apps"
      actionLoader={(integration) => {
        if (integration.is_processing) {
          return <ConnectingButton className="settings-integration-action-button-primary" disabled/>
        }

        if (integration.state === 2) {
          return <PendingButton className="settings-integration-action-button-primary" disabled/>
        }

        return <ConnectButton className="settings-integration-action-button-primary" onClick={() => onConnect && onConnect(integration)}/>
      }}
    />
  )
}

const DEFAULT_INTEGRATION_DATA = {
  isSearching: false,
  search: "",
  lowered: null
}

const SET_SEARCH = "SET_SEARCH"
const setSearchEvent = search => ({type: SET_SEARCH, payload: search})

const IntegrationContentReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        isSearching: action.payload != null,
        search: action.payload || "",
        lowered: action.payload != null ? action.payload.toLowerCase() : null,
      }

    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
}

function IntegrationContent({show, integrations, emptyText, actionLoader}) {
  const [state, dispatch] = useReducer(IntegrationContentReducer, DEFAULT_INTEGRATION_DATA)

  const setSearch = search => dispatch(setSearchEvent(search))

  useEffect(() => {
    setSearch(null)
  }, [show]);

  if (!show) return null

  const hasIntegrations = !!integrations && integrations.length > 0
  const searchHandler = integration => {
    if (state.lowered == null) return true
    return integration.name.toLowerCase().includes(state.lowered)
      || integration.description.toLowerCase().includes(state.lowered)
  }

  return (
    <>
      <Search search={state.search} onSearch={setSearch}/>
      {!hasIntegrations && <EmptyView>{emptyText}</EmptyView>}
      {hasIntegrations && integrations.map(group => {
        const groupIntegrations = group.integrations.filter(searchHandler)
        const hasIntegrations = groupIntegrations.length > 0

        return (
          <IntegrationGroup key={group.name} name={group.name}>
            {groupIntegrations.map(integration => (
              <Integration key={integration.name} integration={integration}>
                {actionLoader(integration)}
              </Integration>
            ))}
            {!hasIntegrations && <EmptyView>No apps found.</EmptyView>}
          </IntegrationGroup>
        )
      })}
    </>
  )
}

function IntegrationGroup({name, ...props}) {
  return (
    <div className="settings-integration-group">
      <span className="settings-integration-group-name">{name}</span>
      <div className="settings-integration-group-items">
        {props.children}
      </div>
    </div>
  )
}

function Integration({integration, ...props}) {
  return (
    <div className="settings-integration-group-item">
      <div className="settings-integration-group-item-info">
        <span className="settings-integration-group-item-name">{integration.name}</span>
        <span className="settings-integration-group-item-description">{integration.description}</span>
      </div>
      <div className="settings-integration-group-item-actions">
        {props.children}
      </div>
    </div>
  )
}

function ConnectionModal({show, integration, onInflate, onConnect, onHide}) {
  const [data, setData] = useState({})
  const [values, setValues] = useState({})
  const [error, setError] = useState(null)
  const hasError = !!error

  useEffect(() => {
    setData({})
    setValues({})
  }, [show]);

  const integrationId = !!integration ? integration.id : null

  useEffect(() => {
    if (!integrationId || onInflate == null) {
      return
    }

    onInflate(integrationId, (data, error) => {
      if (data != null) {
        setData(data)
      } else if (error != null) {
        setError(error)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationId]);

  const isButtonDisabled = () => {
    const hasWrites = !!data.write && Object.keys(data.write).length > 0
    const hasValues = Object.keys(values).length > 0

    if (hasError || !hasWrites || !hasValues) return true

    const entries = Object.entries(data.write)
    const objects = entries.map(([key, entry]) => {
      if (values[key] == null) {
        return false
      }

      if (entry.type === "string") {
        return values[key].length > 0
      }

      if (entry.type === "array") {
        const arrayValues = values[key]
        if (!arrayValues.length) {
          return false
        }

        return arrayValues.filter(v => v != null && v.length > 0)
          .length > 0
      }

      throw new Error(`Unsupported type ${entry.type}`)
    })

    return objects.filter(v => v).length !== entries.length
  }

  const handleOnClick = () => {
    if (onConnect == null) {
      return
    }

    const cleanValues = {}
    for (let [key, value] of Object.entries(values)) {
      if (Array.isArray(value)) {
        cleanValues[key] = value.filter(v => v !== null && v.length)
      } else {
        cleanValues[key] = value
      }
    }

    onConnect(integration, cleanValues)
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide,
    },
    primary: {
      title: "Connect",
      disabled: isButtonDisabled(),
      onClick: handleOnClick
    }
  }

  return (
    <EntryModal cardClassName="settings-integration-connect-modal-card" show={show} title="Connect App" onHide={onHide} buttons={buttons}>
      {hasError && <EmptyView>{error}</EmptyView>}
      {!hasError && <ConnectionBody data={data} values={values} onChange={(key, value) => {
        setValues({
          ...values,
          [key]: value
        })
      }}/>}
    </EntryModal>
  )
}

function ConnectionBody({data, values, onChange}) {
  return (
    <div className="settings-integration-connect-modal-entry-container">
      <div>
        {Object.entries(data.read || {}).map(([key, entry], i) =>
          <EntryInput key={`connection-entry-${i}`} label={entry.label} value={entry.value} editable={false} copyable={true}/>
        )}
      </div>
      <div>
        {Object.entries(data.write || {}).map(([key, entry], i) => {
            if (entry.type === "string") {
              return (
                <EntryInput key={`connection-entry-${i}`} autoFocus={i === 0} label={entry.label} value={values[key] || ""} onChange={(value) => {
                  onChange(key, value)
                }}/>
              )
            }

            if (entry.type === "array") {
              return <ArrayElement key={`connection-entry-${i}`} entry={entry} values={values[key] || []} onChange={(value) => {
                onChange(key, value)
              }}/>
            }

            throw new Error(`Unsupported entry type: ${entry.type}`)
          }
        )}
      </div>
    </div>
  )
}

function ArrayElement({entry, values: originalValues, onChange}) {
  const values = [...originalValues]

  if (!values.length) {
    values.push("")
  }

  return (
    <div className="settings-integration-connect-modal-entry-array-container da-modal-entry-input">
      <span>{entry.label}</span>

      {values.map((value, i) =>
        <div key={`setting-array-option-${i}`}>
          <input value={value} onChange={e => {
            const newValue = e.target.value
            onChange(values.map((v, j) => i === j ? newValue : v))
          }}/>
          <DeleteButton onClick={() => {
            if (values.length <= 1) {
              return
            }

            onChange(values.filter((v, j) => i !== j))
          }}/>
        </div>
      )}
      <AddTextButton onClick={() => onChange([...values, ""])}>{`Add ${entry.item_name}`}</AddTextButton>
    </div>
  )
}

function DisconnectionModal({show, integration, onDisconnect, onHide}) {
  const [current, setCurrent] = useState("")

  useEffect(() => {
    setCurrent("")
  }, [show]);

  if (!show || integration == null) return null

  const handleOnClick = () => onDisconnect && onDisconnect(integration)

  const buttons = {
    secondary: {
      title: "No, keep this app",
      onClick: onHide,
    },
    danger: {
      title: "Yes, disconnect this app",
      disabled: current !== integration.name,
      onClick: handleOnClick
    }
  }

  return (
    <EntryModal cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body" show={show} title="Connect App" onHide={onHide} buttons={buttons}>
      <p>{`This action can not be undone. The disconnection of ${integration.name} is permanent and will effect all users of the app`}</p>
      <div>
        <p>Please type <span>{integration.name}</span> to confirm</p>
        <input autoFocus value={current} onChange={(e) => setCurrent(e.target.value)}/>
      </div>
    </EntryModal>
  )
}
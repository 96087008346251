import React from "react";
import Search from "../../../../shared/components/search";
import {AddButton} from "../../../../shared/components/buttons";

export default function SettingsSearch({buttonTitle, search, onSearch, onAdd}) {
  const hasButton = !!buttonTitle

  return (
    <div className="settings-search">
      <Search search={search} onSearch={onSearch} />
      {hasButton && <AddButton onClick={onAdd}>{buttonTitle}</AddButton>}
    </div>
  )
}
import React, {useEffect} from "react";
import {TableView} from "../../shared/components/table";
import {AddButton} from "../../shared/components/buttons";

import {ReactComponent as FacilityIcon} from "../../assets/icons/alerts/facility_icon.svg";
import {ReactComponent as GeneralIcon} from "../../assets/icons/alerts/general_icon.svg";
import {ReactComponent as StudentIcon} from "../../assets/icons/alerts/student_icon.svg";
import {ReactComponent as PlayIcon} from "../../shared/assets/icons/play_icon.svg";
import {ReactComponent as PauseIcon} from "../../assets/icons/alerts/pause_icon.svg";
import {ReactComponent as DeleteIcon} from "../../shared/assets/icons/button/delete_icon.svg"
import Indicator from "../../shared/components/loader";
import Search from "../../shared/components/search";
import {ColoredSelect, SegmentSelect} from "../../shared/components/select";
import {ErrorToast, SuccessToast} from "../../shared/utils/toast";
import {useLocation, useNavigate} from "react-router";
import {DAILY_FREQUENCY, formatWeekDays, hasRequiredFields, PRIORITY_OPTIONS, secondsToTime, TYPE_OPTIONS, WEEKLY_FREQUENCY} from "./shared";
import {formatDate} from "../../shared/utils/format";
import {HEADER_FILTER_IDS, useAlertContext} from "../../hooks/alert_context";


export default function AlertsView() {
  const {loading, loadDataIfNeeded, data, actions} = useAlertContext()

  const navigate = useNavigate()
  const location = useLocation()

  const navigateToDetail = (alertId) => {
    navigate(`/alert/${alertId}`)
  }

  const navigateToNew = () => navigateToDetail("new")

  useEffect(() => {
    if (location.state != null) {
      navigate(location.pathname, {replace: true})
    }
  }, [location.pathname, location.state, navigate]);

  useEffect(() => {
    loadDataIfNeeded()
  }, [loadDataIfNeeded]);

  return (
    <div className="da-card alerts">
      <Header
        currentFilter={data.selectedFilter}
        meta={data.meta}
        search={data.search}
        onFilterClick={actions.setFilter}
        onSearch={actions.setSearch}
        onNewAlertClick={navigateToNew}
      />

      <TableView
        classNames={{
          container: "alerts-tableview",
          header: "alerts-tableview-header",
          scrollable: "alerts-tableview-content-scrollable",
          row: (rowData) => {
            const classNames = ["alerts-tableview-row"]
            if (rowData.paused) {
              classNames.push("alerts-tableview-row-paused");
            }

            return classNames.join(" ")
          }
        }}
        columns={[
          {label: "ALERT", key: "name"},
          {label: "TYPE", key: "type"},
          {label: "PRIORITY", key: "priority"},
          {label: "SCHEDULE", key: "schedule"},
        ]}
        noDataMessage={loading ? "Loading..." : "No Alerts"}
        data={data.alerts}
        hasOverflow={true}
        viewInflator={(rowData, column) => {
          if (column === "hidden") {
            return null
          }
          if (column === "name") {
            return formatName(rowData.id, rowData.name, rowData.type, rowData.paused)
          }

          if (column === "type") {
            return formatType(rowData.id, rowData.type, rowData.paused, (type) => {
              if (!hasRequiredFields(type.required_fields, rowData.columns)) {
                ErrorToast("Not all required fields are present.  Please update the query and try again")
                return
              }

              actions.update(rowData, {
                body: {type: type.value},
                successCallback: () => SuccessToast(`Type updated for ${rowData.name}`),
                errorCallback: () => ErrorToast(`Unable to update Type for ${rowData.name}`),
              })
            })
          }

          if (column === "priority") {
            return formatPriority(rowData.id, rowData.priority, rowData.paused, (priority) => {
              actions.update(rowData, {
                body: {priority: priority.value},
                successCallback: () => SuccessToast(`Priority updated for ${rowData.name}`),
                errorCallback: () => ErrorToast(`Unable to update Priority for ${rowData.name}`),
              })
            })
          }

          if (column === "schedule") {
            return formatSchedule(rowData.id, rowData.schedule, rowData.paused)
          }

          if (column === "overflow") {
            return {
              options: [
                [
                  {id: "run_alert", label: "Run Alert", icon: <PlayIcon/>},
                ],
                [
                  {id: "pause_alert", label: rowData.paused ? "Unpause Alert" : "Pause Alert", icon: <PauseIcon/>},
                  {id: "delete_alert", label: "Delete Alert", icon: <DeleteIcon/>, variant: "danger"},
                ],
                [
                  {id: "edit_query", label: "Edit Query"},
                ]
              ],
              onItemClick: (id) => {
                switch (id) {
                  case "run_alert":
                    actions.run(rowData.id, {
                      successCallback: () => SuccessToast(`${rowData.name} has begun processing`),
                      errorCallback: () => ErrorToast(`Unable to run ${rowData.name}`),
                    })
                    break

                  case "pause_alert": {
                    const newState = !rowData.paused
                    actions.update(rowData, {
                      body: {paused: newState},
                      successCallback: () => SuccessToast(newState ? `${rowData.name} paused` : `${rowData.name} unpaused`),
                      errorCallback: () => ErrorToast(`Unable to update ${newState ? "pause" : "unpause"} ${rowData.name}`),
                    })
                    break
                  }

                  case "delete_alert":
                    actions.delete(rowData.id, {
                      errorCallback: () => ErrorToast(`Unable to delete ${rowData.name}`),
                    })
                    break

                  case "edit_query":
                    navigateToDetail(rowData.id)
                    break

                  default:
                    throw new Error(`Unhandled alert row onItemClick type: ${id}`)
                }
              }
            }
          }
        }}
      />
      <Indicator show={loading}/>
    </div>
  )
}

const Header = ({currentFilter, meta, search, onFilterClick, onSearch, onNewAlertClick}) => {
  const segmentOptions = Object.values(HEADER_FILTER_IDS).map(f => ({
    id: f,
    label: f,
    count: meta[f.toLowerCase()]
  }))

  return (
    <div className="alerts-header">
      <SegmentSelect selectedId={currentFilter} options={segmentOptions} onChange={onFilterClick}/>
      <Search search={search} onSearch={onSearch}/>
      <AddButton onClick={onNewAlertClick}>New Alert</AddButton>
    </div>
  )
}

const Paused = ({show}) => {
  if (!show) {
    return null
  }

  return (
    <div className="alerts-tableview-row-paused-icon">
      <PauseIcon/>
      <span>Paused</span>
    </div>
  )
}

const formatName = (id, name, type, isPaused) => {
  let icon

  if (type === "Student") {
    icon = <StudentIcon/>
  } else if (type === "Facility") {
    icon = <FacilityIcon/>
  } else {
    icon = <GeneralIcon/>
  }

  const classNames = [
    "alerts-tableview-row-name",
    isPaused ? "alerts-tableview-row-text-paused" : "alerts-tableview-row-text"
  ]

  return (
    <div key={`name-${id}`} className={classNames.join(" ")}>
      {icon}
      <span>{name}</span>
      <Paused show={isPaused}/>
    </div>
  )
}

const formatType = (id, type, isPaused, onChange) => {
  const value = TYPE_OPTIONS.filter(t => t.value === type)[0]
  const options = TYPE_OPTIONS.filter(t => t.value !== type)

  return (
    <div key={`type-${id}`} className="alerts-tableview-row-priority">
      <ColoredSelect
        key={`type-${id}`}
        additionalClassName={isPaused ? "alerts-tableview-row-text-paused" : "alerts-tableview-row-text"}
        value={value}
        options={options}
        onChange={onChange}
      />
    </div>
  )
}

const formatPriority = (id, priority, isPaused, onChange) => {
  const value = PRIORITY_OPTIONS.filter(p => p.value === priority)[0]
  const options = PRIORITY_OPTIONS.filter(p => p.value !== priority)

  return (
    <div key={`priority-${id}`} className="alerts-tableview-row-priority">
      <ColoredSelect
        key={`type-${id}`}
        additionalClassName={isPaused ? "alerts-tableview-row-text-paused" : "alerts-tableview-row-text"}
        value={value}
        options={options}
        onChange={onChange}
      />
    </div>
  )
}

const formatSchedule = (id, schedule, isPaused) => {
  const classNames = [
    "alerts-tableview-row-schedule",
    isPaused ? "alerts-tableview-row-text-paused" : "alerts-tableview-row-text"
  ]

  const formatTime = () => {
    if (schedule == null) {
      return null
    }

    const convertTime = () => {
      const {time, meridiem} = secondsToTime(schedule.run_ts)
      return `${time} ${meridiem === 1 ? "PM" : "AM"}`
    }

    const time = convertTime()

    if (schedule.frequency === DAILY_FREQUENCY.value) {
      return `Daily at ${time}`
    } else if (schedule.frequency === WEEKLY_FREQUENCY.value) {
      const days = formatWeekDays(schedule.days)
      return `Weekly on ${days} at ${time}`
    } else {
      throw new Error(`Unknown schedule frequency: ${schedule.frequency}`)
    }
  }

  const formatNext = () => {
    if (schedule == null || schedule.next == null) {
      return null
    }

    return `Next: ${formatDate(schedule.next)}`
  }

  return (
    <div key={`schedule-${id}`} className={classNames.join(" ")}>
      <span className="alerts-tableview-row-schedule-time">{formatTime()}</span>
      <span className="alerts-tableview-row-schedule-next">{formatNext()}</span>
    </div>
  )
}

import {DataShed} from "./base";
import {AttributeSort} from "../../utils/sort";

export class UsageDataShed extends DataShed {
  constructor(onDataChange) {
    super(onDataChange)

    this._idLookup = {}
    this._allUsages = []
  }

  getById(id) {
    if (!id) {
      return null
    }

    return this._idLookup[id]
  }

  getAllUsages() {
    return this._allUsages
  }

  _handleChange() {
    this._idLookup = {}
    this._allUsages = []

    for (let primaryUsage of this._values) {
      this._idLookup[primaryUsage.id] = primaryUsage
      this._allUsages.push(primaryUsage)

      for (let secondaryUsage of primaryUsage.secondary_usages) {
        this._idLookup[secondaryUsage.id] = secondaryUsage
        this._allUsages.push(secondaryUsage)
      }
    }
  }
}
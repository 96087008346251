import React, {useEffect, useRef, useState} from "react";
import {Group, GroupItem} from "./shared";
import {formatCurrency, formatPercentage} from "../../../utils/format";
import {ErrorToast} from "../../../utils/toast";
import {DEBOUNCE_DURATION} from "../../../utils/const";
import {AddButton} from "../../../components/buttons";

export function AdminTab({show, canManageSchool, data, onItemChange}) {
  const [isFacilityDollarsFocused, setIsFacilityDollarsFocused] = useState(false);
  const [facilityDollarsType, setFacilityDollarsType] = useState("text");

  const [isTargetPercentageFocused, setIsTargetPercentageFocused] = useState(false);
  const [targetPercentageType, setTargetPercentageType] = useState("text");

  if (!show || !canManageSchool) return null

  const focusFacilityDollars = () => {
    setIsFacilityDollarsFocused(true)
    setFacilityDollarsType("number")
  }
  const blurFacilityDollars = () => {
    setIsFacilityDollarsFocused(false)
    setFacilityDollarsType("text")
  }

  const focusTargetPercentage = () => {
    setIsTargetPercentageFocused(true)
    setTargetPercentageType("number")
  }
  const blurTargetPercentage = () => {
    setIsTargetPercentageFocused(false)
    setTargetPercentageType("text")
  }

  const getFacilityDollars = () => {
    let dollarsPerStudent = null
    if (data && data.facility && data.facility.dollars_per_student != null) {
      dollarsPerStudent = data.facility.dollars_per_student
    }

    return isFacilityDollarsFocused || dollarsPerStudent == null ? dollarsPerStudent : formatCurrency(dollarsPerStudent)
  }

  const getTargetPercentage = () => {
    let targetPercentage = null
    if (data && data.dashboard && data.dashboard.target_percentage != null) {
      targetPercentage = data.dashboard.target_percentage
    }

    return isTargetPercentageFocused || targetPercentage == null ? targetPercentage : formatPercentage(targetPercentage)
  }

  const handleOnChange = (data) => {
    if (onItemChange == null) {
      return
    }

    onItemChange(data)
  }

  const handleRatioChanged = ({users, sqft}) => {
    return handleOnChange({
      zone_ratio_users: users,
      zone_ratio_sqft: sqft
    })
  }

  const handleOnFacilityDollarsChanged = (value) => {
    value = Number.parseInt(value)
    if (Number.isNaN(value)) {
      ErrorToast("Invalid value")
      return
    }

    return handleOnChange({dollars_per_student: value})
  }

  const handleTargetPercentageChanged = (value) => {
    value = Number.parseInt(value)
    if (Number.isNaN(value)) {
      ErrorToast("Invalid value")
      return
    }

    return handleOnChange({target_percentage: value})
  }

  return (
    <div className="school-admin-tab">
      <Group divClassName="school-admin-layout" name="Facility Settings">
        <RatioInput
          data={data.facility.zone_ratio}
          onChange={handleRatioChanged}
        />
        <GroupItem
          type={facilityDollarsType}
          name="FACILITY DOLLARS PER STUDENT"
          value={getFacilityDollars()}
          editable={canManageSchool}
          onChange={handleOnFacilityDollarsChanged}
          onFocus={focusFacilityDollars}
          onBlur={blurFacilityDollars}
        />
      </Group>
      <Group divClassName="school-general-layout" name="Dashboard Settings">
        <GroupItem
          type={targetPercentageType}
          name="TARGET PERCENTAGE"
          value={getTargetPercentage()}
          editable={canManageSchool}
          onChange={handleTargetPercentageChanged}
          onFocus={focusTargetPercentage}
          onBlur={blurTargetPercentage}
        />
      </Group>
    </div>
  )
}

const getRadioUserLabel = (users) => users === 1 ? "user" : "users"
const getRatioText = ({users, sqft}) => `${users} ${getRadioUserLabel(users)} per ${sqft} sqft`

function RatioInput({data, onChange}) {
  const [content, setContent] = useState(() => ({...data}))
  const [isFocused, setIsFocused] = useState(false);

  const contentUsers = content && content.users != null ? content.users : null
  const contentSqft = content && content.sqft != null ? content.sqft : null
  const dataUsers = data && data.users != null ? data.users : null
  const dataSqft = data && data.sqft != null ? data.sqft : null

  useEffect(() => {
    setContent(() => ({
      users: dataUsers,
      sqft: dataSqft
    }))
  }, [dataUsers, dataSqft]);

  useEffect(() => {
    if (onChange == null) {
      return
    }

    const timer = setTimeout(() => {
      if (contentUsers == null || contentSqft == null) {
        return
      }

      const newData = {}
      if (contentUsers !== dataUsers) {
        newData.users = contentUsers
      }

      if (contentSqft !== dataSqft) {
        newData.sqft = contentSqft
      }

      if (Object.keys(newData).length) {
        onChange(newData)
      }
    }, DEBOUNCE_DURATION)
    return () => clearTimeout(timer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentUsers, contentSqft, dataUsers, dataSqft]);

  const handleOnClick = () => {
    setIsFocused(true)
  }

  const handleOnFocus = () => {
    setIsFocused(true)
  }

  const handleOnBlur = () => {
    setIsFocused(false)
  }

  const hasValue = contentUsers != null && content.sqft != null
  const showButton = !hasValue && !isFocused
  const showEditState = isFocused
  const showContentState = hasValue && !isFocused

  return (
    <div className="da-modal-entry-input">
      <span className="da-modal-entry-input-label">PEOPLE PER SQFT ZONE RATIO</span>
      <div className="da-modal-entry-input-content">
        {showButton && <AddButton className="school-add-button" variant="secondary" onClick={handleOnClick} onFocus={handleOnClick}/>}
        {showEditState && <RatioEditInput data={content} onChange={setContent} onFocus={handleOnFocus} onBlur={handleOnBlur}/>}
        {showContentState && <input value={getRatioText(data)} readOnly={true} onFocus={handleOnFocus}/>}
      </div>
    </div>
  )
}

function RatioEditInput({data, onChange, onFocus, onBlur}) {
  const userFocusRef = useRef(false)
  const sqftFocusRef = useRef(false)
  const focusRef = useRef(false)
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    if (timer) {
      clearTimeout(timer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnUserChange = e => {
    if (!onChange) {
      return
    }

    const value = Number.parseInt(e.target.value)
    if (Number.isNaN(value) || value === data.users) {
      return
    }

    if (value !== data.users) {
      onChange({
        users: value,
        sqft: data.sqft,
      })
    }
  }

  const handleOnSqftChange = e => {
    if (!onChange) {
      return
    }

    let value = e.target.value
    if (value === "") {
      onChange({
        users: data.users,
        sqft: null
      })
    }

    value = Number.parseInt(value)
    if (Number.isNaN(value)) {
      return
    }

    if (value !== data.sqft) {
      onChange({
        users: data.users,
        sqft: value
      })
    }
  }

  const handleOnUserFocus = () => {
    userFocusRef.current = true
    handleFocusChange()
  }

  const handleOnUserBlur = () => {
    userFocusRef.current = false
    handleFocusChange()
  }

  const handleOnSqftFocus = () => {
    sqftFocusRef.current = true
    handleFocusChange()
  }

  const handleOnSqftBlur = () => {
    sqftFocusRef.current = false
    handleFocusChange()
  }

  const handleFocusChange = () => {
    if (timer) {
      clearTimeout(timer)
    }

    setTimer(setTimeout(() => {
      if (!focusRef.current && (userFocusRef.current || sqftFocusRef.current)) {
        focusRef.current = true
        onFocus && onFocus()
      } else if (focusRef.current && !userFocusRef.current && !sqftFocusRef.current) {
        focusRef.current = false
        onBlur && onBlur()
      }
    }, 50))
  }

  return (
    <div className="school-admin-ratio-input">
      <input type="number" autoFocus={true} value={data.users || ""} onChange={handleOnUserChange} onFocus={handleOnUserFocus} onBlur={handleOnUserBlur}/>
      <span>{`${getRadioUserLabel(data.users)} per`}</span>
      <input type="number" value={data.sqft || ""} onChange={handleOnSqftChange} onFocus={handleOnSqftFocus} onBlur={handleOnSqftBlur}/>
      <span>sqft</span>
    </div>
  )
}

import Card from "./card";
import {Cycle} from "../../pages/wireless_config/cycle";
import {ReactComponent as PreviousIcon} from "../../assets/icons/school/prev_icon.svg";
import {ReactComponent as NextIcon} from "../../shared/assets/icons/next_icon.svg";
import {IconButton} from "./buttons";

export function SplitLayout({hasDetailResource, isHidingLeftPane, toggleLeftPane, isHidingRightPane, toggleRightPane, cycleDatasource, ...props}) {
  return (
    <div className="split-layout">
      <LeftPane isHidingLeftPane={isHidingLeftPane} toggleLeftPane={toggleLeftPane}>
        {props.children[0]}
      </LeftPane>
      <div className="split-layout-center">
        {props.children[1]}
      </div>
      <RightPane
        hasDetailResource={hasDetailResource}
        isHidingRightPane={isHidingRightPane}
        toggleRightPane={toggleRightPane}
        cycleDatasource={cycleDatasource}
      >
        {props.children[2]}
      </RightPane>
    </div>
  )
}

function LeftPane({isHidingLeftPane, toggleLeftPane, ...props}) {
  const icon = isHidingLeftPane ? <NextIcon/> : <PreviousIcon/>
  return (
    <div className="split-layout-pane left" {...props}>
      <Card aria-hidden={isHidingLeftPane}>
        {props.children}
      </Card>

      <ButtonLayout onClick={toggleLeftPane}>
        {icon}
      </ButtonLayout>
    </div>
  )
}

function RightPane({hasDetailResource, isHidingRightPane, toggleRightPane, cycleDatasource, ...props}) {
  const icon = isHidingRightPane ? <PreviousIcon/> : <NextIcon/>

  return (
    <div className={`split-layout-pane right ${!cycleDatasource ? "two-column" : "three-column"}`} {...props}>
      <Card aria-hidden={isHidingRightPane}>
        {cycleDatasource != null && <div className="wireless-config-overlay-right-pane-ap-header">
          <Cycle datasource={cycleDatasource} />
        </div>}

        {props.children}
      </Card>

      <ButtonLayout show={hasDetailResource} onClick={toggleRightPane}>
        {icon}
      </ButtonLayout>
    </div>
  )
}

function ButtonLayout({show = true, onClick, ...props}) {
  if (!show) {
    return null
  }

  return (
    <div className="split-layout-pane-button">
      <IconButton variant="secondary" onClick={onClick}>
        {props.children}
      </IconButton>
    </div>
  )
}
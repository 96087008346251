import {IconButton} from "../../shared/components/buttons";

export function Cycle({datasource}) {
  return (
    <div className="cycle">
      <div className="school-carousel">
        {datasource.icons.map(icon => (
          <IconButton key={icon.id} disabled={icon.disabled} onClick={icon.onClick}>{icon.icon}</IconButton>
        ))}
      </div>
    </div>
  )
}
import {CollapsableListView} from "../../../shared/components/collapsable"
import Search from "../../../shared/components/search"
import {useDataContext} from "../hooks/context"
import {formatVisibility, getNextZoneType, TabIds, VisibilityOptions, ZoneType} from "../consts"
import {AddButton, FilterButton, IconButton, TextButton} from "../../../shared/components/buttons"
import {useCallback, useEffect, useState} from "react"
import {LoadingStatus} from "../../../shared/api/loading"
import {EmptyView} from "../../../shared/components/views"
import {Zone} from "../../../data/models/zone"
import {AccessPoint} from "../../../data/models/ap"
import {ReactComponent as BuildingIcon} from "../../../shared/assets/icons/map/building_icon.svg"
import {ReactComponent as CampusIcon} from "../../../shared/assets/icons/map/campus_icon.svg"
import {ReactComponent as DefaultIcon} from "../../../shared/assets/icons/map/default_icon.svg"
import {ReactComponent as FloorIcon} from "../../../shared/assets/icons/map/floor_icon.svg"
import {ReactComponent as EyeIcon} from "../../../shared/assets/icons/map/eye_icon.svg"
import {ReactComponent as EyeShutIcon} from "../../../shared/assets/icons/map/eye_shut_icon.svg"
import {EntryModal, EntrySelector} from "../../../shared/components/modal";
import WareHouse from "../../../data/warehouse";
import Warehouse from "../../../data/warehouse";
import {AttributeSort} from "../../../utils/sort";

export function ZoneListView() {
  const {selectedTab} = useDataContext()

  if (selectedTab !== TabIds.LAYOUT) {
    return null
  }

  return (
    <>
      <Header/>
      <ZoneListViewContainer/>
    </>
  )
}

function Header() {
  const {schoolZone, selectedResource, setNewZone, search, isSearchingProcessing, onSearch, filters, setFilters, addListViewOpenResources, clearListViewOpenResources} = useDataContext()
  const [showFilterModal, setShowFilterModal] = useState(false)

  const handleOnAdd = useCallback((type) => {
    setNewZone({parent: schoolZone, type, visibility_option: 1})
  }, [schoolZone, setNewZone])

  const handleOnAddCampus = () => handleOnAdd(ZoneType.CAMPUS)
  const handleOnAddBuilding = () => handleOnAdd(ZoneType.BUILDING)

  const handleOpen = () => {
    addListViewOpenResources && addListViewOpenResources()

    const handle = () => {
      const element = document.getElementById(selectedResource.id)
      const rect = element?.getBoundingClientRect()
      if (!rect) {
        return
      }

      if (rect.top >= window.innerHeight || rect.bottom < 0) {
        element.scrollIntoView({behavior: "smooth"})
      }
    }

    setTimeout(handle, 100)
  }

  const showModal = () => setShowFilterModal(true)
  const hideModal = () => setShowFilterModal(false)

  const campusZones = Warehouse.wireless_config.getZones().getCampusZones()
  const buildingZones = Warehouse.wireless_config.getZones().getBuildingZones()

  const showCampusButton = (!campusZones.length && !buildingZones.length) || campusZones.length > 0
  const showBuildingButton = !campusZones.length

  return (
    <div className="wireless-config-overlay-left-pane-ap-content-header">
      <div className="wireless-config-overlay-left-pane-ap-content-header-buttons">
        <div>
          <IconButton variant="secondary" disabled={selectedResource == null} onClick={handleOpen}><EyeIcon/></IconButton>
          <IconButton variant="secondary" onClick={clearListViewOpenResources}><EyeShutIcon/></IconButton>
        </div>
        <div className="row-flex gap-200">
          {showCampusButton && <AddButton variant="primary" onClick={handleOnAddCampus}>New Campus</AddButton>}
          {showBuildingButton && <AddButton variant="primary" onClick={handleOnAddBuilding}>New Building</AddButton>}
        </div>
      </div>
      <div className="wireless-config-overlay-left-pane-ap-content-options">
        <Search search={search} onSearch={onSearch} isSearching={isSearchingProcessing}/>
        <FilterButton variant={filters.hasFilters ? "primary" : "secondary"} onClick={showModal}/>
      </div>

      <FilterModal
        show={showFilterModal}
        onHide={hideModal}
        onApply={(filters) => {
          setFilters(filters)
          hideModal()
        }}
      />
    </div>
  )
}

function StatusSelector({index, counts, onClick}) {
  const getValue = count => {
    return count || 0
  }

  const handleOnClick = newIndex => {
    return () => {
      if (!onClick || newIndex === index) {
        return
      }

      onClick(newIndex)
    }
  }

  return (
    <div className="wireless-config-status-selector">
      <span aria-selected={index === 0} onClick={handleOnClick(0)}>{`All(${getValue(counts?.all)})`}</span>
      <span aria-selected={index === 1} onClick={handleOnClick(1)}>{`Complete(${getValue(counts?.complete)})`}</span>
      <span aria-selected={index === 2} onClick={handleOnClick(2)}>{`Incomplete(${getValue(counts?.incomplete)})`}</span>
    </div>
  )
}

function FilterModal({show, onHide, onApply}) {
  const {filters, clearFilters} = useDataContext()

  const [state, setState] = useState(() => ({...filters.data}))
  const [hasChanges, setHasChanges] = useState(false)

  useEffect(() => {
    setState(() => ({...filters.data}))
    setHasChanges(() => false)
  }, [show, filters.data])

  const handleReset = () => {
    setState(() => ({...filters.data}))
    setHasChanges(() => false)
  }

  const setFilter = data => setState(old => {
    const newState = {...old, ...data}
    const changed = true

    if (changed !== hasChanges) {
      setHasChanges(() => changed)
    }

    return newState
  })

  if (!show) {
    return
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Apply Filters",
      disabled: !hasChanges,
      onClick: () => onApply && onApply(state)
    }
  }

  const formatOption = resource => {
    if (!resource) {
      return null
    }

    return {
      label: resource.name,
      value: resource.id
    }
  }

  const formatUsage = usage => {
    if (!usage) {
      return null
    }

    return {
      label: usage.display_name,
      value: usage.id
    }
  }

  const buildingValue = formatOption(state.buildingId ? WareHouse.wireless_config.getBuildings().getById(state.buildingId) : null)
  const buildingOptions = Warehouse.wireless_config.getBuildings().filter(b => b.id !== state.buildingId)
    .sort(AttributeSort("name"))
    .map(formatOption)

  const usageValues = state.usageIds.map(id => Warehouse.wireless_config.getUsages().getById(id))
    .sort(AttributeSort("name"))
    .map(formatUsage)
  const usageOptions = Warehouse.wireless_config.getUsages().getAllUsages().filter(u => !state.usageIds.includes(u.id))
    .sort(AttributeSort("name"))
    .map(formatUsage)

  let visibilityValue = null
  let visibilityOptions = VisibilityOptions.slice()
  if (state.visibility) {
    visibilityValue = formatVisibility(VisibilityOptions.find(o => o.id === state.visibility))
    visibilityOptions = visibilityOptions.filter(o => o.id !== visibilityValue.value)
  }

  visibilityOptions = visibilityOptions.map(formatVisibility)

  return (
    <EntryModal
      show={show}
      cardClassName="wireless-config-overlay-filter-modal"
      bodyClassName="wireless-config-overlay-filter-modal-body"
      title="Filter By:"
      buttons={buttons}
      onHide={onHide}
      additionalHeaderOptions={(
        <>
          <TextButton disabled={!filters.hasFilters} onClick={clearFilters}>Clear Filters</TextButton>
          <TextButton disabled={!hasChanges} onClick={handleReset}>Reset</TextButton>
        </>
      )}>
      <StatusSelector
        index={state.status}
        counts={Warehouse.wireless_config.getResourceCounts()}
        onClick={index => setFilter({status: index})}
      />
      <EntrySelector
        isClearable={true}
        label="Building Name"
        value={buildingValue}
        options={buildingOptions}
        onChange={newBuilding => setFilter({buildingId: newBuilding?.value})}
      />
      <EntrySelector
        isClearable={true}
        label="Usages"
        value={usageValues}
        options={usageOptions}
        isMulti={true}
        onChange={newUsage => setFilter({usageIds: newUsage.map(u => u.value)})}
      />
      <EntrySelector
        isClearable={true}
        label="Visibility"
        value={visibilityValue}
        options={visibilityOptions}
        onChange={newVisibility => setFilter({visibility: newVisibility?.value})}
      />
    </EntryModal>
  )
}

function ZoneListViewContainer() {
  const {loadingState, selectedResource, zones, aps, strandedAps, isSearching, setSelectedResource, setNewZone, listViewOpen, toggleListViewOpenResource} = useDataContext()

  const isLoading = [LoadingStatus.NONE, LoadingStatus.FETCHING].includes(loadingState)
  const hasData = zones?.length > 0 || aps?.length > 0 || strandedAps?.length > 0

  if (!isLoading && !hasData) {
    if (isSearching) {
      return (
        <EmptyView>No Resources match your search</EmptyView>
      )
    }

    return
    // return (
    //   <MultiEmptyView>
    //     <span>Create your first Ap (Disabled for now)</span>
    //     <AddButton onClick={handleOnAdd}>Add Access Point</AddButton>
    //   </MultiEmptyView>
    // )
  }

  const buildResourceData = resource => ({
    resource,
    hasIcon: () => {
      return resource instanceof Zone || resource instanceof AccessPoint
    },
    isSelected: () => {
      return resource.id === selectedResource?.id
    },
    isSelectable: () => {
      if (resource instanceof Zone) {
        return resource.isSelectable()
      }

      return resource instanceof AccessPoint
    },
    isOpen: () => {
      return listViewOpen.includes(resource.id)
    },
    handleToggle: () => {
      toggleListViewOpenResource(resource)
    },
    isCreatable: () => {
      if (resource instanceof Zone) {
        return !resource.isFloorZone()
      }

      if (resource instanceof AccessPoint) {
        return false
      }

      return resource.resourceType !== "ap"
    },
    getIcon: (resource, onClick) => {
      if (resource instanceof Zone) {
        return getZoneIcon(resource, onClick)
      }

      if (resource instanceof AccessPoint) {
        return getApIcon(resource, onClick)
      }

      return <span/>
    },
    hasSubResources: () => {
      if (resource instanceof Zone) {
        return resource.hasSubZones() || resource.isFloorZone()
      }

      if (resource instanceof AccessPoint) {
        return false
      }

      return resource?.resources?.length > 0
    },
    subResourceDatasources: () => {
      if (!(resource instanceof Zone)) {
        return resource.resources || []
      }

      if (resource.isBuildingZone() && resource.hasBuilding()) {
        const invalidAps = resource.getBuilding().getInvalidAps()
        if (invalidAps.length) {
          return [
            buildResourceData({
              id: `ap-${resource.id}`,
              name: "INVALID ACCESS POINTS",
              resourceType: "ap",
              resource,
              resources: invalidAps.map(ap => buildResourceData(ap)),
              isComplete: () => true
            }),
            buildResourceData({
              id: `zone-${resource.id}`,
              name: "ZONES",
              resourceType: "zone",
              resource,
              resources: resource.getSubZones().map(zone => buildResourceData(zone)),
              isComplete: () => true
            })
          ]
        }
      } else if (resource.isFloorZone()) {
        const options = []
        if (resource.hasBuilding() && resource.hasFloors()) {
          options.push(buildResourceData({
            id: `ap-${resource.id}`,
            name: "ACCESS POINTS",
            resourceType: "ap",
            resource,
            resources: resource.getApsByFloor().map(ap => buildResourceData(ap)),
            isComplete: () => true
          }))
        }

        options.push(buildResourceData({
          id: `zone-${resource.id}`,
          name: "ZONES",
          resourceType: "zone",
          resource,
          resources: resource.getSubZones().map(zone => buildResourceData(zone)),
          isComplete: () => true
        }))

        return options
      }

      return resource.getSubZones().map(zone => buildResourceData(zone))
    }
  })

  const buildDatasource = resources => ({
    isSearching,
    numberOfRows: () => {
      return resources.length
    },
    getDataForRow: row => {
      const resource = resources[row]
      return buildResourceData(resource)
    },
  })

  return (
    <CollapsableListView
      isLoading={isLoading}
      hasData={hasData}
      datasource={buildDatasource([...zones, ...strandedAps])}
      delegate={{
        onCreate: (resource) => {
          if (!(resource instanceof Zone || resource?.resourceType === "zone")) {
            return
          }

          const zone = resource instanceof Zone ? resource : resource.resource

          setNewZone && setNewZone({
            parent: zone,
            type: getNextZoneType(zone),
            building: zone.getBuilding(),
            primaryUsage: zone.getPrimaryUsage(),
            secondaryUsage: zone.getSecondaryUsage(),
            floors: zone.floors,
            visibility_option: zone.visibility_option,
          })
        },
        onSelect: setSelectedResource,
      }}
    />
  )
}

const getZoneIcon = (zone, onClick) => {
  const color = zone.layout.icon_color

  switch (zone.type) {
    case ZoneType.CAMPUS:
      return <CampusIcon style={{color}} onClick={onClick}/>

    case ZoneType.BUILDING:
      return <BuildingIcon style={{color}} onClick={onClick}/>

    case ZoneType.FLOOR:
      return <FloorIcon style={{color}} onClick={onClick}/>

    default:
      return <DefaultIcon style={{color}} onClick={onClick}/>
  }
}

const getApIcon = (ap, onClick) => {
  return <DefaultIcon style={{color: "var(--red-color-500)"}} onClick={onClick}/>
}


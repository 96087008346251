import {TableView} from "../../../../shared/components/table";
import {Button, DeleteButton} from "../../../../shared/components/buttons";
import React, {useEffect, useReducer, useState} from "react";
import {EntryInput, EntryModal} from "../../../../shared/components/modal";
import Indicator from "../../../../shared/components/loader";
import Api from "../../../../shared/api/api";
import {ErrorToast} from "../../../../shared/utils/toast";
import {EmptyView} from "../../../../shared/components/views";
import {ListView} from "../../../../shared/components/list";

const DEFAULT_IGNORE_AP_DATA = {
  createModal: {show: false},
  editModal: {show: false, data: null},
  deleteModal: {show: false, pattern: null},
  syncModal: {show: false},
}

const SHOW_CREATE_MODAL = "SHOW_CREATE_MODAL"
const SHOW_EDIT_MODAL = "SHOW_EDIT_MODAL"
const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL"
const SHOW_SYNC_MODAL = "SHOW_SYNC_MODAL"

const IgnoreApReducer = (state, action) => {
  switch (action.type) {
    case SHOW_CREATE_MODAL:
      return {
        ...state,
        createModal: action.payload
      }

    case SHOW_EDIT_MODAL:
      return {
        ...state,
        editModal: action.payload
      }

    case SHOW_DELETE_MODAL:
      return {
        ...state,
        deleteModal: action.payload
      }

    case SHOW_SYNC_MODAL:
      return {
        ...state,
        syncModal: action.payload
      }

    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export function IgnoreApView({show, school, data, onAdd, onDelete, onSync}) {
  const [state, dispatch] = useReducer(IgnoreApReducer, DEFAULT_IGNORE_AP_DATA)

  if (!show) {
    return null
  }

  const showCreateModal = () => dispatch({type: SHOW_CREATE_MODAL, payload: {show: true}})
  const hideCreateModal = () => dispatch({type: SHOW_CREATE_MODAL, payload: {show: false}})

  const showDeleteModal = (pattern) => dispatch({type: SHOW_DELETE_MODAL, payload: {show: true, pattern}})
  const hideDeleteModal = () => dispatch({type: SHOW_DELETE_MODAL, payload: {show: false, pattern: null}})

  const showSyncModal = () => dispatch({type: SHOW_SYNC_MODAL, payload: {show: true}})
  const hideSyncModal = () => dispatch({type: SHOW_SYNC_MODAL, payload: {show: false}})

  const handleOnAdd = data => {
    onAdd && onAdd(data, hideCreateModal)
  }
  const handleOnDelete = (data) => {
    onDelete && onDelete(data, hideDeleteModal)
  }

  const handleOnSync = () => {
    onSync && onSync(hideSyncModal)
  }

  const existingPatterns = data.patterns.map(p => p.pattern)

  return (
    <div className="settings-ignore-ap">
      <div className="settings-ignore-ap-buttons">
        <Button variant="secondary" onClick={showSyncModal}>Sync</Button>
        <Button variant="primary" onClick={showCreateModal}>Add Pattern</Button>
      </div>
      <TableView
        classNames={{
          row: "settings-ignore-ap-row",
        }}
        columns={[
          {label: "Pattern", key: "pattern"},
          {label: "Description", key: "description"},
          {key: "buttons"}
        ]}
        data={data.patterns}
        viewInflator={(data, column) => {
          if (column !== "buttons") {
            return
          }

          return (
            <div key={`ignore-ap-${data.pattern}`} className="settings-ignore-ap-row-buttons">
              <DeleteButton onClick={() => showDeleteModal(data.pattern)}/>
            </div>
          )
        }}
      />

      <CreatePatternModal
        show={state.createModal.show}
        school={school}
        existingPatterns={existingPatterns}
        onHide={hideCreateModal}
        onAdd={handleOnAdd}
      />

      <DeletePatternModal
        show={state.deleteModal.show}
        pattern={state.deleteModal.pattern}
        onHide={hideDeleteModal}
        onDelete={handleOnDelete}
      />

      <SyncModal
        show={state.syncModal.show}
        onHide={hideSyncModal}
        onSync={handleOnSync}
      />
    </div>
  )
}

function CreatePatternModal({show, school, existingPatterns, onHide, onAdd}) {
  const [pattern, setPattern] = useState("")
  const [description, setDescription] = useState("")
  const [aps, setAps] = useState([])
  const [loading, setLoading] = useState(false)

  const schoolId = school ? school.id : null

  useEffect(() => {
    setPattern("")
    setDescription("")
    setAps([])
    setLoading(false)
  }, [show]);

  useEffect(() => {
    if (!schoolId) {
      return
    }

    const cleanPattern = pattern.trim()

    if (!cleanPattern.length) {
      setAps([])
      return
    }

    const makeCall = () => {
      setLoading(true)
      Api.get(`/settings/schools/${schoolId}/aps/preview?pattern=${encodeURIComponent(cleanPattern)}`)
        .then(response => {
          setAps(response.data.pattern.aps)
          setLoading(false)
        })
        .catch(e => {
          setLoading(false)

          if (e.handled) return

          console.error(e.error)
          ErrorToast("Unable to preview pattern")
        })
    }

    const timer = setTimeout(makeCall, 500)

    return () => clearTimeout(timer)
  }, [schoolId, pattern]);

  const handleOnAdd = () => {
    onAdd && onAdd({
      pattern: pattern.trim(),
      description: description.trim().length ? description.trim() : null,
    })
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Create",
      disabled: pattern.trim().length === 0 || existingPatterns.includes(pattern),
      onClick: handleOnAdd
    }
  }

  return (
    <EntryModal cardClassName="settings-ignore-ap-modal-card" bodyClassName="settings-ignore-ap-modal-body" show={show} title="Create Pattern" onHide={onHide} buttons={buttons}>
      <div className="settings-ignore-ap-modal-inputs">
        <EntryInput autoFocus label="Pattern" value={pattern} onChange={setPattern}/>
        <EntryInput label="Description" value={description} onChange={setDescription}/>
      </div>
      <ApMatches aps={aps}/>
      <Indicator show={loading}/>
    </EntryModal>
  )
}

function ApMatches({aps}) {
  const hasAps = aps.length > 0
  const title = "Preview"

  if (!hasAps) {
    return (
      <div className="da-modal-entry-input">
        <span className="da-modal-entry-input-label" style={{flexGrow: "unset"}}>{title}</span>
        <EmptyView>No Matches</EmptyView>
      </div>
    )
  }

  return (
    <div className="settings-ignore-ap-modal-aps-matches">
      <span className="da-modal-entry-input-label">{title}</span>
      <div className="settings-ignore-ap-modal-aps-matches-content">
        <ListView>
          <div className="settings-ignore-ap-modal-aps">
            {aps.map(ap => (
              <span key={ap.id}>{ap.name}</span>
            ))}
          </div>
        </ListView>
      </div>
    </div>
  )
}

function DeletePatternModal({show, pattern, onHide, onDelete}) {
  const [current, setCurrent] = useState("")

  useEffect(() => {
    setCurrent("")
  }, [show]);

  const handleOnDelete = () => {
    onDelete && onDelete({pattern})
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    danger: {
      title: "Delete",
      disabled: current !== pattern,
      onClick: handleOnDelete
    }
  }

  return (
    <EntryModal cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body" show={show} title="Delete Pattern" onHide={onHide} buttons={buttons}>
      <p>Are you sure?</p>
      <div>
        <p>Please type <span>{pattern}</span> to confirm</p>
        <input autoFocus value={current} onChange={(e) => setCurrent(e.target.value)}/>
      </div>
    </EntryModal>
  )
}

function SyncModal({show, onHide, onSync}) {
  const [current, setCurrent] = useState("")

  useEffect(() => {
    setCurrent("")
  }, [show]);

  const handleOnSync = () => {
    onSync && onSync()
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Sync",
      disabled: current !== "Sync",
      onClick: handleOnSync
    }
  }

  return (
    <EntryModal cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body" show={show} title="Sync Patterns" onHide={onHide} buttons={buttons}>
      <p>Are you sure? This will add and remove ignored aps</p>
      <div>
        <p>Please type <span>Sync</span> to confirm</p>
        <input autoFocus value={current} onChange={(e) => setCurrent(e.target.value)}/>
      </div>
    </EntryModal>
  )
}
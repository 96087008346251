import {useEffect, useRef} from "react"
import {getPointOnPolygon, getSmallestFeature, INTERACTABLE_AP_LAYER_IDS, INTERACTABLE_ZONE_LAYER_IDS} from "./utils"
import {DrawMode, useDataContext} from "../../hooks/context"
import mapboxgl from "mapbox-gl"

export function DefaultMapBehavior({map, isMapLoading}) {
  const {setSelectedZone, setSelectedAp, draw} = useDataContext()
  const popupRef = useRef(new mapboxgl.Popup({closeButton: false, closeOnClick: false}))

  useEffect(() => {
    if (!map || isMapLoading || draw.enabled) {
      return
    }

    const handleOnClickZone = e => {
      if (!e.features.length) {
        return
      }

      if (e.features.length === 1) {
        setSelectedZone(e.features[0].properties.id)
        return
      }

      const {polygon} = getSmallestFeature(e.features)
      if (polygon) {
        setSelectedZone(polygon.properties.id)
      }
    }

    const handleOnClickAp = e => {
      const {point} = getSmallestFeature(e.features)
      if (!point) {
        return
      }

      setSelectedAp(point.properties.id)

      if (e.originalEvent.shiftKey) {
        draw.setMode(DrawMode.CLICK)
      }
    }

    const handleOnMouseEnterResource = (e) => {
      map.getCanvas().style.cursor = "pointer"

      const {point, polygon} = getSmallestFeature(e.features)
      let coordinates = null, name = null

      if (point) {
        coordinates = point.geometry.coordinates
        name = point.properties.name
      } else if (polygon) {
        const popupPoint = getPointOnPolygon(polygon)
        if (popupPoint) {
          coordinates = popupPoint.geometry.coordinates
          name = polygon.properties.name
        }
      }

      if (coordinates && name) {
        popupRef.current.setLngLat(coordinates.slice())
          .setHTML(`<strong>${name}</strong>`)
          .addTo(map)
      }
    }

    const handleOnMouseLeaveResource = () => {
      map.getCanvas().style.cursor = ""
      popupRef.current.remove()
    }

    for (let key of INTERACTABLE_ZONE_LAYER_IDS) {
      map.on("click", key, handleOnClickZone)
      map.on("mouseenter", key, handleOnMouseEnterResource)
      map.on("mouseleave", key, handleOnMouseLeaveResource)
    }

    for (let key of INTERACTABLE_AP_LAYER_IDS) {
      map.on("click", key, handleOnClickAp)
      map.on("mouseenter", key, handleOnMouseEnterResource)
      map.on("mouseleave", key, handleOnMouseLeaveResource)
    }

    return () => {
      for (let key of INTERACTABLE_ZONE_LAYER_IDS) {
        map.off("click", key, handleOnClickZone)
        map.off("mouseenter", key, handleOnMouseEnterResource)
        map.off("mouseleave", key, handleOnMouseLeaveResource)
      }

      for (let key of INTERACTABLE_AP_LAYER_IDS) {
        map.off("click", key, handleOnClickAp)
        map.off("mouseenter", key, handleOnMouseEnterResource)
        map.off("mouseleave", key, handleOnMouseLeaveResource)
      }
    }
  }, [map, isMapLoading, setSelectedZone, setSelectedAp, draw])
}
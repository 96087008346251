import {area, bbox, distance as distanceFrom, lineIntersect, lineString as Line, point as Point, polygon as Feature} from "@turf/turf";

export const MAP_LAYER_OPTIONS = ["building", "landuse", "landuse_overlay"]

export const MapKeys = {
  SOURCE_ID: "da-composite-data",
  DRAW_SOURCE_ID: "da-draw-data",
  OTHER_ZONE_LAYER: "da-other-zone-overlay",
  CURRENT_ZONE_BUILDING_LAYER: "da-current-zone-building-overlay",
  CURRENT_ZONE_FLOOR_LAYER: "da-current-zone-floor-overlay",
  CURRENT_ZONE_FLOOR_CHILD_LAYER: "da-current-zone-floor-child-overlay",
  CURRENT_ZONE_OUTLINE_LAYER: "da-current-zone-outline-overlay",
  DRAW_ZONE_POLYGON_LAYER: "da-draw-zone-polygon-overlay",
  DRAW_ZONE_POINT_LAYER: "da-draw-zone-point-overlay",
  DRAW_ZONE_LINE_LAYER: "da-draw-zone-line-overlay",
  OTHER_AP_LAYER: "da-other-ap-layer",
  CURRENT_AP_LAYER: "da-current-zone-layer",
  DRAW_AP_LAYER: "da-draw-zone-layer",
}

export const INTERACTABLE_ZONE_LAYER_IDS = [
  MapKeys.OTHER_ZONE_LAYER,
  MapKeys.CURRENT_ZONE_BUILDING_LAYER,
  MapKeys.CURRENT_ZONE_FLOOR_LAYER,
  MapKeys.CURRENT_ZONE_FLOOR_CHILD_LAYER
]

export const INTERACTABLE_AP_LAYER_IDS = [
  MapKeys.OTHER_AP_LAYER,
  MapKeys.CURRENT_AP_LAYER
]

export const getBaseLayerId = layerId => `${layerId}-base`

export const buildPolygon = (geometry, properties) => {
  if (!geometry || !["MultiPolygon", "Polygon"].includes(geometry.type) || !geometry.coordinates.length) {
    return []
  }

  if (!properties) {
    properties = {}
  }

  if (geometry.type === "Polygon") {
    return [Feature(geometry.coordinates, properties)]
  }

  return geometry.coordinates.map(coords => Feature(coords, properties))
}

export const isPolygonValid = polygon => {
  if (polygon?.geometry?.type !== "Polygon" || polygon?.geometry.coordinates.length !== 1 || polygon?.geometry?.coordinates[0].length < 3) {
    return false
  }

  const lines = []
  const coordinates = polygon.geometry.coordinates[0]
  for (let i = 1; i < coordinates.length; i++) {
    lines.push(Line([coordinates[i - 1], coordinates[i]]))
  }

  class LineCheck {
    constructor(lines, numberOfIntersections) {
      this.lines = lines
      this.numberOfIntersections = numberOfIntersections
      this.hasIntersection = numberOfIntersections > lines.length
    }

    check(line) {
      const newLines = [...this.lines, line]
      if (Array.from(new Set(newLines)).length !== newLines.length) {
        return new LineCheck(newLines, this.numberOfIntersections + 1)
      }

      let numberOfIntersections = this.numberOfIntersections
      for (let oldLine of this.lines) {
        const intersectingFeatures = lineIntersect(oldLine, line)
        numberOfIntersections += intersectingFeatures.features.length
      }

      return new LineCheck(newLines, numberOfIntersections)
    }
  }

  return !lines.reduce((l1, l2) => l1.check(l2), new LineCheck([], 0)).hasIntersection
}

const DISTANCE_OPTIONS = {units: "miles"}
const convertToFeet = miles => 5280 * miles
export const MERGE_DISTANCE_IN_FEET = 3.14

export const distanceBetweenPoints = (p1, p2) => {
  if (!p1 || !p2) return null
  return convertToFeet(distanceFrom(p1, p2, DISTANCE_OPTIONS))
}

const getClosestFeature = (points, point) => {
  const distanceFromExisting = points.map((oldPoint, i) => ({id: oldPoint.properties.id, distance: distanceBetweenPoints(oldPoint, point), point: oldPoint, index: i}))
    .filter(option => option.distance <= MERGE_DISTANCE_IN_FEET)
    .reduce((accumulator, value) => accumulator == null ? value : accumulator.distance < value.distance ? accumulator : value, null)

  return {
    point: distanceFromExisting ? distanceFromExisting.point : null,
    index: distanceFromExisting ? distanceFromExisting.index : null,
    distance: distanceFromExisting ? distanceFromExisting.distance : null,
  }
}

export const getSmallestFeature = features => {
  const points = []
  const polygons = []

  for (let feature of features) {
    if (feature.geometry.type === "Point") {
      points.push(feature)
      continue
    }

    if (feature.geometry.type === "Polygon") {
      polygons.push(feature)
    }
  }

  const point = points.reduce((accumulator, value) => {
    if (accumulator?.feature?.floor == null) {
      return value
    }

    return accumulator.feature.floor < value.feature.floor ? accumulator : value
  }, null)

  const polygon = polygons.map(f => ({feature: f, size: area(f)}))
    .reduce((accumulator, value) => {
      if (accumulator?.feature == null) {
        return value
      }

      return accumulator.size < value.size ? accumulator : value
    }, null)

  return {point, polygon: polygon?.feature}
}

export const PolygonPointOption = {
  TOP_CENTER: 0,
  CENTER: 1,
  BOTTOM_CENTER: 2
}

export const getPointOnPolygon = (polygon, option = PolygonPointOption.TOP_CENTER) => {
  const boundingBox = bbox(polygon)
  if (!boundingBox) {
    return null
  }

  const minLongitude = Math.min(boundingBox[0], boundingBox[2])
  const maxLongitude = Math.max(boundingBox[0], boundingBox[2])
  const minLatitude = Math.min(boundingBox[1], boundingBox[3])
  const maxLatitude = Math.max(boundingBox[1], boundingBox[3])

  switch (option) {
    case PolygonPointOption.TOP_CENTER:
      return new Point([maxLongitude - ((maxLongitude - minLongitude) / 2), maxLatitude])

    case PolygonPointOption.CENTER:
      return new Point([maxLongitude - ((maxLongitude - minLongitude) / 2), maxLatitude - ((maxLatitude - minLatitude) / 2)])

    case PolygonPointOption.BOTTOM_CENTER:
      return new Point([maxLongitude - ((maxLongitude - minLongitude) / 2), minLatitude])

    default:
      throw new Error(`Unsupported option: ${option}`)
  }
}

export const getPoint = (draw, point) => {
  const existingPoints = draw.geojson.features.filter(feature => feature.properties?.drawType === "pen" && feature.geometry.type === "Point")
  let featureResult = getClosestFeature(existingPoints, point)
  let isPolygonPoint = false
  if (!featureResult.point) {
    const existingPolygonPoints = draw.geojson.features.filter(feature => feature.properties?.isCompleted && feature.geometry.type === "Polygon")
      .flatMap(feature => feature.geometry.coordinates[0].map(coords => Point(coords, feature.properties)))

    featureResult = getClosestFeature(existingPolygonPoints, point)
    if (!featureResult.point) {
      return {result: null, isPolygonPoint: false, isValid: false}
    }

    isPolygonPoint = true
  }

  return {result: featureResult, isPolygonPoint, isValid: true}
}


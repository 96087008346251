import React, {useEffect, useRef} from "react";
import {createPortal} from "react-dom";

import Card from "../card";

export function Dropdown({show, options, onClose, ...props}) {
  const divRef = useRef();
  const countRef = useRef(0);

  useEffect(() => {
    countRef.current = 0
  }, [show]);

  useEffect(() => {
    if (onClose == null) return

    const handleClose = (e) => {
      // debounce the first click since that's basically it opening
      if (countRef.current < 1) {
        countRef.current = 1
        return
      }

      if (divRef.current != null && !divRef.current.contains(e.target)) onClose()
    }

    document.addEventListener("click", handleClose);
    return () => document.removeEventListener("click", handleClose);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!show) return null

  const {className, style, children} = props;

  return createPortal(
    <div className={className} style={style} ref={divRef}>
      <Card style={{top: 50, right: 80}}>
        {children}
      </Card>
    </div>,
    document.getElementById("root")
  )
}
import Warehouse from "../warehouse";
import {AttributeSort} from "../../utils/sort";

export class Building {
  constructor(data) {
    this.id = data?.id
    this.name = data?.name
  }

  getFloors() {
    return Warehouse.wireless_config.getZones().getZoneForBuilding(this.id)?.floors || []
  }

  hasInvalidAps() {
    return this.getInvalidAps().length > 0
  }

  getInvalidAps() {
    const apsByFloor = Warehouse.wireless_config.getAps().getApsInBuildingByFloor(this.id)
    if (!apsByFloor || !Object.keys(apsByFloor).length) {
      return []
    }

    const allApFloors = Object.keys(apsByFloor)
    const zoneFloors = this.getFloors()

    return allApFloors.filter(floor => !zoneFloors.includes(floor))
      .flatMap(f => apsByFloor[f])
      .sort(AttributeSort("name"))
  }
}
import React, {useCallback, useEffect, useState} from "react";
import {onEnterKey} from "../../../shared/utils/key_events";
import {DeleteButton} from "../../../shared/components/buttons";
import {DeleteEntryModal} from "../../../shared/components/modal";
import {ErrorToast} from "../../../shared/utils/toast";
import {TextButtons} from "./text_buttons";

export function TitleView({isCreating, resource, placeholder, onUpdate, onDelete}) {
  const [name, setName] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const showDelete = !!onDelete

  const resolveValue = value => !value?.length ? null : value
  const hasChange = resolveValue(name) !== resolveValue(resource?.name)

  useEffect(() => {
    setShowDeleteModal(false)
  }, [isCreating, resource?.id]);

  useEffect(() => {
    setName(() => resource?.name || null)
  }, [resource?.name]);

  const handleSave = useCallback(() => {
    if (!onUpdate) {
      return
    }

    const newName = name?.trim()
    if (!newName) {
      ErrorToast("Name is a required field")
      return
    }

    if (name !== newName) {
      setName(() => newName)
    }

    if (resource?.name !== newName) {
      onUpdate && onUpdate({
        body: {name: newName},
        onError: () => {
          ErrorToast("Unable to update the Name")
        }
      })
    }
  }, [name, onUpdate, resource?.name])

  const handleCancel = useCallback(() => {
    setName(resource.name)
  }, [resource.name])

  const handleOnDelete = useCallback(() => {
    onDelete && onDelete(() => setShowDeleteModal(false))
  }, [onDelete])

  return (
    <div className="wireless-config-detail-title">
      <div className="wireless-config-detail-title-content">
        <input
          key={`${resource.id}-title`}
          autoFocus={isCreating && resource.name == null}
          placeholder={placeholder}
          value={name ? name : ""}
          onChange={e => {
            const newName = e.target.value.length ? e.target.value : null
            setName(() => newName)

            if (isCreating && onUpdate && name !== newName) {
              onUpdate({
                body: {name: newName},
                onError: () => {
                  ErrorToast("Unable to update the Name")
                }
              })
            }
          }}
          onKeyUp={onEnterKey(({target}) => {
            target.blur()
          })}
        />
        <TextButtons show={hasChange && !isCreating} onSave={handleSave} onCancel={handleCancel}/>
      </div>
      <div className="wireless-config-detail-title-buttons">
        {!isCreating && showDelete && <DeleteButton onClick={() => setShowDeleteModal(true)}/>}
      </div>

      <DeleteEntryModal show={showDeleteModal} value={resource?.name} onHide={setShowDeleteModal} onDelete={handleOnDelete}>
        <p>Are you sure?</p>
      </DeleteEntryModal>
    </div>
  )
}


import {useEffect, useState} from "react"
import {DrawMode, useDataContext} from "./hooks/context"
import {DrawableZoneTypes, getNextZoneType} from "./consts";
import {centerMapOnFeatures, getMapFeatures} from "./map/shared";

export function Hotkeys({map, toggleMapOption, ...props}) {
  const {children} = props
  const [focusElement, setFocusElement] = useState(null)
  const {isCreatingZone, selectedResource, selectedZone, setNewZone, resetNewZone, draw, geojson, cycleDatasource} = useDataContext()

  useEffect(() => {
    const target = document.getElementById("root")

    if (!target) {
      return
    }

    const setHandler = (e) => {
      if (["button"].includes(e.target.localName)) {
        return
      }

      setFocusElement(e.target)
    }

    const clearHandler = () => {
      if (!focusElement) {
        return
      }

      setFocusElement(null)
    }

    const handleKeyStroke = e => {
      if (e.code === "Escape" && focusElement) {
        focusElement.blur()
        return
      }

      if (focusElement) {
        return
      }

      if (e.code === "Escape") {
        if (draw.enabled) {
          draw.setMode(null)
          return
        }

        if (isCreatingZone) {
          resetNewZone()
          return
        }
      }

      if (e.code === "KeyA") {
        // a

        if (e.shiftKey) {
          // toggle the ap map option
          toggleMapOption("aps")
          return
        }

        if (selectedZone?.id && !isCreatingZone) {
          setNewZone({
            parent: selectedZone,
            type: getNextZoneType(selectedZone),
            building: selectedZone.getBuilding(),
            primaryUsage: selectedZone.primaryUsage,
            secondaryUsage: selectedZone.secondaryUsage,
            floors: selectedZone.floors,
          })
        }
        return
      }

      if (e.code === "KeyC" && !e.shiftKey) {
        // center map on selected resource
        if (map && selectedResource) {
          const {center} = getMapFeatures(map, draw?.geojson, geojson, selectedResource)
          centerMapOnFeatures(map, center)
        }
        return
      }

      if (e.code === "KeyF" && e.shiftKey) {
        // toggle floor plan map option
        toggleMapOption("floor_plan")
        return
      }

      if (e.code === "KeyP") {
        // p
        if (selectedResource?.id || (selectedZone?.id && DrawableZoneTypes.has(selectedZone?.type))) {
          if (e.shiftKey) {
            if (draw.mode !== DrawMode.CLICK) {
              draw.setMode(DrawMode.CLICK)
            } else if (draw.mode === DrawMode.CLICK) {
              draw.setMode(null)
            }
          } else if (selectedZone?.id && e.altKey) {
            if (draw.mode !== DrawMode.ANCHOR) {
              draw.setMode(DrawMode.ANCHOR)
            } else {
              draw.setMode(null)
            }
          } else if (selectedZone?.id) {
            if (draw.mode !== DrawMode.PEN) {
              draw.setMode(DrawMode.PEN)
            } else {
              draw.setMode(null)
            }
          }
        }
        return
      }

      if (e.code === "KeyZ" && e.shiftKey) {
        // toggle zones map option
        toggleMapOption("zones")
        return
      }

      if (e.code === "ArrowLeft" && !cycleDatasource.up?.disabled) {
        cycleDatasource.up.onClick()
        return
      }

      if (e.code === "ArrowUp" && !cycleDatasource.previous?.disabled) {
        cycleDatasource.previous.onClick()
        return
      }

      if (e.code === "ArrowDown" && !cycleDatasource.next?.disabled) {
        cycleDatasource.next.onClick()
        return
      }

      if (e.code === "ArrowRight" && !cycleDatasource.down?.disabled) {
        cycleDatasource.down.onClick()
      }
    }

    const handleKeyDown = e => {
      if (focusElement) {
        return
      }

      if (e.code === "ArrowLeft" && !cycleDatasource.up?.disabled) {
        e.preventDefault()
        return
      }

      if (e.code === "ArrowUp" && !cycleDatasource.previous?.disabled) {
        e.preventDefault()
        return
      }

      if (e.code === "ArrowDown" && !cycleDatasource.next?.disabled) {
        e.preventDefault()
        return
      }

      if (e.code === "ArrowRight" && !cycleDatasource.down?.disabled) {
        e.preventDefault()
      }
    }

    target.addEventListener("focusin", setHandler)
    target.addEventListener("focusout", clearHandler)
    window.addEventListener("keyup", handleKeyStroke)
    window.addEventListener("keydown", handleKeyDown)

    return () => {
      target.removeEventListener("focusin", setHandler)
      target.removeEventListener("focusout", clearHandler)
      window.removeEventListener("keyup", handleKeyStroke)
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [map, focusElement, isCreatingZone, selectedZone, setNewZone, resetNewZone, geojson, draw, toggleMapOption, cycleDatasource, selectedResource])

  return (
    <div className="da-flex-fill">
      {children}
    </div>
  )
}
import {ReactComponent as SchoolIconIcon} from "../../assets/icons/school_icon.svg";

const COLOR_MAP = {}
const DEFAULT_COLOR = "school-icon-blue"

let index = 0;
for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
  const key = String.fromCharCode(i)

  switch (index) {
    case 0:
      COLOR_MAP[key] = "school-icon-green"
      break

    case 1:
      COLOR_MAP[key] = "school-icon-red"
      break

    case 2:
      COLOR_MAP[key] = "school-icon-orange"
      break

    case 3:
      COLOR_MAP[key] = "school-icon-purple"
      break

    default:
      COLOR_MAP[key] = DEFAULT_COLOR
  }

  index++
  if (index >= 5) index = 0
}

const getColorForSchool = (school) => {
  if (!school || !school.name || !school.name.length) return DEFAULT_COLOR
  const name = school.name.toLowerCase()
    .replace("university", "")
    .replace("of", "")
    .trim()

  const firstChar = name.charAt(0).toUpperCase()
  const backgroundColors = COLOR_MAP[firstChar]
  return backgroundColors != null ? backgroundColors : DEFAULT_COLOR
}

export const SchoolIcon = ({school}) => {
  return (
    <div className={`school-icon ${getColorForSchool(school)}`}>
      <SchoolIconIcon/>
    </div>
  )
}
import React, {useCallback, useEffect, useRef, useState} from "react";
import {getAccessKey, useMap} from "../../../hooks/map";
import {Geocoder} from "@mapbox/search-js-react"
import {Group, GroupItem} from "./shared";

export function GeneralTab({show, canManageSchool, data, onItemChange}) {
  const [currentLocation, setCurrentLocation] = useState(() => ({
    longitude: data.longitude,
    latitude: data.latitude,
    zoom: data.zoom,
  }))

  const handleOnChange = useCallback((data) => {
    if (onItemChange == null) {
      return
    }

    onItemChange(data)
  }, [onItemChange])

  const handleOnMove = useCallback((longitude, latitude, zoom) => {
    const newData = {}

    if (currentLocation.longitude !== longitude) {
      newData.longitude = longitude
    }

    if (currentLocation.latitude !== latitude) {
      newData.latitude = latitude
    }

    if (currentLocation.zoom !== zoom) {
      newData.zoom = zoom
    }

    if (!Object.keys(newData).length) {
      return
    }

    setCurrentLocation({longitude, latitude, zoom})
    handleOnChange && handleOnChange(newData)
  }, [currentLocation.longitude, currentLocation.latitude, currentLocation.zoom, handleOnChange])

  if (!show) return null

  return (
    <div className="school-general-tab">
      <Group divClassName="school-general-layout" name="Location">
        {/*<GroupItem name="ADDRESS" value={data.address} editable={canManageSchool} onChange={value => handleOnChange("address", value)}/>*/}
        {/*<GroupItem name="CITY" value={data.city} editable={canManageSchool} onChange={value => handleOnChange("city", value)}/>*/}
        {/*<GroupItem name="STATE" value={data.state} editable={canManageSchool} onChange={value => handleOnChange("state", value)}/>*/}
        {/*<GroupItem name="COUNTRY" value={data.country} editable={canManageSchool} onChange={value => handleOnChange("country", value)}/>*/}
        <GroupItem name="TIMEZONE" value={data.timezone} editable={canManageSchool} onChange={value => handleOnChange({timezone: value})}/>
      </Group>
      <SchoolMap
        canManageSchool={canManageSchool}
        address={data.address}
        defaults={currentLocation}
        onAddressChange={handleOnChange}
        onMove={handleOnMove}
      />
    </div>
  )
}

function SchoolMap({canManageSchool, address, defaults, onAddressChange, onMove}) {
  const layoutRef = useRef(null)
  const {map, setContainer, longitude, latitude, zoom} = useMap(defaults);
  const [currentAddress, setAddress] = useState(address || "")

  useEffect(() => {
    setContainer(layoutRef)
  }, [setContainer]);

  useEffect(() => {
    onMove && onMove(longitude, latitude, zoom)
  }, [longitude, latitude, zoom, onMove])

  const hasMap = !!map

  return (
    <div className="school-map-layout" ref={layoutRef} style={{pointerEvents: canManageSchool ? "all" : "none"}}>
      {hasMap && canManageSchool &&
        <Geocoder
          accessToken={getAccessKey()}
          map={map}
          value={currentAddress}
          onChange={(newAddress) => {
            setAddress(newAddress)
          }}
          onRetrieve={result => {
            const context = result.properties.context
            setAddress(context.address.name)

            onAddressChange && onAddressChange({
              address: context.address.name,
              city: context.place.name,
              state: context.region.name,
              country: context.country.name,
              zip: context.postcode.name
            })
          }}
        />
      }
    </div>
  )
}

import {EmptyView} from "./views";
import {OverflowButton} from "./buttons";
import {useEffect, useRef, useState} from "react";
import Divider from "./divider";

export function TableView({classNames, columns, data, noDataMessage = "No data", viewInflator, hasOverflow = false}) {
  const hasData = !!data && data.length > 0
  hasOverflow = !!hasOverflow

  if (noDataMessage == null) {
    noDataMessage = "No data";
  }

  const formattedColumns = columns.filter(column => column.hidden == null || !column.hidden)
  const inflate = (data, column) => {
    if (viewInflator != null) {
      const view = viewInflator(data, column);
      if (view != null) {
        return view
      }
    }

    return <span key={column} className="da-table-view-row-cell">{data[column]}</span>
  }

  const columnStyle = formattedColumns.map((_) => "1fr")
  if (hasOverflow) {
    columnStyle.push("auto")
  }

  const style = {gridTemplateColumns: columnStyle.join(" ")}

  const containerClassNames = ["da-table-view"]
  if (classNames != null && classNames.container != null) {
    containerClassNames.push(classNames.container)
  }

  const headerClassNames = ["da-table-view-header"]
  if (classNames != null && classNames.header != null) {
    headerClassNames.push(classNames.header)
  }

  const defaultRowClassName = "da-table-view-row"
  let rowClassNames
  if (classNames != null && classNames.row != null) {
    if (typeof classNames.row === "function") {
      rowClassNames = (data) => {
        return [defaultRowClassName, classNames.row(data)].join(" ")
      }
    } else {
      rowClassNames = () => {
        return [defaultRowClassName, classNames.row].join(" ")
      }
    }
  } else {
    rowClassNames = () => {
      return defaultRowClassName
    }
  }

  const scrollableClassNames = ["da-table-view-content-scrollable"]
  if (classNames != null && classNames.scrollable != null) {
    scrollableClassNames.push(classNames.scrollable)
  }

  return (
    <div className={containerClassNames.join(" ")}>
      <div className={headerClassNames.join(" ")} style={style}>
        {formattedColumns.map((column, i) => <span key={`table-header-${i}`}>{column.label}</span>)}
        {hasOverflow && <OverflowLayout className="da-table-view-header-overflow" options={[]}/>}
      </div>
      {!hasData && <EmptyView>{noDataMessage}</EmptyView>}
      {hasData &&
        <div className="da-table-view-content">
          <div className={scrollableClassNames.join(" ")}>
            {data.map((row, i) => (
              <div key={`table-row-${i}`} className={rowClassNames(row)} style={style}>
                {formattedColumns.map(column => inflate(row, column.key))}
                {hasOverflow && <OverflowLayout {...inflate(row, "overflow")}/>}
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}

function OverflowLayout({options, onItemClick, ...props}) {
  const [showOverflow, setShowOverflow] = useState(false)

  const {className} = props
  const toggleOverflow = () => setShowOverflow(old => !old)

  const handleOnItemClick = (id) => {
    onItemClick && onItemClick(id)
    setShowOverflow(false)
  }

  return (
    <div className={className} style={{position: "relative"}}>
      <OverflowButton onClick={toggleOverflow}/>
      <OverflowPanel show={showOverflow} options={options} onItemClick={handleOnItemClick} onHide={toggleOverflow}/>
    </div>
  )
}

function OverflowPanel({show, options, onItemClick, onHide}) {
  const overflowRef = useRef()

  useEffect(() => {
    if (!show || onHide == null) {
      return
    }

    let count = 0
    const handleClick = (e) => {
      // ignore the first call since it's from opening the overflow
      count += 1

      if (!overflowRef.current || count <= 1) {
        return
      }

      if (!overflowRef.current.contains(e.target)) {
        onHide()
      }
    }

    window.addEventListener("click", handleClick)
    return () => {
      window.removeEventListener("click", handleClick)
    }
  }, [show, onHide]);

  if (!show) {
    return null
  }

  const content = []

  options.forEach((option, i) => {
    option.forEach(item => {
      content.push(<OverflowPanelItem key={item.id} {...item} onClick={onItemClick}/>)
    })

    if (i !== options.length - 1) {
      content.push(<Divider key={`divider-${i}`}/>)
    }
  })

  return (
    <div className="da-overflow" ref={overflowRef}>
      {content}
    </div>
  )
}

function OverflowPanelItem({id, icon, label, variant, onClick}) {
  let classNames = ["da-overflow-item-layout"]
  if (variant != null && variant === "danger") {
    classNames.push("danger-text")
  }

  return (
    <div className="da-overflow-item" onClick={() => onClick && onClick(id)}>
      <div className={classNames.join(" ")}>
        {icon}
        <span>{label}</span>
      </div>
    </div>
  )
}

import {GroupItem, SliderGroup} from "./shared";
import {EntryInput, EntryModal, EntrySelector} from "../../../components/modal";
import React, {useEffect, useState} from "react";
import {formatDate, formatString} from "../../../utils/format";
import {deepCopy} from "../../../utils/copy";

export function SecurityTab({show, canManageSchool, data, onItemChange}) {
  const [showConfirmDisableModal, setShowConfirmDisableModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    setShowConfirmDisableModal(false)
    setShowCreateModal(false)
    setShowUpdateModal(false)
  }, [show]);

  if (!show) return null

  const handleEnableChange = value => {
    if (!canManageSchool) {
      return
    }

    if (!value) {
      setShowConfirmDisableModal(true)
    } else if (data == null || data.deidentify == null || !data.deidentify.enabled) {
      setShowCreateModal(true)
    } else {
      setShowUpdateModal(true)
    }
  }

  const handleOnChange = (values) => {
    if (onItemChange == null) {
      return
    }

    onItemChange({deidentify: values}, () => {
      if (showConfirmDisableModal) {
        setShowConfirmDisableModal(false)
      }

      if (showCreateModal) {
        setShowCreateModal(false)
      }

      if (showUpdateModal) {
        setShowUpdateModal(false)
      }
    })
  }

  return (
    <div className="school-general-tab">
      <SliderGroup divClassName="school-general-layout" name="De-Identify" disabled={!canManageSchool} value={data.deidentify.enabled} onValueChange={handleEnableChange}>
        <EntryInput
          editable={false}
          label="OPTION"
          value={formatString(data.deidentify.option)}
        />
        <EntryInput
          editable={false}
          label="INTERVAL"
          value={formatString(data.deidentify.interval)}
        />
        <GroupItem name="STARTS ON" value={formatDate(data.deidentify.starts_on)} disabled={!canManageSchool} editable={false}/>
        <GroupItem name="ENDS ON" value={formatDate(data.deidentify.ends_on)} disabled={!canManageSchool} editable={false}/>
      </SliderGroup>
      <ConfirmDisableModal
        show={showConfirmDisableModal}
        onHide={() => setShowConfirmDisableModal(false)}
        onConfirm={() => handleOnChange({enabled: false})}
      />
      <DeIdentifyModal
        show={showCreateModal}
        data={data.deidentify}
        isEdit={false}
        onHide={() => setShowCreateModal(false)}
        onConfirm={(data) => {
          handleOnChange({
            enabled: true,
            ...data
          })
        }}
      />
      <DeIdentifyModal
        show={showUpdateModal}
        data={data.deidentify}
        isEdit={true}
        onHide={() => setShowUpdateModal(false)}
        onConfirm={(data) => {
          handleOnChange(data)
        }}
      />
    </div>
  )
}

function ConfirmDisableModal({show, onHide, onConfirm}) {
  const [current, setCurrent] = useState("")

  useEffect(() => {
    setCurrent("")
  }, [show])

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Disable",
      disabled: current !== "Disable",
      onClick: onConfirm
    }
  }

  return (
    <EntryModal cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body" show={show} title="Confirm Deploy" onHide={onHide} buttons={buttons}>
      <p>Be really sure you want to do this?</p>
      <div>
        <p>Please type <span>Disable</span> to confirm</p>
        <input autoFocus value={current} onChange={(e) => setCurrent(e.target.value)}/>
      </div>
    </EntryModal>
  )
}

const DEFAULT_DEIDENTIFY_DATA = {
  option: null,
  options: [],
  interval: null,
  intervals: [],
}

const copyData = data => {
  if (data == null) {
    return DEFAULT_DEIDENTIFY_DATA
  }

  return {
    option: data.option,
    options: data.options,
    interval: data.interval,
    intervals: data.intervals,
  }
}

function DeIdentifyModal({show, isEdit, data, onHide, onConfirm}) {
  const [current, setCurrent] = useState(() => deepCopy(DEFAULT_DEIDENTIFY_DATA))

  const action = isEdit ? "Update" : "Create"

  useEffect(() => {
    setCurrent(() => deepCopy(show ? copyData(data) : DEFAULT_DEIDENTIFY_DATA))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const toOptionData = () => {
    let option = null
    const options = []

    for (let v of current.options) {
      if (current.option != null && current.option === v.value) {
        option = v
        continue
      }

      options.push(v)
    }

    return {value: option, options}
  }

  const toIntervalData = () => {
    let interval = null
    const intervals = []

    for (let v of current.intervals) {
      if (current.interval != null && current.interval === v.value) {
        interval = v
        continue
      }

      intervals.push(v)
    }

    return {value: interval, options: intervals}
  }

  const handleOnChange = (values) => {
    setCurrent((old) => ({
      ...old,
      ...values
    }))
  }

  const handleOnConfirm = () => {
    if (onConfirm == null) {
      return
    }

    let out = {}
    if (isEdit) {
      if (current.interval !== data.interval) {
        out["interval"] = current.interval
      }

      if (current.option !== data.option) {
        out["option"] = current.option
      }
    } else {
      out = {
        interval: current.interval,
        option: current.option
      }
    }

    onConfirm(out)
  }

  const isButtonDisabled = () => {
    if (isEdit) {
      return current.interval === data.interval && current.option === data.option
    } else {
      return current.interval == null || current.option == null
    }
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: action,
      disabled: isButtonDisabled(),
      onClick: handleOnConfirm
    }
  }

  return (
    <EntryModal show={show} title={`${action} De-Identify Option`} buttons={buttons} onHide={onHide} onConfirm={onConfirm}>
      <div className="school-general-tab">
        <EntrySelector
          label="OPTION"
          onChange={newValue => handleOnChange({"option": newValue.value})}
          {...toOptionData()}
        />
        <EntrySelector
          label="INTERVAL"
          onChange={newValue => handleOnChange({"interval": newValue.value})}
          {...toIntervalData()}
        />
      </div>
    </EntryModal>
  )
}
export const formatString = (value) => {
  if (value == null) {
    return "--"
  }

  if (typeof value === "boolean") {
    return formatBool(value)
  }

  if (typeof value !== "string") {
    value = value.toString()
  }

  return value
}

export const formatBool = (value) => {
  if (value == null) return "--"
  if (typeof value === "number") {
    value = value > 0
  }

  return value ? "TRUE" : "FALSE"
}

const toDate = (value) => {
  let ts

  if (typeof value === "number") {
    ts = new Date(value * 1000)
  } else if (typeof value === "string") {
    ts = new Date(Date.parse(value))
  }

  if (ts === "Invalid Date") {
    return null
  }

  if (value instanceof Date) {
    // assume date object
    ts = value
  }

  return ts
}

export const formatDate = (value) => {
  const ts = toDate(value)
  if (ts) {
    return ts.toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric",
      timeZone: "UTC",
    })
  }

  return formatString(value)
}

export const formatTimestamp = (value) => {
  const ts = toDate(value)
  if (ts) {
    return ts.toLocaleDateString(undefined, {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).replace(",", "")
  }

  return formatString(value)
}

export const formatServerDate = (value) => {
  const ts = toDate(value)
  if (!ts) {
    return null
  }

  const formatValue = v => {
    if (typeof v === "number") {
      v = v.toString()
    }

    if (v.length === 1) {
      v = `0${v}`
    }

    return v
  }

  return `${ts.getUTCFullYear()}-${formatValue(ts.getUTCMonth() + 1)}-${formatValue(ts.getUTCDate())}`
}

const isFloat = (value) => Number(value) === value && value % 1 !== 0

export const formatCurrency = (value) => {
  if (value == null) {
    return formatString(null)
  }

  if (typeof value === "string") {
    return value
  }

  if (typeof value !== "number") {
    return formatString(value)
  }

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(value)
}

export const formatPercentage = (value) => {
  if (value == null) {
    return formatString(null)
  }

  if (typeof value === "string") {
    return value
  }

  if (typeof value !== "number") {
    return formatString(value)
  }

  if (isFloat(value) && value <= 1.0) {
    value *= Math.round(100)
  }

  return `${value}%`
}

export const getInputValue = (data, key) => {
  if (data == null) {
    return ""
  }

  if (key != null) {
    data = data[key]
  }

  if (data == null) {
    return ""
  }

  if (!(typeof data === "string")) {
    throw new Error(`expected 'string' and got '${typeof data}'`)
  }

  return data
}

export const convertArrayToString = value => {
  if (!value?.length) {
    return null
  }

  return value.map(v => v?.toString()).join(", ")
}

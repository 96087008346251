import {isObject} from "@turf/turf";
import {objectEqual} from "./object";

export function arrayEquals(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
  if (arr1 === arr2) return true
  if (arr1 == null || arr2 == null) return false
  if (arr1.length !== arr2.length) return false

  for (let i = 0; i < arr1.length; i++) {
    const v1 = arr1[i]
    const v2 = arr2[i]

    if (typeof v1 !== typeof v2) return false
    if (isObject(v1)) {
      if (!objectEqual(v1, v2)) return false
      else continue
    }

    if (Array.isArray(v1)) {
      if (!arrayEquals(v1, v2)) return false
      else continue
    }

    if (v1 !== v2) return false
  }

  return true
}
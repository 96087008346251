import React, {useEffect, useState} from "react";

import {EntryInput} from "../../../components/modal";
import {AddButton} from "../../../components/buttons";
import {DEBOUNCE_DURATION} from "../../../utils/const";
import {Slider} from "../../../components/slider";

export function Group({divClassName, name, ...props}) {
  const {className, children, ...rest} = props;

  const classNames = ["school-group"]
  if (className != null) classNames.push(className)

  return (
    <div className={classNames.join(" ")} {...rest}>
      <span className="school-group__label">{name}</span>
      <div className={divClassName}>
        {children}
      </div>
    </div>
  )
}

export function SliderGroup({divClassName, name, disabled = false, value, onValueChange, ...props}) {
  const {className, children, ...rest} = props;

  const classNames = ["school-group"]
  if (className != null) classNames.push(className)

  return (
    <div className={classNames.join(" ")} {...rest}>
      <div className="school-group__slider">
        <span className="school-group__label">{name}</span>
        <Slider disabled={disabled} checked={value} onChecked={onValueChange}/>
      </div>
      <div className={divClassName}>
        {children}
      </div>
    </div>
  )
}

export function GroupItem({name, value, editable, type, pattern, onChange, onFocus, onBlur}) {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(value);
  const [autoFocus, setAutoFocus] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setText(value)
  }, [value]);

  useEffect(() => {
    if (value === text) {
      return
    }

    const timer = setTimeout(() => {
      if (value !== text) {
        onChange(text)
      }
    }, DEBOUNCE_DURATION)
    return () => clearTimeout(timer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, text]);

  if (!editable) {
    return (
      <EntryInput label={name} value={value} editable={false} type={type}/>
    )
  }

  const handleOnClick = () => {
    setAutoFocus(true)
    setEditing(true)
  }

  const handleOnFocus = () => {
    setAutoFocus(false)
    setIsFocused(true)
    onFocus && onFocus()
  }

  const handleOnBlur = () => {
    setEditing(false)
    setIsFocused(false)
    onBlur && onBlur()
  }

  const hasValue = !!text && text.length > 0
  if (!hasValue && !editing && !isFocused) {
    return (
      <div className="da-modal-entry-input">
        <span className="da-modal-entry-input-label">{name}</span>
        <div>
          <AddButton className="school-add-button" variant="secondary" onClick={handleOnClick} onFocus={handleOnClick}/>
        </div>
      </div>
    )
  }

  return (
    <EntryInput
      type={type}
      pattern={pattern}
      label={name}
      value={text}
      autoFocus={autoFocus}
      editable={true}
      onChange={setText}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
    />
  )
}

import {createContext, useCallback, useContext, useEffect, useMemo, useReducer} from "react"
import Api from "../../../shared/api/api"
import {LayoutResourceType, TabIds, VisibilityOption, ZoneType} from "../consts"
import {LoadingStatus as LOADING_STATE, LoadingStatus} from "../../../shared/api/loading"
import {featureCollection as FeatureCollection} from "@turf/turf"
import {ErrorToast, SuccessToast} from "../../../shared/utils/toast"
import {deleteAp, deleteZone, getData, postZone, putAp, putZone} from "./network"
import {AttributeSort} from "../../../utils/sort"

import {Zone} from "../../../data/models/zone"

import {ReactComponent as NextIcon} from "../../../shared/assets/icons/next_icon.svg"
import useViewport from "../../../shared/hooks/viewport";
import Warehouse from "../../../data/warehouse";
import WareHouse from "../../../data/warehouse";
import {AccessPoint} from "../../../data/models/ap";
import {QueryArgs, useQuery} from "./query";

export const DataContext = createContext(null)
export const useDataContext = () => useContext(DataContext)

export const DrawMode = {
  CLICK: "click",
  PEN: "pen",
  ANCHOR: "anchor"
}

const DEFAULT_CONTEXT = {
  loading: false,
  search: {
    term: null,
    value: null,
    lowered: null,
    isSearchingProcessing: false,
  },
  filters: {
    hasFilters: false,
    data: {
      status: 0,
      buildingId: null,
      usageIds: [],
      visibility: null
    }
  },
  api_pages: {
    zones: 0,
    aps: 0,
    geojson: 0,
    buildings: 0,
    usages: 0
  },
  listView: {
    opened: []
  },
  zone: {
    index: 0,
    data: []
  },
  newZone: null,
  ap: {
    index: 0,
    data: [],
    stranded: []
  },
  newAp: null,
  zoneTypes: [],
  zoneDraw: {
    mode: null,
    geojson: FeatureCollection([])
  }
}

export const DEFAULT_OPERATING_HOURS = [
  {
    day_of_week: 1,
    start_time: 740,
    end_time: 1020,
  },
  {
    day_of_week: 2,
    start_time: 540,
    end_time: 1020,
  },
  {
    day_of_week: 3,
    start_time: 540,
    end_time: 1020,
  },
  {
    day_of_week: 4,
    start_time: 540,
    end_time: 1020,
  },
  {
    day_of_week: 5,
    start_time: 540,
    end_time: 1020,
  },
  {
    day_of_week: 6,
    start_time: 540,
    end_time: 1020,
  },
  {
    day_of_week: 7,
    start_time: 740,
    end_time: 1020,
  }
]

const buildDefaultZone = (schoolId, options) => {
  return new Zone({
    id: "temporary",
    type: ZoneType.DEFAULT,
    parent: {
      id: schoolId,
      name: "Entire School"
    },
    is_root: false,
    floors: [],
    rooms: [],
    children: [],
    square_footage: {
      calculated: null,
      override: null,
    },
    capacity: {
      calculated: null,
      override: null,
    },
    visibility_option: 1,
    operating_hours: [...DEFAULT_OPERATING_HOURS],
    layout: {
      icon_color: "rgba(238, 91, 91, 1)"
    },
    ...(options || {}),
  })
}

const RESET = "RESET"
const SET_LOADING = "SET_LOADING"

const SET_API_PAGES_LOADING_STATE = "SET_API_PAGES_LOADING_STATE"
const SET_API_PAGES_DATA = "SET_API_PAGES_DATA"

const SET_SEARCH_TERM = "SET_SEARCH_TERM"
const SET_SEARCH_VALUE = "SET_SEARCH_VALUE"
const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS"

const SET_IS_SEARCHING = "SET_IS_SEARCHING"
const SET_SEARCH_DATA = "SET_SEARCH_DATA"

const ADD_ZONE_DATA = "ADD_ZONE_DATA"
const UPDATE_ZONE_DATA = "UPDATE_ZONE_DATA"
const DELETE_ZONE_DATA = "DELETE_ZONE_DATA"
const UPDATE_NEW_ZONE_DATA = "UPDATE_NEW_ZONE_DATA"
const RESET_NEW_ZONE_DATA = "RESET_NEW_ZONE_DATA"
const SET_ZONE_INDEX = "SET_ZONE_INDEX"

const UPDATE_AP_DATA = "UPDATE_AP_DATA"
const DELETE_AP_DATA = "DELETE_AP_DATA"
const UPDATE_NEW_AP_DATA = "UPDATE_NEW_AP_DATA"
const SET_AP_INDEX = "SET_AP_INDEX"

const REPLACE_GEOJSON = "REPLACE_GEOJSON"

const SET_ZONE_DRAW_MODE = "SET_ZONE_DRAW_MODE"
const REPLACE_ZONE_GEOJSON = "REPLACE_ZONE_GEOJSON"

const ADD_LISTVIEW_OPEN = "ADD_LISTVIEW_OPEN"
const REMOVE_LISTVIEW_OPEN = "REMOVE_LISTVIEW_OPEN"
const CLEAR_LISTVIEW_OPEN = "CLEAR_LISTVIEW_OPEN"

const DataProviderReducer = (schoolId, queryParams) => {
  return (state, action) => {
    switch (action.type) {
      case RESET: {
        return DEFAULT_CONTEXT
      }

      case SET_LOADING:
        return {
          ...state,
          loading: action.payload
        }

      case SET_API_PAGES_LOADING_STATE: {
        Warehouse.wireless_config.setLoadingStatus(action.payload)
        return {
          ...state,
          loading: action.payload === LoadingStatus.FETCHING
        }
      }

      case SET_API_PAGES_DATA: {
        return {
          ...state,
          loading: false,
          api_pages: {
            ...state.api_pages,
            ...action.payload.pages
          },
          zone: {
            index: 0,
            data: Warehouse.wireless_config.getZones().filter(z => z.is_root)
          },
          ap: {
            index: 0,
            data: Warehouse.wireless_config.getAps().getAll(),
            stranded: Warehouse.wireless_config.getAps().getAllStranded(),
          },
          geojson: {
            loadingState: LOADING_STATE.FETCHED,
            data: FeatureCollection(action.payload.geojson)
          },
          zoneTypes: action.payload.zoneTypes,
        }
      }

      case ADD_LISTVIEW_OPEN:
        return {
          ...state,
          listView: {
            ...state.listView,
            opened: Array.from(new Set([...state.listView.opened, ...action.payload]))
          }
        }

      case REMOVE_LISTVIEW_OPEN:
        return {
          ...state,
          listView: {
            ...state.listView,
            opened: state.listView.opened.filter(v => !action.payload.includes(v))
          }
        }

      case CLEAR_LISTVIEW_OPEN:
        return {
          ...state,
          listView: {
            ...state.listView,
            opened: []
          }
        }

      case SET_SEARCH_TERM:
        return {
          ...state,
          search: {
            ...state.search,
            term: action.payload
          }
        }

      case SET_SEARCH_VALUE: {
        const lowered = state.search.term ? state.search.term.toLowerCase() : null

        return {
          ...state,
          search: {
            ...state.search,
            value: state.search.term,
            lowered
          }
        }
      }

      case SET_IS_SEARCHING:
        return {
          ...state,
          search: {
            ...state.search,
            isSearchingProcessing: action.payload
          }
        }

      case SET_SEARCH_DATA:
        return {
          ...state,
          search: {
            ...state.search,
            isSearchingProcessing: false
          },
          zone: {
            ...state.zone,
            data: action.payload.zone
          },
          ap: {
            ...state.ap,
            data: action.payload.ap.aps,
            stranded: action.payload.ap.stranded
          }
        }

      case SET_FILTER_OPTIONS:
        return {
          ...state,
          filters: {
            hasFilters: isFilterDifferent(DEFAULT_CONTEXT.filters.data, action.payload),
            data: action.payload
          }
        }

      case ADD_ZONE_DATA:
      case UPDATE_ZONE_DATA:
      case DELETE_ZONE_DATA:
      case UPDATE_AP_DATA:
      case DELETE_AP_DATA:
        return {
          ...state,
          loading: false,
          zoneDraw: {
            mode: null,
            geojson: FeatureCollection([])
          }
        }

      case UPDATE_NEW_ZONE_DATA: {
        const newData = {}

        if (action.payload.name !== undefined && state.newZone?.type === ZoneType.BUILDING) {
          newData.aliases = [action.payload.name]
        }

        if (action.payload.parent_id !== undefined) {
          newData.parent = action.payload.parent_id ? Warehouse.wireless_config.getZones().getById(action.payload.parent_id) : null
        }

        if (action.payload.primary_usage_id !== undefined) {
          newData.primary_usage = action.payload.primary_usage_id ? Warehouse.wireless_config.getUsages().getById(action.payload.primary_usage_id) : null
          newData.secondary_usage = null
        }

        if (action.payload.secondary_usage_id !== undefined) {
          newData.secondary_usage = action.payload.secondary_usage_id ? Warehouse.wireless_config.getUsages().getById(action.payload.secondary_usage_id) : null
        }

        if (action.payload.building_id !== undefined) {
          newData.building = action.payload.building_id ? Warehouse.wireless_config.getBuildings().getById(action.payload.building_id) : null
        }

        if (action.payload.square_footage !== undefined) {
          const squareFootage = typeof action.payload.square_footage === "number" ? action.payload.square_footage : action.payload.square_footage.override
          newData.square_footage = {
            ...state.newZone?.square_footage,
            override: squareFootage,
          }
        }

        if (action.payload.capacity !== undefined) {
          const capacity = typeof action.payload.capacity === "number" ? action.payload.capacity : action.payload.capacity.override
          newData.capacity = {
            ...state.newZone?.capacity,
            override: capacity,
          }
        }

        Object.entries(action.payload).forEach(([key, value]) => {
          switch (key) {
            case "primary_usage_id":
            case "secondary_usage_id":
            case "building_id":
            case "square_footage":
            case "capacity":
              break

            default:
              newData[key] = value
          }
        })

        const additionalArgs = {}

        const parentId = newData?.parent?.id || state.newZone?.parent?.id
        if (action.payload.inherit_geojson && parentId) {
          const existingGeoJSON = Warehouse.wireless_config.getGeoJSON().getFeatures().filter(feature => feature.properties.id === parentId && feature.properties.type === "zone")
            .reduce((accumulator, value) => value, null)

          if (existingGeoJSON) {
            newData.has_geojson = true

            additionalArgs.zoneDraw = {
              ...state.zoneDraw,
              geojson: FeatureCollection([{...existingGeoJSON, properties: {type: "zone", isCompleted: true}}])
            }
          }
        }

        const newZone = !state.newZone ? new Zone(newData) : state.newZone.copy(newData)

        return {
          ...state,
          newZone,
          ...additionalArgs,
        }
      }

      case RESET_NEW_ZONE_DATA:
        return {
          ...state,
          loading: false,
          newZone: buildDefaultZone(),
          zoneDraw: {
            mode: null,
            geojson: FeatureCollection([])
          }
        }

      case SET_ZONE_INDEX:
        return {
          ...state,
          zone: {
            ...state.zone,
            index: action.payload
          }
        }

      case UPDATE_NEW_AP_DATA:
        return {
          ...state
        }

      case SET_AP_INDEX:
        return {
          ...state,
          ap: {
            ...state.ap,
            index: action.payload
          }
        }

      case REPLACE_GEOJSON: {
        const featureIds = new Set(action.payload.map(f => f.properties.id))
        const newFeatures = [...Warehouse.wireless_config.getGeoJSON().getFeatures().filter(f => !featureIds.has(f.properties.id)), ...action.payload]
        Warehouse.wireless_config.getGeoJSON().setAll(schoolId, newFeatures)

        return {
          ...state,
          loading: false,
          zoneDraw: {
            mode: null,
            geojson: FeatureCollection([])
          }
        }
      }

      case SET_ZONE_DRAW_MODE: {
        let args = {}
        let newDataArgs = {}

        if (action.payload == null || action.options?.clear) {
          args = {
            geojson: FeatureCollection([])
          }
          newDataArgs.has_geojson = false
        } else if (queryParams.resourceId && !state.zoneDraw.geojson.features.length) {
          let geojson = Warehouse.wireless_config.getGeoJSON().getFeatures().filter(f => f.properties.id === queryParams.resourceId)
            .reduce((accumulator, value) => value, null)

          if (geojson) {
            geojson = {
              ...Object.assign({}, geojson),
              properties: {
                ...geojson.properties,
                isCompleted: true
              }
            }

            args = {
              geojson: FeatureCollection([geojson])
            }
            newDataArgs.has_geojson = true
          }
        }

        const newZone = !Object.keys(newDataArgs) ? state.newZone : (state.newZone || buildDefaultZone()).copy(newDataArgs)

        return {
          ...state,
          newZone,
          zoneDraw: {
            ...state.zoneDraw,
            mode: action.payload,
            ...args
          }
        }
      }

      case REPLACE_ZONE_GEOJSON:
        return {
          ...state,
          zoneDraw: {
            ...state.zoneDraw,
            geojson: {type: "FeatureCollection", features: action.payload}
          }
        }

      default:
        throw new Error(`Unknown action type ${action.type}`)
    }
  }
}

export const useDataProvider = ({schoolId}) => {
  const [queryParams, queryActions] = useQuery()
  const {scrollIntoViewIfNeeded} = useViewport()

  const hasDetailResource = queryParams.tab === TabIds.LAYOUT && (queryParams.resourceId != null || queryParams.isCreating)
  const isHidingRightPane = queryParams.pane.right || !hasDetailResource
  const isCreatingZone = queryParams.isCreating && queryParams.resourceType === LayoutResourceType.ZONE
  const isCreatingAp = queryParams.isCreating && queryParams.resourceType === LayoutResourceType.AP

  const [state, dispatch] = useReducer(DataProviderReducer(schoolId, queryParams), DEFAULT_CONTEXT)

  const setLoading = useCallback(loading => dispatch({type: SET_LOADING, payload: loading}), [])
  const setApiPagesData = useCallback(data => dispatch({type: SET_API_PAGES_DATA, payload: data}), [])
  const setZoneIndex = useCallback(index => dispatch({type: SET_ZONE_INDEX, payload: index}), [])
  const setApIndex = useCallback(index => dispatch({type: SET_AP_INDEX, payload: index}), [])
  const setDrawMode = useCallback((mode = null, options = null) => dispatch({type: SET_ZONE_DRAW_MODE, options, payload: mode}), [])

  const setTabQuery = queryActions.setTab
  const setIsCreatingQuery = queryActions.setIsCreating
  const setZoneQuery = queryActions.setZone
  const setApQuery = queryActions.setAp
  const setPaneShowQuery = queryActions.setPaneShow

  const allZones = Warehouse.wireless_config.getZones().getAll()
  const allAps = Warehouse.wireless_config.getAps().getAll()
  const strandedAps = Warehouse.wireless_config.getAps().getAllStranded()
  const utilization = Warehouse.wireless_config.getUtilization()
  const utilizationsByType = Warehouse.wireless_config.getUtilizationDefaults()
  const getUtilizationForType = useCallback(type => {
    if (!type) {
      return utilizationsByType.default
    }

    const out = utilizationsByType[type]
    if (!out) {
      return utilizationsByType.default
    }

    return out
  }, [utilizationsByType])

  let selectedResource = null
  // let selectedZone = null
  // let selectedAp = null

  useEffect(() => {
    const handler = () => {
      dispatch({type: RESET})
      setTabQuery(queryParams.tab)
    }

    WareHouse.addSchoolChangeListener(handler)
    return () => {
      WareHouse.removeSchoolChangeListener(handler)
    }
  }, [queryParams.tab, setTabQuery]);

  useEffect(() => {
    if (!state.search.term) {
      dispatch({type: SET_SEARCH_VALUE})
      return
    }

    let timer = setTimeout(() => {
      dispatch({type: SET_SEARCH_VALUE})
    }, 450)

    return () => {
      clearTimeout(timer)
    }
  }, [state.search.term]);

  const setSelectedZone = useCallback(zone => {
    if (queryParams.isCreating) {
      // todo: we need to display a modal or something to confirm the switch
    }

    const zoneId = typeof zone === "string" ? zone : zone?.id
    if (queryParams.resourceType === LayoutResourceType.ZONE && queryParams.resourceId === zoneId) {
      return
    }

    setZoneQuery(zoneId)
    setDrawMode()
  }, [queryParams.isCreating, queryParams.resourceId, queryParams.resourceType, setDrawMode, setZoneQuery])

  const setNewZone = useCallback((options) => {
    const zoneData = buildDefaultZone(schoolId, options)
    if (!options) {
      const previousZoneId = queryParams.previousResourceId
      setZoneQuery(previousZoneId)
    } else {
      const {parent, type} = options

      zoneData.type = type

      if (parent) {
        // todo: adjust this
        zoneData.parentId = parent.id
        zoneData.buildingId = parent.buildingId
        zoneData.floors = parent.floors
        zoneData.primaryUsageId = parent.primaryUsageId
        zoneData.secondaryUsageId = parent.secondaryUsageId
      }

      zoneData.utilization = getUtilizationForType(type)

      if (!zoneData.hasParent() || zoneData.parentId === schoolId) {
        zoneData.is_root = true
      }

      if (options.type === ZoneType.FLOOR) {
        const floor = zoneData.floors.map(floor => Number.parseInt(floor)).filter(floor => Number.isInteger(floor))
          .reduce((acc, floor) => Math.max(acc, floor), 0) + 1

        if (zoneData.hasParent()) {
          zoneData.name = `${zoneData.getParent().name} FL ${floor}`
        }
        zoneData.floors = [floor.toString()]
        zoneData.inherit_geojson = true
      }

      const queryOptions = {[QueryArgs.RESOURCE_TYPE]: LayoutResourceType.ZONE}
      if (parent?.isSelectable()) {
        queryOptions[QueryArgs.PREVIOUS_RESOURCE_ID] = zoneData.parentId
      }

      setIsCreatingQuery(true, queryOptions)
    }

    dispatch({type: UPDATE_NEW_ZONE_DATA, payload: zoneData.raw()})

    if (zoneData.parentId && zoneData.inherit_geojson) {
      let geojson = Warehouse.wireless_config.getGeoJSON().getFeatures().filter(f => f.properties.id === zoneData.parentId)
        .reduce((acc, f) => f, null)

      if (geojson) {
        geojson = Object.assign({}, geojson, {properties: {type: "zone", isCompleted: true}})
        dispatch({type: REPLACE_ZONE_GEOJSON, payload: [geojson]})
      }
    }
  }, [schoolId, queryParams.previousResourceId, setZoneQuery, getUtilizationForType, setIsCreatingQuery])

  useEffect(() => {
    if (!queryParams.resourceType || !queryParams.resourceId) {
      return
    }

    const timer = setTimeout(() => {
      scrollIntoViewIfNeeded(queryParams.resourceId)
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [queryParams.resourceType, queryParams.resourceId, scrollIntoViewIfNeeded]);

  const updateSelectedZone = useCallback(({body, onSuccess, onError}) => {
    if (queryParams.resourceType !== LayoutResourceType.ZONE) {
      return
    }

    if (queryParams.isCreating) {
      dispatch({type: UPDATE_NEW_ZONE_DATA, payload: body})
      onSuccess && onSuccess()
      return
    }

    if (!queryParams?.resourceId) {
      return
    }

    if (body.name != null) {
      body.name = body.name.trim()
    }

    if (body.description != null) {
      body.description = body.description.trim()
    }

    setLoading(true)
    putZone(schoolId, Warehouse.wireless_config.getZones().getById(queryParams.resourceId), body)
      .then((result) => {
        dispatch({type: UPDATE_ZONE_DATA, payload: result})
        onSuccess && onSuccess()
        SuccessToast("Zone successfully updated")
      })
      .catch(e => {
        setLoading(false)
        onError && onError(e)
      })
  }, [queryParams, setLoading, schoolId])

  const deleteSelectedZone = useCallback((callback) => {
    if (queryParams.resourceType !== LayoutResourceType.ZONE && !queryParams.resourceId) {
      return
    }

    const zone = Warehouse.wireless_config.getZones().getById(queryParams.resourceId)

    setLoading(true)
    deleteZone(schoolId, queryParams.resourceId)
      .then(result => {

        setZoneQuery(zone?.parentId)
        dispatch({type: DELETE_ZONE_DATA, payload: result})
        SuccessToast("Zone deleted")
        callback && callback()
      })
  }, [queryParams, setLoading, schoolId, setZoneQuery])

  const setSelectedAp = useCallback(ap => {
    if (queryParams.isCreating) {
      // todo: we need to display a modal or something to confirm the switch
    }

    const apId = typeof ap === "string" ? ap : ap?.id
    if (queryParams.resourceType === LayoutResourceType.AP && queryParams.resourceId === apId) {
      return
    }

    setApQuery(apId)
  }, [queryParams.isCreating, queryParams.resourceType, queryParams.resourceId, setApQuery])


  const isSearching = state.search.term !== null
  // search zones
  useEffect(() => {
    const filterText = state.search.lowered

    const filterZones = new Promise((resolve, reject) => {
      try {
        const zoneFilter = (zone) => {
          const isZoneComplete = zone.isComplete()

          if (state.filters.hasFilters) {
            if (state.filters.data.buildingId && (!zone.getBuilding()?.id || zone.getBuilding()?.id !== state.filters.data.buildingId)) {
              return false
            }

            if (state.filters.data.usageIds.length) {
              const usageIds = [zone.getPrimaryUsage()?.id, zone.getSecondaryUsage()?.id].filter(id => id && state.filters.data.usageIds.includes(id))
              if (!usageIds.length) {
                return false
              }
            }

            // todo: make the magic numbers a struct
            if (state.filters.data.status === 1 && !isZoneComplete) {
              return false
            }

            if (state.filters.data.status === 2 && isZoneComplete) {
              return false
            }

            if (state.filters.data.visibility != null && zone.visibility_option !== state.filters.data.visibility) {
              return false
            }
          }

          if (filterText) {
            return zone.name.toLowerCase().includes(filterText)
          }

          if (!filterText && !state.filters.hasFilters) {
            return zone.is_root
          }

          return true
        }

        const zoneIds = allZones.filter(zoneFilter)
          .map(z => z.id)

        resolve(zoneIds)
      } catch (error) {
        reject(error)
      }
    })

    const filterAps = new Promise((resolve, reject) => {
      try {
        const apFilter = (ap) => {
          const isApComplete = ap.isComplete()

          if (state.filters.hasFilters) {
            if (state.filters.data.buildingId && (!ap.getBuilding()?.id || ap.getBuilding()?.id !== state.filters.data.buildingId)) {
              return false
            }

            if (state.filters.data.status === 1 && !isApComplete) {
              return false
            }

            if (state.filters.data.status === 2 && isApComplete) {
              return false
            }

            if (state.filters.data.usageIds.length || state.filters.data.visibility != null) {
              return false
            }
          }

          if (!filterText) return true
          return ap.name.toLowerCase().includes(filterText)
        }

        const apData = allAps.filter(apFilter)
        const strandedApData = strandedAps.filter(apFilter)

        const uniqueApIds = new Set()
        const uniqueZoneIds = new Set()

        for (let ap of apData) {
          uniqueApIds.add(ap.id)
          const floorZone = ap.getFloorZone()
          if (floorZone) {
            uniqueZoneIds.add(floorZone.id)
          }
        }

        for (let ap of strandedApData) {
          uniqueApIds.add(ap.id)
        }

        resolve({aps: apData, stranded: strandedApData, apIds: Array.from(uniqueApIds), zoneIds: Array.from(uniqueZoneIds)})
      } catch (error) {
        reject(error)
      }
    })

    const setIsSearching = searching => dispatch({type: SET_IS_SEARCHING, payload: searching})

    async function processSearch(response) {
      if (isCancelled) {
        return
      }

      const [zoneInfo, apInfo] = response

      const zoneIds = Array.from(new Set([...zoneInfo, ...apInfo.zoneIds]))
        .flatMap(zoneId => [zoneId, ...Warehouse.wireless_config.getZones().getById(zoneId).parentIter().map(p => p.id)])

      if (!filterText && !state.filters.hasFilters) {
        Warehouse.wireless_config.setResourceLimiter()
      } else {
        Warehouse.wireless_config.setResourceLimiter(zoneIds, apInfo.apIds)
      }

      const zones = Array.from(new Set(zoneIds))
        .map(id => Warehouse.wireless_config.getZones().getById(id))
        .filter(zone => zone?.is_root)
        .sort(AttributeSort("name"))

      dispatch({type: SET_SEARCH_DATA, payload: {zone: zones, ap: apInfo}})
    }

    let isCancelled = false
    setIsSearching(true)
    Promise.all([filterZones, filterAps])
      .then(processSearch)
      .catch(e => {
        setIsSearching(false)
        console.error(e)
      })

    return () => {
      setIsSearching(false)
      isCancelled = true
    }
  }, [schoolId, state.search.lowered, state.filters, allZones, allAps, strandedAps])

  useEffect(() => {
    if (schoolId == null || Warehouse.wireless_config.getLoadingStatus() !== LoadingStatus.NONE) {
      return
    }

    setLoading(true)
    Warehouse.wireless_config.setLoadingStatus(LoadingStatus.FETCHING)
    getData(schoolId)
      .then(response => {
        Warehouse.wireless_config.setData(schoolId, response)
        setApiPagesData(response)
      })
      .catch(e => {
        console.error(e.error)
        Warehouse.wireless_config.setLoadingStatus(LoadingStatus.ERROR)
        setLoading(false)
        ErrorToast("Unable to load")
      })
  }, [schoolId, setLoading, setApiPagesData, queryParams.tab]);

  useEffect(() => {
    if (queryParams.tab === TabIds.LAYOUT && queryParams.resourceType !== LayoutResourceType.ZONE) {
      return
    }

    if (!queryParams.resourceId) {
      setZoneIndex(null)
    } else {
      const zone = Warehouse.wireless_config.getZones().getById(queryParams.resourceId)
      let newSelectedIndex = null
      if (zone) {
        newSelectedIndex = isSearching || zone.is_root ? allZones.findIndex(z => z.id === zone.id) : zone.getParent()?.getSubZones().findIndex(z => z.id === zone.id)
      }

      if (newSelectedIndex == null || newSelectedIndex < 0) {
        setZoneIndex(null)
      } else if (newSelectedIndex !== state.zone.index) {
        setZoneIndex(newSelectedIndex)
      }
    }
  }, [queryParams, setZoneIndex, allZones, state.zone.index, isSearching])

  useEffect(() => {
    if (queryParams.tab === TabIds.LAYOUT && queryParams.resourceType !== LayoutResourceType.AP) {
      return
    }

    if (!queryParams.resourceId) {
      setApIndex(null)
    } else {
      const newSelectedIndex = allAps.findIndex(ap => ap.id === queryParams.resourceId)
      if (newSelectedIndex < 0) {
        setApIndex(null)
      } else if (newSelectedIndex !== state.ap.index) {
        setApIndex(newSelectedIndex)
      }
    }
  }, [queryParams, setApIndex, allAps, state.ap.index])

  const draw = useMemo(() => {
    if (queryParams.tab === TabIds.LAYOUT && queryParams.resourceType === LayoutResourceType.ZONE) {
      return {
        enabled: state.zoneDraw.mode != null,
        mode: state.zoneDraw.mode,
        hasAny: state.zoneDraw.geojson.features.length > 0,
        hasData: state.zoneDraw.geojson.features.filter(filterZoneGeoJSON).length > 0,
        geojson: state.zoneDraw.geojson,

        setMode: setDrawMode,
        replace: (feature) => dispatch({type: REPLACE_ZONE_GEOJSON, payload: feature}),
        clear: () => dispatch({type: REPLACE_ZONE_GEOJSON, payload: []})
      }
    }

    if (queryParams.tab === TabIds.LAYOUT && queryParams.resourceType === LayoutResourceType.AP) {
      return {
        enabled: state.zoneDraw.mode != null,
        mode: state.zoneDraw.mode,
        hasAny: state.zoneDraw.geojson.features.length > 0,
        hasData: state.zoneDraw.geojson.features.filter(filterApGeoJSON).length > 0,
        geojson: state.zoneDraw.geojson,

        setMode: setDrawMode,
        replace: (feature) => dispatch({type: REPLACE_ZONE_GEOJSON, payload: feature}),
        clear: () => dispatch({type: REPLACE_ZONE_GEOJSON, payload: []})
      }
    }

    return {
      enabled: false,
      mode: state.zoneDraw.mode,
      hasAny: false,
      hasData: false,
      geojson: state.zoneDraw.geojson,

      setMode: setDrawMode,
      replace: (feature) => dispatch({type: REPLACE_ZONE_GEOJSON, payload: feature}),
      clear: () => dispatch({type: REPLACE_ZONE_GEOJSON, payload: []})
    }
  }, [queryParams.tab, queryParams.resourceType, state.zoneDraw.mode, state.zoneDraw.geojson, setDrawMode])

  useEffect(() => {
    if (!(queryParams.tab === TabIds.LAYOUT && queryParams.isCreating && !state.newZone && !state.newAp)) {
      return
    }

    const timer = setTimeout(() => setIsCreatingQuery(false), 300)
    return () => clearTimeout(timer)
  }, [queryParams.isCreating, queryParams.tab, setIsCreatingQuery, state.newAp, state.newZone]);

  if (queryParams.tab === TabIds.LAYOUT) {
    if (queryParams.resourceType === LayoutResourceType.ZONE) {
      if (queryParams.isCreating && !state.newZone) {
        // pass.  handled in the useEffect
      } else if (queryParams.isCreating && state.newZone) {
        selectedResource = state.newZone
      } else {
        selectedResource = Warehouse.wireless_config.getZones().getById(queryParams.resourceId)
      }
    }

    if (queryParams.resourceType === LayoutResourceType.AP) {
      if (queryParams.isCreating && !state.newAp) {
        // pass.  handled in the useEffect
      } else if (queryParams.isCreating && state.newAp) {
        selectedResource = state.newAp
      } else {
        selectedResource = Warehouse.wireless_config.getAps().getById(queryParams.resourceId)
      }
    }
  }

  return {
    loading: state.loading,

    schoolId,
    schoolZone: Warehouse.wireless_config.getZones().getById(schoolId),
    selectedTab: queryParams.tab,
    selectedResource,
    selectedResourceType: queryParams.resourceType,
    listViewOpen: state.listView.opened,
    addListViewOpenResources: useCallback(() => {
      const ids = []
      const selectedZone = queryParams.resourceType === LayoutResourceType.ZONE ? Warehouse.wireless_config.getZones().getById(queryParams.resourceId) : null
      const selectedAp = queryParams.resourceType === LayoutResourceType.AP ? Warehouse.wireless_config.getAps().getById(queryParams.resourceId) : null

      if (selectedZone) {
        for (let zone of selectedZone.parentIter()) {
          ids.push(zone.id)
          if (zone.isFloorZone()) {
            ids.push(`zone-${zone.id}`)
          }
        }
      }

      if (selectedAp) {
        ids.push(selectedAp.id)

        const floorZone = selectedAp.getFloorZone()
        if (floorZone) {
          ids.push(`ap-${floorZone.id}`)
          ids.push(floorZone.id)
          for (let zone of floorZone.parentIter()) {
            ids.push(zone.id)
          }
        }
      }

      dispatch({type: ADD_LISTVIEW_OPEN, payload: ids})
    }, [queryParams]),
    clearListViewOpenResources: useCallback(() => {
      dispatch({type: CLEAR_LISTVIEW_OPEN})
    }, []),
    toggleListViewOpenResource: useCallback((resource) => {
      const key = state.listView.opened.includes(resource.id) ? REMOVE_LISTVIEW_OPEN : ADD_LISTVIEW_OPEN
      dispatch({type: key, payload: [resource.id]})
    }, [state.listView.opened]),
    setTab: useCallback(tab => {
      if (tab?.id === queryParams.tab) {
        return
      }

      const options = {}
      if (queryParams.pane.left) {
        options[QueryArgs.PANE.HIDING_LEFT] = queryParams.pane.left
      }

      if (queryParams.pane.right) {
        options[QueryArgs.PANE.HIDING_RIGHT] = queryParams.pane.right
      }

      setTabQuery(tab?.id, options)
    }, [queryParams.pane.left, queryParams.pane.right, queryParams.tab, setTabQuery]),

    hasDetailResource,

    search: state.search.term,
    isSearching: state.search.term != null,
    isSearchingProcessing: state.search.isSearchingProcessing,
    onSearch: useCallback(value => dispatch({type: SET_SEARCH_TERM, payload: value}), []),

    filters: state.filters,
    setFilters: useCallback(filters => dispatch({type: SET_FILTER_OPTIONS, payload: filters}), []),
    clearFilters: useCallback(() => dispatch({type: SET_FILTER_OPTIONS, payload: DEFAULT_CONTEXT.filters.data}), []),

    isCreatingResource: queryParams.isCreating,
    isCreatingZone,
    isCreatingAp,

    selectedZone: queryParams.resourceType === LayoutResourceType.ZONE ? selectedResource : null,
    zones: state.zone.data,
    loadingState: Warehouse.wireless_config.getLoadingStatus(),
    setSelectedResource: useCallback(resource => {
      const selectedZone = queryParams.resourceType === LayoutResourceType.ZONE ? Warehouse.wireless_config.getZones().getById(queryParams.resourceId) : null
      const selectedAp = queryParams.resourceType === LayoutResourceType.AP ? Warehouse.wireless_config.getAps().getById(queryParams.resourceId) : null

      if ((!resource && selectedZone) || resource instanceof Zone) {
        setSelectedZone(resource)
      } else if ((!resource && selectedAp) || resource instanceof AccessPoint) {
        setSelectedAp(resource)
      } else {
        throw new Error(`unknown data type [${resource}]`)
      }
    }, [queryParams, setSelectedAp, setSelectedZone]),
    setSelectedZone,
    setNewZone,
    resetNewZone: useCallback(() => {
      dispatch({type: RESET_NEW_ZONE_DATA})
      setIsCreatingQuery(false)
    }, [setIsCreatingQuery]),
    saveNewZone: useCallback(() => {
      const data = state.newZone
      const body = {
        name: data.name.trim(),
        description: data.description?.trim(),
        floors: data.floors,
        rooms: data.rooms,
        type: data.type,
        building_id: data.buildingId,
        primary_usage_id: data.primaryUsageId,
        secondary_usage_id: data.secondaryUsageId,
        parent_id: data.getParent()?.id || schoolId,
        inherit_geojson: data.inherit_geojson,
        geojson: state.zoneDraw.geojson?.features[0]?.geometry ? {type: "Feature", geometry: state.zoneDraw.geojson?.features[0].geometry} : null,
        square_footage: data.square_footage?.override,
        capacity: data.capacity?.override,
        utilization: data.utilization || 1,
        visibility_option: data.visibility_option != null ? data.visibility_option : VisibilityOption.VISIBLE.id,
      }

      if (data.type === ZoneType.BUILDING) {
        body.aliases = data.aliases
        body.operating_hours = data.operatingHours

        if (data.maintenance?.yearly != null) {
          body.yearly_maintenance_dollars = data.maintenance.yearly
        }

        if (data.maintenance?.deferred != null) {
          body.deferred_maintenance_dollars = data.maintenance.deferred
        }
      }

      setLoading(true)

      postZone(schoolId, body, state.zone.lookup).then((result) => {
        dispatch({type: ADD_ZONE_DATA, payload: result})
        setZoneQuery(result.zoneId)
      })
        .catch(e => {
          setLoading(false)

          if (e.handled) return

          console.error(e.error)
          ErrorToast(e.error?.response?.data?.error?.message || "Unable to Create Zone")
        })
    }, [state.newZone, state.zoneDraw.geojson?.features, state.zone.lookup, schoolId, setLoading, setZoneQuery]),
    updateZone: updateSelectedZone,
    deleteZone: deleteSelectedZone,

    selectedAp: queryParams.resourceType === LayoutResourceType.AP ? selectedResource : null,
    aps: state.ap.data,
    strandedAps: state.ap.stranded,
    apLoadingState: state.ap.loadingState,
    setSelectedAp,

    updateAp: useCallback(({body, onSuccess, onError}) => {
      if (queryParams.tab !== TabIds.LAYOUT || queryParams.resourceType !== LayoutResourceType.AP) {
        return
      }

      if (queryParams.isCreating) {
        dispatch({type: UPDATE_NEW_AP_DATA, payload: body})
        onSuccess && onSuccess()
        return
      }

      if (!queryParams.resourceId) {
        return
      }

      setLoading(true)
      putAp(schoolId, Warehouse.wireless_config.getAps().getById(queryParams.resourceId), body)
        .then((result) => {
          dispatch({type: UPDATE_AP_DATA, payload: result})
          onSuccess && onSuccess()
          SuccessToast("Ap successfully updated")
        })
        .catch(e => {
          setLoading(false)
          onError && onError(e)
        })
    }, [queryParams, setLoading, schoolId]),
    deleteAp: useCallback((callback) => {
      if (queryParams.tab !== TabIds.LAYOUT || queryParams.resourceType !== LayoutResourceType.AP || !queryParams.resourceId) {
        return
      }

      setLoading(true)
      deleteAp(schoolId, queryParams.resourceId)
        .then(result => {
          setApQuery(Warehouse.wireless_config.getAps().getById(queryParams.resourceId)?.getParent?.id)
          dispatch({type: DELETE_AP_DATA, payload: result})
          SuccessToast("Ap deleted")
          callback && callback()
        })
    }, [queryParams, schoolId, setApQuery, setLoading]),

    cycleDatasource: useMemo(() => {
      if (queryParams.tab !== TabIds.LAYOUT || !queryParams.resourceType) {
        return null
      }

      const selectedZone = queryParams.resourceType === LayoutResourceType.ZONE ? Warehouse.wireless_config.getZones().getById(queryParams.resourceId) : null

      switch (queryParams.resourceType) {
        case LayoutResourceType.ZONE: {
          let leftDisabled, verticalDisabled, rightDisabled

          if (isSearching) {
            leftDisabled = rightDisabled = true
            verticalDisabled = allZones.length <= 1
          } else {
            leftDisabled = !selectedZone?.hasParent()
            rightDisabled = !selectedZone?.hasSubZones()
            verticalDisabled = selectedZone?.is_root ? allZones.length <= 1 : selectedZone?.getParent()?.getSubZones().length <= 1
          }


          const up = {
            id: "up",
            disabled: leftDisabled,
            icon: <NextIcon style={{transform: "rotate(180deg)"}}/>,
            onClick: () => {
              if (leftDisabled) {
                return
              }

              setSelectedZone(selectedZone.getParent())
            }
          }

          const previous = {
            id: "previous",
            disabled: verticalDisabled,
            icon: <NextIcon style={{transform: "rotate(-90deg)"}}/>,
            onClick: () => {
              if (verticalDisabled) {
                return
              }

              const zones = isSearching || selectedZone.is_root ? allZones : selectedZone.getParent().getSubZones()

              let nextIndex = state.zone.index - 1
              if (nextIndex < 0) {
                nextIndex = zones.length - 1
              }

              setSelectedZone(zones[nextIndex])
            }
          }

          const next = {
            id: "next",
            disabled: verticalDisabled,
            icon: <NextIcon style={{transform: "rotate(90deg)"}}/>,
            onClick: () => {
              if (verticalDisabled) {
                return
              }

              const zones = isSearching || selectedZone.is_root ? allZones : selectedZone.getParent().getSubZones()

              let nextIndex = state.zone.index + 1
              if (nextIndex >= zones.length) {
                nextIndex = 0
              }

              setSelectedZone(zones[nextIndex])
            }
          }

          const down = {
            id: "down",
            disabled: rightDisabled,
            icon: <NextIcon/>,
            onClick: () => {
              if (rightDisabled) {
                return
              }

              setSelectedZone(selectedZone.getSubZones()[0])
            }
          }

          return {
            up,
            previous,
            next,
            down,

            icons: [
              up,
              previous,
              next,
              down,
            ],
          }
        }

        case LayoutResourceType.AP: {
          const previous = {
            id: "previous",
            disabled: allAps.length <= 1,
            icon: <NextIcon style={{transform: "rotate(-90deg)"}}/>,
            onClick: () => {
              let nextIndex = state.ap.index - 1
              if (nextIndex < 0) {
                nextIndex = allAps.length - 1
              }

              setSelectedAp(allAps[nextIndex])
            }
          }

          const next = {
            id: "next",
            disabled: allAps.length <= 1,
            icon: <NextIcon style={{transform: "rotate(90deg)"}}/>,
            onClick: () => {
              let nextIndex = state.ap.index + 1
              if (nextIndex >= allAps.length) {
                nextIndex = 0
              }

              setSelectedAp(allAps[nextIndex])
            }
          }

          return {
            previous,
            next,

            icons: [
              previous,
              next
            ],
          }
        }

        default:
          throw new Error(`Unknown tab type ${queryParams.resourceType}`)
      }
    }, [queryParams, isSearching, allZones, setSelectedZone, state.zone.index, state.ap.index, allAps, setSelectedAp]),

    zoneTypes: state.zoneTypes,
    utilization,

    isHidingLeftPane: queryParams.pane.left,
    toggleLeftPane: useCallback(() => {
      setPaneShowQuery(QueryArgs.PANE.HIDING_LEFT, !queryParams.pane.left)
    }, [queryParams.pane.left, setPaneShowQuery]),

    isHidingRightPane,
    toggleRightPane: useCallback(() => {
      setPaneShowQuery(QueryArgs.PANE.HIDING_RIGHT, !queryParams.pane.right)
    }, [queryParams.pane.right, setPaneShowQuery]),

    geojson: Warehouse.wireless_config.getGeoJSON().getAll(),
    updateGeoJSON: useCallback(() => {
      if (queryParams.tab === TabIds.LAYOUT && queryParams.resourceType === LayoutResourceType.ZONE) {
        const geoJSON = state.zoneDraw.geojson.features.filter(filterZoneGeoJSON)
        if (geoJSON.length !== 1) {
          ErrorToast("Invalid Zone GeoJSON")
          return
        }

        const body = {
          type: "Feature",
          geometry: geoJSON[0].geometry
        }

        setLoading(true)
        Api.put(`schools/${schoolId}/wireless/zones/${queryParams.resourceId}/geojson`, body)
          .then(response => dispatch({type: REPLACE_GEOJSON, payload: response.data.geojson}))
          .catch(e => {
            setLoading(false)

            if (e.handled) return

            console.error(e.error)
            ErrorToast("Unable to Update Zone GeoJSON")
          })
      } else if (queryParams.tab === TabIds.LAYOUT && queryParams.resourceType === LayoutResourceType.AP) {
        const geoJSON = state.zoneDraw.geojson.features.filter(filterApGeoJSON)
        if (geoJSON.length !== 1) {
          ErrorToast("Invalid Ap GeoJSON")
          return
        }

        const body = {
          longitude: geoJSON[0].geometry.coordinates[0],
          latitude: geoJSON[0].geometry.coordinates[1],
        }

        setLoading(true)
        Api.put(`schools/${schoolId}/wireless/aps/${queryParams.resourceId}`, body)
          .then(response => dispatch({type: REPLACE_GEOJSON, payload: [response.data.geojson]}))
          .catch(e => {
            setLoading(false)

            if (e.handled) return

            console.error(e.error)
            ErrorToast("Unable to Update Zone GeoJSON")
          })
      }
    }, [queryParams.tab, queryParams.resourceType, queryParams.resourceId, state.zoneDraw.geojson.features, setLoading, schoolId]),

    draw
  }
}

const filterZoneGeoJSON = feature => {
  return feature.properties?.isCompleted && feature.properties?.type === "zone" && feature.geometry?.type === "Polygon"
}

const filterApGeoJSON = feature => {
  return feature.properties?.isCompleted && feature.properties?.type === "ap" && feature.geometry?.type === "Point"
}

const isFilterDifferent = (base, current) => {
  if (!current) {
    return true
  }

  for (let [key, value] of Object.entries(base)) {
    if (current[key] !== value) {
      return true
    }
  }

  return false
}
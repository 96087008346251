import React, {useEffect, useReducer, useState} from "react";
import Search from "../../../components/search";
import {Group} from "./shared";
import {EmptyView} from "../../../components/views";
import {formatDate, formatServerDate, formatString} from "../../../utils/format";
import {AddButton, DeleteButton, EditButton} from "../../../components/buttons";
import {EntryDate, EntryModal, EntrySelector} from "../../../components/modal";
import {getPredictiveDate} from "../../../utils/date/manager";
import {buildData, getChanges} from "../../../utils/validation";

const DEFAULT_DATA = {
  search: {
    value: null,
    isSearching: false
  },
  createModal: {
    show: false
  },
  editModal: {
    show: false,
    semester: null
  },
  deleteModal: {
    show: false,
    semester: null
  }
}

const SEARCH = "SEARCH"

const SHOW_CREATE_MODAL = "SHOW_CREATE_MODAL"
const SHOW_EDIT_MODAL = "SHOW_EDIT_MODAL"
const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL"

const SemesterReducer = (state, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        search: {
          value: action.payload,
          isSearching: action.payload != null
        }
      }

    case SHOW_CREATE_MODAL:
      return {
        ...state,
        createModal: {
          ...state.createModal,
          show: action.payload
        }
      }

    case SHOW_EDIT_MODAL:
      return {
        ...state,
        editModal: {
          ...state.editModal,
          show: action.payload != null,
          semester: action.payload
        }
      }

    case SHOW_DELETE_MODAL:
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          show: action.payload != null,
          semester: action.payload
        }
      }

    default:
      throw new Error("Unknown action type");
  }
}

export function SemesterTab({show, canManageSchool, data, onSemesterAdd, onSemesterUpdate, onSemesterRemove}) {
  const [state, dispatch] = useReducer(SemesterReducer, DEFAULT_DATA)

  const setSearch = search => dispatch({type: SEARCH, payload: search})

  const showCreateModal = () => dispatch({type: SHOW_CREATE_MODAL, payload: true})
  const hideCreateModal = () => dispatch({type: SHOW_CREATE_MODAL, payload: false})

  const showEditModal = (semester) => dispatch({type: SHOW_EDIT_MODAL, payload: semester})
  const hideEditModal = () => dispatch({type: SHOW_EDIT_MODAL, payload: null})

  const showDeleteModal = (semester) => dispatch({type: SHOW_DELETE_MODAL, payload: semester})
  const hideDeleteModal = () => dispatch({type: SHOW_DELETE_MODAL, payload: null})

  useEffect(() => {
    setSearch(null)
  }, [show]);

  if (!show) return null

  const handleOnSearch = search => {
    setSearch(search)
  }

  const handleOnAdd = (data) => {
    const cleanData = {
      ...data,
      start_date: formatServerDate(data.start_date),
      end_date: formatServerDate(data.end_date),
    }

    onSemesterAdd && onSemesterAdd(cleanData, hideCreateModal)
  }

  const handleOnEdit = (semester, data) => {
    const cleanData = {...data}
    if (cleanData.start_date != null) {
      cleanData.start_date = formatServerDate(cleanData.start_date)
    }

    if (cleanData.end_date != null) {
      cleanData.end_date = formatServerDate(cleanData.end_date)
    }

    onSemesterUpdate && onSemesterUpdate(semester, cleanData, hideEditModal)
  }

  const handleOnDelete = (semester) => {
    onSemesterRemove && onSemesterRemove(semester, hideDeleteModal)
  }

  const filterData = () => {
    if (!state.search.isSearching) {
      return data
    }

    const search = state.search.value.toLowerCase()
    const filterSemester = semester => {
      if (!semester) {
        return false
      }

      if (semester.name && semester.name.length > 0 && semester.name.toLowerCase().includes(search)) {
        return true
      }

      if (semester.start_date && semester.start_date.length > 0 && semester.start_date.toLowerCase().includes(search)) {
        return true
      }

      return semester.end_date && semester.end_date.length > 0 && semester.end_date.toLowerCase().includes(search)
    }

    return {
      current: filterSemester(data.current) ? data.current : null,
      previous: data.previous.filter(filterSemester),
      future: data.future.filter(filterSemester)
    }
  }

  const filteredData = filterData()
  const hasCurrentSemester = !!filteredData.current
  const hasPreviousSemesters = filteredData.previous.length > 0
  const hasFutureSemesters = filteredData.future.length > 0

  return (
    <div className="school-semester-tab">
      <div className="school-semester-search">
        <Search search={state.search.value} onSearch={handleOnSearch}/>
        {canManageSchool && <AddButton onClick={showCreateModal}>Add Semester</AddButton>}
      </div>
      <div className="school-semester-tab-groups">
        <Group divClassName="school-semesters" name="Current Semester">
          {!hasCurrentSemester && <EmptyView>No Semester is Session</EmptyView>}
          {hasCurrentSemester &&
            <>
              <SemesterHeaderView/>
              <SemesterView semester={filteredData.current} editable={canManageSchool} onEditClick={() => showEditModal(filteredData.current)}/>
            </>
          }
        </Group>
        <Group divClassName="school-semesters" name="Future Semesters">
          {!hasFutureSemesters && <EmptyView>No Future Semesters</EmptyView>}
          {hasFutureSemesters &&
            <>
              <SemesterHeaderView/>
              {filteredData.future.map((semester) =>
                <SemesterView
                  key={semester.id}
                  semester={semester}
                  editable={canManageSchool}
                  onEditClick={() => showEditModal(semester)}
                  onDeleteClick={() => showDeleteModal(semester)}
                />)}
            </>
          }
        </Group>
        <Group divClassName="school-semesters" name="Previous Semesters">
          {!hasPreviousSemesters && <EmptyView>No Previous Semesters</EmptyView>}
          {hasPreviousSemesters &&
            <>
              <SemesterHeaderView/>
              {filteredData.previous.map((semester) =>
                <SemesterView
                  key={semester.id}
                  semester={semester}
                  editable={canManageSchool}
                  onEditClick={() => showEditModal(semester)}
                  onDeleteClick={() => showDeleteModal(semester)}
                />)}
            </>
          }
        </Group>
      </div>
      <CreateModal
        {...state.createModal}
        onHide={hideCreateModal}
        onSave={handleOnAdd}
      />
      <EditModal
        {...state.editModal}
        onHide={hideEditModal}
        onSave={handleOnEdit}
      />
      <DeleteModal
        {...state.deleteModal}
        onHide={hideDeleteModal}
        onDelete={handleOnDelete}
      />
    </div>
  )
}

function SemesterHeaderView() {
  return (
    <div className="school-semester-header school-semester-layout">
      <span>SEMESTER</span>
      <span>START DATE</span>
      <span>END DATE</span>
      <span/>
    </div>
  )
}

function SemesterView({semester, editable, onEditClick, onDeleteClick}) {
  editable = !!editable

  const handleOnEdit = () => {
    onEditClick && onEditClick(semester)
  }

  const handleOnDelete = () => {
    onDeleteClick && onDeleteClick(semester)
  }

  return (
    <div className="school-semester school-semester-layout">
      <span>{formatString(semester.name)}</span>
      <span>{formatDate(semester.start_date)}</span>
      <span>{formatDate(semester.end_date)}</span>
      {editable &&
        <div>
          <EditButton onClick={handleOnEdit}/>
          <DeleteButton onClick={handleOnDelete}/>
        </div>
      }
    </div>
  )
}

const SEMESTER_NAME_OPTIONS = [
  {label: "Fall Semester", value: "Fall Semester"},
  {label: "Winter Semester", value: "Winter Semester"},
  {label: "Spring Semester", value: "Spring Semester"},
  {label: "Summer Semester", value: "Summer Semester"},
]

const getName = (data) => {
  if (!data || !data.name) return null
  return {label: data.name, value: data.name}
}

const _getDate = (data, key) => {
  if (!data || !data[key]) return null

  let value = data[key]
  const isServerDate = formatServerDate(value) === value
  if (isServerDate) {
    value = formatDate(value)
  }

  return value
}

const getStartDate = (data) => _getDate(data, "start_date")
const getEndDate = (data) => _getDate(data, "end_date")

const isValidDate = date => {
  const asDate = new Date(date)

  if (Number.isNaN(asDate.getDate())) {
    return false
  }

  const formattedDate = formatDate(date)
  if (formattedDate === "Invalid Date") {
    return false
  }

  const results = getPredictiveDate(formattedDate)
  if (!results) {
    return false
  }

  return results.text === formattedDate
}

function CreateModal({show, onSave, onHide}) {
  return (
    <SemesterModal
      show={show}
      title="Create Semester"
      buttonTitle="Create"
      isButtonDisabled={changes => {
        if (changes == null) return true

        const hasData = (key) => changes[key] != null && changes[key].length > 0
        if (!hasData("name") || !hasData("start_date") || !hasData("end_date")) {
          return true
        }

        if (!isValidDate(changes.start_date) || !isValidDate(changes.end_date)) {
          return true
        }

        const startDate = new Date(changes.start_date)
        const endDate = new Date(changes.end_date)

        return endDate.getTime() < startDate.getTime();
      }}
      onHide={onHide}
      onSave={onSave}
    />
  )
}

function EditModal({show, semester, onSave, onHide}) {
  return (
    <SemesterModal
      show={show}
      semester={semester}
      title="Edit Semester"
      buttonTitle="Update"
      isButtonDisabled={changes => {
        if (changes == null) return true

        const hasData = (key) => changes[key] != null && changes[key].length > 0
        if (hasData("start_date") && !isValidDate(changes.start_date)) {
          return true
        }

        if (hasData("end_date") && !isValidDate(changes.end_date)) {
          return true
        }

        if (hasData("start_date") || hasData("end_date")) {
          const startDate = new Date(changes.start_date || semester.start_date)
          const endDate = new Date(changes.end_date || semester.end_date)

          if (endDate.getTime() < startDate.getTime()) {
            return true
          }
        }

        return false
      }}
      onHide={onHide}
      onSave={(data) => onSave(semester, data)}
    />
  )
}

function SemesterModal({show, title, buttonTitle, isButtonDisabled, semester, onSave, onHide}) {
  const [data, setData] = useState(() => buildData({}))

  useEffect(() => {
    if (show && !!semester) {
      setData(() => buildData(semester))
    } else {
      setData({})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleOnHide = () => {
    onHide && onHide()
  }

  const handleOnSave = () => {
    onSave && onSave(getChanges(semester, data))
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: handleOnHide
    },
    primary: {
      title: buttonTitle,
      disabled: isButtonDisabled(getChanges(semester, data)),
      onClick: handleOnSave
    }
  }

  const name = getName(data)
  let options = SEMESTER_NAME_OPTIONS
  if (semester != null && options.filter(o => o.value !== semester.name)) {
    options = [
      ...options,
      getName(semester),
    ]
  }

  const startDate = getStartDate(data)
  const endDate = getEndDate(data)

  return (
    <EntryModal bodyClassName="school-semester-modal-body" show={show} title={title} buttons={buttons} onHide={handleOnHide}>
      <EntrySelector
        creatable
        isClearable
        label="Semester"
        placeholder="Semester Name"
        value={name}
        options={options}
        onChange={(newValue) => {
          const isCustom = !!newValue && !!newValue.__isNew__

          let name = !!newValue && !!newValue.value ? newValue.value : null
          if (!isCustom && startDate && isValidDate(startDate)) {
            const date = new Date(startDate)
            name = `${name} ${date.getUTCFullYear()}`
          }

          setData((old) => ({
            ...old,
            name
          }))
        }}
      />
      <EntryDate
        label="Start Date"
        value={startDate}
        onChange={(newValue) => {
          if (newValue != null && !newValue.length) {
            newValue = null
          }

          if (data.start_date === newValue) {
            return
          }

          let name = data.name
          const hasName = !!data.name && data.name.length > 0
          const nameOption = SEMESTER_NAME_OPTIONS.filter(o => name.toLowerCase().includes(o.value.toLowerCase()))
          const isCustomName = !nameOption.length

          if (!isCustomName && hasName && isValidDate(newValue)) {
            const date = new Date(newValue)
            if (!data.name.endsWith(date.getUTCFullYear())) {
              name = `${nameOption[0].value} ${date.getUTCFullYear()}`
            }
          }

          setData((old) => {
            const out = {
              ...old,
              start_date: newValue
            }

            if (name !== data.name) {
              out.name = name
            }

            return out
          })
        }}
      />
      <EntryDate
        label="End Date"
        value={endDate}
        onChange={(newValue) => {
          if (newValue != null && !newValue.length) {
            newValue = null
          }

          if (data.start_date === newValue) {
            return
          }

          setData((old) => ({
            ...old,
            end_date: newValue
          }))
        }}
      />
    </EntryModal>
  )
}

function DeleteModal({show, semester, onHide, onDelete}) {
  const [current, setCurrent] = useState("")

  useEffect(() => {
    setCurrent("")
  }, [show]);

  if (!show || semester == null) return null

  const handleOnClick = () => onDelete && onDelete(semester)

  const buttons = {
    secondary: {
      title: "No, keep this semester",
      onClick: onHide
    },
    danger: {
      title: "Yes, delete this semester",
      disabled: current !== semester.name,
      onClick: handleOnClick
    }
  }

  return (
    <EntryModal show={show} title={`Delete ${semester.name}?`} onHide={onHide} buttons={buttons} cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body">
      <p>{`This action can not be undone. The deletion of ${semester.name} is permanent and will delete all data associated.`}</p>
      <div>
        <p>Please type <span>{semester.name}</span> to confirm</p>
        <input autoFocus value={current} onChange={(e) => setCurrent(e.target.value)}/>
      </div>
    </EntryModal>
  )
}
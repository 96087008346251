import React, {useEffect, useRef} from "react"
import {useMap} from "../../shared/hooks/map"
import {ElementLoader} from "../../shared/components/element_loader"

// const DEFAULT_MAP_DATA = {
//   loading: false,
//   options: {
//     zones: false,
//     aps: false,
//     floor_plan: false
//   },
//   filter: FILTER_IDS.DEFAULT,
//   selectedZone: null,
//   listData: [],
//   geojson: null
// }
//
// const SET_LOADING = "SET_LOADING"
// const TOGGLE_SHOW_UPDATED = "TOGGLE_SHOW_UPDATED"
// const FILTER_SELECTED = "FILTER_SELECTED"
// const SET_DATA = "SET_DATA"
// const SET_SELECTED_ZONE = "SET_SELECTED_ZONE"
//
// const MapReducer = (state, action) => {
//   switch (action.type) {
//     case SET_LOADING:
//       return {
//         ...state,
//         loading: action.payload
//       }
//
//     case TOGGLE_SHOW_UPDATED: {
//       const options = state.options
//
//       return {
//         ...state,
//         options: {
//           ...options,
//           [action.payload]: !options[action.payload]
//         }
//       }
//     }
//
//     case FILTER_SELECTED:
//       return {
//         ...state,
//         filter: action.payload
//       }
//
//     case SET_DATA:
//       return {
//         ...state,
//         loading: false,
//         ...action.payload
//       }
//
//     case SET_SELECTED_ZONE:
//       return {
//         ...state,
//         selectedZone: action.payload
//       }
//
//     default:
//       throw new Error(`Unknown Reducer type: ${action.type}`);
//   }
// }

export default function MapView({school}) {
  // const [state, dispatch] = useReducer(MapReducer, DEFAULT_MAP_DATA)

  // const setSelectedZone = zone => dispatch({type: SET_SELECTED_ZONE, payload: zone})

  // useEffect(() => {
  //   if (schoolId == null) {
  //     return
  //   }
  //
  //   setLoading(true)
  //   Api.get(`/schools/${schoolId}/map/zones`)
  //     .then((response) => setData(response.data.zones, response.data.geojson))
  //     .catch(e => {
  //       setLoading(false)
  //
  //       if (e.handled) return
  //
  //       console.error(e.error)
  //       ErrorToast("Unable to fetch Zone data")
  //     })
  // }, [schoolId])

  return (
    <>
      <ElementLoader elementId="map-content">
        <Map school={school}/>
      </ElementLoader>
      <div className="map-overlay">
        <div className="da-card">
          {/*<ZoneListView*/}
          {/*  recent={[]}*/}
          {/*  zones={state.listData}*/}
          {/*  selectedZone={state.selectedZone}*/}
          {/*  onZoneSelected={setSelectedZone}*/}
          {/*/>*/}
        </div>
        <div className="map-buttons">
          <div>
            {/*<MapOptions state={state.options} onChange={toggleOption}/>*/}
            {/*<Filters selected={state.filter} onChange={setFilter}/>*/}
            {/*<Card className="map-buttons-controls placeholder" style={{textOrientation: "upright"}}>Map Controls</Card>*/}
          </div>
        </div>
        {/*<Indicator show={state.loading}/>*/}
      </div>
    </>
  )
}

function Map({school}) {
  const ref = useRef()
  const {isLoading, setLocation, setZoom, setContainer} = useMap(school.location)

  const longitude = school?.location?.longitude
  const latitude = school?.location?.latitude
  const zoom = school?.location?.zoom

  useEffect(() => {
    setContainer(ref)
  }, [setContainer]);

  useEffect(() => {
    if (isLoading || (longitude == null || latitude == null || zoom == null)) {
      return
    }

    setLocation(longitude, latitude)
    setZoom(zoom)
  }, [isLoading, longitude, latitude, zoom, setLocation, setZoom]);

  return <div className="map-container" ref={ref}/>
}
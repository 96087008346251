import {useCallback, useEffect, useRef, useState} from "react";

import mapboxgl from 'mapbox-gl';


export function getAccessKey() {
  return process.env.REACT_APP_MAP_ACCESS_KEY
}

export function initMap() {
  mapboxgl.accessToken = getAccessKey();
}

export const MapStyles = {
  DEFAULT: "mapbox://styles/mapbox/standard",
  STREETS: "mapbox://styles/mapbox/streets-v12",
  OUTDOORS: "mapbox://styles/mapbox/outdoors-v12",
  LIGHT: "mapbox://styles/mapbox/light-v11",
  DARK: "mapbox://styles/mapbox/dark-v11",
  SATELLITE: "mapbox://styles/mapbox/satellite-v9",
  SATELLITE_STREETS: "mapbox://styles/mapbox/satellite-streets-v12",
  DAY: "mapbox://styles/mapbox/navigation-day-v1",
  NIGHT: "mapbox://styles/mapbox/navigation-night-v1"
}

const DEFAULTS = {
  latitude: 30.506620,
  longitude: -97.830317,
  zoom: 14.3,
  boundingBox: [-97.83652745147761, 30.488293755936766, -97.81359456440025, 30.50612416861027],
  style: MapStyles.LIGHT
}

export function useMap(defaults) {
  const mapRef = useRef(null)

  const cleanDefaults = {
    ...DEFAULTS,
    ...(defaults || {})
  }

  const [latitude, setLatitude] = useState(() => cleanDefaults.latitude);
  const [longitude, setLongitude] = useState(() => cleanDefaults.longitude);
  const [zoom, setZoom] = useState(() => cleanDefaults.zoom);
  const [style, setStyle] = useState(() => cleanDefaults.style);
  const [boundingBox, setBoundingBox] = useState(() => cleanDefaults.boundingBox);
  const [isLoading, setIsLoading] = useState(true);

  const setContainer = useCallback((ref) => {
    if (mapRef.current != null) {
      return
    }

    mapRef.current = new mapboxgl.Map({
      container: ref.current,
      style,
      center: [longitude, latitude],
      zoom,
    })

    mapRef.current.on("style.load", () => {
      setIsLoading(false)
    })
  }, [latitude, longitude, zoom, style])

  useEffect(() => {
    if (isLoading) {
      return
    }

    const getInfo = () => {
      const center = mapRef.current.getCenter()

      const currentLatitude = center.lat
      const currentLongitude = center.lng
      const currentZoom = mapRef.current.getZoom()
      const bounds = mapRef.current.getBounds()
      const sw = bounds.getSouthWest()
      const ne = bounds.getNorthEast()
      const boundingBox = [sw.lng, sw.lat, ne.lng, ne.lat]

      const changedLocation = latitude !== currentLatitude || longitude !== currentLongitude
      const changedZoom = zoom !== currentZoom

      return {
        latitude: currentLatitude,
        longitude: currentLongitude,
        zoom: currentZoom,
        boundingBox,
        hasLocationChange: changedLocation,
        hasZoomChange: changedZoom,
        hasChange: changedLocation || changedZoom,
      }
    }

    const handleChange = () => {
      const info = getInfo()
      if (info.hasLocationChange) {
        setLatitude(info.latitude)
        setLongitude(info.longitude)
      }

      if (info.hasZoomChange) {
        setZoom(info.zoom)
      }

      if (info.hasChange) {
        setBoundingBox(info.boundingBox)
      }
    }

    // mapRef.current.on("boxzoomend", handleChange)
    // mapRef.current.on("wheel", handleChange)
    mapRef.current.on("moveend", handleChange)

    return () => {
      // mapRef.current.off("boxzoomend", handleChange)
      // mapRef.current.off("wheel", handleChange)
      mapRef.current.on("moveend", handleChange)
    }
  }, [latitude, longitude, zoom, style, isLoading])

  const updateLocation = useCallback((longitude, latitude) => {
    setLongitude(longitude)
    setLatitude(latitude)

    if (mapRef.current == null) {
      return
    }

    mapRef.current.setCenter([longitude, latitude])
  }, [])

  const updateZoom = useCallback((zoom) => {
    setZoom(zoom)

    if (mapRef.current == null) {
      return
    }

    mapRef.current.setZoom(zoom)
  }, [])

  return {
    map: mapRef.current,

    isLoading,
    setContainer,

    boundingBox,
    latitude,
    longitude,
    zoom,

    setLocation: updateLocation,
    setZoom: updateZoom
  }
}
import {useEffect, useState} from "react"
import {EntryModal} from "../shared/components/modal";
import Api from "../shared/api/api";
import {ErrorToast} from "../shared/utils/toast";

const EVENTS = [
  "keydown",
  "mousemove",
  "mousedown",
  "wheel",
  "scroll",
  "touchdown"
]

const minutes = value => {
  return value * 60000
}

const IDLE_DURATION = minutes(60)
const PING_DURATION = minutes(1)
const DISPLAY_DURATION = minutes(1)

class _IdleService {
  constructor() {
    this.interval = null
    this.lastUpdated = Date.now()
    this.callback = null

    EVENTS.forEach((event) => window.addEventListener(event, this._debounce(), {passive: true}))
  }

  setCallback(callback) {
    this.callback = callback

    if (callback) {
      if (this.interval) {
        clearInterval(this.interval)
      }

      this.interval = setInterval(this._handleInterval(), 5000)
    } else if (this.interval) {
      clearInterval(this.interval)
    }
  }

  _debounce() {
    const service = this
    return () => {
      service.lastUpdated = Date.now()
    }
  }

  _handleInterval() {
    const service = this
    return () => {
      service.callback && service.callback(Date.now() - service.lastUpdated)
    }
  }
}

const IdleService = new _IdleService()

export default function IdleTimeout() {
  const [lastPing, setLastPing] = useState(Date.now())
  const [show, setShow] = useState(false)

  useEffect(() => {
    IdleService.setCallback((millisecondsSinceInteraction) => {
      if (!show && millisecondsSinceInteraction >= IDLE_DURATION) {
        setShow(true)
        IdleService.setCallback(null)
      }

      if (Date.now() - lastPing >= PING_DURATION) {
        Api.get("/auth/ping")
          .then(() => {
            setLastPing(Date.now())
          })
          .catch(e => {
            if (!e.handled) return
            console.error(e)
            ErrorToast("Unable to retrieve Ping")
          })
      }
    })

    return () => {
      IdleService.setCallback()
    }
  }, [show, lastPing, setLastPing]);

  if (!show) {
    return null
  }

  return (
    <WarningModal
      onHide={() => setShow(false)}
      onLogout={() => {
        Api.post(`/auth/logout`, {})
          .catch(e => {
            if (e.handled) return

            console.error(e.error)
            ErrorToast("Unable to logout")
          })
      }}
    />
  )
}

function WarningModal({onLogout, onHide}) {
  const [remaining, setRemaining] = useState(DISPLAY_DURATION / 1000)

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining((remaining) => {
        if (remaining <= 0) {
          return 0
        }

        return remaining - 1
      })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, []);

  useEffect(() => {
    if (remaining > 0) {
      return
    }

    onLogout()
  }, [remaining, onLogout]);

  const buttons = {
    secondary: {
      title: "Log Out",
      onClick: onLogout
    },
    primary: {
      title: "Keep Going!",
      onClick: onHide
    }
  }

  return (
    <EntryModal show={true} title="Continue Working?" onHide={onHide} buttons={buttons}>
      <span>You will be automatically logged out in <span>{remaining}</span> seconds</span>
    </EntryModal>
  )
}
import {DrawMode, useDataContext} from "../../hooks/context";
import {useEffect, useRef} from "react";
import {featureCollection as FeatureCollection, intersect, lineString as Line, point as Point, polygon as Polygon} from "@turf/turf";
import {distanceBetweenPoints, getPoint, isPolygonValid, MAP_LAYER_OPTIONS, MapKeys, MERGE_DISTANCE_IN_FEET} from "./utils";

const buildLines = points => {
  const lines = []
  const lineProperties = {type: "zone"}
  for (let i = 1; i < points.length; i++) {
    lines.push(Line([points[i - 1].geometry.coordinates, points[i].geometry.coordinates], lineProperties))
  }
  // lines.push(Line([points[points.length - 1].geometry.coordinates, points[0].geometry.coordinates], lineProperties))

  return lines
}

export function PenDraw({map, isMapLoading}) {
  const {draw, selectedZone, isCreatingZone, geojson} = useDataContext()
  const dragPointRef = useRef(null)

  useEffect(() => {
    if (!map || isMapLoading || draw.mode !== DrawMode.PEN) {
      return
    }

    map.getCanvas().style.cursor = "crosshair"

    const handleOnClick = e => {
      const existingPoint = getPoint(draw, Point([e.lngLat.lng, e.lngLat.lat]))
      if (existingPoint.isValid && existingPoint.isPolygonPoint) {
        return
      }

      if (existingPoint.isValid && existingPoint.result.point.properties.pointIndex === 0) {
        const existingPoints = draw.geojson.features.filter(feature => feature.properties?.drawType === "pen" && feature.geometry.type === "Point")
        if (existingPoints.length < 3) {
          // invalid shape
          return
        }

        if (existingPoint.result.point.properties.pointIndex === 0) {
          // closing a point
          const points = existingPoints.map(p => p.geometry.coordinates)
          const polygon = Polygon([[...points, points[0]]], {
            isCompleted: true,
            id: selectedZone?.id ? selectedZone.id : "temporary",
            "type": "zone"
          })

          if (!isPolygonValid(polygon)) {
            return
          }

          if (e.originalEvent.shiftKey) {
            // constrain the polygon to either the parent or the building found on the map
            let parentGeoJSON = null
            if (isCreatingZone && selectedZone?.hasParent() && selectedZone.getParent().has_geojson) {
              const parentId = selectedZone.getParent().id
              parentGeoJSON = geojson.features.filter(f => f.properties.id === parentId)
                .map(f => f.geometry.coordinates)
            }

            if (!parentGeoJSON) {
              parentGeoJSON = map.queryRenderedFeatures()
                .filter(f => MAP_LAYER_OPTIONS.includes(f.sourceLayer))
                .filter(f => ["MultiPolygon", "Polygon"].includes(f.geometry.type))
                .flatMap(f => f.geometry.type === "Polygon" ? [f.geometry.coordinates] : f.geometry.coordinates)
            }

            const result = parentGeoJSON.map(c => intersect(FeatureCollection([polygon, Polygon(c)]), {properties: polygon.properties}))
              .filter(f => f != null && f.geometry.type === "Polygon")
              .reduce((accumulator, value) => accumulator || value, null)

            if (result && isPolygonValid(result)) {
              draw.replace([result])
            }
          } else {
            // just close it without constraining it
            draw.replace([polygon])
          }
        }

        return
      }

      const existingPoints = draw.geojson.features.filter(feature => feature.properties?.drawType === "pen" && feature.geometry.type === "Point")
      let properties = {
        id: existingPoints.length,
        pointIndex: existingPoints.length,
        type: "zone",
        drawType: "pen",
        isComplete: false
      }

      if (selectedZone) {
        properties = {
          ...properties,
          id: selectedZone.id,
          name: selectedZone.name,
          building_id: selectedZone.getBuilding()?.id,
        }
      }

      const point = Point([e.lngLat.lng, e.lngLat.lat], properties)
      if (!existingPoints.length) {
        draw.replace([point])
        return
      }

      const newPoints = [...existingPoints, point]
      draw.replace([...newPoints, ...buildLines(newPoints)])
    }

    const handleOnMouseEnterPoint = () => {
      map.getCanvas().style.cursor = "grab"
    }

    const handleOnMouseLeavePoint = () => {
      map.getCanvas().style.cursor = "crosshair"
    }

    const handleOnDragPointStart = e => {
      const point = getPoint(draw, Point([e.lngLat.lng, e.lngLat.lat]))
      if (!point.isValid) {
        return
      }

      map.getCanvas().style.cursor = "grabbing"
      map.dragPan.disable()
      dragPointRef.current = {
        action: "dragging",
        id: point.result.point.properties.id,
        ts: Date.now(),
        originalPoint: point.result.point,
        isPolygonPoint: point.isPolygonPoint,
        index: point.result.index
      }
    }

    const handleOnDragPoint = e => {
      if (dragPointRef.current?.action !== "dragging") {
        return
      }

      let newFeatures = null
      if (dragPointRef.current.isPolygonPoint) {
        const polygon = draw.geojson.features.filter(feature => feature.geometry.type === "Polygon" && feature.properties?.isCompleted && feature.properties?.type === "zone")
          .reduce((accumulator, value) => accumulator == null ? value : accumulator, null)

        if (polygon) {
          const polygonCoordinates = polygon.geometry.coordinates[0]
          const lastIndex = polygonCoordinates.length - 1
          const replaceFirstAndEnd = dragPointRef.current.index === 0 || dragPointRef.current.index === lastIndex

          const shouldReplace = (index) => {
            return (replaceFirstAndEnd && (index === 0 || index === lastIndex)) || dragPointRef.current.index === index
          }

          const coordinates = polygonCoordinates.map((coords, i) => shouldReplace(i) ? [e.lngLat.lng, e.lngLat.lat] : coords)
          newFeatures = [Polygon([coordinates], polygon.properties)]
        }
      } else {
        const point = draw.geojson.features.filter(feature => feature.properties?.drawType === "pen" && feature.geometry.type === "Point" && feature.properties.pointIndex === dragPointRef.current.index)
          .reduce((accumulator, value) => value, null)

        if (point) {
          const newPoint = Point([e.lngLat.lng, e.lngLat.lat], point.properties)
          const points = draw.geojson.features.filter(feature => feature.properties?.drawType === "pen" && feature.geometry.type === "Point")
            .map(feature => feature.properties.pointIndex === newPoint.properties.pointIndex ? newPoint : feature)

          newFeatures = [...points, ...buildLines(points)]
        }
      }

      if (!newFeatures) {
        return;
      }

      draw.replace(newFeatures)
    }

    const handleOnDragPointEnd = e => {
      if (dragPointRef.current?.action !== "dragging") {
        return
      }

      const duration = Date.now() - dragPointRef.current.ts
      const distance = distanceBetweenPoints(dragPointRef.current.originalPoint, Point([e.lngLat.lng, e.lngLat.lat]))
      const isClick = duration < 200 && distance < MERGE_DISTANCE_IN_FEET

      if (!isClick) {
        // make sure that the last movement is captured
        handleOnDragPoint(e)
      }

      map.getCanvas().style.cursor = "grab"
      map.dragPan.enable()
      dragPointRef.current = null
    }

    map.on("click", handleOnClick)
    map.on("mouseenter", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnMouseEnterPoint)
    map.on("mouseleave", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnMouseLeavePoint)
    map.on("mousedown", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnDragPointStart)
    map.on("mousemove", handleOnDragPoint)
    map.on("mouseup", handleOnDragPointEnd)

    return () => {
      map.getCanvas().style.cursor = ""

      map.off("click", handleOnClick)
      map.off("mouseenter", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnMouseEnterPoint)
      map.off("mouseleave", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnMouseLeavePoint)
      map.off("mousedown", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnDragPointStart)
      map.off("mousemove", handleOnDragPoint)
      map.off("mouseup", handleOnDragPointEnd)
    }
  }, [map, isMapLoading, selectedZone, isCreatingZone, draw, geojson]);
}


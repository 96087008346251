import {useCallback, useEffect, useRef, useState} from "react"
import {EntryModal} from "../../../shared/components/modal"
import {ListView} from "../../../shared/components/list"
import {onEnterKey} from "../../../shared/utils/key_events"
import {AddTextButton, DeleteButton} from "../../../shared/components/buttons"

export const formatResource = resource => {
  if (!resource) {
    return null
  }

  return {label: resource.name, value: resource}
}

export const formatValue = value => {
  if (!value) {
    return null
  }

  return {label: value, value}
}

export function TitledAttributes({title, variant = "single", ...props}) {
  const {className, children, ...rest} = props

  return (
    <div className={`wireless-config-detail-titled-attributes ${className || ""}`} {...rest}>
      <span className="wireless-config-detail-titled-attributes-title">{title}</span>
      <div className={`wireless-config-detail-titled-attributes-content ${variant}`}>
        {children}
      </div>
    </div>
  )
}

export function AttributeItem({label, variant = "horizontal", wrapperClassName, ...props}) {
  const {children, className, ...rest} = props

  const classNames = []
  if (variant) classNames.push(variant)
  if (className) classNames.push(className)

  const wrapperClassNames = []
  if (wrapperClassName) wrapperClassNames.push(wrapperClassName)

  return (
    <div className={`wireless-config-detail-titled-attributes-item ${classNames.join(" ")}`} {...rest}>
      <div className="wireless-config-detail-titled-attributes-item-title">
        <span className="da-modal-entry-input-label">{label}</span>
      </div>
      <div className={`wireless-config-detail-titled-attributes-item-content ${wrapperClassNames.join(" ")}`}>
        {children}
      </div>
    </div>
  )
}

export function MultiRowSelector({label, values, useComma = true, showing, onShowPicker, ...props}) {
  const {children} = props

  const handleOnClick = () => {
    onShowPicker && onShowPicker()
  }

  const hasValues = values != null && values.length > 0

  return (
    <>
      <div className="wireless-config-detail-attributes-multi-content" aria-multiline={!useComma} aria-selected={showing} onClick={handleOnClick}>
        {!hasValues && <span key="multirow-selector-single">--</span>}
        {useComma && hasValues && <span key="multirow-selector-single">{values.join(", ")}</span>}
        {!useComma && hasValues && values.map((value, i) => <span key={`multirow-selector-${i}`}>{value}</span>)}
      </div>
      {children}
    </>
  )
}

export function StringArrayModal({show, modelKey, isSingle = false, isEdit, values, onHide, onSave, validator}) {
  const [items, setItems] = useState([])
  const inputRef = useRef(null)
  const [currentValue, setCurrentValue] = useState("")

  useEffect(() => {
    setItems(values || [])
    setCurrentValue("")
  }, [show, isSingle, values])

  const getValues = useCallback(() => {
    const out = isSingle ? [] : [...items]
    const current = currentValue.trim()

    if (current.length) {
      out.push(current)
    }

    return out
  }, [isSingle, currentValue, items])

  const isSaveDisabled = useCallback(() => {
    const ogValues = (values || []).sort()
    const currentValues = getValues().sort()

    if (ogValues.length !== currentValues.length) {
      return false
    }

    for (let i = 0; i < currentValues.length; i++) {
      if (ogValues[i] !== currentValues[i]) {
        return false
      }
    }

    return true
  }, [values, getValues])

  const handleOnSave = useCallback(() => {
    onSave && onSave(getValues())
  }, [onSave, getValues])

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: isEdit ? "Update" : "Save",
      disabled: isSaveDisabled(),
      onClick: handleOnSave
    }
  }

  const handleOnValueChange = index => {
    return e => {
      if (validator && !validator(e.target.value)) {
        return
      }

      setItems(oldValues => oldValues.map((v, i) => i === index ? e.target.value : v))
    }
  }

  const handleOnCurrentChange = e => {
    if (validator && !validator(e.target.value)) {
      return
    }

    setCurrentValue(e.target.value)
  }

  const handleOnDelete = index => {
    return () => {
      setItems(oldValues => oldValues.filter((v, i) => i !== index))
    }
  }

  const handleOnAdd = () => {
    if (currentValue.trim() === "") {
      return
    }

    if (validator && !validator(currentValue)) {
      return
    }

    if (isSingle) {
      setItems([currentValue.trim()])
    } else {
      setItems(oldValues => [...oldValues, currentValue.trim()])
    }

    setCurrentValue(() => "")
    inputRef.current.focus()
  }

  return (
    <EntryModal show={show} title={isEdit ? `Update ${modelKey}` : `Add ${modelKey}`} buttons={buttons} onHide={onHide}>
      <div className="wireless-config-detail-attributes-floor-modal">
        {!isSingle &&
          <ListView>
            <div className="wireless-config-detail-attributes-floor-modal-existing-floors">
              {(items || []).map((floor, index) => (
                <div key={index}>
                  <input
                    value={floor}
                    onChange={handleOnValueChange(index)}
                    onKeyUp={onEnterKey(({shift}) => {
                      if (!shift) {
                        inputRef.current && inputRef.current.focus()
                        return
                      }

                      if (!isSaveDisabled()) {
                        handleOnSave()
                      }
                    })}
                  />
                  <DeleteButton onClick={handleOnDelete(index)}/>
                </div>
              ))}
            </div>
          </ListView>
        }
        <div className="wireless-config-detail-attributes-floor-modal-controls">
          <input
            ref={inputRef}
            autoFocus
            placeholder={(isSingle && values?.length ? values[0] : "") || ""}
            value={currentValue}
            onChange={handleOnCurrentChange}
            onKeyUp={onEnterKey(({shift}) => {
              if (isSingle) {
                if (!isSaveDisabled()) {
                  handleOnSave()
                }
                return
              }

              if (!shift) {
                handleOnAdd()
                return
              }

              if (!isSaveDisabled()) {
                handleOnSave()
              }
            })}/>
          {!isSingle && <AddTextButton variant="primary" onClick={handleOnAdd}>{`Add ${modelKey}`}</AddTextButton>}
        </div>
      </div>
    </EntryModal>
  )
}


import React from "react"

export function Slider({checked, onChecked, disabled = false, ...props}) {
  let {className, style} = props
  className = "slider-container" + (className != null ? ` ${className}` : "")

  const handleOnClick = () => {
    if (disabled || !onChecked) {
      return
    }

    onChecked(!checked)
  }

  return (
    <div className={className} style={style} onClick={handleOnClick}>
      <span className="slider" aria-checked={checked} aria-disabled={!!disabled}/>
    </div>
  )
}
import {createRef} from "react";

import {ReactComponent as AddIcon} from "../assets/icons/button/add_icon.svg";
import {ReactComponent as CaretIcon} from "../assets/icons/button/caret_icon.svg";
import {ReactComponent as CheckIcon} from "../assets/icons/checkbox/checkbox_stroked.svg";
import {ReactComponent as ClearIcon} from "../assets/icons/button/clear_icon.svg";
import {ReactComponent as CloseIcon} from "../assets/icons/button/close_icon.svg";
import {ReactComponent as CopyIcon} from "../assets/icons/button/copy_icon.svg";
import {ReactComponent as ConnectIcon} from "../assets/icons/button/connect_icon.svg";
import {ReactComponent as DeleteIcon} from "../assets/icons/button/delete_icon.svg";
import {ReactComponent as DisconnectIcon} from "../assets/icons/button/disconnect_icon.svg";
import {ReactComponent as EditIcon} from "../assets/icons/button/edit_icon.svg";
import {ReactComponent as FilterIcon} from "../assets/icons/button/filter_icon.svg";
import {ReactComponent as LoadingIcon} from "../assets/icons/loading_icon.svg";
import {ReactComponent as OverflowIcon} from "../assets/icons/button/overflow-icon.svg";
import {ErrorToast, SuccessToast} from "../utils/toast";

const getClassesFromVariant = variant => {
  if (variant == null) return ["da-button-secondary"]

  const out = []
  switch (variant) {
    case "primary":
      out.push("da-button-primary")
      break

    case "secondary":
      out.push("da-button-secondary")
      break

    case "danger":
      out.push("da-button-danger")
      break

    default:
      throw new Error(`Unknown variant variant ${variant}`)
  }

  return out
}

const getClassesFromIconVariant = variant => {
  if (variant == null) return ["da-icon-button-secondary"]

  const out = []
  switch (variant) {
    case "primary":
      out.push("da-icon-button-primary")
      break

    case "secondary":
      out.push("da-icon-button-secondary")
      break

    case "danger":
      out.push("da-icon-button-danger")
      break

    default:
      throw new Error(`Unknown variant variant ${variant}`)
  }

  return out
}

export function IconButton({variant, onClick, ...props}) {
  const {className, children, ...rest} = props;

  let classes = ["da-icon-button"]
  if (props.className != null) classes.push(className)
  classes = classes.concat(getClassesFromIconVariant(variant))

  return (
    <button className={classes.join(" ")} onClick={() => onClick && onClick()} {...rest}>
      {children}
    </button>
  )
}

export function Button({variant, disabled, onClick, onFocus, onBlur, ...props}) {
  const {className, children, ...rest} = props
  let classes = []
  if (className != null) classes.push(className)
  classes.push("da-button")
  classes = classes.concat(getClassesFromVariant(variant))

  const handleOnClick = () => {
    onClick && onClick()
  }

  const handleOnFocus = () => {
    onFocus && onFocus()
  }

  const handleOnBlur = () => {
    onBlur && onBlur()
  }

  return (
    <button className={classes.join(" ")} disabled={!!disabled} onClick={handleOnClick} onFocus={handleOnFocus} onBlur={handleOnBlur} {...rest}>
      {children}
    </button>
  )
}

export function AddButton({variant = "primary", onClick, ...props}) {
  const {children, ...rest} = props

  return (
    <Button variant={variant} onClick={onClick} {...rest}>
      <AddIcon/>
      {children}
    </Button>
  )
}

export function PlusButton({variant = "secondary", onClick, ...props}) {
  const {children, ...rest} = props

  return (
    <IconButton className="da-button-no-outline circle" variant={variant} onClick={onClick} {...rest}>
      <AddIcon/>
      {children}
    </IconButton>
  )
}

export function FilterButton({variant = "secondary", onClick}) {
  return (
    <IconButton className="da-button-no-outline circle" variant={variant} onClick={onClick}>
      <FilterIcon/>
    </IconButton>
  )
}

export function TextButton({variant = "primary", onClick, ...props}) {
  const {children, ...rest} = props
  return (
    <Button className="da-button-no-outline" variant={variant} onClick={onClick} {...rest}>{children}</Button>
  )
}

export function AddTextButton({variant = "primary", onClick, ...props}) {
  return <AddButton className="da-button-no-outline" variant={variant} onClick={onClick} {...props} />
}

export function CloseButton({variant = "secondary", onClick, ...props}) {
  return <IconButton variant={variant} onClick={onClick} {...props}><CloseIcon/></IconButton>
}

export function CheckButton({variant = "secondary", onClick}) {
  return <IconButton className="da-icon-button-clear" variant={variant} onClick={onClick}><CheckIcon/></IconButton>
}

export function ClearButton({onClick}) {
  return <IconButton className="da-icon-button-clear" variant="danger" onClick={onClick}><ClearIcon/></IconButton>
}

export function DeleteButton({onClick, ...props}) {
  return <IconButton variant="danger" onClick={onClick} {...props}><DeleteIcon/></IconButton>
}

export function EditButton({variant = "secondary", onClick, ...props}) {
  return <IconButton variant={variant} onClick={onClick} {...props}><EditIcon/></IconButton>
}

export function SaveButton({onClick, ...props}) {
  return <Button variant="primary" onClick={onClick} {...props}>Save</Button>
}

export function CancelButton({onClick, ...props}) {
  return <Button variant="secondary" onClick={onClick} {...props}>Cancel</Button>
}

export function ConnectButton({onClick, ...props}) {
  return (
    <Button variant="primary" onClick={onClick} {...props}>
      <ConnectIcon/>
      Connect
    </Button>
  )
}

export function ConnectingButton({...props}) {
  return <IndicatorButton {...props}>Connecting...</IndicatorButton>
}

export function DisconnectingButton({...props}) {
  return <IndicatorButton {...props}>Disconnecting...</IndicatorButton>
}

export function PendingButton({...props}) {
  return <IndicatorButton {...props}>Pending...</IndicatorButton>
}

export function IndicatorButton({...props}) {
  let {className, children, ...rest} = props

  const classNames = ["da-indicator-button"]
  if (className != null) classNames.push(className)

  className = classNames.join(" ")

  return (
    <Button className={className} variant="secondary" {...rest}>
      <LoadingIcon/>
      {props.children}
    </Button>
  )
}

export function DisconnectButton({onClick, ...props}) {
  return (
    <Button variant="danger" onClick={onClick} {...props}>
      <DisconnectIcon/>
      Disconnect
    </Button>
  )
}

export function OverflowButton({onClick, ...props}) {
  return (
    <IconButton variant="secondary" onClick={onClick} {...props}>
      <OverflowIcon/>
    </IconButton>
  )
}

export function DropDownButton({title, buttonRef, onClick, ...props}) {
  if (buttonRef == null) buttonRef = createRef()

  let {className, children} = props;
  if (className != null) className = `da-dropdown-button ${className}`
  else className = "da-dropdown-button"

  const handleClick = () => {
    if (onClick == null) return
    const rect = buttonRef.current.getBoundingClientRect()

    onClick({
      top: rect.bottom,
      left: rect.left,
      right: rect.right,
    })
  }

  return (
    <div className={className} onClick={handleClick} ref={buttonRef} {...props}>
      <div className="da-dropdown-button-container">
        <span>{title}</span>
        <CaretIcon/>
      </div>
      {children}
    </div>
  )
}

export function CopyButton({variant = "primary", value}) {
  const handleCopy = () => {
    navigator.clipboard.writeText(value)
      .then(() => SuccessToast("Copied to clipboard!"))
      .catch(error => {
        console.error(error)
        ErrorToast("Unable to copy to clipboard")
      })
  }

  return (
    <IconButton variant={variant} onClick={handleCopy}>
      <CopyIcon/>
    </IconButton>
  )

}
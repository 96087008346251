export const AttributeSort = (...attributes) => {
  const handler = (a, b, attributeList) => {
    const attribute = attributeList.shift()

    let core1 = a[attribute];
    let core2 = b[attribute];

    if (core1 != null && typeof core1 === "string") core1 = core1.toLocaleLowerCase()
    if (core2 != null && typeof core2 === "string") core2 = core2.toLocaleLowerCase()

    if ((core1 == null && core2 != null) || (core1 > core2)) return 1;
    if ((core1 != null && core2 == null) || (core1 < core2)) return -1;

    if (attributeList.length > 1) return handler(a, b, attributeList);
    else return 0
  }

  return function (a, b) {
    return handler(a, b, attributes.slice())
  }
}
import {useSearchParams} from "react-router-dom"
import {LayoutResourceType, TabIds, TabOptions} from "../consts"
import {useMemo} from "react"

export const QueryArgs = {
  TAB: "tab",
  RESOURCE_TYPE: "resourceType",
  RESOURCE_ID: "resourceId",
  PREVIOUS_RESOURCE_ID: "previousResourceId",
  CREATING_RESOURCE: "isCreating",
  PANE: {
    HIDING_LEFT: "hideLeftPane",
    HIDING_RIGHT: "hideRightPane",
  }
}

export function useQuery() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = useMemo(() => ({
    tab: searchParams.get(QueryArgs.TAB) || TabOptions[0].id,
    resourceType: searchParams.get(QueryArgs.RESOURCE_TYPE),
    resourceId: searchParams.get(QueryArgs.RESOURCE_ID),
    previousResourceId: searchParams.get(QueryArgs.PREVIOUS_RESOURCE_ID),
    isCreating: searchParams.get(QueryArgs.CREATING_RESOURCE) === "true",
    pane: {
      left: searchParams.get(QueryArgs.PANE.HIDING_LEFT) === "true",
      right: searchParams.get(QueryArgs.PANE.HIDING_RIGHT) === "true",
    }
  }), [searchParams])

  const actions = useMemo(() => ({
    setTab: (tab, options = {}) => {
      if (!options) {
        options = {}
      }

      setSearchParams(() => {
        return {[QueryArgs.TAB]: tab, ...options}
      }, {replace: true})
    },
    setIsCreating: (isCreating, options = {}) => {
      if (!options) {
        options = {}
      }

      const cleanValue = value => {
        if (value == null || value === "null") {
          return null
        }

        return value
      }

      setSearchParams(old => {
        const newParams = {...options}

        const loadDefault = params => {
          for (let param of params) {
            const value = cleanValue(old.get(param))
            if (value == null) {
              continue
            }

            newParams[param] = value
          }
        }

        loadDefault([QueryArgs.TAB, QueryArgs.RESOURCE_TYPE, QueryArgs.PANE.HIDING_RIGHT])

        const resolveValue = key => {
          let value = newParams[key]
          if (value != null) {
            return value
          }

          return cleanValue(old.get(key))
        }

        const previousResourceId = resolveValue(QueryArgs.PREVIOUS_RESOURCE_ID)

        if (isCreating) {
          newParams[QueryArgs.CREATING_RESOURCE] = true
        } else if (previousResourceId) {
          newParams[QueryArgs.RESOURCE_ID] = previousResourceId
        }

        return newParams
      }, {replace: true})
    },
    setZone: zoneId => {
      setSearchParams(() => {
        const changes = {
          [QueryArgs.TAB]: TabIds.LAYOUT,
          [QueryArgs.RESOURCE_TYPE]: LayoutResourceType.ZONE,
        }

        if (zoneId != null) {
          changes[QueryArgs.RESOURCE_ID] = zoneId
        }

        return changes
      }, {replace: true})
    },
    setAp: apId => {
      setSearchParams(() => {
        const changes = {
          [QueryArgs.TAB]: TabIds.LAYOUT,
          [QueryArgs.RESOURCE_TYPE]: LayoutResourceType.AP,
        }

        if (apId != null) {
          changes[QueryArgs.RESOURCE_ID] = apId
        }

        return changes
      }, {replace: true})
    },
    setPaneShow: (key, show) => {
      setSearchParams(old => {
        if (show) {
          old.set(key, "true")
        } else {
          old.delete(key)
        }

        return old
      }, {replace: true})
    },
  }), [setSearchParams])

  return [params, actions]
}
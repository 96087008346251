import Card from "../../shared/components/card";
import {EntryInput, EntrySelector} from "../../shared/components/modal";
import {useCallback, useReducer} from "react";
import {Button} from "../../shared/components/buttons";
import Api from "../../shared/api/api";
import {ErrorToast, SuccessToast} from "../../shared/utils/toast";
import Indicator from "../../shared/components/loader";

const JobOptions = {
  WIFI_SESSION: {"label": "Wifi Session", value: "wifi_sessions"},
  STUDENT: {"label": "Student", value: "wifi_student_parser"},
  FACILITY_USAGE: {"label": "Facility Usage", value: "facility_usage"},
  CLASS_ATTENDANCE: {"label": "Class Attendance", value: "class_attendance"},
  WEEKLY_BEHAVIOR: {"label": "Weekly Behavior", value: "derived_variable"},
  EVENT_ATTENDANCE: {"label": "Event Attendance", value: "event_attendance"},
}

const DEFAULT_DATA = {
  loading: false,
  job: null,
  start: null,
  end: null
}

const SET_LOADING = "SET_LOADING"
const SET_VALUE = "SET_VALUE"

const SparkViewReducer = (state = DEFAULT_DATA, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case SET_VALUE:
      return {
        ...state,
        [action.key]: action.payload
      }

    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export function SparkView({school}) {
  const [state, dispatch] = useReducer(SparkViewReducer, DEFAULT_DATA)

  const setLoading = useCallback(loading => dispatch({type: SET_LOADING, payload: loading}), [])

  const handleOnJobChange = useCallback((job) => dispatch({type: SET_VALUE, key: "job", payload: job}), [])
  const handleOnStartChange = useCallback((start) => dispatch({type: SET_VALUE, key: "start", payload: start}), [])
  const handleOnEndChange = useCallback((end) => dispatch({type: SET_VALUE, key: "end", payload: end}), [])

  const jobOptions = Object.values(JobOptions).filter(v => !state.job || state.job.value !== v.value)

  const handleOnRun = useCallback(() => {
    setLoading(true)
    Api.post(`schools/${school.id}/spark`, {job: state.job.value, start: state.start, end: state.end})
      .then(() => {
        SuccessToast("Job started successfully.")
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)

        if (e.handled) {
          return
        }

        console.log(e.error)
        ErrorToast("Unable to start job")
      })
  }, [school?.id, setLoading, state])

  const isButtonDisabled = !school?.id || !state.job || !state.start

  return (
    <Card className="spark-view">
      <div className="spark-view-content">
        <span>Run Job</span>
        <EntrySelector label="Job" value={state.job} options={jobOptions} onChange={handleOnJobChange}/>
        <EntryInput label="Start Date" type="date" value={state.start} onChange={handleOnStartChange}/>
        <EntryInput label="End Date" type="date" value={state.end} onChange={handleOnEndChange}/>
      </div>
      <div className="spark-view-buttons">
        <Button variant="primary" disabled={isButtonDisabled} onClick={handleOnRun}>Run</Button>
      </div>
      <Indicator show={state.loading}/>
    </Card>
  )
}
import {DataShed} from "./sheds/base"
import {WirelessConfigDepo} from "./depos/wireless_config"

class DataWareHouse {
  constructor() {
    this.onSchoolChangedListeners = []

    this.users = new DataShed()
    this.schools = new DataShed()
    this.wireless_config = new WirelessConfigDepo()
  }

  addSchoolChangeListener(callback) {
    this.onSchoolChangedListeners.push(callback)
  }

  removeSchoolChangeListener(callback) {
    this.onSchoolChangedListeners = this.onSchoolChangedListeners.filter(l => l !== callback)
  }

  onSchoolChanged(school) {
    for (let listener of this.onSchoolChangedListeners) {
      listener(school)
    }
  }
}

const WareHouse = new DataWareHouse()

export default WareHouse
import React, {useEffect, useState} from "react";
import Api from "../shared/api/api";
import Indicator from "../shared/components/loader";

export default function Console({school}) {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(null);
  const [error, setError] = useState("Skippy");

  const schoolId = school != null ? school.id : null

  useEffect(() => {
    if (schoolId == null) return

    const controller = new AbortController();
    Api.get(`/console/${schoolId}`, {signal: controller.signal})
      .then(response => {
        setLoading(false)
        setUrl(response.data.url)
        setError(null)
      })
      .catch(e => {
        setLoading(false)
        setError("Unable to load dashboard")

        if (e.handled) return

        console.error(e.error)
      })

    return () => controller.abort()
  }, [schoolId]);

  if (loading) {
    return (
      <>
        <Indicator show={true}/>
      </>
    )
  }

  if (error) {
    return (
      <div className="error-container">
        <div>
          <span>{error}</span>
        </div>
      </div>
    )
  }

  return (
    <iframe
      className="da-quicksight-console"
      title="Quicksight Console"
      width="100%"
      height="100%"
      src={url}
    />
  )
}
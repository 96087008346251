import React, {useCallback} from "react";

import {useDataContext} from "../hooks/context";
import {TabIds} from "../consts";

import {TitleView} from "../shared/titleview";
import {DescriptionView} from "../shared/descriptionview";
import {ListView} from "../../../shared/components/list";
import {AttributeItem, formatResource, TitledAttributes} from "../shared/components";
import {ErrorToast} from "../../../shared/utils/toast";
import {Select} from "../../../shared/components/select";
import Warehouse from "../../../data/warehouse";
import {AttributeSort} from "../../../utils/sort";

export function AccessPointDetailView() {
  const {selectedTab, selectedAp, updateAp} = useDataContext()

  const handleOnUpdate = useCallback(values => {
    updateAp(values)
  }, [updateAp])

  if (selectedTab !== TabIds.LAYOUT || !selectedAp) {
    return null
  }

  return (
    <div className="wireless-config-detail">
      <TitleView
        isCreating={false}
        resource={selectedAp}
        placeholder="Ap Name"
        onUpdate={handleOnUpdate}
        // onDelete={handleOnDelete}
      />
      <ListView>
        <DescriptionView
          isCreating={false}
          resource={selectedAp}
          onUpdate={handleOnUpdate}
        />
        <BaseAttributes/>
      </ListView>
      {/*<Buttons/>*/}
    </div>
  )
  // const handleOnEdit = (key, value) => {
  //   const data = {
  //     [key]: value
  //   }
  //
  //   onEdit && onEdit(data)
  // }
  //
  // const hasPatterns = ap.patterns.length > 0
  // const hasManual = hasPatterns && ap.patterns.includes("manual")
  //
  // return (
  //   <div className="wireless-config-overlay-right-pane-ap">
  //     <div className="wireless-config-overlay-right-pane-ap-header">
  //       <Cycle
  //         disabled={cycleDisabled}
  //         onPrevious={onCyclePrevious}
  //         onNext={onCycleNext}
  //       />
  //     </div>
  //     <div className="wireless-config-overlay-right-pane-ap-content">
  //       <span className="wireless-config-overlay-right-pane-ap-content-title">{ap.name}</span>
  //       <div className="wireless-config-overlay-right-pane-ap-content-sliders">
  //         <EntrySlider disabled label="Ignored" value={ap.ignored}/>
  //         <EntrySlider label="Ignore Override" value={hasManual} onChange={() => handleOnEdit("ignored", !hasManual)}/>
  //       </div>
  //       <div className="wireless-config-overlay-right-pane-ap-content-patterns">
  //         <div className="da-modal-entry-input">
  //           <span className="da-modal-entry-input-label">Ignored Patterns</span>
  //           {!hasPatterns && <EmptyView>No Matching Patterns</EmptyView>}
  //           {hasPatterns && (
  //             <div className="da-modal-entry-input-content wireless-config-overlay-right-pane-ap-content-pattern-item">
  //               {ap.patterns.map(p => (
  //                 <span key={p}>{p}</span>
  //               ))}
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

function BaseAttributes() {
  const {selectedAp, updateAp} = useDataContext()

  let parentOptions = Warehouse.wireless_config.getZones().getFloorZones()
    .filter(z => z.hasFloors())

  const buildingFloors = []
  const otherFloors = []

  for (let zone of parentOptions) {
    if (zone.buildingId === selectedAp?.buildingId) {
      buildingFloors.push(zone)
    } else {
      otherFloors.push(zone)
    }
  }

  parentOptions = [
    ...buildingFloors.sort(AttributeSort("name")),
    ...otherFloors.sort(AttributeSort("name")),
  ]

  const parent = selectedAp?.getFloorZone()
  if (parent) {
    parentOptions = parentOptions.filter(o => o.id !== parent.id)
  }

  return (
    <TitledAttributes>
      <AttributeItem label="PARENT">
        <Select
          placeholder="--"
          value={formatResource(parent)}
          options={parentOptions.map(formatResource)}
          isSearchable
          onChange={newParent => {
            updateAp({
              body: {
                building_id: newParent.value.buildingId,
                floor: newParent.value.floors[0],
              },
              onError: () => {
                ErrorToast("Unable to assign Building")
              }
            })
          }}
        />
      </AttributeItem>
    </TitledAttributes>
  )
}
import {ReactComponent as SearchIcon} from "../assets/icons/search_icon.svg"
import {InlineIndicator} from "./loader"
import {ClearButton} from "./buttons"
import {createRef} from "react";

export default function Search({placeholder = "Search", search, isSearching, onSearch, ...props}) {
  const inputRef = createRef()
  const showClearButton = search != null && search !== ""

  let {className} = props

  if (className == null) className = "da-search"
  else className += " da-search"

  search = search || ""

  return <div className={className} onClick={() => inputRef.current?.focus()}>
    <SearchIcon/>
    <input
      className="da-search-input"
      ref={inputRef}
      type="text"
      placeholder={placeholder}
      value={search}
      onChange={(e) => {
        let value = e.target.value
        onSearch && onSearch(value.length ? value : null)
      }}
    />
    <InlineIndicator show={isSearching}/>
    {showClearButton && <ClearButton onClick={() => onSearch && onSearch(null)}/>}
  </div>
}
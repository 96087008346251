import {ReactComponent as BackgroundImageSvg} from "../assets/images/login-background.svg";
import {useEffect, useState} from "react";

const SVG_WIDTH = 1420

export const BackgroundImage = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    const getWidth = () => {
      const width = window.innerWidth
      const currentCount = Math.max(1, Math.ceil(width / SVG_WIDTH))

      if (count !== currentCount) {
        setCount(currentCount)
      }
    }

    window.addEventListener("resize", getWidth);
    getWidth()

    return () => {
      window.removeEventListener("resize", getWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStyle = () => {
    return {
      display: "grid",
      gridTemplateColumns: `repeat(${count}, ${SVG_WIDTH}px)`,
    }
  }

  const getImages = () => {
    const images = []
    for (let i = 0; i < count; i++) {
      images.push(<BackgroundImageSvg key={i.toString()}/>)
    }

    return images
  }

  return (
    <div className="login-redirect-background">
      <div style={getStyle()}>
        {getImages()}
      </div>
    </div>
  )
}
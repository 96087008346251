import axios from "axios";
import {ErrorToast} from "../utils/toast";

export const SHORT_POLL_INTERVAL = 5000;
export const POLL_INTERVAL = 15000;

const api = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_API_BASE}/api/v1.0`,
})

export const getErrorMessage = (error) => {
  if (!error || !error.response) return null

  const data = error.response.data
  if (!data) return null

  return (!data.error || !data.error.error_code || !data.error.message) ? null : data.error.message
}

const defaultErrorHandler = (error) => {
  if (!error || !error.response) return false

  const status = error.response.status

  if (status === 401) {
    ErrorToast("You do not have permissions to perform this action")
    return true
  }

  if (status === 403) {
    window.location = error.response.data.error.context.login_url
    return true
  }

  return false
}

class Api {
  constructor() {
    this.errorHandler = defaultErrorHandler
  }

  registerErrorHandler(errorHandler) {
    this.errorHandler = errorHandler
  }

  get(route, config) {
    return new Promise((resolve, reject) => {
      api.get(route, config)
        .then(response => resolve(response))
        .catch(error => {
          const handled = this.errorHandler(error)
          reject({error, handled})
        })
    })
  }

  post(route, data, config) {
    return new Promise((resolve, reject) => {
      api.post(route, data, config)
        .then(response => resolve(response))
        .catch(error => {
          const handled = this.errorHandler(error)
          reject({error, handled})
        })
    })
  }

  put(route, data, config) {
    return new Promise((resolve, reject) => {
      api.put(route, data, config)
        .then(response => resolve(response))
        .catch(error => {
          const handled = this.errorHandler(error)
          reject({error, handled})
        })
    })
  }

  delete(route, config) {
    return new Promise((resolve, reject) => {
      api.delete(route, config)
        .then(response => resolve(response))
        .catch(error => {
          const handled = this.errorHandler(error)
          reject({error, handled})
        })
    })
  }
}

const API = new Api()
export default API
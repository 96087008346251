import {AttributeSort} from "../../utils/sort";
import {ZoneType} from "../../pages/wireless_config/consts";
import Warehouse from "../warehouse";
import {keyLoader} from "../../shared/utils/object";
import {convertOperationalHoursToDisplay} from "../../shared/utils/date/manager";

const ROOT_LEVEL_TYPES = new Set([ZoneType.SCHOOL, ZoneType.CAMPUS])

export class OperationHour {
  constructor(data) {
    this.day_of_week = data.day_of_week
    this.start_time = data.start_time
    this.end_time = data.end_time
  }
}

export class Maintenance {
  constructor(data) {
    this.yearly = data?.yearly
    this.deferred = data?.deferred
  }
}

export class Zone {
  constructor(data) {
    this.id = data?.id

    this.name = data?.name
    this.description = data?.description

    this.type = data?.type || ZoneType.DEFAULT
    this.is_root = data?.is_root || false

    this.floors = data?.floors ? [...data.floors] : []
    this.rooms = data?.rooms ? [...data.rooms] : []

    this.buildingId = keyLoader(data, ["buildingId", "building.id", "building_id"])
    this.parentId = keyLoader(data, ["parentId", "parent.id", "parent_id"])

    this.primaryUsageId = keyLoader(data, ["primaryUsageId", "primary_usage.id"])
    this.secondaryUsageId = keyLoader(data, ["secondaryUsageId", "secondary_usage.id"])

    this.has_geojson = data?.has_geojson || false
    this.inherit_geojson = data?.inherit_geojson || false

    this.square_footage = data?.square_footage
    this.capacity = data?.capacity

    this.visibility_option = data?.visibility_option != null ? data?.visibility_option : 1
    this.utilization = data?.utilization != null ? data?.utilization : 1

    this.aliases = []
    if (this.type === ZoneType.BUILDING && data?.aliases?.length) {
      this.aliases = [...data.aliases]
    }

    this.operatingHours = []
    this.operatingDisplayHours = []
    this.hasOperatingHours = false

    const hours = keyLoader(data, ["operating_hours", "operatingHours"])
    if (this.type === ZoneType.BUILDING && hours?.length) {
      this.operatingHours = hours.map(h => new OperationHour(h))
      this.operatingDisplayHours = convertOperationalHoursToDisplay(this.operatingHours)
      this.hasOperatingHours = true
    }

    this.maintenance = new Maintenance(data.maintenance)
    if (data.yearly_maintenance_dollars != null) {
      this.maintenance.yearly = data.yearly_maintenance_dollars
    }

    if (data.deferred_maintenance_dollars != null) {
      this.maintenance.deferred = data.deferred_maintenance_dollars
    }

    this.layout = data?.layout
    if (!this.layout?.icon_color) {
      this.layout = {
        ...data?.layout,
        icon_color: "rgba(255, 162, 23, 1)"
      }
    }
  }

  raw() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      type: this.type,
      is_root: this.is_root,
      floors: this.floors?.length ? [...this.floors] : [],
      rooms: this.rooms?.length ? [...this.rooms] : [],
      building_id: this.buildingId,
      parent_id: this.parentId,
      primary_usage_id: this.primaryUsageId,
      secondary_usage_id: this.secondaryUsageId,
      has_geojson: this.has_geojson,
      inherit_geojson: this.inherit_geojson,
      square_footage: this.square_footage,
      capacity: this.capacity,
      visibility_option: this.visibility_option,
      operating_hours: this.operatingHours?.length ? this.operatingHours : [],
      utilization: this.utilization,
      yearly_maintenance_dollars: this.maintenance.yearly,
      deferred_maintenance_dollars: this.maintenance.deferred,
    }
  }

  isSelectable() {
    return ![ZoneType.SCHOOL, ZoneType.CAMPUS].includes(this.type)
  }

  getFloorZone() {
    let current = this
    while (current != null) {
      if (current.type === ZoneType.FLOOR) {
        return current
      }

      current = current.getParent()
    }

    return null
  }

  getRootLevel() {
    let current = this.getParent()
    while (current) {
      if (!ROOT_LEVEL_TYPES.has(current.type)) {
        return current
      }

      current = current.getParent()
    }

    return null
  }

  hasBuilding() {
    return this.buildingId != null
  }

  getBuilding() {
    if (!this.buildingId) {
      return null
    }

    return Warehouse.wireless_config.getBuildings().getById(this.buildingId)
  }

  getBuildingZone() {
    if (this.type === ZoneType.BUILDING) {
      return this
    }

    let current = this.getParent()
    while (current) {
      if (current.type === ZoneType.BUILDING) {
        return current
      }

      current = current.getParent()
    }

    return null
  }

  hasParent() {
    return this.parentId != null
  }

  getParent() {
    if (!this.hasParent()) {
      return null
    }

    return getZoneById(this.parentId)
  }

  parentIter() {
    const out = []

    let current = this.getParent()
    while (current != null) {
      out.push(current)
      current = current.getParent()
    }

    return out
  }

  isBuildingZone() {
    return this.type === ZoneType.BUILDING
  }

  isFloorZone() {
    return this.type === ZoneType.FLOOR
  }

  hasFloors() {
    return this.floors?.length > 0
  }

  // hasAps() {
  //   return this.hasBuilding() && this.hasFloors() && Warehouse.wireless_config.getAps().getByBuildingFloor(this.buildingId, this.floors[0]).length > 0
  // }

  getApsByFloor() {
    let aps = Warehouse.wireless_config.getAps().getByBuildingFloor(this.buildingId, this.floors[0])
    const limiter = Warehouse.wireless_config.getResourceLimiter()
    if (limiter?.apIds) {
      aps = aps.filter(ap => limiter.apIds.includes(ap.id))
    }

    return aps
  }

  // getApsInZone() {
  //   const aps = Warehouse.wireless_config.getAps().getByBuildingFloor(this.buildingId, this.floors[0])
  //   debugger
  //   return []
  // }

  hasSubZones() {
    return this.getSubZones().length > 0
  }

  getSubZones() {
    let zones = Warehouse.wireless_config.getZones().filter(z => z.parentId === this.id).sort(AttributeSort("name"))
    const limiter = Warehouse.wireless_config.getResourceLimiter()
    if (limiter?.zoneIds) {
      zones = zones.filter(zone => limiter.zoneIds.includes(zone.id))
    }

    return zones
  }

  subZoneIter(filter) {
    function* handler(zones) {
      for (let child of zones) {
        if (!filter || filter(child)) {
          yield child
        }

        for (let innerChild of handler(child.getSubZones())) {
          if (!filter || filter(innerChild)) {
            yield innerChild
          }
        }
      }
    }

    return Array.from(handler(this.getSubZones()))
  }

  getPrimaryUsage() {
    if (!this.primaryUsageId) {
      return null
    }

    return Warehouse.wireless_config.getUsages().getById(this.primaryUsageId)
  }

  getSecondaryUsage() {
    if (!this.secondaryUsageId) {
      return null
    }

    return Warehouse.wireless_config.getUsages().getById(this.secondaryUsageId)
  }

  isComplete() {
    if ([ZoneType.SCHOOL, ZoneType.CAMPUS].includes(this.type)) {
      return true
    }

    if (!this.hasBuilding()) {
      return false
    }

    if (!this.hasFloors()) {
      return false
    }

    if (this.type !== ZoneType.BUILDING && !this.hasParent()) {
      return false
    }

    if (this.getBuilding()?.hasInvalidAps()) {
      return false
    }

    return this.has_geojson
  }

  getAliases() {
    return this.aliases
  }

  getOperatingHours() {
    return this.operatingDisplayHours
  }

  copy(other) {
    return new Zone({
      ...this,
      ...other,
    })
  }
}

const getZoneById = zoneId => {
  if (!zoneId) {
    return null
  }

  return Warehouse.wireless_config.getZones().getById(zoneId)
}
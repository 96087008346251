import {AttributeSort} from "../../utils/sort";

export class DataShed {
  constructor(onDataChange) {
    this._values = []
    this._onDataChangeListener = onDataChange
  }

  getById(id) {
    return this._values.find(v => v.id === id)
  }

  getAll() {
    if (!this._values) {
      return []
    }

    return this._values
  }

  filter(func) {
    return this.getAll().filter(func)
  }

  setAll(schoolId, values) {
    if (!values) {
      values = []
    }

    if (Array.isArray(values)) {
      this._values = values
    } else if (typeof values === "object") {
      this._values = values.data
    } else {
      throw new Error("Invalid values type")
    }

    this._handleChange(schoolId)
    this._onDataChangeListener && this._onDataChangeListener()
  }

  add(value) {
    if (!value) {
      return
    }

    this.addBulk([value])
  }

  addBulk(values) {
    if (!values?.length) {
      return
    }

    const ids = values.map(v => v.id)
    this._values = [...this._values.filter(v => !ids.includes(v.id)), ...values]
      .sort(AttributeSort("name"))

    this._handleChange()
    this._onDataChangeListener && this._onDataChangeListener()
  }

  remove(id) {
    if (!id) {
      return
    }

    this.removeBulk([id])
  }

  removeBulk(ids) {
    if (!ids?.length) {
      return
    }

    this._values = this._values.filter(v => !ids.includes(v.id))

    this._handleChange()
    this._onDataChangeListener && this._onDataChangeListener()
  }

  clear() {
    this._values = []
    this._handleChange()
  }

  _handleChange(schoolId) {
  }
}


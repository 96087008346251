import React, {useState} from "react"
import {Link} from "react-router-dom";

import Card from "../card";
import {Dropdown} from "./dropdown";
import Divider from "../divider";
import NameIcon from "../name_icon";
import {Badge} from "../badge";

const formatDropdownOptions = (options, onDropdownItemSelected) => {
  const out = []

  options.forEach((items, index) => {
    if (index > 0) out.push(<Divider key={`navgroup-divider-${index}`}/>)
    out.push(<DropdownGroup key={index} id={`navgroup-${index}`} items={items} onItemSelected={onDropdownItemSelected}/>)
  })

  return out
}

export default function NavigationBar({user, currentId, navigationOptions, dropdownOptions, onDropdownItemSelected, ...props}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const {children} = props

  const handleDropdownItemSelected = (...args) => {
    setShowDropdown(false)
    onDropdownItemSelected && onDropdownItemSelected(...args)
  }

  return (
    <Card className="da-navbar">
      <div className="da-navbar-layout">
        <div className="da-navbar-layout-content">
          <img src="/images/da_logo.png" alt="DegreeAnalyitcs Logo"/>
          <div>
            {navigationOptions.routes.map((option, index) => (
              <NavigationBarItem key={`navbar${index}`} id={option.id} currentId={currentId} label={option.label} route={option.route != null ? option.route : option.id} search={option.search}>
                {option.icon}
              </NavigationBarItem>
            ))}
          </div>
        </div>
        <div className="da-navbar-layout-actions">
          {children}
          <div className="nav-username">
            <NameIcon name={user.name} badgeCount={navigationOptions.badgeCount} onClick={() => setShowDropdown(true)}/>
            <Dropdown className="nav-dropdown" show={showDropdown} options={[]} onClose={() => setShowDropdown(false)}>
              <div className="nav-dropdown-content">
                {formatDropdownOptions(dropdownOptions, handleDropdownItemSelected)}
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <div id="da-navbar-tabs"/>
    </Card>
  )
}

function NavigationBarItem({id, currentId, route, search, label, ...props}) {
  const {children} = props
  id = id != null ? id : route
  const isSelected = id === currentId;
  const fullRoute = !search ? route : `${route}${search}`

  return (
    <div className="da-nav-item" aria-selected={isSelected}>
      {children}
      <Link to={fullRoute}>{label}</Link>
    </div>
  )
}

export function DropdownGroup({id, items, onItemSelected}) {
  return (
    <div className="nav-dropdown-group">
      {items.map((item, i) => (
        <DropdownItem key={`${id}${i}`} item={item} onItemSelected={onItemSelected}>
          {item.icon}
        </DropdownItem>
      ))}
    </div>
  )
}

export function DropdownItem({item, onItemSelected, ...props}) {
  let {style, children} = props
  const classNames = ["da-nav-dropdown-item"]
  if (item.className != null) classNames.push(item.className)

  return (
    <div className={classNames.join(" ")} style={style} onClick={() => onItemSelected && onItemSelected(item.id)}>
      {children}
      <div>
        <div className="da-nav-dropdown-item-layout">
          <span>{item.label}</span>
          <Badge count={item.badgeCount}/>
        </div>
      </div>
    </div>
  )
}
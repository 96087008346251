import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";

import {Button, CloseButton, CopyButton} from "./buttons";
import Card from "./card";
import {ColoredSelect, CreatableSelect, Select} from "./select";

import {formatString} from "../utils/format";

import {ReactComponent as CalendarIcon} from "../assets/icons/calendar_icon.svg";
import {getPredictiveDate} from "../utils/date/manager";
import {Slider} from "./slider";
import {onEnterKey} from "../utils/key_events";

export function Modal({show, cardClassName, onHide, cardRef, ...props}) {
  if (!show) return null

  if (!cardClassName) cardClassName = "da-modal-content";
  else cardClassName = `da-modal-content ${cardClassName}`;

  return createPortal(
    <div className="da-modal" ref={cardRef} {...props}>
      <div className="da-modal-content-background" onClick={() => onHide && onHide()}/>
      <Card className={cardClassName}>
        {props.children}
      </Card>
    </div>,
    document.getElementById("modal-content")
  )
}

export function EntryModal({show, title, onHide, buttons, additionalHeaderOptions, cardClassName, bodyClassName, ...props}) {
  show = !!show
  const hasButtons = !!buttons

  if (cardClassName == null) cardClassName = "da-modal-entry-content"
  else cardClassName += " da-modal-entry-content"

  if (bodyClassName == null) bodyClassName = "da-modal-entry-body"
  else bodyClassName += " da-modal-entry-body"

  return (
    <Modal cardClassName={cardClassName} show={show} onHide={onHide} {...props}>
      <div className="da-modal-entry-header">
        <span>{title}</span>
        <div>
          {additionalHeaderOptions}
          <CloseButton onClick={() => onHide && onHide()}/>
        </div>
      </div>
      <div className={bodyClassName}>
        {props.children}
      </div>
      {hasButtons && <div className="da-modal-entry-footer">
        {buttons.secondary != null && <Button variant="secondary" disabled={buttons.secondary.disabled} onClick={buttons.secondary.onClick}>{buttons.secondary.title}</Button>}
        {buttons.primary != null && <Button variant="primary" disabled={buttons.primary.disabled} onClick={buttons.primary.onClick}>{buttons.primary.title}</Button>}
        {buttons.danger != null && <Button variant="danger" disabled={buttons.danger.disabled} onClick={buttons.danger.onClick}>{buttons.danger.title}</Button>}
      </div>}
    </Modal>
  )
}

export function DeleteEntryModal({show, value, onHide, onDelete, cardClassName, bodyClassName, ...props}) {
  const {children, ...rest} = props
  const [current, setCurrent] = useState("")

  useEffect(() => {
    setCurrent("")
  }, [show])

  const isDisabled = () => current !== value

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    danger: {
      title: "Delete",
      disabled: isDisabled(),
      onClick: onDelete
    }
  }

  return (
    <EntryModal show={show} title={`Delete ${value}?`} onHide={onHide} buttons={buttons} cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body" {...rest}>
      {children}
      <div>
        <p>Please type <span>{value}</span> to confirm</p>
        <input autoFocus value={current} onChange={(e) => setCurrent(e.target.value)} onKeyUp={onEnterKey(() => {
          if (!isDisabled() && onDelete) {
            onDelete()
          }
        })}/>
      </div>
    </EntryModal>
  )
}

export function EntryInput({label, value, editable = true, copyable = false, multi, autoFocus, type = "text", onChange, onFocus, onBlur, ...props}) {
  const classNames = ["da-modal-entry-input"]
  if (props.className != null) {
    classNames.push(props.className)
  }

  const isMulti = !!multi
  const isEditable = !!editable

  const handleChange = (e) => {
    onChange && onChange(e.target.value)
  }

  const handleFocus = () => {
    onFocus && onFocus()
  }

  const handleBlur = () => {
    onBlur && onBlur()
  }

  return (
    <div className={classNames.join(" ")}>
      <span className="da-modal-entry-input-label">{label}</span>
      <div className="da-modal-entry-input-content">
        {copyable && <CopyButton value={value}/>}
        {isEditable && !isMulti && <input placeholder={props.placeholder} value={value || ""} autoFocus={autoFocus} onChange={handleChange} type={type} onFocus={handleFocus} onBlur={handleBlur}/>}
        {isEditable && isMulti && <textarea placeholder={props.placeholder} value={value || ""} autoFocus={autoFocus} onChange={handleChange}/>}
        {!isEditable && <span className="da-modal-entry-input-label da-modal-entry-input-text">{formatString(value)}</span>}
        {props.children}
      </div>
    </div>
  )
}

export function EntrySlider({label, value, disabled = false, onChange, ...props}) {
  const {className, ...rest} = props
  return (
    <div className={`da-modal-entry-input da-modal-entry-slider ${className || ""}`} {...rest}>
      <span className="da-modal-entry-input-label">{label}</span>
      <Slider disabled={disabled} checked={value} onChecked={onChange}/>
    </div>
  )
}

export function EntrySelector({label, placeholder, value, options, disabled = false, creatable = false, isClearable = false, isMulti = false, isSearchable = true, onChange, ...props}) {
  const {className, ...rest} = props

  creatable = !!creatable
  isMulti = !!isMulti
  isSearchable = !!isSearchable
  isClearable = !!isClearable

  const classNames = ["da-modal-entry-input", "da-modal-entry-select"]
  if (className != null) classNames.push(className)

  const handleOnChange = (value) => {
    onChange && onChange(value)
  }

  return (
    <div className={classNames.join(" ")} {...rest}>
      <span className="da-modal-entry-input-label">{label}</span>
      <div>
        {creatable &&
          <CreatableSelect
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            options={options}
            isMulti={isMulti}
            isSearchable={isSearchable}
            isClearable={isClearable}
            onChange={handleOnChange}
          />
        }
        {!creatable &&
          <Select
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            options={options}
            isMulti={isMulti}
            isSearchable={isSearchable}
            isClearable={isClearable}
            onChange={handleOnChange}
          />
        }
      </div>
    </div>
  )
}

export function EntryColoredSelector({label, value, options, onChange}) {
  return (
    <div className="da-modal-entry-input da-modal-entry-input-colored-selector">
      <span className="da-modal-entry-input-label">{label}</span>
      <ColoredSelect value={value} options={options} onChange={onChange}/>
    </div>
  )
}

export function EntryResource({label, ...props}) {
  const {children} = props

  return (
    <div className="da-modal-entry-input da-modal-entry-input-colored-selector">
      <span className="da-modal-entry-input-label">{label}</span>
      {children}
    </div>
  )
}

export function EntryDate({label, value, onChange, ...props}) {
  const inputRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)
  const [autocomplete, setAutocomplete] = useState(null)
  const [predictive, setPredictive] = useState(null)

  useEffect(() => {
    if (!isFocused || !autocomplete) {
      return
    }

    const handleAutocomplete = (e) => {
      if (e.code !== "Tab" && e.code !== "Enter") {
        return
      }

      onChange && onChange(autocomplete)
      setAutocomplete(null)
      setPredictive(null)
    }

    window.addEventListener("keydown", handleAutocomplete)
    return () => window.removeEventListener("keydown", handleAutocomplete)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, autocomplete]);

  const {className, ...rest} = props

  const classNames = ["da-modal-entry-input", "da-modal-entry-date"]
  if (className != null) classNames.push(className)

  const focusInput = () => {
    if (!inputRef.current) {
      return
    }

    inputRef.current.focus()
  }

  const handleOnFocus = () => setIsFocused(true)
  const handleOnBlur = () => setIsFocused(false)

  const handleOnChange = (e) => {
    let newValue = e.target.value
    let result = getPredictiveDate(newValue)

    if (!result) {
      return
    }

    if (newValue === "" && (value || "").length) {
      newValue = newValue.slice(0, -1)
    }

    if (newValue.endsWith(" ")) {
      const components = newValue.split(" ")
        .map(v => v.trim())
        .filter(v => v !== "")

      if (components.length === 1) {
        newValue = `${result.month} `
      } else if (components.length === 2) {
        newValue = `${result.month} ${result.day}, `
      } else if (components.length === 3) {
        newValue = result.text
      } else {
        return
      }

      result = getPredictiveDate(newValue)
      if (result) {
        setAutocomplete(result.text)
        setPredictive(result.predictive)
      }
    } else {
      setAutocomplete(result.text)
      setPredictive(result.predictive)
    }

    if (newValue !== value) {
      onChange && onChange(newValue)
    }
  }

  return (
    <div className={classNames.join(" ")} {...rest} onClick={focusInput}>
      <span>{label}</span>
      <div>
        <div className="da-modal-entry-date-input">
          <div>
            <span className="da-modal-entry-date-input-content">{value}</span>
            <input ref={inputRef} value={value || ""} onChange={handleOnChange} onFocus={handleOnFocus} onBlur={handleOnBlur}/>
            <span>{predictive}</span>
          </div>
          <CalendarIcon/>
        </div>
      </div>
    </div>
  )
}

export function ContactAdminModal({show, title, placeholderOptions, onContact, onHide}) {
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    setMessage("")
  }, [show]);

  let placeholder
  let usePlaceholder
  if (!placeholderOptions) {
    placeholder = "Your message..."
    usePlaceholder = false
  } else if (placeholderOptions.message) {
    placeholder = placeholderOptions.message
    usePlaceholder = true
  } else if (placeholderOptions.resource) {
    placeholder = `Hi! This is ${placeholderOptions.resource.user.name} and I'm requesting permissions to be a ${placeholderOptions.resource.value} editor`
    usePlaceholder = true
  } else {
    console.debug("Unknown placeholderOption format")
    placeholder = "Your message..."
    usePlaceholder = false
  }


  const handleOnClick = () => {
    const value = message.trim().length ? message.trim() : placeholder
    onContact && onContact(value)
  }

  const isButtonDisabled = () => {
    return !usePlaceholder && !message.length
  }

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide,
    },
    primary: {
      title: "Request",
      disabled: isButtonDisabled(),
      onClick: handleOnClick,
    }
  }

  return (
    <EntryModal show={show} title={title} onHide={onHide} buttons={buttons} cardClassName="da-modal-contact-admin-card" bodyClassName="da-modal-entry-input">
      <textarea autoFocus value={message} placeholder={placeholder} onChange={e => setMessage(e.target.value)}/>
    </EntryModal>

  )
}

import React, {useEffect, useReducer, useRef, useState} from "react";
import {TabBody, TabHeader, Tabs} from "../../../../shared/components/tabs";
import Indicator from "../../../../shared/components/loader";
import {EntryModal} from "../../../../shared/components/modal";
import Search from "../../../../shared/components/search";
import {EmptyView} from "../../../../shared/components/views";
import {CreatableSelect} from "../../../../shared/components/select";
import {SchoolIcon} from "../../../icons/school";
import {formatString, formatTimestamp} from "../../../../shared/utils/format";
import {ReactComponent as CheckIcon} from "../../../../shared/assets/icons/item_selected_icon.svg"
import {ReactComponent as CaretIcon} from "../../../../shared/assets/icons/next_icon.svg"
import {Button, IndicatorButton} from "../../../../shared/components/buttons";
import {POLL_INTERVAL} from "../../../../shared/api/api";

const INTEGRATIONS_TAB = {id: "integrations", label: "Integrations"}
const PENDING_TAB = {id: "pending", label: "Pending"}

const TABS = [
  INTEGRATIONS_TAB,
  PENDING_TAB,
]

const DEFAULT_DATA = {
  search: {
    isSearching: false,
    value: null,
    integrations: [],
  },
  integrations: {
    expanded: {}
  },
  approvePendingModal: {
    show: false,
  },
  rejectPendingModal: {
    show: false,
  },
  selectedTabId: INTEGRATIONS_TAB.id,
}

const SET_SEARCH = "SET_SEARCH"

const SHOW_APPROVE_PENDING_MODAL = "SHOW_APPROVE_PENDING_MODAL"
const SHOW_REJECT_PENDING_MODAL = "SHOW_REJECT_PENDING_MODAL"

const RESET = "RESET"
const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"
const SET_INTEGRATION_EXPANDED = "SET_INTEGRATION_EXPANDED"

const mapIntegration = (searchLower) => {
  return (integration) => {
    const schoolIds = integration.schools.filter(s => s.school.name.toLowerCase().includes(searchLower))
      .map(s => s.school.id)

    let schools = [...integration.schools]
    if (schoolIds.length > 0) {
      schools = schools.filter(s => schoolIds.includes(s.school.id))
    }

    const response = {
      ...integration,
      schools
    }

    if (integration.name && integration.name.toLowerCase().includes(searchLower)) {
      return response
    }

    if (("active".includes(searchLower) || searchLower === "active") && integration.is_active) {
      return response
    }

    if (((searchLower.startsWith("n") && "not active".includes(searchLower)) || (searchLower.startsWith("!") && "!active".includes(searchLower)) || searchLower === "not active" || searchLower === "!active") && !integration.is_active) {
      return response
    }

    if (("admin".includes(searchLower) || searchLower === "admin") && integration.is_system_admin_only) {
      return response
    }

    if (((searchLower.startsWith("n") && "not admin".includes(searchLower)) || (searchLower.startsWith("!") && "!admin".includes(searchLower)) || searchLower === "not active" || searchLower === "!active") && !integration.is_system_admin_only) {
      return response
    }

    if (integration.tag != null && integration.tag.name.toLowerCase().includes(searchLower)) {
      return response
    }

    if (schoolIds.length) {
      return response
    }

    return null
  }
}


const PaneReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH: {
      if (action.payload.search == null) {
        return {
          ...state,
          search: {
            isSearching: false,
            value: null,
            integrations: [],
          }
        }
      }

      const lowered = action.payload.search.toLowerCase()
      const integrations = action.payload.integrations.map(mapIntegration(lowered)).filter(i => i)

      return {
        ...state,
        search: {
          isSearching: true,
          value: action.payload.search,
          integrations,
        }
      }
    }

    case SHOW_APPROVE_PENDING_MODAL:
      return {
        ...state,
        approvePendingModal: {
          show: action.payload,
        }
      }

    case SHOW_REJECT_PENDING_MODAL:
      return {
        ...state,
        rejectPendingModal: {
          show: action.payload,
        }
      }

    case RESET:
      return DEFAULT_DATA

    case SET_ACTIVE_TAB:
      return {
        ...state,
        selectedTabId: action.payload
      }

    case SET_INTEGRATION_EXPANDED:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          expanded: {
            ...state.integrations.expanded,
            ...action.payload
          }
        }
      }

    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
}

export function AdminIntegrationPane({show, cancelPolling, data, onApprovePending, onRejectPending, onPoll}) {
  const [state, dispatch] = useReducer(PaneReducer, DEFAULT_DATA)

  const setIntegrationsSearch = search => dispatch({type: SET_SEARCH, payload: {search, integrations: data.integrations}})

  const showApprovePendingModal = () => dispatch({type: SHOW_APPROVE_PENDING_MODAL, payload: true})
  const hideApprovePendingModal = () => dispatch({type: SHOW_APPROVE_PENDING_MODAL, payload: false})

  const showRejectPendingModal = () => dispatch({type: SHOW_REJECT_PENDING_MODAL, payload: true})
  const hideRejectPendingModal = () => dispatch({type: SHOW_REJECT_PENDING_MODAL, payload: false})

  const resetData = () => dispatch({type: RESET})
  const setSelectedTab = tab => dispatch({type: SET_ACTIVE_TAB, payload: tab.id})
  const setIntegrationExpanded = (integration, expanded) => dispatch({type: SET_INTEGRATION_EXPANDED, payload: {[integration.id]: expanded}})

  const setSearch = search => {
    setIntegrationsSearch(search)
  }

  const isProcessing = data.pending.filter(p => p.is_processing).length > 0

  useEffect(() => {
    resetData()

    let interval = null
    let controller = null

    if (show && !cancelPolling && isProcessing) {
      interval = setInterval(() => {
        controller = new AbortController()
        onPoll(controller.signal)
      }, POLL_INTERVAL)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      if (controller) {
        controller.abort()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, cancelPolling, isProcessing]);

  if (!show) return null

  const notifications = data.notifications
  const tabs = TABS.map(tab => ({
    ...tab,
    badgeCount: notifications[tab.id]
  }))

  const handleOnApprovePending = () => {
    onApprovePending && onApprovePending(hideApprovePendingModal)
  }

  const handleOnRejectPending = () => {
    onRejectPending && onRejectPending(hideRejectPendingModal)
  }

  let integrations = data.integrations
  if (state.search.isSearching) {
    integrations = state.search.integrations
  }

  return (
    <>
      <Tabs>
        <TabHeader selectedTab={state.selectedTabId} items={tabs} onSelectTab={setSelectedTab}/>
        <TabBody className="settings-integrations-tab-body">
          <IntegrationTab
            show={state.selectedTabId === INTEGRATIONS_TAB.id}
            isSearching={state.search.isSearching}
            search={state.search.value}
            onSearch={setSearch}
            data={integrations}
            tags={data.tags}
            expanded={state.integrations.expanded}
            onExpanded={setIntegrationExpanded}
          />
          <PendingTab
            show={state.selectedTabId === PENDING_TAB.id}
            data={data.pending}
            onApprove={showApprovePendingModal}
            onCancel={showRejectPendingModal}
          />
        </TabBody>
      </Tabs>
      <Indicator show={state.loading}/>
      <ApprovePendingModal
        onHide={hideApprovePendingModal}
        onApprove={handleOnApprovePending}
        {...state.approvePendingModal}
      />
      <CancelPendingModal
        onHide={hideRejectPendingModal}
        onReject={handleOnRejectPending}
        {...state.rejectPendingModal}
      />
    </>
  )
}

function IntegrationTab({show, isSearching, search, data, tags, expanded, onExpanded, onSearch}) {
  if (!show) return null

  const hasIntegrations = !!data && data.length > 0

  return (
    <div className="settings-integrations-tab-body">
      <Search search={search} onSearch={onSearch}/>
      {hasIntegrations &&
        <div className="settings-admin-integration-list">
          <IntegrationHeader/>
          {data.map(integration =>
            <IntegrationItem
              key={integration.id}
              data={integration}
              tags={tags}
              expanded={expanded[integration.id] === true || isSearching}
              onExpanded={onExpanded}
            />
          )}
        </div>
      }
      {!hasIntegrations && <EmptyView>No Integrations</EmptyView>}
    </div>
  )
}

function IntegrationItem({data, tags, expanded, onExpanded}) {
  return (
    <div className="settings-admin-integration" aria-expanded={expanded}>
      <IntegrationItemHeader data={data} tags={tags} expanded={expanded} onExpandChange={onExpanded}/>
      {expanded && <IntegrationSchools data={data.schools}/>}
    </div>
  )
}

function IntegrationHeader() {
  return (
    <div className="settings-admin-integration-header">
      <span/>
      <span>INTEGRATION</span>
      <span>TAG</span>
      <span>ACTIVE</span>
      <span>SINCE</span>
      <span>SYS ADMIN ONLY</span>
    </div>
  )
}

const hasParent = (target, element) => {
  if (target == null || element == null) return false
  if (target === element) return true

  let current = element.parentElement
  while (current != null) {
    if (target === current) return true
    current = current.parentElement
  }

  return false
}

function IntegrationItemHeader({data, tags, expanded, onExpandChange}) {
  const tagRef = useRef(null)

  const handleOnExpand = (e) => {
    const forward = !hasParent(tagRef.current, e.target)
    forward && onExpandChange && onExpandChange(data, !expanded)
  }

  return (
    <div className={`settings-admin-integration-item-header ${expanded ? 'settings-admin-integration-item-header-expanded' : 'settings-admin-integration-item-header-closed'}`} onClick={handleOnExpand}>
      <IntegrationItemHeaderIcon isExpanded={expanded}/>
      <IntegrationItemHeaderTitle name={data.name} description={data.description}/>
      <IntegrationItemHeaderTag tag={data.tag} tags={tags} innerRef={tagRef}/>
      <IntegrationItemHeaderActive isActive={data.is_active}/>
      <IntegrationItemHeaderSince ts={data.status_ts}/>
      <IntegrationItemHeaderSystemAdmin isSystemAdminOnly={data.is_system_admin_only}/>
    </div>
  )
}

function IntegrationItemHeaderIcon({isExpanded}) {
  return (
    <div className="settings-admin-integration-item-header-icon" aria-expanded={isExpanded}>
      <CaretIcon/>
    </div>
  )
}

function IntegrationItemHeaderTitle({name, description}) {
  const hasDescription = !!description && description.length > 0

  return (
    <div className="settings-admin-integration-item-header-title">
      <span>{name}</span>
      {hasDescription && <span>{description}</span>}
    </div>
  )
}

function IntegrationItemHeaderTag({tag, tags, innerRef}) {
  const formatTag = (t) => ({
    label: t.name,
    value: t
  })

  const value = tag != null ? formatTag(tag) : null
  let options = (tags || []).filter(t => tag == null || t.id !== tag.id)
    .map(formatTag)

  return (
    <div className="settings-admin-integration-item-header-tag">
      <div ref={innerRef}>
        <CreatableSelect
          value={value}
          options={options}
        />
      </div>
    </div>
  )
}

function IntegrationItemHeaderActive({isActive}) {
  return <Checked className="settings-admin-integration-item-header-is-active" allowRed checked={isActive}/>
}

function IntegrationItemHeaderSince({ts}) {
  return <span className="settings-admin-integration-item-header-since">{formatTimestamp(ts)}</span>
}

function IntegrationItemHeaderSystemAdmin({isSystemAdminOnly}) {
  return <Checked className="settings-admin-integration-item-header-is-sys-admin" checked={isSystemAdminOnly}/>
}

function IntegrationSchools({data}) {
  const hasSchools = data && data.length > 0

  return (
    <div className="settings-admin-integration-schools">
      {!hasSchools && <EmptyView>No Schools</EmptyView>}
      {hasSchools &&
        <>
          <IntegrationSchoolHeader/>
          {data.map(row =>
            <IntegrationSchoolRow
              key={row.school.id}
              data={row}
            />
          )}
        </>
      }
    </div>
  )
}

function IntegrationSchoolHeader() {
  return (
    <div className="settings-admin-integration-school-header">
      <span>SCHOOL</span>
      <span>CONNECTION STATUS</span>
      <span>SINCE</span>
    </div>
  )
}

function IntegrationSchoolRow({data}) {
  return (
    <div className="settings-admin-integration-school">
      <div className="settings-admin-integration-school__school">
        <SchoolIcon school={data.school}/>
        <span>{data.school.name}</span>
      </div>
      <span>{formatString(data.connection_status)}</span>
      <span className="settings-admin-timestamp">{formatTimestamp(data.connection_ts)}</span>
    </div>
  )
}

function Checked({allowRed, checked, onChecked, ...props}) {
  const {className, ...rest} = props
  const classNames = ["settings-admin-integration-checked"]
  if (className != null) classNames.push(className)

  const handleOnClick = () => {
    onChecked && onChecked(!checked)
  }

  checked = !!checked
  allowRed = !!allowRed

  const clickable = !!onChecked
  const singleColor = !allowRed
  const hasCheckmark = checked || !singleColor

  return (
    <div className={classNames.join(" ")} {...rest}>
      <div className={`settings-admin-integration-checked__checkbox ${singleColor ? 'single-color' : 'double-color'}`} aria-readonly={!clickable} aria-checked={checked} onClick={handleOnClick}>
        {hasCheckmark && <CheckIcon/>}
      </div>
    </div>
  )
}

function PendingTab({show, data, onApprove, onCancel}) {
  if (!show) return null

  if (!data || !data.length) {
    return <EmptyView>No Pending Authorizations</EmptyView>
  }

  data = data[0]

  return (
    <div className="settings-admin-pending">
      <PendingHeader data={data} onApprove={onApprove} onCancel={onCancel}/>
      <PendingSchools data={data.schools}/>
    </div>
  )
}

function PendingHeader({data, onApprove, onCancel}) {
  return (
    <div className="settings-admin-pending-header">
      <IntegrationItemHeaderTitle name={data.name}/>
      <div className="settings-admin-pending-header__buttons">
        {data.is_processing &&
          <IndicatorButton>Processing...</IndicatorButton>
        }
        {!data.is_processing &&
          <>
            <Button variant="danger" onClick={onCancel}>Cancel</Button>
            <Button variant="primary" onClick={onApprove}>Approve</Button>
          </>
        }
      </div>
    </div>
  )
}

function PendingSchools({data}) {
  return (
    <div className="settings-admin-pending-schools">
      <PendingSchoolHeader/>
      {data.map(s =>
        <PendingSchoolRow
          key={s.school.id}
          data={s}
        />
      )}
    </div>
  )
}

function PendingSchoolHeader() {
  return (
    <div className="settings-admin-pending-school-header">
      <span>SCHOOL</span>
      <span>SINCE</span>
    </div>
  )
}

function PendingSchoolRow({data}) {
  return (
    <div className="settings-admin-pending-school">
      <div className="settings-admin-pending-school__school">
        <SchoolIcon school={data.school}/>
        <span>{data.school.name}</span>
      </div>
      <span className="settings-admin-timestamp">{formatTimestamp(data.ts)}</span>
    </div>
  )
}

function ApprovePendingModal({show, onApprove, onHide}) {
  if (!show) return null

  const handleOnHide = () => onHide && onHide()
  const handleOnApprove = () => onApprove && onApprove()

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: handleOnHide
    },
    primary: {
      title: "Approve",
      onClick: handleOnApprove
    }
  }

  return (
    <EntryModal cardClassName="settings-integration-connect-modal-card" show={show} title="Approve Authentications" onHide={onHide} buttons={buttons}>
      <p>Approve pending authorization configs</p>
    </EntryModal>
  )
}

function CancelPendingModal({show, onReject, onHide}) {
  const [current, setCurrent] = useState("")

  useEffect(() => {
    setCurrent("")
  }, [show]);

  if (!show) return null

  const handleOnHide = () => onHide && onHide()
  const handleOnReject = () => onReject && onReject()

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: handleOnHide
    },
    danger: {
      title: "Reject",
      disabled: current !== "Reject",
      onClick: handleOnReject
    }
  }

  return (
    <EntryModal cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body" show={show} title="Reject Authentications" onHide={onHide} buttons={buttons}>
      <p>Reject pending authorization configs</p>
      <div>
        <p>Please type <span>Reject</span> to confirm</p>
        <input autoFocus value={current} onChange={(e) => setCurrent(e.target.value)}/>
      </div>
    </EntryModal>
  )
}
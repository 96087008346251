import {DrawMode, useDataContext} from "../../hooks/context";
import {useEffect} from "react";
import {booleanPointInPolygon, featureCollection as FeatureCollection, point as Point, union as unionFeatures} from "@turf/turf";
import {buildPolygon, MAP_LAYER_OPTIONS} from "./utils";
import {ErrorToast} from "../../../../shared/utils/toast";
import {LayoutResourceType, TabIds} from "../../consts";

export function ClickDraw({map, isMapLoading}) {
  const {selectedResourceType, selectedTab, selectedZone, selectedAp, draw} = useDataContext()

  useEffect(() => {
    if (!map || isMapLoading || selectedTab !== TabIds.LAYOUT || selectedResourceType !== LayoutResourceType.AP || !selectedAp?.id || draw.mode !== DrawMode.CLICK) {
      return
    }

    map.getCanvas().style.cursor = "crosshair"
    return () => map.getCanvas().style.cursor = ""
  }, [map, isMapLoading, draw.enabled, draw.mode, selectedTab, selectedAp?.id, selectedResourceType])

  useEffect(() => {
    if (!map || isMapLoading || draw.mode !== DrawMode.CLICK) {
      return
    }

    const handleOnZoneClick = e => {
      const point = Point([e.lngLat.lng, e.lngLat.lat])
      const features = map.queryRenderedFeatures()

      const buildings = features.filter(feature => MAP_LAYER_OPTIONS.includes(feature.sourceLayer) && booleanPointInPolygon(point, feature))
        .flatMap(feature => buildPolygon(feature.geometry))
        .filter(feature => feature)

      if (!buildings.length) {
        return
      }

      let polygon = buildings[0]
      if (e.originalEvent.shiftKey && draw.hasData) {
        polygon = unionFeatures(FeatureCollection([...draw.geojson.features.filter(feature => feature.geometry?.type === "Polygon"), polygon]))
      }

      if (polygon?.geometry?.type === "MultiPolygon") {
        ErrorToast("Invalid GeoJSON")
        return
      }

      polygon.properties.type = "zone"
      polygon.properties.isCompleted = true

      if (!selectedZone?.name) {
        const buildingLabels = features.filter(feature => feature.sourceLayer === "poi_label" && booleanPointInPolygon(point, feature))
          .map(label => label.properties.name)

        if (buildingLabels.length) {
          polygon.properties.name = buildingLabels[0]
        }
      } else {
        polygon.properties.name = selectedZone.name
      }

      draw.replace([polygon])
    }

    const handleOnApClick = e => {
      draw.replace([Point([e.lngLat.lng, e.lngLat.lat], {type: "ap", isCompleted: true, name: selectedAp?.name || "current"})])
    }

    const handleOnMouseEnter = () => {
      map.getCanvas().style.cursor = "crosshair"
    }

    const handleOnMouseLeave = () => {
      map.getCanvas().style.cursor = ""
    }

    if (selectedTab === TabIds.LAYOUT && selectedResourceType === LayoutResourceType.ZONE) {
      map.on("click", handleOnZoneClick)
      map.on("mouseenter", MAP_LAYER_OPTIONS, handleOnMouseEnter)
      map.on("mouseleave", MAP_LAYER_OPTIONS, handleOnMouseLeave)
    } else if (selectedTab === TabIds.LAYOUT && selectedResourceType === LayoutResourceType.AP) {
      map.on("click", handleOnApClick)
      map.on("mouseenter", handleOnMouseEnter)
      map.on("mouseleave", handleOnMouseLeave)
    }

    return () => {
      if (selectedTab === TabIds.LAYOUT && selectedResourceType === LayoutResourceType.ZONE) {
        map.off("click", handleOnZoneClick)
        map.off("mouseenter", MAP_LAYER_OPTIONS, handleOnMouseEnter)
        map.off("mouseleave", MAP_LAYER_OPTIONS, handleOnMouseLeave)
      } else if (selectedTab === TabIds.LAYOUT && selectedResourceType === LayoutResourceType.AP) {
        map.off("click", handleOnApClick)
        map.off("mouseenter", handleOnMouseEnter)
        map.off("mouseleave", handleOnMouseLeave)
      }
    }
  }, [map, isMapLoading, selectedTab, draw, selectedZone?.name, selectedAp?.name]);

  return null
}

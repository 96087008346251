import {EmptyView} from "../../../../shared/components/views"
import {TableView} from "../../../../shared/components/table"
import React, {useEffect, useReducer, useState} from "react"
import {Button} from "../../../../shared/components/buttons"
import {EntryModal} from "../../../../shared/components/modal";

const DEFAULT_PANE_DATA = {
  isEditing: false,
  showCancelModal: false,
  showConfirmDeployModal: false,
  hasChanges: false,
  editData: {}
}

const SET_EDITING = "SET_EDITING"
const SHOW_CANCEL_MODAL = "SHOW_CANCEL_MODAL"
const SHOW_CONFIRM_DEPLOY_MODAL = "SHOW_CONFIRM_DEPLOY_MODAL"
const CONFIRM_CANCEL = "CONFIRM_CANCEL"
const UPDATE_EDIT_DATA = "UPDATE_EDIT_DATA"
const RESET = "RESET"

const SchoolUrlPaneReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_EDITING:
      return {
        ...state,
        isEditing: action.payload,
        hasChanges: false,
        editData: {}
      }

    case SHOW_CANCEL_MODAL:
      return {
        ...state,
        showCancelModal: action.payload
      }

    case SHOW_CONFIRM_DEPLOY_MODAL:
      return {
        ...state,
        showConfirmDeployModal: action.payload
      }

    case CONFIRM_CANCEL:
      return {
        ...state,
        showCancelModal: false,
        isEditing: false,
        hasChanges: false,
        editData: {}
      }

    case UPDATE_EDIT_DATA:
      return {
        ...state,
        hasChanges: Object.keys(action.payload).length > 0,
        editData: action.payload
      }

    case RESET:
      return DEFAULT_PANE_DATA

    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

export function SchoolUrlPane({show, data, onUpdate, onDeploy}) {
  const [state, dispatch] = useReducer(SchoolUrlPaneReducer, DEFAULT_PANE_DATA)

  const showEdit = () => dispatch({type: SET_EDITING, payload: true})
  const hideEdit = () => dispatch({type: SET_EDITING, payload: false})
  const showCancelModal = () => dispatch({type: SHOW_CANCEL_MODAL, payload: true})
  const hideCancelModal = () => dispatch({type: SHOW_CANCEL_MODAL, payload: false})
  const showConfirmDeployModal = () => dispatch({type: SHOW_CONFIRM_DEPLOY_MODAL, payload: true})
  const hideConfirmDeployModal = () => dispatch({type: SHOW_CONFIRM_DEPLOY_MODAL, payload: false})
  const confirmCancel = () => dispatch({type: CONFIRM_CANCEL})
  const updateChanges = changes => dispatch({type: UPDATE_EDIT_DATA, payload: changes})
  const resetState = () => dispatch({type: RESET})

  useEffect(() => {
    resetState()
  }, [show])

  if (!show) return null

  const hasSchools = !!data && !!data.schools && data.schools.length > 0
  const showingNoData = !hasSchools
  const showingDisplay = hasSchools && !state.isEditing
  const showingEdit = hasSchools && state.isEditing

  const handleOnCancel = () => {
    if (state.hasChanges) {
      showCancelModal()
    } else {
      hideEdit()
    }
  }
  const handleOnSave = () => {
    onUpdate && onUpdate(state.editData, hideEdit)
  }

  const handleOnDeploy = () => {
    onDeploy && onDeploy(hideConfirmDeployModal)
  }

  return (
    <div className="settings-school-urls-body">
      <div className="settings-school-urls-buttons">
        {state.isEditing && <Button onClick={handleOnCancel}>Cancel</Button>}
        {!state.isEditing && <Button variant="secondary" onClick={showConfirmDeployModal}>Deploy Stack</Button>}
        {!state.isEditing && <Button variant="primary" onClick={showEdit}>Edit</Button>}
      </div>
      <div className="settings-school-urls-content">
        <NoDataView show={showingNoData}/>
        <DisplayView show={showingDisplay} data={data}/>
        <EditView
          show={showingEdit}
          data={data}
          editData={state.editData}
          onChange={updateChanges}
        />
        {showingEdit &&
          <div className="settings-school-urls-buttons">
            <Button onClick={handleOnCancel}>Cancel</Button>
            <Button disabled={!state.hasChanges} variant="primary" onClick={handleOnSave}>Save</Button>
          </div>
        }
      </div>
      <ConfirmCancelModal
        show={state.showCancelModal}
        onHide={hideCancelModal}
        onCancel={confirmCancel}
      />
      <ConfirmDeployModal
        show={state.showConfirmDeployModal}
        onHide={hideConfirmDeployModal}
        onConfirm={handleOnDeploy}
      />
    </div>
  )
}

function NoDataView({show}) {
  if (!show) {
    return null
  }

  return <EmptyView>No Schools to display</EmptyView>
}

function DisplayView({show, data}) {
  if (!show) {
    return null
  }

  return (
    <TableView
      classNames={{
        row: "settings-school-urls-row",
      }}
      columns={[
        {label: "School", key: "name"},
        {label: "Short Name", key: "short_name"},
        {label: "Url", key: "url"}
      ]}
      data={data.schools}
      viewInflator={(data, column) => {
        if (column === "short_name" && data.short_name == null) {
          return <span key="short_name">--</span>
        }

        if (column === "url") {
          if (data.url == null) {
            return <span key="url">--</span>
          }

          return <a key={data.id} href={data.url}>{data.url}</a>
        }
      }}
    />
  )
}

function EditView({show, data, editData, onChange}) {
  if (!show) {
    return null
  }

  const initialData = {}
  for (let school of data.schools) {
    initialData[school.id] = school.short_name
  }

  const handleOnChange = (schoolId, newValue) => {
    let out
    if (!Object.keys(editData).includes(schoolId)) {
      out = {
        ...editData,
        [schoolId]: newValue
      }
    } else {
      out = {}
      for (let [key, value] of Object.entries(editData)) {
        if (key === schoolId) {
          if (newValue !== initialData[schoolId]) {
            out[schoolId] = newValue
          }
        } else {
          out[key] = value
        }
      }
    }

    onChange && onChange(out)
  }

  const schoolData = data.schools.map(school => {
    const keys = Object.keys(editData)
    return {
      ...school,
      short_name: keys.includes(school.id) ? editData[school.id] : school.short_name,
    }
  })

  return (
    <TableView
      classNames={{
        row: "settings-school-urls-row",
      }}
      columns={[
        {label: "School", key: "name"},
        {label: "Short Name", key: "short_name"}
      ]}
      data={schoolData}
      viewInflator={(data, column) => {
        if (column === "short_name") {
          return <ShortName
            key={data.id}
            data={data}
            onChange={(newValue) => handleOnChange(data.id, newValue)}
          />
        }
      }}
    />
  )
}

function ShortName({data, onChange}) {
  const [value, setValue] = useState(data.short_name)
  const handleOnChange = e => {
    let value = e.target.value.trim()
    if (!value.length) {
      value = null
    }

    setValue(value)
    onChange && onChange(value)
  }

  return <input value={value || ""} onChange={handleOnChange}/>
}

function ConfirmCancelModal({show, onHide, onCancel}) {
  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    danger: {
      title: "Discard",
      onClick: onCancel
    }
  }

  return (
    <EntryModal show={show} title="Confirm Cancel" onHide={onHide} buttons={buttons}>
      <p>Data will be lost if you cancel</p>
    </EntryModal>
  )
}

function ConfirmDeployModal({show, onHide, onConfirm}) {
  const [current, setCurrent] = useState("")

  useEffect(() => {
    setCurrent("")
  }, [show]);

  const buttons = {
    secondary: {
      title: "Cancel",
      onClick: onHide
    },
    primary: {
      title: "Deploy",
      disabled: current !== "Deploy",
      onClick: onConfirm
    }
  }

  return (
    <EntryModal cardClassName="da-modal-delete-content" bodyClassName="da-modal-delete-body" show={show} title="Confirm Deploy" onHide={onHide} buttons={buttons}>
      <p>Be really sure you want to do this. This will deploy AWS resources</p>
      <div>
        <p>Please type <span>Deploy</span> to confirm</p>
        <input autoFocus value={current} onChange={(e) => setCurrent(e.target.value)}/>
      </div>
    </EntryModal>
  )
}
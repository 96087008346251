import {useCallback} from "react";

export function PillGroup({...props}) {
  const {className, children, ...rest} = props
  const classNames = ["da-pill-group"]
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(" ")} {...rest}>
      {children}
    </div>
  )
}

export function Pill({value, isSelected, onClick, ...props}) {
  const {className} = props

  const classNames = ["da-pill"]
  if (className) classNames.push(className)

  const handleOnClick = useCallback(() => {
    onClick && onClick(value?.value)
  }, [onClick, value])

  return (
    <div className={classNames.join(" ")} aria-selected={!!isSelected} onClick={handleOnClick}>
      <span>{value.label}</span>
    </div>
  )
}
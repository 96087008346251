import {DrawMode, useDataContext} from "../../hooks/context";
import {useEffect} from "react";
import {point as Point} from "@turf/turf";
import {getPoint, MapKeys} from "./utils";


const pruneCoords = (invalidCoords) => {
  const comparePoint = points => {
    return points.filter(point => !(point[0] === invalidCoords[0] && point[1] === invalidCoords[1]))
  }

  return (feature) => {
    return {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: feature.geometry.coordinates.map(comparePoint)
      }
    }
  }
}

export function AnchorDraw({map, isMapLoading}) {
  const {draw} = useDataContext()

  useEffect(() => {
    if (!map || isMapLoading || draw.mode !== DrawMode.ANCHOR) {
      return
    }

    if (draw.enabled) {
      map.boxZoom.disable()
    } else {
      map.boxZoom.enable()
    }
  }, [map, isMapLoading, draw.enabled, draw.mode]);

  useEffect(() => {
    if (!map || isMapLoading || draw.mode !== DrawMode.ANCHOR) {
      return
    }

    const handleOnClick = e => {
      if (!draw.hasData) {
        return
      }

      const existingPoint = getPoint(draw, Point([e.lngLat.lng, e.lngLat.lat]))
      if (existingPoint?.result?.point?.geometry?.coordinates && e.originalEvent.altKey) {
        // delete point
        draw.replace(draw.geojson.features.map(pruneCoords(existingPoint.result.point.geometry.coordinates)))
        return
      }

      if (!existingPoint && e.originalEvent.shiftKey) {
        // add point
        debugger
      }
    }

    const handleOnMouseEnter = () => {
      map.getCanvas().style.cursor = "crosshair"
    }

    const handleOnMouseLeave = () => {
      map.getCanvas().style.cursor = ""
    }

    map.on("click", MapKeys.DRAW_ZONE_POLYGON_LAYER, handleOnClick)
    map.on("mouseenter", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnMouseEnter)
    map.on("mouseleave", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnMouseLeave)

    // map.on("dragstart", (e) => {
    //   // debugger
    // })
    //
    // map.on("dragend", (e) => {
    //   // debugger
    // })

    return () => {
      map.off("click", MapKeys.DRAW_ZONE_POLYGON_LAYER, handleOnClick)
      map.off("mouseenter", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnMouseEnter)
      map.off("mouseleave", MapKeys.DRAW_ZONE_POINT_LAYER, handleOnMouseLeave)
    }
  }, [map, isMapLoading, draw]);

  return null
}

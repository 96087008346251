import {useCallback, useEffect, useReducer, useRef, useState} from "react"
import {TabHeader, Tabs} from "../../shared/components/tabs"
import {ElementLoader} from "../../shared/components/element_loader"
import {useMap} from "../../shared/hooks/map"
import Indicator from "../../shared/components/loader"
import {MapOptions} from "./options"
import {SplitLayout} from "../../shared/components/split_layout"
import {ZoneListView} from "./zones/list"
import {ZoneDetailView} from "./zones/detail"
import {TabOptions, ZoneType} from "./consts"
import {DataContext, DrawMode, useDataContext, useDataProvider} from "./hooks/context"

import {Button} from "../../shared/components/buttons"
import {ReactComponent as PenDrawIcon} from "../../shared/assets/icons/map/draw/pen_draw.svg"
import {ReactComponent as ClickDrawIcon} from "../../shared/assets/icons/map/draw/click_draw.svg"
import {ReactComponent as AnchorDrawIcon} from "../../shared/assets/icons/map/draw/anchor_draw.svg"
import {ReactComponent as DeleteDrawIcon} from "../../shared/assets/icons/button/delete_icon_large.svg"
import {PenDraw} from "./map/drawHandlers/pen"
import {ClickDraw} from "./map/drawHandlers/click"
import {MapSource} from "./map/drawHandlers/source"
import {DefaultMapBehavior} from "./map/drawHandlers/default"
import {PolygonInfo} from "./map/drawHandlers/polygon_info"
import {CenterMapButton, centerMapOnFeatures, getMapFeatures, MapControlButtonGroup, MapIconControl} from "./map/shared"
import {Hotkeys} from "./hotkeys"
import {AnchorDraw} from "./map/drawHandlers/anchor"
import {AccessPointDetailView} from "./aps/detail"

const DEFAULT_WIRELESS_CONFIG_DATA = {
  options: {
    zones: true,
    aps: true,
    floor_plan: false
  },
}

const TOGGLE_OPTION = "TOGGLE_OPTION"

const WirelessConfigReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_OPTION:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload]: !state.options[action.payload]
        }
      }

    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

export default function WirelessConfigView({school, reloadState, onReloaded}) {
  const schoolId = school ? school.id : null
  const provider = useDataProvider({schoolId})
  return (
    <DataContext.Provider value={provider}>
      <View school={school} reloadState={reloadState} onReloaded={onReloaded}/>
    </DataContext.Provider>
  )
}

function View({school, reloadState, onReloaded}) {
  const [state, dispatch] = useReducer(WirelessConfigReducer, DEFAULT_WIRELESS_CONFIG_DATA)
  const {hasDetailResource, isHidingLeftPane, toggleLeftPane, isHidingRightPane, toggleRightPane, cycleDatasource, showResource} = useDataContext()

  const toggleOption = useCallback(option => dispatch({type: TOGGLE_OPTION, payload: option}), [])
  const [map, setMap] = useState(null)
  const handleOnMapChange = useCallback((map) => setMap(map), [])

  return (
    <Hotkeys map={map} toggleMapOption={toggleOption}>
      <ElementLoader elementId="da-navbar-tabs">
        <Tabs className="wireless-config-tab-layout">
          <WirelessConfigTabHeader/>
        </Tabs>
      </ElementLoader>

      <ElementLoader elementId="map-content">
        <Map
          school={school}
          showZones={state.options.zones}
          showAps={state.options.aps}
          onMapChange={handleOnMapChange}
        />
      </ElementLoader>

      <SplitLayout
        hasDetailResource={hasDetailResource}
        isHidingLeftPane={isHidingLeftPane}
        toggleLeftPane={toggleLeftPane}
        isHidingRightPane={isHidingRightPane}
        toggleRightPane={toggleRightPane}
        cycleDatasource={cycleDatasource}
        onOpenResource={showResource}
      >
        <>
          <ZoneListView/>
          {/*<AccessPointsListView reloadState={reloadState} onReloaded={onReloaded}/>*/}
        </>
        <div className="map-overlay-center-layout">
          <div>
            <MapOptions map={map} state={state.options} onChange={toggleOption}/>
            <DrawMapControlGroup/>
            <MapControlGroup map={map}/>
            <PolygonInfo/>
          </div>
          <EditBanner/>
        </div>
        <>
          <ZoneDetailView/>
          <AccessPointDetailView/>
        </>
      </SplitLayout>
      <WirelessConfigIndicator/>
    </Hotkeys>
  )
}

function WirelessConfigTabHeader() {
  const {selectedTab, setTab} = useDataContext()

  return (
    <TabHeader selectedTab={selectedTab} items={TabOptions} onSelectTab={setTab}/>
  )
}

function WirelessConfigIndicator() {
  const {loading} = useDataContext()

  return (
    <Indicator show={loading}/>
  )
}

function DrawMapControlGroup() {
  const {selectedZone, isCreatingZone, selectedAp, isCreatingAp, draw} = useDataContext()

  const show = selectedZone != null || isCreatingZone || selectedAp != null || isCreatingAp

  useEffect(() => {
    if (!show && draw.enabled) {
      draw.setMode(null)
    }
  }, [show, draw]);

  const onPenClick = useCallback(() => {
    draw.setMode(draw.mode !== DrawMode.PEN ? DrawMode.PEN : null)
  }, [draw])

  const onClickClick = useCallback(() => {
    draw.setMode(draw.mode !== DrawMode.CLICK ? DrawMode.CLICK : null)
  }, [draw])

  const onAnchorClick = useCallback(() => {
    draw.setMode(draw.mode !== DrawMode.ANCHOR ? DrawMode.ANCHOR : null)
  }, [draw])

  const onDeleteClick = useCallback(() => {
    draw.clear()
  }, [draw])

  if (!show || selectedZone?.type === ZoneType.CAMPUS) {
    return null
  }

  return (
    <div className="map-overlay-draw-buttons">
      <MapControlButtonGroup>
        <MapIconControl show={selectedZone != null || isCreatingZone} selected={draw.mode === DrawMode.PEN} onClick={onPenClick}>
          <PenDrawIcon/>
        </MapIconControl>
        <MapIconControl selected={draw.mode === DrawMode.CLICK} onClick={onClickClick}>
          <ClickDrawIcon/>
        </MapIconControl>
        <MapIconControl show={selectedZone != null || isCreatingZone} selected={draw.mode === DrawMode.ANCHOR} onClick={onAnchorClick}>
          <AnchorDrawIcon/>
        </MapIconControl>
      </MapControlButtonGroup>
      <MapControlButtonGroup>
        <MapIconControl show={draw.hasAny} variant="danger" onClick={onDeleteClick}>
          <DeleteDrawIcon/>
        </MapIconControl>
      </MapControlButtonGroup>
    </div>
  )
}

function MapControlGroup({map}) {
  const {selectedResource, selectedZone} = useDataContext()

  if (!selectedResource?.id || selectedZone?.type === ZoneType.CAMPUS) {
    return
  }

  return (
    <div className="map-overlay-control-buttons">
      <MapControlButtonGroup>
        <CenterMapButton map={map}/>
      </MapControlButtonGroup>
    </div>
  )
}

function EditBanner() {
  const [keyEnabled, setKeyEnabled] = useState(true)
  const {schoolId, isCreatingResource, selectedResource, draw, updateGeoJSON} = useDataContext()

  const show = !(!schoolId || isCreatingResource || !selectedResource?.name || !draw.enabled || !draw.hasData || !updateGeoJSON)

  useEffect(() => {
    if (!show) {
      return
    }

    const setEnabled = () => setKeyEnabled(true)
    const setDisabled = () => setKeyEnabled(false)

    const root = document.getElementById("root")
    root.addEventListener("focusin", setDisabled)
    root.addEventListener("focusout", setEnabled)

    return () => {
      root.removeEventListener("focusin", setDisabled)
      root.removeEventListener("focusout", setEnabled)
    }
  }, [show]);

  useEffect(() => {
    if (!show || !keyEnabled) {
      return
    }

    window.addEventListener("keyup", updateGeoJSON)
    return () => {
      window.removeEventListener("keyup", updateGeoJSON)
    }
  })

  if (!show) {
    return null
  }

  const handleOnCancel = () => {
    draw.setMode(null)
  }

  return (
    <div className="map-overlay-edit-banner">
      <span>{`Editing ${selectedResource.name}`}</span>
      <div>
        <Button variant="secondary" onClick={handleOnCancel}>Cancel</Button>
        <Button disabled={!draw.hasData} variant="primary" onClick={updateGeoJSON}>Save</Button>
      </div>
    </div>
  )
}

function Map({school, showZones, showAps, onMapChange}) {
  const ref = useRef()
  const {map, isLoading, setLocation, setZoom, setContainer} = useMap(school?.location)
  const {isCreatingZone, draw, geojson, selectedZone, isCreatingAp, selectedAp} = useDataContext()
  const [mapCenterId, setMapCenterId] = useState(null)

  let longitude = null, latitude = null, zoom = null
  if (school?.location != null) {
    longitude = school.location.longitude
    latitude = school.location.latitude
    zoom = school.location.zoom
  }

  useEffect(() => {
    setContainer(ref)
  }, [setContainer])

  useEffect(() => {
    onMapChange && onMapChange(map)
  }, [onMapChange, map]);

  useEffect(() => {
    if (!map || isLoading) {
      return
    }

    map.boxZoom.disable()
    map.keyboard.disable()
  }, [map, isLoading]);

  useEffect(() => {
    if (isLoading || (longitude == null || latitude == null || zoom == null)) {
      return
    }

    setLocation(longitude, latitude)
    setZoom(zoom)
  }, [isLoading, longitude, latitude, zoom, setLocation, setZoom])

  useEffect(() => {
    if (!map || isCreatingZone || !selectedZone?.id || !selectedZone?.has_geojson || selectedZone?.id === mapCenterId) {
      return
    }

    const {center} = getMapFeatures(map, draw?.geojson, geojson, selectedZone)
    if (!center) {
      return
    }

    centerMapOnFeatures(map, center)
    setMapCenterId(selectedZone?.id)
  }, [map, isCreatingZone, draw?.geojson, geojson, selectedZone, mapCenterId]);

  useEffect(() => {
    if (!map || isCreatingAp || !selectedAp?.id || !selectedAp?.has_geojson || selectedAp?.id === mapCenterId) {
      return
    }

    const {center} = getMapFeatures(map, draw?.geojson, geojson, selectedAp)
    if (!center) {
      return
    }

    centerMapOnFeatures(map, center)
    setMapCenterId(selectedAp?.id)
  }, [map, isCreatingZone, draw?.geojson, geojson, selectedZone, mapCenterId, isCreatingAp, selectedAp]);

  return (
    <div className="map-container" ref={ref}>
      <MapSource map={map} isMapLoading={isLoading} showZones={showZones} showAps={showAps}/>
      <DefaultMapBehavior map={map} isMapLoading={isLoading}/>
      <PenDraw map={map} isMapLoading={isLoading}/>
      <ClickDraw map={map} isMapLoading={isLoading}/>
      <AnchorDraw map={map} isMapLoading={isLoading}/>
    </div>
  )
}


